/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Dropdown, Menu } from 'antd'
import styled from 'styled-components'
import { colours } from '@qflow/theme'

import { ChevronDown, Tick } from 'portal/lib/primitives/icons'

export function HeadingWithDropdown({
  current,
  dropdownOptions,
  onMenuItemClick
}) {
  const handleMenuClick = (key: string | null) => {
    if (key && onMenuItemClick) {
      onMenuItemClick(key)
    }
  }

  const menu = (
    <Menu>
      {dropdownOptions.map((option: { key: string | null; label: string }) => (
        <StyledMenuItem
          key={option.key}
          {...(!option.key && { disabled: true })}
          onClick={() => handleMenuClick(option.key)}
        >
          <div>{option.label}</div> {option.label === current && <StyledTick />}
        </StyledMenuItem>
      ))}
    </Menu>
  )

  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <InteractiveColumnHeading onClick={e => e.preventDefault()}>
        <span>{current}</span> <ChevronDown />
      </InteractiveColumnHeading>
    </Dropdown>
  )
}

const InteractiveColumnHeading = styled.a`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledMenuItem = styled(Menu.Item)`
  width: 100%;

  &:hover {
    color: ${colours.ORANGE};
  }

  &.ant-dropdown-menu-item-disabled {
    color: ${colours.BATTLESHIP_GREY};
    cursor: default;
  }

  > .ant-dropdown-menu-title-content {
    display: flex;
    justify-content: space-between;
    min-width: 112px;
  }
`

const StyledTick = styled(Tick)`
    color: ${colours.ORANGE};
    margin-top: 2px;
 }
`
