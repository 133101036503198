import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import querystring from 'query-string'

import { TextLink } from 'portal/lib/primitives'
import { useModuleIds } from 'portal/lib/hooks'

import { useMixpanelEvents } from './mixpanel'

export function NavigateButton({ item }) {
  const { accountId } = useModuleIds()

  const { trackEventClick } = useMixpanelEvents()
  const onClick = useCallback(() => {
    trackEventClick(item.sourceId, item.sourceType)
  }, [item, trackEventClick])

  return (
    <TextLink
      onClick={onClick}
      to={{
        pathname: item.destinationAddress,
        hash: querystring.stringify({ accountId })
      }}
    >
      {item.destinationDisplayName || 'View'}
    </TextLink>
  )
}

NavigateButton.propTypes = {
  item: PropTypes.shape({
    destinationAddress: PropTypes.string.isRequired,
    destinationDisplayName: PropTypes.string,
    sourceId: PropTypes.string.isRequired,
    sourceType: PropTypes.string.isRequired
  }).isRequired
}
