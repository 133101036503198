import styled from 'styled-components'
import { colours, shadows } from '@qflow/theme'

export const Container = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  left: 0;
  right: 0;

  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  top: ${({ $visible }) => ($visible ? 1 : 0)}rem;
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  transition: 300ms all ease-in-out;
  transition-property: opacity, top;
`
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: start;
  align-items: center;
  border-radius: 6px;
  padding: 10px;

  background-color: ${colours.WHITE};
  z-index: 10;

  ${shadows.MID}
`
export const ActionStripCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
  width: 100%;
  min-width: 500px;

  & button:focus {
    outline: 2px solid ${colours.BLACK};
  }

  & button.selected {
    color: #fff;
    background-color: ${colours.DEEP_SKY_BLUE};
  }
`

export const AssignmentButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`
