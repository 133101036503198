export const USER_EDIT_START = 'USER_EDIT_START'
export const userEditStart = userDetails => ({
  type: USER_EDIT_START,
  userDetails
})

export const USER_UPDATE_START = 'USER_UPDATE_START'
export const userUpdateStart = (
  accountId,
  userId,
  projectPermissions,
  notificationsEnabled,
  projectSettings
) => ({
  type: USER_UPDATE_START,
  accountId,
  userId,
  projectPermissions,
  notificationsEnabled,
  projectSettings
})

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const userUpdateSuccess = (
  accountId,
  userId,
  memberships,
  userProfile
) => ({
  type: USER_UPDATE_SUCCESS,
  accountId,
  userId,
  memberships,
  userProfile
})

export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const userUpdateFail = (accountId, userId, error) => ({
  type: USER_UPDATE_FAIL,
  accountId,
  userId,
  error
})
