import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as ButtonBase from 'portal/lib/primitives/buttons/ButtonBase'

import { ChevronDown } from 'portal/lib/primitives/icons'
import { Spacer } from 'portal/lib/primitives/layout'
import { colours, sizes } from '@qflow/theme'
import { useResponsive } from 'portal/lib/hooks'

export function NavButton({
  disabled,
  onClick,
  children,
  isOpen,
  RightIcon = ChevronDown,
  ...props
}) {
  const { isMobile } = useResponsive()

  return (
    <NavButtonComponent {...props} onClick={onClick} disabled={disabled}>
      {children}

      {!disabled && RightIcon && (isMobile ? <Spacer.Fine /> : <Spacer.Tiny />)}
      {!disabled && RightIcon && <RightIcon rotate={isOpen ? 180 : 0} />}
    </NavButtonComponent>
  )
}

NavButton.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  RightIcon: PropTypes.elementType
}

const NavButtonComponent = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getShapeCSS({
    getHeight: '100%',
    getFontSize: sizes.MEDIUM
  })}
  
  justify-content: flex-start;
  border-radius: 0;

  min-width: 4rem;
  max-width: 20rem;

  padding: 0 1rem;

  color: ${colours.CORE_NAVY};
  background-color: transparent;

  :hover,
  :focus,
  :active {
    color: ${colours.TEAL};
  }

  :disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.primary.disabled};
  }

  & .ant-typography {
    color: inherit;
  }
`
