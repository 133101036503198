import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function ChevronRight({
  size = sizes.LARGER,
  colour = 'currentColor',
  rotate = 0,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="11"
      viewBox="0 0 8 11"
      style={{
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
        transform: `rotate(${rotate}deg)`,
        transition: 'transform 0.1s ease-in-out'
      }}
    >
      <path
        fill={colour}
        fillRule="nonzero"
        d="M0.912109 8.9124L4.72878 5.0874L0.912109 1.2624L2.08711 0.0874023L7.08711 5.0874L2.08711 10.0874L0.912109 8.9124Z"
        {...props}
      />
    </svg>
  )
}

ChevronRight.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string,
  rotate: PropTypes.number
}
