import { trackMixpanel } from 'portal/store/mixpanel/actions'

const EV_RECORD_REMOVED = 'frontend_record_removed'

export function trackRecordRemoved(recordId, recordType, accountId, projectId) {
  return trackMixpanel(EV_RECORD_REMOVED, {
    record_id: recordId,
    record_type: recordType,
    project_id: projectId,
    account_id: accountId
  })
}
