import { takeLatest, all, put, call } from 'redux-saga/effects'
import * as actions from './actions'
import axios from 'portal/utils/axios-msal'

function* accountProjectsGetSaga({ accountId }) {
  if (accountId === '' || accountId === null) {
    yield put(actions.accountProjectsGetFail('No Account Id was set'))
  }
  try {
    const response = yield call(
      axios.get,
      '/accounts/' + accountId + '/projects/all'
    )
    yield put(actions.accountProjectsGetSuccess(response.data))
  } catch (error) {
    yield put(actions.accountProjectsGetFail(error))
  }
}

export function* watch() {
  yield all([takeLatest(actions.ACCOUNT_PROJS_GET, accountProjectsGetSaga)])
}
