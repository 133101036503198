import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'

import { Flex, Text, icons, ColourButton, Spacer } from 'portal/lib/primitives'
import { useModuleIds, useUserProjectRole } from 'portal/lib/hooks'
import { sizes } from '@qflow/theme'

import { DELIVERY_EVENTS, WASTE_EVENTS } from 'portal/routes'
import { datePickerChanged } from 'portal/store/global/actions'
import { selectGlobalDateISO } from 'portal/store/global/selectors'
import { queueExport } from 'portal/store/records/exports/actions'
import { oneClickEnabledProjects } from './OneClickHelpers'
import { OneClickExport } from './OneClickExport'
export function RecordsExport({
  title,
  startDate,
  endDate,
  filters,
  trackDataExported,
  useCreatedAtDate
}) {
  const { working, error } = useSelector(state => state.records.exports)
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  // Temp Solution
  // Hard-coded ProjectIds for Projects with OneClick Enabled
  const CheckIfOneClickIsEnabled = projectId => {
    if (!projectId || moduleName !== 'delivery') {
      return false
    }

    return oneClickEnabledProjects.includes(projectId)
  }

  const oneClickEnabled = CheckIfOneClickIsEnabled(projectId)

  const selectGlobalDateFunc = selectGlobalDateISO()
  const globalValue = useSelector(selectGlobalDateFunc)
  const history = useHistory()

  const updateDateRange = useCallback(() => {
    // Set DateRange endDate to EndOfToday before redirecting so Report will show on Events Page.
    const newDateRange = { ...globalValue }
    let endOfToday = new Date().setHours(23, 59, 59, 999)
    endOfToday = new Date(endOfToday).toISOString()

    newDateRange.endDate = endOfToday
    dispatch(datePickerChanged(newDateRange, 'global'))
  }, [dispatch, globalValue])

  const onSuccess = useCallback(() => {
    updateDateRange()

    if (moduleName.toLowerCase() == 'delivery')
      history.push(
        generatePath(DELIVERY_EVENTS, {
          accountId: accountId,
          projectId: projectId,
          moduleName,
          tab: 'events'
        })
      )
    if (moduleName.toLowerCase() == 'waste')
      history.push(
        generatePath(WASTE_EVENTS, {
          accountId: accountId,
          projectId: projectId,
          moduleName,
          tab: 'events'
        })
      )
  }, [accountId, history, moduleName, projectId, updateDateRange])

  const exportRecords = useCallback(() => {
    dispatch(
      queueExport(
        accountId,
        projectId,
        moduleName,
        title,
        {
          startDate: startDate,
          endDate: endDate
        },
        filters,
        useCreatedAtDate,
        { onSuccess }
      )
    )

    trackDataExported()
  }, [
    dispatch,
    accountId,
    projectId,
    moduleName,
    title,
    startDate,
    endDate,
    filters,
    useCreatedAtDate,
    onSuccess,
    trackDataExported
  ])

  const { isReadOnly } = useUserProjectRole(accountId, projectId)
  if (isReadOnly) {
    // Readers should not be able to export records
    return null
  }

  return (
    <ButtonContainer>
      {oneClickEnabled && (
        <OneClickExport filters={filters} onSuccess={onSuccess} />
      )}
      <ExportButton
        id="ExportButton"
        onClick={exportRecords}
        disabled={working}
      >
        {!working && <icons.Download size={sizes.SMALL} />}
        {working && <icons.Loading size={sizes.SMALL} />}
        <Spacer.Fine />
        Export CSV
      </ExportButton>
      {error && (
        <Text color="error">An issue has occured while generating export</Text>
      )}
    </ButtonContainer>
  )
}

const ButtonContainer = styled(Flex.Col)`
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`
const ExportButton = styled(ColourButton.Action)`
  width: 7rem;
`

RecordsExport.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  trackDataExported: PropTypes.func
}
