import React from 'react'
import { useRouteMatch, generatePath } from 'react-router'

import { DELIVERY_RECORD_DETAILS } from 'portal/routes'

import { PageContainer } from 'portal/pages/PageContainer'
import { LineItemEdit } from 'portal/components/RecordsModules'

export const DeliveryLineItemEdit = () => {
  const doneUri = useDoneRedirect()

  return (
    <PageContainer limitWidth>
      <LineItemEdit doneUri={doneUri} />
    </PageContainer>
  )
}

function useDoneRedirect() {
  const { params } = useRouteMatch()

  return generatePath(DELIVERY_RECORD_DETAILS, params)
}
