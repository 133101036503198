import { Select } from 'antd'
import { SmallSpinner } from 'portal/lib/primitives'
import PropTypes from 'prop-types'
import _ from 'lodash'
const { Option } = Select

function dropdownRender(menu, name, state, projectId) {
  if (_.get(state.options, [projectId, name, 'loading'], true)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <SmallSpinner />
      </div>
    )
  }
  if (state.options[projectId][name].error !== null) {
    return <div> An error occured while loading options.</div>
  }
  return menu
}

export function RecordsSelect(
  name,
  state,
  fetchOptions,
  filters,
  updateFilter,
  projectId,
  tableId
) {
  return (
    <Select
      className="records-filter-select"
      autoFocus
      autoClearSearchValue={false}
      maxTagCount="responsive"
      dropdownMatchSelectWidth={false}
      mode="multiple"
      showSearch
      allowClear
      style={{ width: '100%' }}
      optionFilterProp="children"
      dropdownRender={menu => dropdownRender(menu, name, state, projectId)}
      filterOption={(input, option) =>
        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      data-testid={`${name}Filter`}
      onDropdownVisibleChange={isVisible => {
        if (
          isVisible &&
          _.get(state.options, [projectId, name]) === undefined
        ) {
          fetchOptions(name)
        }
      }}
      getPopupContainer={() => document.getElementById(tableId)}
      value={filters[name]}
      onChange={value => {
        updateFilter(name, value)
      }}
    >
      {Array(..._.get(state.options, [projectId, name, 'options'], []))
        .filter(option => option && /\S/.test(option))
        .sort((a, b) =>
          a.label
            ? a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
            : a.localeCompare(b, 'en', { sensitivity: 'base' })
        )
        // Options can be an array of strings or objects with 'key' & 'label' properties
        .map(option => (
          <Option key={option.key || option}>{option.label || option}</Option>
        ))}
    </Select>
  )
}

RecordsSelect.propTypes = {
  name: PropTypes.string,
  state: PropTypes.object,
  fetchOptions: PropTypes.func,
  filters: PropTypes.object,
  updateFilter: PropTypes.func
}
