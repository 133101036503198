import React from 'react'
import PropTypes from 'prop-types'

import { AttachmentsPanel, AttachmentsItem } from './AttachmentsPanel'
import { useModuleIds } from 'portal/lib/hooks'

export function AttachmentsSection({ record, attachments = [] }) {
  const { moduleName } = useModuleIds()

  return (
    <AttachmentsPanel>
      {attachments
        .filter(attachment => !!attachment.readUri)
        .map((attachment, i) => (
          <AttachmentsItem
            key={'downloadRow' + (i + 1)}
            attachment={attachment}
            label={i + 1}
            moduleType={moduleName}
            record={record}
          />
        ))}
    </AttachmentsPanel>
  )
}

AttachmentsSection.propTypes = {
  record: AttachmentsItem.propTypes.record,
  attachments: PropTypes.arrayOf(AttachmentsItem.propTypes.attachment)
    .isRequired
}
