import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import * as Modules from 'portal/lib/constants/moduleConstants'
import { NavDropdown, NavDropdownOption, icons } from 'portal/lib/primitives'
import { useModuleIds, useResponsive } from 'portal/lib/hooks'
import { topLevelModules } from 'portal/store/projectDetails/selectors'
import * as ROUTES from 'portal/routes'
import { projectGetDetailsSaga } from 'portal/store/projectDetails/actions'
import { trackSwitcherSelect } from './trackSwitcherSelect'
import { trackSwitcherOpen } from './trackSwitcherOpen'
import { Divider } from 'portal/components/Navigation/Main/Toolbar/Divider'

const SupportedModules = {
  [Modules.WASTE.toLowerCase()]: {
    icon: icons.Modules.Waste,
    label: 'Waste Monitoring',
    module: 'waste'
  },
  [Modules.DELIVERY.toLowerCase()]: {
    icon: icons.Modules.Delivery,
    label: 'Product Deliveries',
    module: 'delivery'
  }
}

export function ModuleSwitcher(props) {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName = '' } = useModuleIds()
  const [open, setOpen] = useState(false)
  const { isMobile } = useResponsive()

  const modules = useSelector(topLevelModules)

  useEffect(() => {
    if (accountId && projectId) {
      dispatch(projectGetDetailsSaga(accountId, projectId))
    }
  }, [accountId, dispatch, projectId])

  const { pathname } = useLocation()

  const items = useMemo(() => {
    const append = pathname.endsWith('/events') ? '/events' : ''

    return modules
      .map(m => [m.name.toLowerCase(), m])
      .filter(([key, m]) => m.isTagged && key in SupportedModules)
      .map(([key, m]) => ({
        key,
        label: SupportedModules[key].label,
        module: SupportedModules[key].module,
        enabled: m.isTagged,
        icon: SupportedModules[key].icon,
        uri:
          accountId &&
          projectId &&
          `${generatePath(ROUTES.BASE_MODULE_NAV, {
            accountId,
            projectId,
            moduleName: key
          })}${append}`
      }))
  }, [accountId, modules, pathname, projectId])

  const [selectedModuleKey, selectedItem] = useMemo(() => {
    const selectedItem =
      items.find(item => item.key === moduleName.toLowerCase()) || {}

    const selectedModuleKey = selectedItem.key

    return [selectedModuleKey, selectedItem]
  }, [items, moduleName])

  const handleSetOpen = useCallback(
    nextOpen => {
      setOpen(nextOpen)

      if (nextOpen && !open) {
        dispatch(trackSwitcherOpen(selectedModuleKey))
      }
    },
    [dispatch, open, selectedModuleKey]
  )

  const handleClick = useCallback(
    item => {
      setOpen(false)
      setTimeout(() => {
        dispatch(
          trackSwitcherSelect(selectedModuleKey, item.key, accountId, projectId)
        )
      }, 0)
    },
    [accountId, dispatch, projectId, selectedModuleKey]
  )

  const loaded = !!projectId && !!selectedModuleKey

  if (!moduleName) {
    return <Container {...props} />
  }

  return (
    <Container {...props} data-testid="module-switcher">
      <Divider />

      <NavDropdown
        id="ModuleNavDropdown"
        open={open}
        setOpen={handleSetOpen}
        Icon={selectedItem.icon}
        label={isMobile ? 'Module' : selectedItem.label}
        title={selectedItem.label}
        disabled={items.length <= 1}
        loading={!loaded}
      >
        {items.map(
          item =>
            item.key != selectedModuleKey && (
              <NavDropdownOption
                id={`NavigateTo_${item.label}`}
                data-testid={`NavigateTo_${item.module}`}
                key={item.key}
                Icon={item.icon}
                label={item.label}
                uri={item.uri}
                onClick={handleClick}
                onClickPassbackValue={item}
              />
            )
        )}
      </NavDropdown>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
`
