import styled from 'styled-components'
import { colours } from '@qflow/theme'

export const BodyContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: 2rem;
  background-color: ${colours.WHITE};
  border: solid 1px ${colours.alpha(colours.BLUEY_GREY, 0.5)};
  border-radius: 3px;
`

export const BodyInner = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 50rem;
`
