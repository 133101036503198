import styled, { css } from 'styled-components'
import { sizes } from '@qflow/theme'

export const Label = styled.div`
  color: inherit;

  font-size: ${({ size }) => size || sizes.SMALL_MEDIUM};
  transform: ${({ scale }) => `scale(${scale || 1})`};
  transform-origin: left;

  font-weight: normal;
  line-height: normal;
  text-decoration: ${({ strike }) => strike && 'line-through'};

  margin-bottom: 0.5rem;
  margin-top: 1rem;

  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'normal')};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    `}

  ${({ isChild }) =>
    isChild &&
    css`
      margin: 0;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  transition: all 0.1s ease-in-out;
  transition-property: fill, transform;

  pointer-events: none;
`
