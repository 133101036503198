import { takeEvery, put, call, select } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import { BIREPORT_LOAD, biReportLoaded, biReportLoadFailure } from './actions'
import { biReportByAccountAndName } from './selectors'

function getURI(accountId, reporTitle) {
  return `bireports/accounts/${accountId}/reports/${reporTitle}`
}

function* loadBIReportSaga(action) {
  const { accountId, reportType } = action

  const biReport = yield select(biReportByAccountAndName(accountId, reportType))
  if (biReport.value) {
    yield put(biReportLoaded(accountId, reportType, biReport))
    return
  }

  try {
    const response = yield call(() =>
      axios.get(getURI(accountId, reportType), {
        validateStatus: status =>
          status === 200 || status === 401 || status === 204
      })
    )
    let status = response.status > 400 ? 'inaccessible' : 'success'
    status = response.status === 204 ? 'unavailable' : status
    yield put(biReportLoaded(accountId, reportType, response.data, status))
  } catch (error) {
    console.warn('Failed to load biReport:', error)
    yield put(biReportLoadFailure(accountId, reportType, error))
  }
}

export function* watch() {
  yield takeEvery(BIREPORT_LOAD, loadBIReportSaga)
}
