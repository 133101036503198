import { PageContainer } from 'portal/pages/PageContainer'
import { Heading, Text } from 'portal/lib/primitives'
import { sizes, useTheme } from '@qflow/theme'
import styled from 'styled-components'
import { LOGIN_FAILED_ERRORS } from 'portal/lib/constants/loginFailedErrors'
import { useLocation } from 'react-router'
import { WarningOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

function getErrorKey(errorMessage: string) {
  return Object.keys(LOGIN_FAILED_ERRORS).find(key =>
    errorMessage.includes(LOGIN_FAILED_ERRORS[key])
  )
}

function getFailedLoginCopyFromSearchParams(errorMessage: string | null) {
  const errorKey = errorMessage ? getErrorKey(errorMessage) : ''
  const ConfiguredText = ({ children }) => (
    <Text as={'p'} size={sizes.MEDIUM}>
      {children}
    </Text>
  )
  switch (errorKey) {
    case 'USER_DOESNT_EXIST_IN_QFLOW':
      return [
        <ConfiguredText>
          We haven't been able to verify your login details.
        </ConfiguredText>,
        <ConfiguredText>
          Please contact your company administrator and ask them to provide us
          with your details so you can log in.
        </ConfiguredText>,
        <ConfiguredText>
          Contact us at{' '}
          <a href="mailto:support@qualisflow.com">support@qualisflow.com</a> if
          you have any further issues.
        </ConfiguredText>
      ]
    case 'INVALID_SSO_IDP_URL':
    case 'INVALID_SSO_IDP_INVALID_CLIENT':
    case 'NON_EXISTENT_SSO_IDP':
      return [
        <ConfiguredText>
          We're having trouble communicating with your SSO provider.
        </ConfiguredText>,
        <ConfiguredText>
          Please contact your company adminstrator and ask them to provide us
          with your SSO provider details so you can log in.
        </ConfiguredText>,
        <ConfiguredText>
          Contact us at{' '}
          <a href="mailto:support@qualisflow.com">support@qualisflow.com</a> if
          you have any further issues.
        </ConfiguredText>
      ]
    default:
      return [
        <ConfiguredText>Something went wrong with your login.</ConfiguredText>,
        <ConfiguredText>
          Please contact us at{' '}
          <a href="mailto:support@qualisflow.com">support@qualisflow.com</a> to
          attempt to rectify the issue.
        </ConfiguredText>
      ]
  }
}

export function LoginFailedPage() {
  const checked = useSelector<any>(state => state.auth.checked)
  const theme = useTheme()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const message = getFailedLoginCopyFromSearchParams(
    searchParams.get('errorMessage')
  )

  if (!checked) {
    return null
  }

  return (
    <PageContainer limitWidth>
      <StyledInnerContainer>
        <WarningOutlined
          style={{ fontSize: '5em', color: theme.primary.error }}
        />
        <Heading as={'h1'}>Login Failed</Heading>
        {message.map((part, index) => (
          <span key={index}>{part}</span>
        ))}
      </StyledInnerContainer>
    </PageContainer>
  )
}

const StyledInnerContainer = styled.div`
  max-width: 60ch;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 3em;
  text-align: center;

  & h1 {
    margin-bottom: 1em;
  }
`
