import React, { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { sizes } from '@qflow/theme'
import { Spacer } from 'portal/lib/primitives/layout'
import * as ButtonBase from 'portal/lib/primitives/buttons/ButtonBase'
import { SIDEBAR_HORIZONTAL_PADDING } from './constants'

export function NavButton({
  id = 'Nav',
  children,
  onClick,
  to,
  Icon,
  disabled,
  btnTag = ''
}) {
  const [isActive, setIsActive] = useState(false)

  const isActiveRef = useRef(false)
  const handleIsActive = useCallback(match => {
    isActiveRef.current = !!match
    return !!match
  }, [])

  // This is a hack because react router is inflexible,
  //  but we want to check this on every single render without warnings coming up
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isActive !== isActiveRef.current) {
      setIsActive(isActiveRef.current)
    }
  })

  return (
    <StyledNavLink
      id={id + 'SideBarButton'}
      to={to}
      onClick={onClick}
      isActive={handleIsActive}
      disabled={disabled}
      exact
    >
      {disabled || <ActiveBar active={isActive} />}

      <ButtonBody disabled={isActive} optionUnavailable={disabled}>
        {Icon && (
          <>
            <Icon size={sizes.MEDIUM} colour="currentColor" />
            <Spacer.Tiny />
          </>
        )}
        {children}
        {btnTag !== '' && (
          <>
            <Spacer.Large />
            <Spacer.Small /> <TagDiv>{btnTag}</TagDiv>
          </>
        )}
      </ButtonBody>
    </StyledNavLink>
  )
}

NavButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.elementType,
  disabled: PropTypes.bool,
  btnTag: PropTypes.string
}

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  position: relative;

  width: calc(100% + ${SIDEBAR_HORIZONTAL_PADDING});
  text-decoration: none;

  margin-left: -${SIDEBAR_HORIZONTAL_PADDING};
  margin-top: 0.5rem;

  ${({ isActive, disabled }) =>
    (isActive?.() || disabled) &&
    css`
      pointer-events: none;
    `}
`

const ButtonBody = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: () => 'transparent',
    getBgActive: ({ theme }) => theme.sidebar.navButtonBgActive,
    getBgDisabled: ({ theme }) => theme.sidebar.navButtonBgActive,
    getBgHover: () => 'transparent',
    getFg: ({ theme }) => theme.sidebar.navButtonFg,
    getFgHover: ({ theme }) => theme.sidebar.navButtonFgActive,
    getFgActive: ({ theme }) => theme.sidebar.navButtonFgActive,
    getFgDisabled: ({ theme }) => theme.sidebar.navButtonFg
  })}
  ${ButtonBase.getShapeCSS({
    getHeight: () => '2rem'
  })}

  ${({ optionUnavailable, theme }) =>
    optionUnavailable &&
    css`
      background-color: 'transparent' !important;
      color: ${theme.sidebar.navButtonFgUnavailable} !important;
      pointer-events: none;
    `}

  width: 100%;
  justify-content: flex-start;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  padding-left: 20px;

  font-size: ${sizes.SMALL_MEDIUM};
`

const ActiveBar = styled.div`
  position: absolute;

  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 0.25rem;

  transition: background-color 100ms ease-in-out;

  background-color: ${({ active, theme }) =>
    active ? theme.colours.TEAL : 'transparent'};

  ${StyledNavLink}:hover & {
    background-color: ${({ theme }) => theme.colours.TEAL};
  }
`

const TagDiv = styled.div`
  border-style: ridge;
  border-radius: 4px;
  padding: 17ox;
  padding-right: 7px;
  padding-left: 7px;
  font-weight: bolder;
`
