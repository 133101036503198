import {
  RECORDS_REMOVE_RECORD,
  RECORDS_REMOVE_RECORD_SUCCESS,
  RECORDS_REMOVE_RECORD_FAILURE
} from './actions'
import produce from 'immer'

const initialState = {
  working: false,
  error: false
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case RECORDS_REMOVE_RECORD: {
        draft.working = true
        return
      }
      case RECORDS_REMOVE_RECORD_SUCCESS: {
        draft.working = false
        draft.error = false
        return
      }
      case RECORDS_REMOVE_RECORD_FAILURE: {
        draft.working = false
        draft.error = true
        return
      }
      default:
        return state
    }
  })
}
