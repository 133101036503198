import React, { useCallback, useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, useTheme } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import {
  Modal,
  Padding,
  Flex,
  ColourButton,
  Text,
  SubHeading,
  Spacer,
  icons
} from 'portal/lib/primitives'

import { STATUS } from 'portal/store/records/massUpload/reducer'
import {
  massUploadReset,
  massUploadConfirm
} from 'portal/store/records/massUpload/actions'
import {
  NO_DATA,
  TOO_LARGE,
  UPLOAD_ERROR
} from 'portal/store/records/massUpload/errors'
import { ImportMessageLine } from './ImportMessageLine'
import { useModuleIds } from 'portal/lib/hooks'
import { useMixpanelEvents } from './mixpanel'

export function ImportModal({ title = 'Import' }) {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { accountId, projectId, moduleName } = useModuleIds()
  const { trackImportValidated, trackImportConfirmed } = useMixpanelEvents()
  const { status, error, data } = useSelector(state => state.records.massUpload)
  const [isClosing, setIsClosing] = useState(false)

  const showModal = [
    STATUS.UPLOADED,
    STATUS.CONFIRMING,
    STATUS.CONFIRMED
  ].includes(status)

  const canUpdate = data.summary.canUpdate

  const handleClose = useCallback(() => {
    setIsClosing(true)
  }, [])
  const handleCloseFinished = useCallback(() => {
    setIsClosing(false)
    dispatch(massUploadReset())
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    dispatch(massUploadConfirm(accountId, projectId, moduleName, data.id))
    trackImportConfirmed(data.outputs.length)
  }, [
    accountId,
    data.outputs.length,
    data.id,
    dispatch,
    moduleName,
    projectId,
    trackImportConfirmed
  ])

  useEffect(() => {
    if (status === STATUS.UPLOADED) {
      trackImportValidated(canUpdate, data.outputs.length)
    }
  }, [canUpdate, data.outputs.length, status, trackImportValidated])

  function Content(children) {
    return (
      <Modal
        show={showModal && !isClosing}
        closeClick={handleClose}
        onCloseFinished={handleCloseFinished}
        title={title}
        css={ModalCSS}
      >
        <Padding.Modal>{children}</Padding.Modal>
      </Modal>
    )
  }

  if (error) {
    return Content(
      <Flex.Col horizontalCentre>
        <SubHeading>{mapGlobalErrorCodeToCopy(error)}</SubHeading>
        <icons.CrossCircle colour={theme.primary.error} size={'10rem'} />
      </Flex.Col>
    )
  }

  if (!error && status === STATUS.CONFIRMED) {
    return Content(
      <Flex.Col horizontalCentre>
        <SubHeading>Your import has started</SubHeading>
        <icons.CheckCircle colour={theme.primary.highlight} size={'10rem'} />
      </Flex.Col>
    )
  }

  return Content(
    <>
      {data.outputs.length > 0 ? (
        <>
          <SubHeading>
            {data.outputs.length} {'Message'}
            {data.outputs.length === 1 ? '' : 's'}
          </SubHeading>

          <Text>
            {canUpdate
              ? 'You could correct the following issues before importing.'
              : 'Please correct the following errors and try again'}
          </Text>

          {data.outputs.map((message, i) => (
            <Fragment key={i}>
              <Spacer.Small />
              <ImportMessageLine message={message} />
            </Fragment>
          ))}
        </>
      ) : (
        <SubHeading>No warnings or errors</SubHeading>
      )}

      <Spacer.Medium />

      <Flex.Centred>
        <Flex.By />

        <ColourButton.Action
          id="ConfirmImportButton"
          flex={1}
          onClick={handleSubmit}
          disabled={!canUpdate}
        >
          Import {data.summary.validCount} Update
          {data.summary.validCount === 1 ? '' : 's'}
        </ColourButton.Action>

        <Flex.By />
      </Flex.Centred>
    </>
  )
}

ImportModal.propTypes = {
  title: PropTypes.string
}

const ModalCSS = css`
  width: 75vw;
  max-width: 40rem;
`

function mapGlobalErrorCodeToCopy(code) {
  switch (code) {
    case NO_DATA:
      return 'The document was empty or no longer exists'
    case TOO_LARGE:
      return 'The document is too large to upload'
    case UPLOAD_ERROR:
      return 'A problem occured uploading your document, please try again'
    default:
      return 'A problem occured, please try again'
  }
}
