import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as ButtonBase from './ButtonBase'

export const Clickable = forwardRef(
  ({ children, onClick, innerCSS, ...props }, ref) => {
    return (
      <StyledButton onClick={onClick} {...props} ref={ref}>
        <Children css={innerCSS}>{children}</Children>
      </StyledButton>
    )
  }
)

Clickable.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  innerCSS: PropTypes.any
}

export const ClickableCSS = css`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: () => 'transparent',
    getBgDisabled: () => 'transparent'
  })}
  ${ButtonBase.getShapeCSS()}

  flex: 0 0 auto;

  min-width: 2rem;
  width: auto;
  min-height: 2rem;
  max-height: none;

  ${({ primary, theme }) =>
    primary &&
    css`
      background-color: ${theme.primary.highlight};

      :hover {
        background-color: ${theme.primary.highlightHover};
      }

      :active {
        background-color: ${theme.primary.highlightFocused};
      }
    `}

  ${({ css }) => css}
`

const StyledButton = styled.button`
  ${ClickableCSS}
`

const Children = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ css }) => css}
`
