import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import {
  Flex,
  Spacer,
  ColourButton,
  NavButtonDecorator
} from 'portal/lib/primitives'

export function ApplyOrCancelControls({ canApply, cancelUri, onCancelled }) {
  return (
    <Flex.Row amount={0}>
      <NavButtonDecorator to={cancelUri}>
        <ColourButton.Basic
          id="CancelButton"
          css={buttonCSS}
          type="button"
          onClick={onCancelled}
        >
          Cancel
        </ColourButton.Basic>
      </NavButtonDecorator>

      <Spacer.Tiny />

      <ColourButton.Action
        id="ApplyButton"
        css={buttonCSS}
        disabled={!canApply}
        type="submit"
      >
        Save
      </ColourButton.Action>
    </Flex.Row>
  )
}

ApplyOrCancelControls.propTypes = {
  cancelUri: PropTypes.string.isRequired,
  canApply: PropTypes.bool,
  onCancelled: PropTypes.func
}

const buttonCSS = css`
  width: 4rem;
`
