import * as actions from './actions'
import produce from 'immer'
import {
  isValidModule,
  getModulePriority
} from 'portal/lib/constants/moduleConstants'
import _ from 'lodash'

const initialState = {
  projects: null,
  error: null,
  isLoading: false,
  timeZones: null
}

const projectsStart = draft => {
  draft.error = null
  draft.projects = null
  draft.isLoading = true
}

const projectsSuccess = (draft, action) => {
  draft.projects = action.projects.map(project => {
    let eligibleModules = _(project.moduleTags)
      .filter(moduleTag => isValidModule(moduleTag.moduleTagDefinitionName))
      .sortBy(tag => getModulePriority(tag.moduleTagDefinitionName))
      .value()
    return {
      ...project,
      moduleTags: eligibleModules
    }
  })

  draft.error = null
  draft.isLoading = false
}

const projectsFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const projectPutSuccess = (draft, action) => {
  draft.error = null
  draft.isLoading = false
}

const projectPutFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const projectsClear = draft => {
  draft.error = null
  draft.projects = null
  draft.isLoading = false
}

const timeZonesGetSuccess = (draft, action) => {
  draft.timeZones = action.timezones
  draft.error = null
  draft.isLoading = false
}

const timeZonesGetFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.PROJS_GET_START:
        return projectsStart(draft, action)
      case actions.PROJS_GET_SUCCESS:
        return projectsSuccess(draft, action)
      case actions.PROJS_GET_FAIL:
        return projectsFail(draft, action)
      case actions.PROJ_PUT_SUCCESS:
        return projectPutSuccess(draft, action)
      case actions.PROJ_PUT_FAIL:
        return projectPutFail(draft, action)
      case actions.PROJS_CLEAR:
        return projectsClear(draft, action)
      case actions.TIMEZONES_GET_SUCCESS:
        return timeZonesGetSuccess(draft, action)
      case actions.TIMEZONES_GET_FAIL:
        return timeZonesGetFail(draft, action)
      default:
        return state
    }
  })
}

export { reducer }
