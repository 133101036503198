import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colours } from '@qflow/theme'
import { ButtonBase } from 'portal/lib/primitives/buttons/ButtonBase'
import { ChevronDown } from 'portal/lib/primitives/icons'

export const DropdownButton = forwardRef(
  (
    { open = false, onClick, itemSelected = false, children, ...props },
    ref
  ) => {
    return (
      <ComponentButton
        id="DropdownButton"
        active={open}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        <ComponentLabel id="DropdownLabel" dim={!itemSelected}>
          {children}
        </ComponentLabel>

        <ChevronIcon rotate={open ? '180' : '0'} colour={colours.BLUEY_GREY} />
      </ComponentButton>
    )
  }
)

DropdownButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  itemSelected: PropTypes.bool,
  children: PropTypes.node
}

const ComponentButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  background-color: ${colours.WHITE};
  color: ${colours.CORE_NAVY};
  border-color: ${colours.BORDER_GREY};

  :active,
  :focus,
  :hover {
    color: ${colours.CORE_NAVY};
    background-color: ${colours.WHITE};
    border-color: ${colours.TEAL};
  }
`

const ComponentLabel = styled.div`
  display: flex;
  flex: 1 1 auto;
  text-align: left;

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colours.CORE_NAVY};

  ${({ dim }) =>
    dim &&
    css`
      color: ${colours.BLUEY_GREY};
    `}
`

const ChevronIcon = styled(ChevronDown)`
  display: flex;
  margin-left: 0.5rem;
  color: ${colours.CORE_NAVY};
`
