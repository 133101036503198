import React from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { Login } from './Login'
import { DestinationRedirect } from './DestinationRedirect'
import { useMixpanelEvents } from './useMixpanelEvents'
import { useMountEffect } from 'portal/lib/hooks'
import { Spinner } from 'portal/lib/primitives'

export function DestinationLandingPage() {
  const { checked, userUuid, isLoading } = useSelector(state => state.auth)
  const authenticated = !!userUuid

  const { destinationType } = useParams()
  const { trackDestinationOpened } = useMixpanelEvents()
  useMountEffect(() => {
    trackDestinationOpened(destinationType)
  })

  if (isLoading) {
    return <Spinner />
  }

  if (authenticated && checked) {
    return <DestinationRedirect />
  } else {
    return <Login />
  }
}
