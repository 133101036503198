import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function MagnifyingGlass({ size = sizes.SMALL, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      {...props}
      style={{ width: size, height: size }}
    >
      <defs>
        <path
          id="prefix__a"
          d="M15 13.94L13.94 15l-4.243-4.243 1.06-1.06L15 13.94zM5.25 9C3.182 9 1.5 7.318 1.5 5.25S3.182 1.5 5.25 1.5 9 3.182 9 5.25 7.318 9 5.25 9zm0-9C2.35 0 0 2.35 0 5.25s2.35 5.25 5.25 5.25 5.25-2.35 5.25-5.25S8.15 0 5.25 0z"
        />
      </defs>

      <g fill="none" fillRule="evenodd">
        <use fill={colour || 'currentColor'} xlinkHref="#prefix__a" />
      </g>
    </svg>
  )
}

MagnifyingGlass.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
