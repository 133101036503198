import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Select } from 'antd'

import { useModuleIds } from 'portal/lib/hooks'
import { SmallSpinner } from 'portal/lib/primitives'
const { Option } = Select

export function EventsFilter({
  value,
  setValue,
  options,
  loading,
  filterName,
  tableId,
  testId
}) {
  return (
    <EventsSelect
      value={value}
      setValue={setValue}
      options={options}
      loading={loading}
      filterName={filterName}
      tableId={tableId}
      testId={testId}
    />
  )
}

EventsFilter.propTypes = {
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filterName: PropTypes.string,
  testId: PropTypes.string
}

const LoadingContainer = styled.div`
  text-align: center;
`

function dropdownRender(menu, loading) {
  if (loading) {
    return (
      <LoadingContainer>
        <SmallSpinner />
      </LoadingContainer>
    )
  }
  return menu
}

function EventsSelect({
  value,
  setValue,
  options,
  loading,
  filterName,
  tableId,
  testId
}) {
  const { moduleName } = useModuleIds()
  const SESSION_STORE_KEY = `${moduleName}-events-filters`

  let useValue = value
  const storedFilters = JSON.parse(sessionStorage.getItem(SESSION_STORE_KEY))

  if (useValue.length === 0 && storedFilters && storedFilters[filterName]) {
    useValue = storedFilters[filterName]
    setValue(useValue)
  }

  const handleOnChange = value => {
    // Use existing stored value
    let tempStoredFilters = JSON.parse(
      sessionStorage.getItem(SESSION_STORE_KEY)
    )
    // If there this is null set to an empty object
    if (!tempStoredFilters) {
      tempStoredFilters = {}
    }

    // Expect value to be an array and replace existing value with new value
    // Else if the value is an empty array remove it from the temp object
    if (value.length > 0) {
      tempStoredFilters[filterName] = value
    } else {
      delete tempStoredFilters[filterName]
    }

    sessionStorage.setItem(SESSION_STORE_KEY, JSON.stringify(tempStoredFilters))
    setValue(value)
  }

  return (
    <Select
      className="events-filter-select"
      autoFocus
      autoClearSearchValue={false}
      maxTagCount="responsive"
      dropdownMatchSelectWidth={false}
      mode="multiple"
      showSearch
      allowClear
      style={{ width: '100%', minWidth: '10px' }}
      dropdownRender={menu => dropdownRender(menu, loading)}
      dropdownStyle={{ maxWidth: 300 }}
      filterOption={(input, option) =>
        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      getPopupContainer={() => document.getElementById(tableId)}
      value={useValue}
      onChange={value => {
        handleOnChange(value)
      }}
      data-testid={testId}
    >
      {!loading &&
        options
          .filter(option => !(option.label == '' || option.label == null))
          .map(option => <Option key={option.key}>{option.label}</Option>)}
    </Select>
  )
}

EventsSelect.propTypes = {
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  testId: PropTypes.string
}
