import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import produce from 'immer'

import './Spinner.css'

import Fade from '@material-ui/core/Fade'

// IE11 has problems with real symbols so this will do
let symbolId = 0
function getSymbol() {
  symbolId += 1
  return symbolId
}

const SpinnerContext = createContext()

export const SpinnerProvider = ({ children }) => {
  const [instances, setInstances] = useState({})
  const show = useCallback(() => {
    const symbol = getSymbol()

    setInstances(
      produce(instances => {
        instances[symbol] = true
      })
    )

    return () => {
      setInstances(
        produce(instances => {
          delete instances[symbol]
        })
      )
    }
  }, [])

  const isShown = Object.keys(instances).length > 0

  return (
    <SpinnerContext.Provider value={show}>
      <Fade in={isShown} enter exit>
        <Container
          id="Spinner"
          data-testid={`spinner${isShown ? '-active' : ''}`}
        >
          <div className="lds-grid">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </Container>
      </Fade>

      {children}
    </SpinnerContext.Provider>
  )
}

SpinnerProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const Spinner = () => {
  const show = useContext(SpinnerContext)

  useEffect(() => {
    return show()
  })

  return null
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.modal.backfill};

  z-index: 6000;
`
