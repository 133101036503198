import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'
import { Text } from 'portal/lib/primitives/text'
import { Flex } from 'portal/lib/primitives/layout'

const ROW_HEIGHT = '2rem'

const ColumnHeaderTh = styled.th`
  text-align: left;
  padding-left: 0.5rem;
  height: ${ROW_HEIGHT};
`

const ColumnHeaderText = styled(Text)`
  color: ${colours.CHARCOAL_GREY};
`

export const TableContainer = styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: initial;
  border-spacing: 0 5px;
`

export const ColumnHeader = ({ children, width }) => (
  <ColumnHeaderTh style={{ width }}>
    <ColumnHeaderText>{children}</ColumnHeaderText>
  </ColumnHeaderTh>
)

ColumnHeader.propTypes = {
  width: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

const ControlsContainer = styled(Flex.Row).attrs({ as: 'td' })`
  align-items: center;

  background-color: transparent !important;
  text-decoration: none !important;
`

const TdInner = styled.div`
  display: flex;
  align-items: center;
`

export const Row = styled.tr`
  td {
    text-align: left;
    height: ${ROW_HEIGHT};
    padding-left: 0.5rem;
    overflow: hidden;

    font-size: ${sizes.SMALL};

    background-color: transparent;
  }

  :nth-child(odd) {
    > td {
      background-color: ${colours.alpha(colours.BLACK, 0.05)};
    }
  }
`

export const AutoRow = ({
  index,
  item,
  headers,
  controls,
  removed = false,
  renderIcon = null,
  ...props
}) => {
  return (
    <Row {...props}>
      {headers.map(header => (
        <td key={header.key}>
          <TdInner>
            {renderIcon?.(header.key)}

            {typeof header.render === 'function' ? (
              header.render(item, index)
            ) : (
              <Text dim={removed} strike={removed}>
                {item[header.key]}
              </Text>
            )}
          </TdInner>
        </td>
      ))}

      {controls && <ControlsContainer>{controls}</ControlsContainer>}
    </Row>
  )
}

AutoRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.any.isRequired,
  headers: PropTypes.array.isRequired,
  controls: PropTypes.node,
  removed: PropTypes.bool,
  renderIcon: PropTypes.func
}
