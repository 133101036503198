import React from 'react'
import { Typography, Col, Row, Space } from 'antd'
import { UserAvatar } from './UserAvatar'

const { Title, Link } = Typography

export function UserCard({ displayName, email }) {
  return (
    <Row>
      <Space size="middle">
        <Row style={{ padding: '5px 0' }}>
          <UserAvatar
            displayName={displayName}
            displayTooltip={false}
            size="large"
          />
        </Row>
        <Col style={{ backgroundColor: 'white' }}>
          <Title level={3} style={{ marginBottom: '0' }}>
            {displayName}
          </Title>
          <Link href={'mailto:' + email}>{email}</Link>
        </Col>
      </Space>
    </Row>
  )
}
