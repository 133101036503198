import React from 'react'

import { LightThemeProvider } from '@qflow/theme'

import { BIEmbededReport } from 'portal/components/BIReports'
import { Sidebar } from 'portal/components/Sidebar'

export function BIReport() {
  return (
    <LightThemeProvider>
      <Sidebar />

      <BIEmbededReport />
    </LightThemeProvider>
  )
}
