import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { sizes } from '@qflow/theme'
import { ColourButton, icons, Spacer, Spinner } from 'portal/lib/primitives'
import {
  useModuleIds,
  useFileInput,
  useMountEffect,
  useUserProjectRole
} from 'portal/lib/hooks'
import {
  massUploadReset,
  massUploadStart
} from 'portal/store/records/massUpload/actions'

import { STATUS } from 'portal/store/records/massUpload/reducer'
import { ImportModal } from './ImportModal'
import { useMixpanelEvents } from './mixpanel'

export function ImportButton({ children = 'Upload CSV' }) {
  const { status } = useSelector(state => state.records.massUpload)
  const working = [STATUS.UPLOADING, STATUS.CONFIRMING].includes(status)

  const { handleFileSelected } = useImport()
  const { hiddenInput, handleUploadClick } = useFileInput(
    'text/csv',
    handleFileSelected,
    {
      inputId: 'ImportFileInput'
    }
  )

  const { trackImportClick } = useMixpanelEvents()
  const handleButtonClick = useCallback(() => {
    handleUploadClick()
    trackImportClick()
  }, [handleUploadClick, trackImportClick])

  const { accountId, projectId } = useModuleIds()
  const { isReadOnly } = useUserProjectRole(accountId, projectId)
  if (isReadOnly) {
    // Readers should not be able to edit records by mass import
    return null
  }

  return (
    <>
      <ColourButton.Basic id="ImportButton" onClick={handleButtonClick}>
        <icons.Upload size={sizes.SMALL} />

        <Spacer.Fine />

        {children}
      </ColourButton.Basic>

      {hiddenInput}
      {working && <Spinner />}

      <ImportModal title={children} />
    </>
  )
}

ImportButton.propTypes = {
  children: PropTypes.string
}

const useImport = () => {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const { trackImportFileSelected } = useMixpanelEvents()
  const handleFileSelected = useCallback(
    fileList => {
      dispatch(massUploadStart(accountId, projectId, moduleName, fileList[0]))
      trackImportFileSelected()
    },
    [accountId, dispatch, moduleName, projectId, trackImportFileSelected]
  )

  useMountEffect(() => {
    dispatch(massUploadReset())
  })

  return {
    handleFileSelected
  }
}
