import produce from 'immer'
import moment from 'moment'

import { GLOBAL_DATE_PICKER_CHANGED } from './actions'

const DEFAULT_DAYS_LENGTH = process.env.NODE_ENV === 'development' ? 3000 : 90

export const initialState = {
  dateRange: {
    global: {
      startDate: moment()
        .startOf('day')
        .subtract(DEFAULT_DAYS_LENGTH, 'days')
        .toISOString(),
      endDate: moment().endOf('day').toISOString()
    }
  }
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GLOBAL_DATE_PICKER_CHANGED: {
        const { value, valueKey } = action
        draft.dateRange[valueKey] = { ...value }
      }
    }
  })
}
