import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import {
  ColourButton,
  Modal,
  icons,
  Spacer,
  Label,
  Padding
} from 'portal/lib/primitives'
import { sizes } from '@qflow/theme'
import { useToggleState } from 'portal/utils/useToggleState'

export function LineItemDetailButton({ title, detail }) {
  const [open, setOpen, setClosed] = useToggleState(false)

  return (
    <>
      <ColourButton.Basic onClick={setOpen}>
        <icons.Eye size={sizes.SMALL} colour="currentColor" />

        <Spacer.Fine />

        <Label isChild colour="inherit">
          View
        </Label>
      </ColourButton.Basic>

      <Modal
        show={open}
        closeClick={setClosed}
        title={title ?? 'View'}
        backfillClickable
        centred
        IconLeft={icons.Eye}
        css={ModalCSS}
      >
        <Padding noVertical>{detail}</Padding>
      </Modal>
    </>
  )
}

LineItemDetailButton.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.node.isRequired
}

const ModalCSS = css`
  width: 25rem;
  max-width: 95vw;
`
