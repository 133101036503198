export const BIREPORT_LOAD = 'BIREPORT_LOAD'
export const biReportLoad = (accountId, reportType) => ({
  type: BIREPORT_LOAD,
  accountId,
  reportType
})
export const BIREPORT_LOADED = 'BIREPORT_LOADED'
export const biReportLoaded = (accountId, reportType, biReport, status) => ({
  type: BIREPORT_LOADED,
  accountId,
  reportType,
  biReport,
  status
})
export const BIREPORT_LOAD_FAILURE = 'BIREPORT_LOAD_FAILURE'
export const biReportLoadFailure = (accountId, reportType, error) => ({
  type: BIREPORT_LOAD_FAILURE,
  accountId,
  reportType,
  error
})
export const BIREPORT_HAD_FIRST_VISIT = 'BIREPORT_HAD_FIRST_VISIT'
export const biReportNavigatedAway = (accountId, reportType) => ({
  accountId,
  reportType,
  type: BIREPORT_HAD_FIRST_VISIT
})
