import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'

import { DateFormatFull } from 'portal/lib/functions'
import { useModuleIds } from 'portal/lib/hooks'
import { Flag } from 'portal/lib/primitives/icons'
import { colours, sizes, SPACING } from '@qflow/theme'

import { DetailRow, AttachmentsSection } from 'portal/components/RecordDetails'
import { convertAssignedToArrayToString, imperial } from 'portal/lib/functions'
import {
  Flex,
  SubHeading,
  Spacer,
  ResponsiveTable,
  Text,
  PillBox
} from 'portal/lib/primitives'
import {
  RemoveRecordButton,
  EditRecordButton,
  LineItemButtons,
  LineItemDetailButton,
  ViewInAdminButton
} from 'portal/components/RecordsModules/common'
import { AttachmentsHeader } from 'portal/components/RecordDetails/AttachmentsHeader'
import { useInfractionGetter } from 'portal/store/infractions/InfractionsContext'

import { UpgradeToPro } from 'portal/components/RecordDetails/SharedComponents/UpgradeToPro'

export function Delivery({ record }) {
  const { accountId, moduleName } = useModuleIds()
  const getInfraction = useInfractionGetter()

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )

  const projectIsProLicence = useSelector(
    state => state.projectDetails.modules?.isProLicence
  )

  const productTableHeaders = useMemo(
    () => [
      { key: 'name', label: 'Name' },
      {
        key: '__issues__',
        label: 'Issues',
        render: (item, index) => {
          const infractions = getInfraction(`data.products[${index}]`, {
            isPrefix: true
          })

          if (!infractions?.length) {
            return null
          }

          return (
            <PillBox>
              {infractions.map((v, i) => (
                <InfractionFlag key={i} />
              ))}
            </PillBox>
          )
        }
      },
      { key: 'quantity', label: 'Quantity' },
      {
        key: '__groups__',
        label: 'Product Groups',
        render: item =>
          item.productGroups?.map(group => {
            return (
              <Text highlightBg key={group.productGroupId}>
                {group.name}
              </Text>
            )
          })
      },
      {
        key: 'embodiedCarbonKgCO2e',
        label: (
          <StyledLabel>
            <div
              style={
                !projectIsProLicence ? { color: colours.BATTLESHIP_GREY } : {}
              }
            >
              Embodied Carbon (kg CO<sub>2</sub>e)
            </div>
            {!projectIsProLicence && <UpgradeToPro />}
          </StyledLabel>
        ),
        render: item =>
          item.embodiedCarbonKgCO2e
            ? _.round(item.embodiedCarbonKgCO2e, 2)
            : null
      }
    ],
    [getInfraction, projectIsProLicence]
  )

  let assignedTo = ''

  if (record.assignedTo) {
    assignedTo = convertAssignedToArrayToString(record.assignedTo)
  }

  return (
    <>
      <Flex.Row amount={0} verticalCentre>
        <SubHeading
          id="DeliveryDetailHeading"
          isChild
          style={{ fontSize: '1.5rem', color: colours.BLUEY_BLACK }}
        >
          Delivery Record
        </SubHeading>

        <Flex.By amount={1} />
        {record.canEdit && (
          <Flex.By amount={0}>
            <ViewInAdminButton
              record={record}
              accountId={accountId}
              moduleName={moduleName}
            />
            <Spacer.Tiny />
            <EditRecordButton />
            <Spacer.Tiny />
            <RemoveRecordButton />
          </Flex.By>
        )}
      </Flex.Row>

      <DetailRow label="Assigned To" content={assignedTo} />

      <DetailRow
        label="Record Created"
        infraction={getInfraction('createdAt')}
        content={
          record.createdAt
            ? new Date(record.createdAt).toLocaleString(
                [],
                DateFormatFull(projectTimeZone)
              )
            : null
        }
      />

      <DetailRow
        label="Delivery Arrival"
        infraction={getInfraction('data.deliveryArrivalDate')}
        content={
          record.data.deliveryArrivalDate
            ? new Date(record.data.deliveryArrivalDate).toLocaleString(
                [],
                DateFormatFull(projectTimeZone)
              )
            : null
        }
      />

      <DetailRow
        label="Delivery Exit"
        infraction={getInfraction('data.deliveryExitDate')}
        content={
          record.data.deliveryExitDate
            ? new Date(record.data.deliveryExitDate).toLocaleString(
                [],
                DateFormatFull(projectTimeZone)
              )
            : null
        }
      />

      <DetailRow
        label="Delivery ID"
        content={record.data.deliveryId}
        infraction={getInfraction('data.deliveryId')}
      />

      <DetailRow
        label="Delivery Address"
        content={record.data.deliveryAddress}
        infraction={getInfraction('data.deliveryAddress')}
      />

      <DetailRow
        label="Dispatch Address"
        content={record.data.givenDispatchAddress}
        infraction={getInfraction('data.givenDispatchAddress')}
      />

      <DetailRow
        label="Receiver Reference"
        content={record.data.receiverReferenceNumber}
        infraction={getInfraction('data.receiverReferenceNumber')}
      />

      <DetailRow
        label="Trade Contractor"
        content={record.data.tradeContractor}
        infraction={getInfraction('data.tradeContractor')}
      />

      <DetailRow
        label="Supplier Reference"
        content={record.data.supplierReferenceNumber}
        infraction={getInfraction('data.supplierReferenceNumber')}
      />

      <DetailRow
        label="Given Supplier Name"
        content={record.data.supplierName}
        infraction={getInfraction('data.supplierName')}
      />

      <DetailRow
        label="Known Supplier Company"
        content={record.data.knownSupplierName}
        infraction={getInfraction('data.knownSupplierName')}
      />

      <DetailRow
        label="Given Supplier Certifications"
        content={record.data.givenSupplierCertifications}
        infraction={getInfraction('data.givenSupplierCertifications')}
      />

      <DetailRow
        label="Known Supplier Certifications"
        infraction={getInfraction('data.knownSupplierCertifications')}
      >
        <Flex.Col>
          {record.data.knownSupplierCertifications?.map(
            ({ name, resourceURI }) => (
              <CertificationLinkText key={name + resourceURI}>
                {resourceURI ? (
                  <a href={resourceURI} target="_blank" rel="noreferrer">
                    {name}
                  </a>
                ) : (
                  name
                )}
              </CertificationLinkText>
            )
          )}
        </Flex.Col>
      </DetailRow>

      <DetailRow
        label="Supplier Vehicle Registration"
        content={record.data.vehicleRegistration}
        infraction={getInfraction('data.vehicleRegistration')}
      />

      <DetailRow
        label="External Reference"
        content={record.data.clientReference}
        infraction={getInfraction('data.clientReference')}
      />

      <DetailRow
        label="Notes"
        content={record.data.notes}
        infraction={getInfraction('data.notes')}
      />

      <DetailRow
        label="Journey Distance (mi)"
        content={
          record.data.journey?.distanceMiles
            ? _.round(record.data.journey.distanceMiles, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.distanceMiles')}
        tooltip="Journey distance represents the laden journey only"
      />
      <DetailRow
        label={
          <span>
            Laden Journey Emissions (kg CO<sub>2</sub>e)
          </span>
        }
        content={
          record.data.journey?.ladenEmissionsKgCO2e
            ? _.round(record.data.journey.ladenEmissionsKgCO2e, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.ladenEmissionsKgCO2e')}
        upgradeToPro={!projectIsProLicence}
      />
      <DetailRow
        label={
          <span>
            Unladen Journey Emissions (kg CO<sub>2</sub>e)
          </span>
        }
        content={
          record.data.journey?.unladenEmissionsKgCO2e
            ? _.round(record.data.journey.unladenEmissionsKgCO2e, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.unladenEmissionsKgCO2e')}
        upgradeToPro={!projectIsProLicence}
      />

      <Spacer.Medium />

      <AttachmentsHeader record={record} />
      <AttachmentsSection record={record} attachments={record.attachments} />

      <Spacer.Medium />
      <Flex.Row amount={0} verticalCentre>
        <SubHeading isChild>Products</SubHeading>
      </Flex.Row>

      <ResponsiveTable.Table
        id="ProductsTable"
        headers={productTableHeaders}
        data={record.data.products ?? []}
        style={{ borderCollapse: 'separate', borderSpacing: '0 5px' }}
        renderRow={({ item, index, headers }) =>
          !item.isDeleted ? (
            <ResponsiveTable.AutoRow
              id={'Product' + index}
              key={index}
              index={index}
              item={item}
              headers={headers}
              removed={item.isRemoved}
              controls={<ProductControls record={record} index={index} />}
            />
          ) : null
        }
        renderCard={({ item, headers, index }) => (
          <ResponsiveTable.AutoCard
            id={'Product' + index}
            key={index}
            headers={headers}
            index={index}
            item={item}
            titleKey="name"
            removed={item.isRemoved}
            controls={<ProductControls record={record} index={index} />}
          />
        )}
      />
    </>
  )
}

Delivery.propTypes = {
  record: PropTypes.object.isRequired
}

const InfractionFlag = () => <Flag colour={colours.RED} size={sizes.MEDIUM} />

const ProductControls = ({ record, index }) => {
  const getInfraction = useInfractionGetter()

  const item = record.data.products[index]

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )

  const projectCountry = useSelector(
    state => state.projectDetails.modules?.country
  )

  const isImperial = imperial(projectCountry)

  return (
    <>
      <LineItemDetailButton
        title={`Product Details`}
        detail={
          <ResponsiveTable.AutoCard
            headers={productDetailHeaders(projectTimeZone, isImperial)}
            index={index}
            item={item}
            titleKey="name"
            removed={item.isRemoved}
            renderIcon={key => {
              const infraction = getInfraction(`data.products[${index}].${key}`)
              return infraction ? <InfractionFlag /> : null
            }}
            controls={
              <LineItemButtons
                record={record}
                index={index}
                isImperial={isImperial}
              />
            }
          />
        }
      />

      <Spacer.Tiny />

      <LineItemButtons record={record} index={index} isImperial={isImperial} />
    </>
  )
}

ProductControls.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}

const productDetailHeaders = (projectTimeZone, isImperial) => [
  { key: 'name', label: 'Given Name' },
  {
    key: 'productionTime',
    label: 'Produced At',
    render: item =>
      item.productionTime ? (
        <Text>
          {new Date(item.productionTime).toLocaleString(
            [],
            DateFormatFull(projectTimeZone)
          )}
        </Text>
      ) : null
  },
  {
    key: 'usedAtTime',
    label: 'Used At',
    render: item =>
      item.usedAtTime ? (
        <Text>
          {new Date(item.usedAtTime).toLocaleString(
            [],
            DateFormatFull(projectTimeZone)
          )}
        </Text>
      ) : null
  },
  { key: 'height', label: isImperial ? 'Height' : 'Height (m)' },
  { key: 'width', label: isImperial ? 'Width' : 'Width (m)' },
  { key: 'length', label: isImperial ? 'Length' : 'Length (m)' },
  {
    key: 'calculatedVolume',
    label: isImperial ? (
      'Calculated volume'
    ) : (
      <>
        Calculated volume (m<sup>3</sup>)
      </>
    )
  },
  {
    key: 'givenVolume',
    label: isImperial ? (
      'Volume'
    ) : (
      <>
        Volume (m<sup>3</sup>)
      </>
    )
  },
  {
    key: 'estimatedWeight',
    label: isImperial ? 'Estimated Weight' : 'Estimated Weight (t)'
  },
  { key: 'weight', label: isImperial ? 'Weight' : 'Weight (t)' },
  { key: 'quantity', label: 'Quantity (units)' },
  {
    key: 'givenCertifications',
    label: 'Given Certifications',
    render: item => {
      const certifications = (item.givenCertifications ?? []).filter(Boolean)
      if (!certifications?.length) {
        return null
      }
      return (
        <CertificationLinks>
          {certifications.map((certification, index) => (
            <Text key={index}>{certification}</Text>
          ))}
        </CertificationLinks>
      )
    }
  },
  {
    key: 'knownCertifications',
    label: 'Known Certifications',
    render: item => {
      const certifications = item.knownCertifications ?? []
      if (!certifications?.length) {
        return null
      }

      return (
        <CertificationLinks>
          {certifications.map(({ name, resourceURI }, index) => (
            <CertificationLinkText key={index}>
              {resourceURI ? (
                <a href={resourceURI} target="_blank" rel="noreferrer">
                  {name}
                </a>
              ) : (
                name
              )}
            </CertificationLinkText>
          ))}
        </CertificationLinks>
      )
    }
  }
]

const CertificationLinks = styled(Flex.Col)`
  align-items: flex-end;
`

const CertificationLinkText = styled(Text)`
  margin-top: ${SPACING.TINY};

  :first-child {
    margin-top: 0;
  }
`

const StyledLabel = styled(Flex.Row)`
  gap: 4px;
  align-items: center;
`
