import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import artworkNotFound from 'portal/assets/NotFound/artworkNotFound.svg'

import { ColourButton } from 'portal/lib/primitives'
import { PageContainer } from 'portal/pages/PageContainer'

const goBackHandler = history => {
  history.goBack()
}

export const NotFound = props => {
  return (
    <Container limitWidth>
      <Image />

      <Button id="notFoundBackBtn" onClick={() => goBackHandler(props.history)}>
        Go Back
      </Button>
    </Container>
  )
}

const Container = styled(PageContainer)`
  align-items: center;
  justify-content: center;
`

const Image = styled.img.attrs(() => ({
  src: artworkNotFound
}))`
  width: 80%;
  max-width: 40rem;
  height: auto;
`

const Button = styled(ColourButton.Action)`
  margin-top: 5rem;

  min-width: 4rem;
  width: 25%;
  max-width: 15rem;
`

NotFound.propTypes = {
  history: PropTypes.object
}
