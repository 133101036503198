export const TRACK_MIXPANEL = 'TRACK_MIXPANEL'
export const trackMixpanel = (
  name = '',
  params = {},
  { reset = false } = {}
) => ({
  type: TRACK_MIXPANEL,
  name,
  params,
  opts: {
    reset
  }
})
