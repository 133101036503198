import { takeEvery, put, call, select } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import {
  CONFIGURATION_LOAD,
  loadedConfiguration,
  loadConfigurationFailed
} from './actions'
import { selectConfiguration } from './selectors'

function getURI(accountId, projectId, moduleName) {
  return (
    `/accounts/${accountId}/projects/${projectId}/modules/` +
    moduleName +
    `/configuration`
  )
}

function* loadConfigurationSaga(action) {
  const { accountId, projectId, moduleName } = action

  const configuration = yield select(
    selectConfiguration(accountId, projectId, moduleName)
  )
  if (configuration.value) {
    yield put(
      loadedConfiguration(accountId, projectId, moduleName, configuration.value)
    )
    return
  }

  try {
    const response = yield call(() =>
      axios.get(getURI(accountId, projectId, moduleName), {
        validateStatus: status => status === 200
      })
    )

    yield put(
      loadedConfiguration(accountId, projectId, moduleName, response.data)
    )
  } catch (error) {
    console.warn('Failed to load configuration:', error)
    yield put(loadConfigurationFailed(accountId, projectId, moduleName, error))
  }
}

export function* watch() {
  yield takeEvery(CONFIGURATION_LOAD, loadConfigurationSaga)
}
