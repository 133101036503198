import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { RECORD_STATUS } from 'portal/lib/constants'
import { ColourButton } from 'portal/lib/primitives'
import { useToggleState } from 'portal/utils/useToggleState'
import { RemoveRecordModal } from './RemoveRecordModal'
import { RemoveButton } from './RemoveButton'

export function RemoveRecordButton({ removedSuccessUrl, ...props }) {
  const disabled = useSelector(
    state =>
      state.records.details.item?.status === RECORD_STATUS.REMOVED ?? true
  )
  const [showRemove, setShowRemove, setHideRemove] = useToggleState(false)

  return (
    <>
      <RemoveRecordModal
        show={showRemove}
        closeClick={setHideRemove}
        removedSuccessUrl={removedSuccessUrl}
      />

      <RemoveButton
        id="RemoveRecordButton"
        disabled={disabled}
        {...props}
        onClick={setShowRemove}
      />
    </>
  )
}

RemoveRecordButton.propTypes = {
  ...ColourButton.Action.propTypes,
  removedSuccessUrl: PropTypes.string
}
