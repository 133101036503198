import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RcCheckbox from 'rc-checkbox'

import { sizes } from '@qflow/theme'

const prefix = 'q'

/*
The checkbox is a native input element on the web, but the behaviour and styling differs by browser and can't be overriden.

rc-checkbox provides all the basic behaviour needed to implement a JS/CSS checkbox, but without any styling.
rc-checkbox (and its family of rc-x components) is used by Ant Design internally for the same benefits.
This file largely just constructs a Qflow CSS layer over the component

Some notes to help understand rc-checkbox:
- a checkbox input is rendered and hidden from view
- a span is used to render a checkbox
- CSS is used to connect events like hover/focus back to the span
- what users actually click/focus when interacting with the element is the input itself!
*/

/**
 * @param {{
 *   name: string,
 *   value: boolean,
 *   onChange: (boolean) => void,
 *   size: 'small' | 'medium' | 'large',
 *   children: string
 * }} props
 * @returns {React.ReactElement}
 */
export function Checkbox({
  name,
  value,
  onChange,
  size = 'medium',
  children = null,
  ...props
}) {
  const handleChange = useCallback(
    e => {
      onChange?.(e.target.checked)
    },
    [onChange]
  )

  let checkboxSize = sizes.MEDIUM
  let textSize = sizes.SMALL
  if (size === 'small') {
    checkboxSize = sizes.SMALL
    textSize = sizes.SMALL
  }
  if (size === 'large') {
    checkboxSize = sizes.LARGER
    textSize = sizes.MEDIUM
  }

  const element = (
    <StyledRcCheckbox
      {...props}
      name={name}
      checked={value}
      onChange={handleChange}
      prefixCls={prefix}
      $size={checkboxSize}
    />
  )

  return children ? (
    <Label $size={textSize}>
      {element}
      {children}
    </Label>
  ) : (
    element
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  children: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.bool,
  onChange: PropTypes.func
}

const Label = styled.label`
  font-size: ${({ $size }) => $size};

  display: flex;
  align-items: center;
  cursor: pointer;

  * {
    margin: auto;
    margin-right: 5px;
  }
`

const StyledRcCheckbox = styled(RcCheckbox)`
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;

  /* native input hidden for accessibility needs */
  .q-input {
    cursor: pointer;

    position: absolute;
    z-index: 9999;
    opacity: 0;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 100%;
    margin: 0;

    :enabled {
      :hover ~ .q-inner {
        background-color: ${({ theme }) => theme.secondary.bgHover};
      }
      :focus ~ .q-inner {
        background-color: ${({ theme }) => theme.secondary.bgHover};
      }
      :active ~ .q-inner {
        background-color: ${({ theme }) => theme.secondary.bgFocused};
      }

      :checked {
        :hover ~ .q-inner {
          background-color: ${({ theme }) => theme.primary.highlightHover};
        }
        :focus ~ .q-inner {
          background-color: ${({ theme }) => theme.primary.highlightHover};
        }
        :active ~ .q-inner {
          background-color: ${({ theme }) => theme.primary.highlightFocused};
        }
      }
    }

    :disabled {
      cursor: default;
    }
  }

  .q-inner {
    position: relative;
    display: block;
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};

    border-radius: 3px;

    transition: 150ms all ease-in-out;
    background-color: ${({ theme }) => theme.secondary.bg};
    border: 1px ${({ theme }) => theme.secondary.border} solid;
    border-collapse: separate;

    &:after {
      pointer-events: none;

      transition: 300ms all ease-in-out;
      opacity: 0;
      transform: rotate(0deg);
      height: 70%;
      width: 30%;

      display: table;
      margin: auto;
      margin-top: 10%;

      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;

      content: ' ';
    }

    ${({ checked = false }) => checked && CSSInnerChecked}
    ${({ disabled = false }) => disabled && CSSInnerDisabled}
  }
`

const CSSInnerChecked = css`
  background-color: ${({ theme }) => theme.primary.highlight};
  border-color: ${({ theme }) => theme.primary.highlight};

  &:after {
    opacity: 1;

    transform: rotate(45deg);
    height: 70%;
    width: 30%;
  }
`
const CSSInnerDisabled = css`
  background-color: ${({ theme }) => theme.primary.disabled};
  border-color: ${({ theme }) => theme.primary.disabled};
`
