import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Toolbar } from './Toolbar'
import { LightThemeProvider } from '@qflow/theme'
import { SideBar, ControlPanel } from 'portal/lib/primitives'
import { useResponsive } from 'portal/lib/hooks'

export const Layout = ({ children }) => {
  const { isMobile } = useResponsive()

  return (
    <SideBar.PortalProvider>
      <ControlPanel.PortalProvider>
        {!isMobile && <Toolbar />}

        <Content>
          <SideBar.PortalExit />

          <LightThemeProvider>
            <ScrollContainer>{children}</ScrollContainer>
          </LightThemeProvider>

          <ControlPanel.PortalExit />
        </Content>

        {isMobile && <Toolbar />}
      </ControlPanel.PortalProvider>
    </SideBar.PortalProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

const Content = styled.main`
  display: flex;
  width: 100%;
  height: 100%;

  overflow: hidden;

  position: relative;
`

const ScrollContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: auto;

  justify-content: center;

  background-color: ${({ theme }) => theme.general.bg};
`
