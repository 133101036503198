import * as actions from './actions'
import produce from 'immer'

const initialState = {
  modules: null,
  error: null,
  isLoading: false
}

const projectDetailsStart = draft => {
  draft.error = null
  draft.modules = null
  draft.isLoading = true
}

const projectDetailsSuccess = (draft, action) => {
  draft.modules = action.modules
  draft.error = null
  draft.isLoading = false
}

const projectDetailsFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const projectDetailsClear = draft => {
  draft.error = null
  draft.modules = null
  draft.isLoading = false
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.PROJ_GET_DETAILS_START:
        return projectDetailsStart(draft, action)
      case actions.PROJ_GET_DETAILS_SUCCESS:
        return projectDetailsSuccess(draft, action)
      case actions.PROJ_GET_DETAILS_FAIL:
        return projectDetailsFail(draft, action)
      case actions.PROJ_CLEAR_DETAILS:
        return projectDetailsClear(draft, action)
      default:
        return state
    }
  })
}

export { reducer }
