import axios from 'axios'

import { apiURL, correlationHeader } from 'portal/lib/config/env-config'
import createLoggingContext from 'portal/utils/logger/logger'
import {
  msal,
  authenticationParameters,
  handleTokenAcquireError,
  redirectPromise
} from 'portal/store/auth/msal'

const instance = axios.create({
  baseURL: apiURL
})

async function getToken() {
  try {
    await redirectPromise

    return await msal.acquireTokenSilent({
      ...authenticationParameters,
      account: msal.getAllAccounts()[0]
    })
  } catch (err) {
    handleTokenAcquireError(err, { forceInteration: false })
    throw err
  }
}

instance.interceptors.request.use(
  config => {
    return getToken()
      .then(response => {
        config.headers['Authorization'] = 'bearer ' + response.accessToken
        config.headers['Accept'] = 'application/json; charset=utf-8'
        config.headers[correlationHeader] = createLoggingContext()
        return Promise.resolve(config)
      })
      .catch(() => {
        // Bail safely, we handle and log this separately
      })
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance

export { axios as axiosRaw }
