import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AppRoute } from './AppRoute'
import { authCheckState } from 'portal/store/auth/actions'
import { isSignedIn, authIsChecked } from 'portal/store/auth/selectors'

export const UnauthenticatedRoute = ({
  redirectOnAuthenticated = false,
  checkAuth = true,
  ...props
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (checkAuth) {
      dispatch(authCheckState({ interactOnUnauthorised: false }))
    }
  }, [dispatch, checkAuth])

  const authChecked = useSelector(authIsChecked)
  const authenticated = useSelector(isSignedIn)

  if (redirectOnAuthenticated && authenticated && authChecked) {
    return <Redirect to={'/accounts'} />
  }

  return <AppRoute {...props} />
}

UnauthenticatedRoute.propTypes = {
  ...AppRoute.propTypes,
  redirectOnAuthenticated: PropTypes.bool
}
