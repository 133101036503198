import { all, put, call, takeLatest } from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'

import {
  loadRecordInfractionsFailure,
  loadRecordInfractionsSuccess,
  RECORD_INFRACTIONS_LOAD,
  loadRecordInfractions
} from 'portal/store/infractions/actions'

import { RECORD_DETAILS_LOAD } from 'portal/store/records/details/actions'

function getURI(accountId, projectId, moduleName, recordId) {
  return (
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/infractions/modules/' +
    moduleName +
    '/records/' +
    recordId
  )
}

function* loadRecordInfractionsSaga(action) {
  const { accountId, projectId, moduleName, recordId } = action
  try {
    const response = yield call(() =>
      axios.get(getURI(accountId, projectId, moduleName, recordId), {
        validateStatus: status => status == 200
      })
    )
    yield put(loadRecordInfractionsSuccess(recordId, response.data))
  } catch (error) {
    yield put(loadRecordInfractionsFailure(recordId, error))
  }
}

export function* watch() {
  yield all([
    takeLatest(
      [RECORD_DETAILS_LOAD],
      function* ({ accountId, projectId, moduleName, recordId }) {
        yield put(
          loadRecordInfractions(accountId, projectId, moduleName, recordId)
        )
      }
    )
  ])

  yield all([
    takeLatest(
      [RECORD_INFRACTIONS_LOAD, RECORD_DETAILS_LOAD],
      loadRecordInfractionsSaga
    )
  ])
}
