import React, {
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef
} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Text } from 'portal/lib/primitives/text'
import { Flex, Spacer } from 'portal/lib/primitives/layout'
import * as TextInputs from './TextInputs'

const selectOnFocus = e => e.target.select()

const TYPE_CELL = 'cell'
const TYPE_CELL_SELECTOR = 'cell_selector'
const TYPE_SPACER = 'spacer'
const TYPE_SEPARATOR = 'separator'
export const CELL_TYPES = {
  TYPE_CELL,
  TYPE_CELL_SELECTOR,
  TYPE_SEPARATOR,
  TYPE_SPACER
}

const isCellType = ({ type }) =>
  type === TYPE_CELL || type === TYPE_CELL_SELECTOR

export const TextInputCells = forwardRef(
  ({ value = {}, cells = [], onChange, onBlur, ...props }, ref) => {
    const orderedKeys = useMemo(
      () => cells.filter(isCellType).map(cell => cell.key),
      [cells]
    )

    const refsByKey = useMemo(
      () =>
        cells
          .filter(isCellType)
          .reduce(
            (aggr, { key }) => ({ ...aggr, [key]: React.createRef() }),
            {}
          ),
      [cells]
    )

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          refsByKey[orderedKeys[0]].current?.focus()
        }
      }),
      [orderedKeys, refsByKey]
    )

    const callbacksByKey = useMemo(
      () =>
        cells
          .filter(isCellType)
          .reduce((callbacksByKey, { key, maxLength }) => {
            callbacksByKey[key] = keyValue => {
              onChange({ key, value: { ...value, [key]: keyValue } })

              const nextKey = orderedKeys[orderedKeys.indexOf(key) + 1]
              if (keyValue && keyValue.length >= maxLength && nextKey != null) {
                refsByKey[nextKey].current.focus()
              }
            }

            return callbacksByKey
          }, {}),
      [cells, onChange, orderedKeys, refsByKey, value]
    )

    const handleBlur = useCallback(
      /** @param {import('react').SyntheticEvent} e */
      e => {
        if (e.persist) {
          e.persist()
        }
        if (e.stopPropagation) {
          e.stopPropagation()
        }

        setTimeout(() => {
          const active = document.activeElement
          const anyInputFocused = Object.values(refsByKey).some(
            ref => ref.current === active
          )

          if (!anyInputFocused) {
            onBlur?.(e)
          }
        }, 0)
      },
      [onBlur, refsByKey]
    )

    return (
      <Flex.Row verticalCentre amount={0}>
        {cells.map(({ type, ...rest }, i) => {
          switch (type) {
            case TYPE_CELL: {
              const { key, maxLength, placeholder } = rest

              return (
                <TextInputs.Text
                  {...props}
                  id={'cell_' + key}
                  key={key}
                  value={value[key]}
                  onChange={callbacksByKey[key]}
                  onBlur={handleBlur}
                  onFocus={selectOnFocus}
                  maxLength={maxLength}
                  cols={maxLength}
                  css={TextInputCSS}
                  placeholder={placeholder}
                  ref={refsByKey[key]}
                />
              )
            }

            case TYPE_CELL_SELECTOR: {
              const { key, options, placeholder } = rest

              return (
                <TextInputs.Selector
                  {...props}
                  id={'cell_' + key}
                  key={key}
                  value={value[key]}
                  options={options}
                  onChange={callbacksByKey[key]}
                  onBlur={handleBlur}
                  css={SelectInputCSS}
                  placeholder={placeholder}
                  ref={refsByKey[key]}
                />
              )
            }

            case TYPE_SPACER:
              return <Spacer.Medium key={i} />

            case TYPE_SEPARATOR: {
              const { value = ' ' } = rest

              return <Separator key={i}>{value}</Separator>
            }

            default:
              throw 'Unknown cell type: ' + type
          }
        })}
      </Flex.Row>
    )
  }
)

TextInputCells.propTypes = {
  value: PropTypes.object.isRequired,
  cells: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        type: PropTypes.oneOf([TYPE_CELL]).isRequired,
        key: PropTypes.string.isRequired,
        maxLength: PropTypes.number,
        placeholder: PropTypes.string
      }),
      PropTypes.exact({
        type: PropTypes.oneOf([TYPE_SPACER]).isRequired
      }),
      PropTypes.exact({
        type: PropTypes.oneOf([TYPE_SEPARATOR]).isRequired,
        value: PropTypes.string
      })
    ])
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

const Separator = styled(Text)`
  min-width: 0.5rem;
  text-align: center;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
`

const TextInputCSS = css`
  text-align: center;
`

const SelectInputCSS = css`
  text-align: center;

  option {
    font-size: 14px;
  }
`
