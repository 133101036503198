export const MASS_UPLOAD_RESET = 'MASS_UPLOAD_RESET'
export const massUploadReset = () => ({
  type: MASS_UPLOAD_RESET
})

//
// Upload

export const MASS_UPLOAD_START = 'MASS_UPLOAD_START'
export const massUploadStart = (accountId, projectId, moduleName, file) => ({
  type: MASS_UPLOAD_START,
  accountId,
  projectId,
  moduleName,
  file
})

export const MASS_UPLOAD_SUCCESS = 'MASS_UPLOAD_SUCCESS'
export const massUploadSuccess = (id, outputs, summary) => ({
  type: MASS_UPLOAD_SUCCESS,
  id,
  outputs,
  summary
})

export const MASS_UPLOAD_FAILURE = 'MASS_UPLOAD_FAILURE'
export const massUploadFailure = error => ({
  type: MASS_UPLOAD_FAILURE,
  error
})

//
// Confirmaton

export const MASS_UPLOAD_CONFIRM_START = 'MASS_UPLOAD_CONFIRM_START'
export const massUploadConfirm = (accountId, projectId, moduleName, id) => ({
  type: MASS_UPLOAD_CONFIRM_START,
  accountId,
  projectId,
  moduleName,
  id
})

export const MASS_UPLOAD_CONFIRM_SUCCESS = 'MASS_UPLOAD_CONFIRM_SUCCESS'
export const massUploadConfirmSuccess = () => ({
  type: MASS_UPLOAD_CONFIRM_SUCCESS
})

export const MASS_UPLOAD_CONFIRM_FAILURE = 'MASS_UPLOAD_CONFIRM_FAILURE'
export const massUploadConfirmFailure = error => ({
  type: MASS_UPLOAD_CONFIRM_FAILURE,
  error
})
