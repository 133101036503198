import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Text, Flex } from 'portal/lib/primitives'
import { fileSizes } from 'portal/lib/constants'

export function AttachmentUploadError() {
  const { error } = useSelector(state => state.records.attachmentUpload)
  if (!error) {
    return null
  }

  return (
    <Container amount={0}>
      <Text error>{getErrorMessage(error)}</Text>
    </Container>
  )
}

const getErrorMessage = error => {
  return error != 'ATTACHMENT_VALIDATION_TOO_LARGE'
    ? 'Upload failed, please try again'
    : 'File size too big. The limit is ' + fileSizes.ATTACHMENTLIMIT_MB + 'MB'
}

const Container = styled(Flex.Centred)`
  margin-left: 0.5rem;
`
