import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useRef,
  useLayoutEffect
} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { icons, Text, Spacer, Clickable } from 'portal/lib/primitives'
import { useTheme } from '@qflow/theme'

const Context = React.createContext()

export function EditablesProvider({ children, ...props }) {
  const [editableKeys, setEditableKeys] = useState({})

  const toggleEditable = useCallback(key => {
    setEditableKeys(editableKeys => ({
      [key]: !editableKeys[key]
    }))
  }, [])

  const resetEditables = useCallback(() => {
    setEditableKeys({})
  }, [])

  const value = useMemo(() => {
    return {
      editableKeys,
      toggleEditable
    }
  }, [editableKeys, toggleEditable])

  return (
    <Context.Provider value={value}>
      {React.cloneElement(children, { ...props, resetEditables })}
    </Context.Provider>
  )
}

EditablesProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export function withEditables(WrappedComponent) {
  return props => (
    <EditablesProvider>
      <WrappedComponent {...props} />
    </EditablesProvider>
  )
}

export function useEditable(key) {
  const { editableKeys, toggleEditable } = useContext(Context)

  const toggle = useCallback(() => {
    toggleEditable(key)
  }, [key, toggleEditable])

  return {
    editable: !!editableKeys[key],
    toggle
  }
}

export function Editable({
  name,
  value,
  children,
  formatter = value => value,
  suffix = ''
}) {
  const theme = useTheme()

  const { editable, toggle } = useEditable(name + suffix)

  const fieldRef = useRef()
  useLayoutEffect(() => {
    if (editable) {
      fieldRef.current?.focus()
    }
  }, [editable])

  const displayValue = useMemo(() => formatter(value), [value, formatter])
  if (!editable) {
    return (
      <Clickable onClick={toggle} css={ClickableCSS}>
        <PencilIcon colour={theme.primary.highlight} />

        <Spacer.Tiny />

        <Text dim={displayValue == null || displayValue === ''}>
          {displayValue || '(empty)'}
        </Text>
      </Clickable>
    )
  }

  return React.cloneElement(children, { ref: fieldRef })
}

Editable.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  formatter: PropTypes.func,
  suffix: PropTypes.string
}

const ClickableCSS = css`
  flex: 1 1 auto;
  justify-content: flex-start;

  text-align: left;
`

const PencilIcon = styled(icons.EditPencil)`
  display: flex;
  flex: 0 0 auto;
`
