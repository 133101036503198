import { Table } from './Table'
import { Row, AutoRow } from './Large'
import { Card, AutoCard } from './Small'

export const ResponsiveTable = {
  Table,
  Row,
  AutoRow,
  Card,
  AutoCard
}
