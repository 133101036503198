import React, { createRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NODE_REF = createRef()

export function PortalRootNode() {
  return <PortalRoot id="PortalRootNode" ref={NODE_REF} />
}

export function Portal({ children }) {
  if (!NODE_REF.current) {
    process.env.NODE_ENV === 'development' &&
      console.warn('PortalRootNode is not yet mounted.')
    return null
  }

  return createPortal(children, NODE_REF.current)
}

Portal.propTypes = {
  children: PropTypes.node.isRequired
}

const PortalRoot = styled.div`
  z-index: 10000;
`
