import styled from 'styled-components'
import { colours } from '@qflow/theme'

export const AssignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-height: 66.7vh;
  min-width: 100%;
  overflow-y: auto;
  overflow: display;
  background-color: ${colours.WHITE};
  border-radius: 6px;
  padding-top: 4px;

  & button:focus {
    outline: 2px solid ${colours.BLACK};
  }
`

export const AssignmentStripCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
`

export const AssignmentButtonContainer = styled.div<{
  $remainingAssignees: number
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $remainingAssignees }) =>
    $remainingAssignees > 0 ? 'space-between' : 'flex-end'};
  padding: 0.5rem;
`

export const ButtonWithMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const LoadingMessage = styled.div`
  height: 40px;
  padding-top: 0.75rem;
`

export const MaxThresholdMessage = styled.div`
  width: 388px;
  margin: 0 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem 0;
  border: 2px solid ${colours.RED};
  border-radius: 0.5rem;
  background-color: ${colours.WHITE};
`
