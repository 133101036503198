import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colours } from '@qflow/theme'

import { SubHeading } from 'portal/lib/primitives/text'
import * as icons from 'portal/lib/primitives/icons'
import { Modal } from 'portal/lib/primitives/portals'

import { NavButton } from './NavButton'
import { TrayContainer, TrayCardBase, BlurPanel } from './primitives'
import { useResponsive } from 'portal/lib/hooks'

export function NavDropdown({
  id = 'NavDropdown',
  open,
  setOpen,
  Icon,
  label,
  title,
  disabled = false,
  loading = false,
  customLabelElement = null,
  children
}) {
  const { isMobile } = useResponsive()

  const setClosed = useCallback(() => {
    setOpen && setOpen(false)
  }, [setOpen])

  const toggleOpen = useCallback(() => {
    setOpen && setOpen(!open)
  }, [setOpen, open])

  return (
    <Container id={id}>
      <BlurPanel id="BlurPanel" open={open} onClick={setClosed} />

      <NavButton
        id="DropdownButton"
        onClick={toggleOpen}
        disabled={disabled || loading}
        isOpen={open}
      >
        {loading ? (
          <icons.Loading data-testid="spinner-active" />
        ) : (
          <>
            {customLabelElement ?? (
              <SubHeading IconComponent={isMobile ? null : Icon} isChild>
                {label}
              </SubHeading>
            )}{' '}
          </>
        )}
      </NavButton>

      {isMobile ? (
        <Modal
          title={title ?? label}
          show={open}
          closeClick={setClosed}
          IconLeft={Icon}
        >
          <MobileCard id="DropdownCard">{children}</MobileCard>
        </Modal>
      ) : (
        <TrayContainer open={open}>
          <DropdownWrapper>
            <Card id="DropdownCard">{children}</Card>
          </DropdownWrapper>
        </TrayContainer>
      )}
    </Container>
  )
}

NavDropdown.propTypes = {
  id: PropTypes.string,
  Icon: PropTypes.elementType,
  label: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  customLabelElement: PropTypes.object
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;

  flex-direction: column;
`

const DropdownWrapper = styled.div`
  max-height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
`

const Card = styled(TrayCardBase)`
  color: ${colours.CORE_NAVY};
  background-color: ${colours.WHITE};

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const MobileCard = styled(Card)`
  position: relative;
`
