import React, {
  createContext,
  useState,
  useContext as useReactContext,
  useEffect,
  useRef,
  useLayoutEffect
} from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'

import { LightThemeProvider, sizes } from '@qflow/theme'
import { Clickable } from 'portal/lib/primitives/buttons/Clickable'
import * as icons from 'portal/lib/primitives/icons'
import { useResponsive } from 'portal/lib/hooks'
import { Panel } from './Panel'

const SideBarContext = createContext()

export function PortalProvider(props) {
  const [element, setElement] = useState(null)
  const [mobileShow, setMobileShow] = useState(false)
  const [isOnPage, setIsOnPage] = useState(false)

  const location = useLocation()
  const lastLocation = useRef(location)
  useEffect(() => {
    if (location !== lastLocation.current) {
      lastLocation.current = location
      setMobileShow(false)
    }
  }, [location, mobileShow])

  return (
    <SideBarContext.Provider
      {...props}
      value={{
        element,
        setElement,
        mobileShow,
        setMobileShow,
        isOnPage,
        setIsOnPage
      }}
    />
  )
}

export function useContext() {
  return useReactContext(SideBarContext)
}

export function PortalExit() {
  const { setElement } = useContext()

  const ref = useRef()
  useEffect(() => {
    setElement(ref.current)
  }, [setElement])

  return <PortalExitDiv id="SideBarPortalRoot" ref={ref} />
}

export function Show({ children }) {
  const { isMobile, isSmall } = useResponsive()
  const { element, mobileShow, setMobileShow, setIsOnPage } = useContext()

  useLayoutEffect(() => {
    if (!element) {
      return
    }

    setIsOnPage(true)
    return () => setIsOnPage(false)
  }, [element, setIsOnPage])

  if (!element) {
    return null
  }

  return createPortal(
    <LightThemeProvider>
      <Fade in={isMobile && mobileShow} enter exit>
        <Backdrop onClick={() => setMobileShow(false)} />
      </Fade>

      <Panel
        id="SideBar"
        isMobile={isMobile || isSmall}
        mobileShow={mobileShow}
        extraElements={
          isSmall &&
          !isMobile && (
            <ExpandButtonContainer onClick={() => setMobileShow(show => !show)}>
              {mobileShow ? (
                <icons.ChevronRight colour="currentColor" size={sizes.SMALL} />
              ) : (
                <icons.Settings size={sizes.MEDIUM} />
              )}
            </ExpandButtonContainer>
          )
        }
      >
        {children}
      </Panel>
    </LightThemeProvider>,
    element
  )
}

Show.propTypes = {
  children: PropTypes.node.isRequired
}

const PortalExitDiv = styled.div`
  display: flex;
  flex: 0 0 auto;
`

const ExpandButtonContainer = styled(Clickable)`
  background-color: ${({ theme }) => theme.controlPanel.bg};
  color: ${({ theme }) => theme.general.fg};
  opacity: 1;

  border: solid 1px;
  border-color: ${({ theme }) => theme.controlPanel.border};
  border-right: none;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  outline: none;
  padding: 0;

  position: absolute;
  height: 5rem;
  top: 3rem;
  min-width: 0;
  width: 1.5rem;
  left: calc(-1.5rem - 1px);

  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.modal.backfill};

  z-index: 1999;
`
