import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Workers({ size = sizes.SMALL, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="80"
      viewBox="0 0 72 80"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <path
            fill="#C6CAD2"
            d="M3.124 68.039c.735-.277 1.308-.277 1.719 0 1.043.534 2.396.705 4.059.513.73-1.452 1-4.127.808-8.026.646-2.977 1.21-5.125 1.69-6.443 0-2.042.375-4.152 1.122-6.332l.809-3.911.594 3.49 1.222 4.88V64.838c.154 1.27.518 2.004 1.094 2.203 1.621.562 3.213.552 4.775-.028l.536-5.763c.232-1.831.232-3.553 0-5.167 0-2.035-.433-3.722-1.3-5.061.606-2.038 1.04-4.126 1.3-6.266v-4.416l-.536-5.022-7.091 1.032c-2.345.234-4.803.234-7.373 0-1.07.753-1.541 2.913-1.415 6.477-.15 1.9-.248 3.687-.294 5.36-.049 1.744-.143 3.349-.285 4.813-.47 1.29-.767 2.317-.889 3.083-.246 1.547-.246 3.028 0 4.444l-.545 2.808c-.348 2.129-.348 3.697 0 4.705z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M6.552 36.349c2.183.283 4.36.362 6.527.24 4.278-.47 4.748-.817 7.937-1.063.229-.488.229-1.516 0-3.082v-9.339l3.803-1.737c-1.202-2.84-2.355-5.351-3.46-7.532-2.458-.906-3.799-1.194-5.23-1.85-.376.944-.64.958-1.919 1.85-1.056.609-1.304.227-2.518 0-.781-.146-1.237-.544-1.367-1.194l-4.872 2.586-2.59 7.877c.262.183.905.465 1.928.845 1.024.38 1.907.452 2.652.218-.516 1.352-.813 2.55-.891 3.595 0 1.127.136 3.012.407 5.655v.241c-.067.28-.203.66-.407 1.14-.07.458-.07.974 0 1.55z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M6.683 34.13c.388.569.816.904 1.286 1.003 1.659.353 1.985.381 3.441 0 .764-.206 1.574-.718 2.43-1.536 1.17 1.024 2.859 1.667 5.069 1.93.518 0 1.024-.235 1.518-.703l.814-3.21.485-3.653v-2.828l-.71-2.028.225-9.5c-.794-.61-1.931-1.15-3.411-1.618-.224-.071-.791-.071-1.701 0l-1.115 5.823c-.782 3.178-1.343 4.943-1.683 5.295-.824-4.097-1.921-5.997-2.84-7.865-.342-1.189.657-2.745 0-2.747-1.682.711-3.371 1.519-5.068 2.422l.585 3.147.675 7.07v8.997z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M19.491 35.319c-.07-1.82-.136-3.72-.197-5.703-.061-1.968-.083-1.778-.108-3.629-.029-2.084-.062-2.26 0-4.24.09-2.898.273-2.745.305-5.515-.131-1.755-.197-3.011-.197-3.769l-2.028-.79c.191 1.663.287 3.437.287 5.324 0 1.853-.096 3.712-.287 5.577l-.273 3.413c-.052 1.229-.052 2.27 0 3.127.202 3.289.177 5.038.56 6.02.904.261 1.55.323 1.938.185zM16.224 12.552c-.65-.587-1.02-1.315-1.11-2.186h-3.888c-.2 1.08-.493 2.11-.879 3.089.503.91 1.453 1.346 2.984 1.09 1.53-.255 2.426-.787 2.893-1.993zM10.347 35.39c0-2.073-.17-4.006-.513-5.799-.061-1.968.025-3.425 0-5.276-.124-.91-.368-2.063-.306-4.044-.185-2.121-.211-1.488-.18-4.258 0-1.696.06-2.75.18-3.164l-2.436.987c.162.56.244 1.783.244 3.67 0 1.853.096 3.687.288 5.501l-.288 3.088c-.052 1.228-.052 2.27 0 3.126.201 3.29.434 4.92.817 5.901.904.262 1.635.352 2.194.269z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M10.527 10.044c.747 1.522 1.296 2.284 2.56 2.284.894 0 1.726-.762 2.499-2.284.737-1.11 1.125-2.248 1.164-3.414.047-1.438-.297-2.141-.954-2.961-.433-.486-1.162-.888-2.282-.888-.84 0-1.666.296-2.478.888-.804.821-1.246 1.733-1.326 2.734-.134 1.686.2 2.379.817 3.64z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M9.336 6.788c1.926-1.062 5.801-1.411 6.739-1.439.278-.008.536.104.79.335.242-.303.376-.542.404-.72l-.569-.532c-.226-3.22-1.623-4.67-4.191-4.35-3.852.481-3.173 4.13-3.173 5.267-.408.252-.276.252-.387.587-.093.279.111.518.387.852zM23.757 21.838c.242.727.409 1.291.5 1.693.252 1.103.932 2.087 1.22 3.835.18 1.635.12 3.453-.177 5.454h-2.258c-.434-1.418-.789-2.907-1.066-4.467-.161-.91-.245-1.893-.25-2.946l-.71-2.302 2.741-1.267zM6.546 24.315c.179 1.21.179 2.558 0 4.046.18 1.634-.32 3.978-1.5 7.03l-1.934-.504v-4.573c.153-1.393.474-2.787.963-4.185l.185-2.399 2.286.585z"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M21.998 33.336l.965-.675c1.498-.127 2.37.016 2.615.427.128.46.128 1.227 0 2.303l.192.403-.192.555.39.95-.532 1.989c-.411.395-.965.676-1.66.845-.798.137-1.262-.061-1.778-.253-.118-.044-.209-.185-.272-.423h-.304l-.159-1.72c.158-.454.347-.86.567-1.217.178-.29.388-.533.629-.726-.24-.169-.393-.378-.46-.628-.049-.176-.01-.393.114-.65l-.115-1.18zM2.625 34.061c1.005.675 1.938 1.012 2.798 1.012l1.257.864-1.257 2.158-.144 4.19h-.63l-.516-.47c-.333.076-.673.114-1.02.114-1.138-.548-1.282-1.75-1.282-2.328.125-.6.306-1.102.542-1.506l.49-.924c-.327-1.359-.406-2.395-.238-3.11z"
            transform="translate(45 4.938)"
          />
          <path
            stroke="#979797"
            stroke-linecap="round"
            stroke-width=".1"
            d="M21.836 39.451L22.432 39.009 22.933 38.102 23.498 37.321M4.256 41.858L4.447 41.092 4.447 40.498 4.579 39.801"
            opacity=".294"
            transform="translate(45 4.938)"
          />
          <path
            fill="#C6CAD2"
            d="M19.742 71.772c3.148.839 3.917.126 4.175-.488.172-.409.172-.92 0-1.533l-1.327-1.402-1.574-1.337c-.97.29-1.9.398-2.793.32-.88-.076-1.722-.336-2.526-.78-.295.749-.355 1.44-.18 2.076.202.727.399.859 1.273 1.323.518.275 1.04-.17 1.433.155.72.597 0 1.018 1.52 1.666zM2.328 73.063c1.172-.038 3.08-1.096 3.433-1.625.43-.648.802-.648 1.383-.833.614-.196 1.19-.613 1.725-1.251v-1.186l-3.108-.807c-1.009-.54-2.059-.117-3.15 1.267-.492.66-1.353.727-2.394 1.977-.163.523-.393 1.517 0 1.842.72.597.972.296 2.111.616z"
            transform="translate(45 4.938)"
          />
        </g>
        <g fill="#C6CAD2">
          <path
            d="M0 36.282c2.369.92 2.844.671 6.926.92 2.722.166 5.448.166 8.179 0 .842-1.339 1.263-2.763 1.263-4.273 0-5.955-.039-7.664-1.263-12.168s-1.634-4.24-4-6.628c-1.947-1.415-3.34-1.957-4.179-1.625-1.744 1.056-3.263 3.807-4.557 8.253C.302 27.855.302 28.38 0 36.281z"
            opacity=".689"
            transform="translate(23)"
          />
          <path
            d="M.759 26.847c.342-.256 1.804-.256 3.02 0 1.269.19 1.349.561 3.377.596 3.471.06 6.103 0 9.212-.406v3.323c-3.385.242-6.343.242-8.873 0-2.233-.214-2.469-.817-4.284-1.155-.854-.16-1.794-.16-2.82 0 .143-1.119.266-1.905.368-2.358zM.206 30.962c.342-.255 1.33.199 3.207.283 2.97.113 3.217.723 4.533.723 2.616-.355 5.313.05 8.422-.355v2.054c-3.048.048-5.96.16-8.735.337-1.625-.252-2.905-.384-4.72-.723-.854-.159-1.777-.261-2.77-.307.042-.907.063-1.578.063-2.012zM6.349 22.729h6.064c.883-2.503.092-6.116-1.181-7.314-1.273-1.197-3.048-.71-4.472 1.996-.95 1.803-1.087 3.575-.411 5.318z"
            transform="translate(23)"
          />
          <path
            d="M6.63 22.729c.133 1.07.363 2.117.691 3.141.573 1.787 1.474 3.55 2.703 5.29l13.33-1.831c.408-1.244.612-2.228.612-2.954-.74-.685-1.649-.853-2.725-.505-1.054.884-2.282 1.415-3.685 1.593-1.44 0-3.029-.173-4.768-.518.157-2.008.032-3.413-.375-4.216-.425-.262-2.353-.262-5.783 0zM2.681 71.809c-.168 1.142-.294 1.979-.378 2.512-.558.865-.665 1.646-.322 2.345l3.252.137c5.225.003 7.862.003 7.912 0 1.067-.285 1.51-.942.508-1.775-1.056-.527-.952-.974-2.818-1.679-1.216-.37-1.98-.625-2.293-.765-1.423-.213-2.681-.266-3.773-.16-.992-.046-1.688-.251-2.088-.615z"
            transform="translate(23)"
          />
          <path
            d="M5.034 74.73c-.527.526-.833 1.055-.917 1.588-.084.525.053 1.055.412 1.587h4.543c3.678.101 6.391.101 8.14 0 .237-.514.788-1.305-.215-2.138-1.056-.527-1.479.178-3.344-.527 0-.567-.58-1.132-1.742-1.696-1.81.594-3.12 1.013-3.933 1.257-.992-.047-1.974-.258-2.944-.632v.562zM5.077 72.837l-2.774-1.006.514-1.467v-.53c-.022-1.015-.195-1.804-.514-2.983.789-5.7.348-6.82 1.355-11.656.306-.812.779-1.58 1.42-2.305l-.285-2.447c.173-1.654.268-2.828.284-3.524.016-.7-.367-1.342-.284-1.924.19-.555.522-1.045.996-1.471-1.168-.492-1.879-1.032-2.131-1.62.306-.78.459-1.683.459-2.707l3.267-1.317 6.269.537-1.256 18.117-2.373 8.87-.317 8.14-4.63-.707z"
            transform="translate(23)"
          />
          <path
            d="M5.014 74.169c1.426.562 2.506.75 3.241.562.735-.188 1.954-.583 3.656-1.187l-1.143-1.59 1.572-9.661 1.922-7.999 1.036-9.025v-7.943l-11.181-.413v3.292l.897 2.887 1.616 2.593-.703 8.61-1.44 18.542.527 1.332zM9.817 9.06c-.46 1.143-.755 1.617-1.633 3.73.071.318 1.84 0 3.823 2.512 0-1.052.97-1.547 1.723-2.194-2.09-1.593-2.773-3.438-3.913-4.049z"
            transform="translate(23)"
          />
          <path
            d="M15.598 13.327c1.302-1.8 2.074-2.273 2.719-6.163-.256-3.057-.658-5.826-2.72-6.043-2.06-.217-5.255.427-5.573 2.326-.317 1.898-.666 3.905-.207 5.612.458 1.708 4.479 6.068 5.78 4.268z"
            transform="translate(23)"
          />
          <path
            d="M8.587 6.447c.681 0 1.606.097 2.37 0 1.298-2.598 1.797-2.029 3.703-.827.642.396 2.177.995 4.603 1.798.04-2.125-.055-3.554-.283-4.286-.342-1.097-1.312-2.024-2.91-2.78-2.126-.646-3.74-.402-4.844.729-1.655 1.697-2.078 3.223-2.64 5.366z"
            transform="translate(23)"
          />
          <path
            d="M15.71 6.053c1.062.39 1.832.774 2.311 1.152.28.262.321.525.123.792 1.36.214 2.06.214 2.096 0 .204-.792-.421-1.34-1.457-1.65-.64-.26-1.663-.358-3.072-.294z"
            transform="translate(23)"
          />
        </g>
        <g fill="#C6CAD2">
          <path d="M15.586 10.405c.466-.386.835-.806 1.106-1.26 0-.297 0-.518-.542-.518-.395.344-.835.762-1.318 1.257l-.834 1.3c-1.282.901-1.973 1.567-2.075 1.997-.007.032.223-.26 1.448.82 1.128.207 2.394.207 3.07-.57-.273-.895-.41-1.426-.41-2.247-.238-.29-.429-.391-.575-.305l.13-.474z" />
          <path d="M6.96 11.298l-.7-1.983-.65-2.462c-.185-1.664.187-3.103 1.118-4.316 2.704-2.957 5.697-.985 7.182 1.191.804 4.6-.418 8.225-.88 8.225-.713 1.094-1.443 1.528-2.189 1.301-.249.492-.527.748-.835.767-1.718.228-2.671.051-2.858-.53l-.189-2.193z" />
          <path d="M3.655 36.146c1.418 1.106 2.89 1.865 4.416 2.278 3.2.854 6.356 1.017 9.47.488h2.33s-.106-2.834-.486-4.759c-.287-1.449-.602-1.449-.848-2.643-.145-.978-.145-2.082 0-3.31v-1.238l-.795-2.812-.539-.859c-.509.573-1.097.859-1.764.859-.757-.435-1.15-.435-1.529-4.325 0-2.6-.44-5.078 1.903-5.314-1.891-1.222-2.297-.535-5.13-1.222-.788-.26-.612-.26-1.505-.846-1.144-.671-2.251-.844-3.32-.52-1.1.756-2.096 1.619-2.992 2.588-.434.443-1.333.894-2.03 2.083-.49.84-.77 1.917-.836 3.231l1.532 2.624c-.054.92-.03 1.825.073 2.715 0 2.44.622 2.796.622 3.284.151 1.082.248 2.177.293 3.287-.195 1.806.183 3.002 1.135 3.587.095.368.095.643 0 .824z" />
          <path d="M17.09 23.898c.704-.269 1.295-1.015 1.77-2.24l1.807-2.344-2.392-3.068c-.864-1.419-1.755-2.128-2.673-2.128-.917 0-1.614.976-2.09 2.93v2.981l.776 3.372c.95 1.02 1.884 1.185 2.802.497zM18.493 79.761c1.444 0 2.088.433 4.442.134.817-.652 1.29.189 2.25 0C31 80.256 31 78.304 31 78.304s-.373-1.403-1.15-1.959c-.273-.173-.955-.173-2.046 0l-1.516-.276-1.104-.775c-3.102.386-5.332.736-6.691 1.051.135.883.135.883.135 1.765-.135.194-.62.54-.135 1.651zM6.775 75.89l.312 1.757-.625.404c-.207.611-.103 1.203.313 1.776.922.213 2.214.248 3.603 0 .577-.626.53-.518 1.294-.75.9.083 1.68-.033 2.337-.348l.299-1.317c-.2-1.262-.703-1.968-1.51-2.118l-2.168 1.18-3.855-.585z" />
          <path d="M12.907 76.696c.412-.327 0-2.58 0-4.96 0-2.887-.612-2.956-.868-5.482-.119-1.173-.119-2.202 0-3.089.579-2.597.868-4.238.868-4.925 0-.687.202-2.073.606-4.159L12.039 46.1l-7.194-5.315-2.422 1.247-.635 3.333c-.403 2.295-.192 4.416.635 6.365.28 2.467.032 2.917.498 5.001.497 2.224 1.138 4.598 1.924 7.123v3.868l.674 5.827 1.512 3.734c2.626.636 4.585.44 5.876-.586z" />
          <path d="M18.828 76.863h2.543l3.15-.562.915-1.587-.915-2.149.915-2.506-1.676-4.744-.942-2.554.942-4.008s-.256-5.222-.942-5.222c-.686 0 0-4.951 0-6.292 0-.894-.229-1.984-.686-3.271l-1.573-4.18c-.231-1.136-.59-1.797-1.076-1.982-3.369-1.283-6.864-2.09-10.487-2.422l-5.74-1.659c-.947.904 0 2.463 0 2.888 0 .639-.871.683-.871 3.957 0 0 1.44 4.816 4.296 5.095 2.09 1.05 2.315 2.868 2.315 2.868s1.084-.204 1.61 1.975c2.958 6.076 3.304 5.988 3.304 10.007 0 4.018 2.755 4.8 2.755 4.8s-.545 2.213 0 4.744c0 2.303.465 1.753.818 2.506 0 0-.02 4.298.663 4.298h.682zM2.684 31.373l2.91-.297h3.289l3.386-.31 2.665-.495 2.265.495h1.878v-2.233c-.242-.192-1.23.07-2.198 0-.736-.053-1.384-.152-1.945-.298h-1.677c-.955.227-2.026.392-3.213.496-1.038.092-1.555-.029-2.75 0-2.542.39-.966 0-3.12.144-.587.017-1.184.017-1.79 0l.3 2.498zM20.718 19.608c1.55 1.36 2.47 2.26 2.76 2.7.29.44.678 1.782 1.163 4.028l-.499.724c-1.407.26-2.301.391-2.681.391-.57 0-1.005-.158-2.41-.39-.628-.34-1.067-.88-1.316-1.621l-.248-2.488 1.294-2.523 1.937-.821z" />
          <path d="M1.796 26.609h2.838c2.083 0 2.328.13 4.166 0 1.838-.13 1.437-.386 3.04-.446s2.794.192 3.776.192c1.029 0 1.197.05 2.083 0 .59-.033 1.05-.097 1.378-.192l-.442-1.413-.936-.24c.282.041-.412.041-2.083 0-2.506-.064-3.173-.257-4.961-.177-1.79.08-2.452.343-3.524.417-.715.05-1.547.05-2.497 0H1.59l.206 1.859zM12.381 23.518c-.08.107-.2-.52-.358-1.884-.237-2.045-.35-3.08-.35-4.218.002-1.14-.263-.604.355-2.177.411-1.049.906-1.884 1.485-2.507l-2.123-.183c-.596.8-1.009 1.481-1.239 2.043-.344.843-.376.937-.507 2.228-.13 1.292-.151.79 0 2.794.102 1.336.27 2.637.507 3.904h2.23zM1.258 21.96l-.185-2.127V17.89l.74-2.989-.555.28C.8 16.185.57 17.266.57 18.428c0 1.16.229 2.339.687 3.533zM7.96 9.112c1.807 0 .991-.21 1.999-.21.65 0 1.398-.215 2.24-.645.692-.954.692-1.537 0-1.747-.06-.018-.168.208-.235.191-.467-.113-1.518-.256-3.153-.426-1.737.753-2.692.977-2.865.672-.173-.305-.297.037-.371 1.029-.05.393.074.701.371.926.256.866.267.41 2.015.21z" />
          <path d="M11.128 6.048c-.313.522-.706.935-1.284 1.26-1.447.719-2.698.803-3.916 1.32-.152-.537-.273-.976-.364-1.32 1.19-.465 2.442-.886 3.756-1.26.134-.082.286-.268.455-.558.69.294 1.14.48 1.353.558z" />
          <path d="M5.1 4.647c.469-.51.388-.32.897-1.525.508-1.206.508-.873 1.082-1.637C9.545-1.01 12.007.289 13.202.755c1.195.466 1.723 1.964 1.723 2.367 0 .402.442 2.903.442 3.155 0 .132.37.267 1.22.86.211.146.104.382-.322.706l-2.336-1.299C10.95 5.28 9.007 4.724 8.102 4.88c-.905.155-1.59.62-2.056 1.398-.033.334-.505.423-1.416.267 0 0-1.75-.604.47-1.897z" />
          <path d="M6.937 8.627C7.949 8.24 8.82 8.52 9.48 7.854c.142-.59 0-1.049-.208-1.58-1.114 0-1.398.254-2.914.807.132.54.132.773.578 1.546z" />
        </g>
      </g>
    </svg>
  )
}

Workers.propTypes = {
  size: PropTypes.string
}
