import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useModuleIds } from 'portal/lib/hooks'

import { selectConfiguration } from './selectors'
import { loadConfiguration } from './actions'

export const useConfiguration = () => {
  const { accountId, projectId, moduleName } = useModuleIds()
  const dispatch = useDispatch()
  const { loading, error, value } = useSelector(
    selectConfiguration(accountId, projectId, moduleName)
  )

  useEffect(() => {
    if (!value && !loading && !error) {
      dispatch(loadConfiguration(accountId, projectId, moduleName))
    }
  }, [accountId, dispatch, error, loading, moduleName, projectId, value])

  return {
    loading,
    error,
    value
  }
}
