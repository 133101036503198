import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useModuleIds } from 'portal/lib/hooks'
import { loadEventsFilter } from 'portal/store/events/actions'

export const useEventsFilterLoader = () => {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const reload = useCallback(() => {
    dispatch(loadEventsFilter(accountId, projectId, moduleName))
  }, [accountId, dispatch, moduleName, projectId])

  useEffect(() => {
    reload()
  }, [reload])

  const {
    loading,
    error,
    description,
    tradeContractor,
    wasteCarrier,
    subcontractor,
    supplier,
    assigneeIds
  } = useSelector(state => {
    return state.events[`${moduleName}Filter`]
  })

  return {
    loadingFilter: loading,
    errorFilter: error,
    description,
    tradeContractor,
    wasteCarrier,
    subcontractor,
    supplier,
    assigneeIds
  }
}
