import { takeEvery, put, call } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import * as actions from './actions'

function* accountLoadSaga(action) {
  try {
    const response = yield call(axios.get, '/accounts/' + action.accountId, {
      validateStatus: status => status === 200
    })
    const data = response.data
    yield put(
      actions.accountLoaded(
        data.accountId,
        data.createdAt,
        data.name,
        data.members
      )
    )
  } catch (error) {
    yield put(actions.accountLoadFailure(error))
  }
}

export function* watch() {
  yield takeEvery(actions.ACCOUNT_LOAD, accountLoadSaga)
}
