import { colours } from '@qflow/theme'

export const style = {
  gridItemAvatar: {
    color: colours.WHITE,
    backgroundColor: colours.TEAL,
    borderRadius: 8,
    textTransform: 'uppercase'
  }
}
