import React from 'react'
import PropTypes from 'prop-types'

import { sizes } from '@qflow/theme'

export function ChevronDown({
  size = sizes.TINY,
  colour = 'currentColor',
  rotate = 0,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
      style={{
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
        transform: `rotate(${rotate}deg)`,
        transition: 'transform 0.1s ease-in-out'
      }}
      {...props}
    >
      <path
        fill={colour}
        fillRule="evenodd"
        d="M1.175 0.912109L5 4.72878L8.825 0.912109L10 2.08711L5 7.08711L0 2.08711L1.175 0.912109Z"
        style={{ transition: 'inherit' }}
      />
    </svg>
  )
}

ChevronDown.propTypes = {
  size: PropTypes.string,
  colour: PropTypes.string
}
