import React from 'react'
import { useSelector } from 'react-redux'
import { useFormContext, useFieldArray } from 'react-hook-form'

import produce from 'immer'
import { DateTime } from 'luxon'
import styled from 'styled-components'

import { sizes } from '@qflow/theme'
import {
  FormInput,
  FormRules,
  FormItem,
  FormArrayItem
} from 'portal/components/RecordEditing'
import { DateFormatFull } from 'portal/lib/functions'
import {
  CheckDate1ObjisBeforeDate2String,
  CheckHeightLengthWidth,
  CheckProductCertification,
  CheckProductName,
  CheckProductUsedAt,
  CheckQuantity,
  CheckVolume,
  CheckWeight
} from 'portal/lib/functions/SimpleDataValidation'
import { Spacer, Input, ColourButton, Flex, icons } from 'portal/lib/primitives'
import { dateValidator } from 'portal/lib/primitives/forms/Validators'

export function Delivery({ isImperial }) {
  const form = useFormContext()

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )
  const deliveryArrivalDate = useSelector(
    state => state.records.details.item.data.deliveryArrivalDate
  )
  const deliveryExitDate = useSelector(
    state => state.records.details.item.data.deliveryExitDate
  )

  const givenCertificationFields = useFieldArray({
    name: 'givenCertifications'
  })

  const CalculatedVolumeLabelBody = !isImperial ? (
    <>
      Calculated Volume (m<sup>3</sup>)
    </>
  ) : (
    'Calculated Volume'
  )

  const VolumeLabelBody = !isImperial ? (
    <>
      Volume (m<sup>3</sup>)
    </>
  ) : (
    'Volume'
  )

  const WeightLabelBody = !isImperial ? 'Weight (t)' : 'Weight'

  return (
    <>
      <FormInput.Hidden name="id" />
      <FormItem label="Name">
        <FormInput.Text
          label="Name"
          name="name"
          autoFocus
          rules={{
            required: 'Name is required'
          }}
          simpleDataValidationRules={CheckProductName}
        />
      </FormItem>
      <FormItem label="Produced At">
        <FormInput.DateTimeCells
          name="productionTime"
          rules={dateValidator(form.getValues, 'productionTime')}
          simpleDataValidationRules={v =>
            CheckDate1ObjisBeforeDate2String(
              'Produced At',
              form.getValues('productionTime'),
              `Delivery Arrival Date (${DateTime.fromISO(deliveryArrivalDate, {
                zone: 'local'
              }).toLocaleString(DateFormatFull(projectTimeZone), {
                locale: Intl.DateTimeFormat().resolvedOptions().locale
              })})`,
              deliveryArrivalDate
            )
          }
        />
      </FormItem>
      <FormItem label="Used At">
        <FormInput.DateTimeCells
          name="usedAtTime"
          rules={dateValidator(form.getValues, 'usedAtTime')}
          simpleDataValidationRules={v =>
            CheckProductUsedAt(
              'Produced At',
              form.getValues('productionTime'),
              'Used At',
              form.getValues('usedAtTime'),
              `Delivery Exit Date (${DateTime.fromISO(deliveryExitDate, {
                zone: 'local'
              }).toLocaleString(DateFormatFull(projectTimeZone), {
                locale: Intl.DateTimeFormat().resolvedOptions().locale
              })})`,
              deliveryExitDate
            )
          }
        />
      </FormItem>
      <Spacer.Small />
      {!isImperial && (
        <FormItem label="Height (m)">
          <MultiInputWrapper>
            <FormInput.Text
              label="Height"
              name="height"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              simpleDataValidationRules={CheckHeightLengthWidth}
            />
          </MultiInputWrapper>
        </FormItem>
      )}
      {isImperial && (
        <FormItem label="Height">
          <MultiInputWrapper>
            <FormInput.Text
              name="heightFoot"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="foot"
            />
            <FormInput.Text
              name="heightInch"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="inch"
            />
          </MultiInputWrapper>
        </FormItem>
      )}

      {!isImperial && (
        <FormItem label="Width (m)">
          <MultiInputWrapper>
            <FormInput.Text
              label="Width"
              name="width"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              simpleDataValidationRules={CheckHeightLengthWidth}
            />
          </MultiInputWrapper>
        </FormItem>
      )}
      {isImperial && (
        <FormItem label="Width">
          <MultiInputWrapper>
            <FormInput.Text
              name="widthFoot"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="foot"
            />
            <FormInput.Text
              name="widthInch"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="inch"
            />
          </MultiInputWrapper>
        </FormItem>
      )}

      {!isImperial && (
        <FormItem label="Length (m)">
          <MultiInputWrapper>
            <FormInput.Text
              label="Length"
              name="length"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              simpleDataValidationRules={CheckHeightLengthWidth}
            />
          </MultiInputWrapper>
        </FormItem>
      )}
      {isImperial && (
        <FormItem label="Length">
          <MultiInputWrapper>
            <FormInput.Text
              name="lengthFoot"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="foot"
            />
            <FormInput.Text
              name="lengthInch"
              type="number"
              rules={FormRules.OptionalPositiveDecimal()}
              inputUnit="inch"
            />
          </MultiInputWrapper>
        </FormItem>
      )}
      <Spacer.Small />
      <FormItem label={CalculatedVolumeLabelBody}>
        <MultiInputWrapper>
          <FormInput.Text
            label="Calculated Volume"
            name="calculatedVolume"
            type="number"
            disabled
            inputUnit={isImperial ? 'cubic yard' : null}
          />
        </MultiInputWrapper>
      </FormItem>
      <FormItem label={VolumeLabelBody}>
        <MultiInputWrapper>
          <FormInput.Text
            label="Volume"
            name="givenVolume"
            type="number"
            rules={FormRules.OptionalPositiveDecimal()}
            simpleDataValidationRules={v =>
              CheckVolume(v, isImperial ? 'CubicYard' : 'CubicMeter')
            }
            inputUnit={isImperial ? 'cubic yard' : null}
          />
        </MultiInputWrapper>
      </FormItem>
      <Spacer.Small />
      <FormInput.Hidden name="estimatedWeight" />

      <FormItem label={WeightLabelBody}>
        <MultiInputWrapper>
          <FormInput.Text
            label="Weight"
            name="weight"
            type="number"
            rules={FormRules.OptionalPositiveDecimal()}
            simpleDataValidationRules={v =>
              CheckWeight(v, isImperial ? 'Pound' : 'Tonne')
            }
            inputUnit={isImperial ? 'lbs' : null}
          />
        </MultiInputWrapper>
      </FormItem>
      <Spacer.Small />
      <FormItem label="Quantity (units)">
        <MultiInputWrapper>
          <FormInput.Text
            label="Quantity"
            name="quantity"
            type="number"
            rules={FormRules.OptionalPositiveDecimal()}
            simpleDataValidationRules={CheckQuantity}
          />
        </MultiInputWrapper>
      </FormItem>
      <FormItem label="RefId" hidden={true}>
        <FormInput.Text name="referenceProductId" />
      </FormItem>
      <Spacer.Small />
      <FormArrayItem
        label="Given Certifications"
        name="givenCertifications"
        fieldArray={givenCertificationFields}
        defaultValue={{ value: '' }}
        render={(field, index, fieldArray) => (
          <>
            <Flex.Row>
              <FormInput.Text
                label="Given Certifications"
                name={`givenCertifications[${index}].value`}
                defaultValue={field.value}
                type="string"
                simpleDataValidationRules={CheckProductCertification}
              />

              <Spacer.Fine />

              <ColourButton.BasicBorderless
                type="button"
                onClick={() => {
                  fieldArray.remove(index)
                }}
              >
                <icons.TrashCan size={sizes.SMALL} colour="currentColour" />
              </ColourButton.BasicBorderless>
            </Flex.Row>

            <Spacer.Tiny />
          </>
        )}
      />
    </>
  )
}

Delivery.transformToFormData = (item, projectTimeZone) =>
  produce(item, draft => {
    draft.productionTime =
      Input.DateTimeCells.getDateTimeInputValuesFromISOString(
        item.productionTime,
        projectTimeZone
      )
    draft.usedAtTime = Input.DateTimeCells.getDateTimeInputValuesFromISOString(
      item.usedAtTime,
      projectTimeZone
    )
    draft.givenCertifications = (draft.givenCertifications ?? [])
      .filter(Boolean)
      .map(cert => ({ value: cert }))
  })

Delivery.transformFromFormData = (formItem, projectTimeZone, isImperial) =>
  produce(formItem, draft => {
    draft.productionTime = Input.DateTimeCells.getISOStringFromValues(
      formItem.productionTime,
      projectTimeZone
    )
    draft.usedAtTime = Input.DateTimeCells.getISOStringFromValues(
      formItem.usedAtTime,
      projectTimeZone
    )

    // If isImperial merge Height, Width and Length fields in to their own arrays
    if (isImperial) {
      draft.height = []
      if (formItem.heightFoot) {
        draft.height.push({ value: formItem.heightFoot, unit: 'Foot' })
      }
      if (formItem.heightInch) {
        draft.height.push({ value: formItem.heightInch, unit: 'Inch' })
      }

      draft.width = []
      if (formItem.widthFoot) {
        draft.width.push({ value: formItem.widthFoot, unit: 'Foot' })
      }
      if (formItem.widthInch) {
        draft.width.push({ value: formItem.widthInch, unit: 'Inch' })
      }

      draft.length = []
      if (formItem.lengthFoot) {
        draft.length.push({ value: formItem.lengthFoot, unit: 'Foot' })
      }
      if (formItem.lengthInch) {
        draft.length.push({ value: formItem.lengthInch, unit: 'Inch' })
      }

      // And do similar for Volume and Weight fields
      if (formItem.calculatedVolume) {
        draft.calculatedVolume = [
          { value: formItem.calculatedVolume, unit: 'CubicYard' }
        ]
      } else {
        draft.calculatedVolume = []
      }

      if (formItem.givenVolume) {
        draft.givenVolume = [{ value: formItem.givenVolume, unit: 'CubicYard' }]
      } else {
        draft.givenVolume = []
      }

      if (formItem.estimatedWeight) {
        draft.estimatedWeight = [
          { value: formItem.estimatedWeight, unit: 'Pound' }
        ]
      } else {
        draft.estimatedWeight = []
      }

      if (formItem.weight) {
        draft.weight = [{ value: formItem.weight, unit: 'Pound' }]
      } else {
        draft.weight = []
      }
    }
    draft.givenCertifications = (formItem.givenCertifications ?? [])
      .filter(Boolean)
      .map(cert => cert.value)
  })

export const MultiInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-width: 15rem;
  gap: 0 0.5rem;
  flex: 0 1 auto;
`
