import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function StatusSuccess({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
      style={{ width: size, height: size }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={colour || colours.CHARCOAL_GREY}
          d="M5.748 5a1 1 0 0 1 .999.967c.136 4.073 2.162 6.508 6.079 7.305.057.014.174.012.174.224V19.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-14A.5.5 0 0 1 .5 5h5.248zM11.5 17h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm0-3h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm-5-3h-5a.5.5 0 1 0 0 1h5a.5.5 0 1 0 0-1zm-2-3h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
        />
        <path
          fill={colours.VIVID_GREEN}
          d="M14 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm.044 2c-.242 0-.44.182-.44.41v6.244l-1.854-1.76a.452.452 0 0 0-.62 0 .395.395 0 0 0 0 .587l2.598 2.456a.46.46 0 0 0 .316.123.458.458 0 0 0 .316-.123l2.598-2.456a.404.404 0 0 0 0-.586.452.452 0 0 0-.62 0l-1.86 1.758V2.41a.42.42 0 0 0-.434-.41z"
        />
      </g>
    </svg>
  )
}

StatusSuccess.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
