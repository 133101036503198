import React from 'react'
import { Tooltip } from 'antd'
import styled from 'styled-components'

import { colours } from '@qflow/theme'
import { icons } from 'portal/lib/primitives'

export function SupportLink() {
  return (
    <SupportContainer>
      <Tooltip
        overlayStyle={{ whiteSpace: 'pre-line' }}
        title={'Support library'}
        placement="bottom"
      >
        <SupportAnchor
          href="https://qualisflow.com/qflow-support/"
          target="_blank"
          rel="noopener noreferrer nofollow"
          aria-label="Help, training videos and guidance documents"
        >
          <icons.Library colour="currentColor" />
        </SupportAnchor>
      </Tooltip>
    </SupportContainer>
  )
}

const SupportAnchor = styled.a`
  display: flex;
  align-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid ${colours.CORE_NAVY};
  border-radius: 20px;
  color: ${colours.CORE_NAVY};
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    color: ${colours.TEAL};
    background-color: ${colours.CONTROL_PANEL_GREY};
    border-color: ${colours.TEAL};
  }
`

const SupportContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: initial;
  align-items: center;
`
