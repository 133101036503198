import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function StatusFailed({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
      style={{ width: size, height: size }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={colour || colours.CHARCOAL_GREY}
          d="M5.748 5a1 1 0 0 1 .999.967c.136 4.073 2.162 6.508 6.079 7.305.057.014.174.012.174.224V19.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-14A.5.5 0 0 1 .5 5h5.248zM11.5 17h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm0-3h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm-5-3h-5a.5.5 0 1 0 0 1h5a.5.5 0 1 0 0-1zm-2-3h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
        />
        <path
          fill={colours.RED}
          d="M14 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm1.61 3.855l-1.612 1.613-1.589-1.59a.375.375 0 1 0-.53.532l1.589 1.586-1.6 1.601a.38.38 0 0 0 0 .537.38.38 0 0 0 .534 0l1.6-1.602 1.589 1.59a.376.376 0 1 0 .53-.532l-1.588-1.586 1.61-1.613a.38.38 0 0 0 0-.536.38.38 0 0 0-.534 0z"
        />
      </g>
    </svg>
  )
}

StatusFailed.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
