import React from 'react'
import PropTypes from 'prop-types'

export function QLogo({ size = '100%', colour = 'currentColor', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{
        width: size,
        height: 'auto'
      }}
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#00A4B4"
          d="M10.03,14.93l2.88,3.31c-0.99,0.25-2.05,0.3-3.15,0.09c-3.13-0.58-5.56-3.19-5.91-6.35   c-0.52-4.63,3.3-8.53,7.9-8.15c3.24,0.27,6.3,3.24,6.66,6.47c0.19,1.7-0.21,3.3-1.02,4.63h4.2c0.5-1.38,0.74-2.88,0.65-4.45   C21.91,4.74,17.15,0.14,11.39,0C4.89-0.15-0.38,5.28,0.02,11.84c0.34,5.41,4.62,9.84,10.01,10.37c1.99,0.19,3.88-0.14,5.56-0.88   l0.45,0.51c0.83,0.96,2.03,1.5,3.3,1.5h3.4l-3.76-4.33l-2.25-2.58c-0.83-0.96-2.03-1.5-3.3-1.5H10.03z"
        />
      </g>
    </svg>
  )
}

QLogo.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.any
}
