import {
  takeEvery,
  takeLeading,
  all,
  call,
  put,
  debounce
} from 'redux-saga/effects'

import * as actions from './actions'

import {
  msal,
  authenticationParameters,
  handleTokenAcquireError,
  redirectPromise
} from './msal'
import { trackMixpanel } from 'portal/store/mixpanel/actions'
import axios from 'portal/utils/axios-msal'

const EV_LOG_IN = 'frontend_log_in'
const EV_LOG_OUT = 'frontend_log_out'

function isQualisFlowInternal(email) {
  if (email.endsWith('@qualisflow.com')) {
    return true
  }
  return false
}

function* checkAuthStateSaga({ interactOnUnauthorised = false }) {
  yield call(() => redirectPromise)

  // It's okay if this ends up undefined, we handle that error
  const account = msal.getAllAccounts()[0]

  /**
   * @type {import("@azure/msal-browser").AuthenticationResult}
   */
  let authResult = null
  try {
    authResult = yield call(() =>
      msal.acquireTokenSilent({
        ...authenticationParameters,
        account
      })
    )

    if (!authResult?.idTokenClaims) {
      throw 'ID Token Not Returned'
    }
  } catch (error) {
    yield call(() =>
      handleTokenAcquireError(error, {
        forceInteration: interactOnUnauthorised
      })
    )
    yield put(actions.authCheckFail(error))

    return
  }
  const {
    'given_name': givenName = null, // first name
    name = null, // full name
    'sub': accountIdentifier = null,
    'signInNames.emailAddress': email = null
  } = authResult.idTokenClaims

  const isQflowInternal = isQualisFlowInternal(email)
  yield put(
    actions.authCheckSuccess(
      name || givenName,
      accountIdentifier,
      isQflowInternal
    )
  )
}

function* loginSaga({ firstSignIn = false, signInHint = null }) {
  yield put(trackMixpanel(EV_LOG_IN, { first_signin: firstSignIn }))

  yield msal.loginRedirect({
    ...authenticationParameters,
    loginHint: signInHint || msal.getAllAccounts()[0]?.username || undefined
  })
}

function* logoutSaga() {
  try {
    yield put(trackMixpanel(EV_LOG_OUT, {}, { reset: true }))
    yield call(() => msal.logoutRedirect())
    yield put(actions.logoutSuccess())
  } catch (error) {
    yield put(actions.logoutFail(JSON.stringify(error)))
  }
}

function* loadSelfSaga() {
  try {
    const response = yield call(() =>
      axios.get(`users/self-account-mappings`, {
        validateStatus: status => status === 200
      })
    )
    yield put(actions.selfLoaded(response.data))
  } catch (error) {
    yield put(actions.selfLoadFailure(error))
  }
}

export function* watch() {
  yield all([
    takeEvery(actions.AUTH_CHECK_STATE, checkAuthStateSaga),
    debounce(300, actions.SELF_LOAD, loadSelfSaga),
    takeLeading(actions.AUTH_LOGIN_START, loginSaga),
    takeLeading(actions.AUTH_LOGOUT, logoutSaga)
  ])
}
