import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { trackMixpanel } from 'portal/store/mixpanel/actions'

export function useMixpanelEvents() {
  const dispatch = useDispatch()

  return {
    trackDashboardEventClick: useCallback(
      (projectId, accountId) => {
        dispatch(
          trackMixpanel('frontend_dashboard_click', {
            project_id: projectId,
            account_id: accountId
          })
        )
      },
      [dispatch]
    ),
    trackDashboardProEventClick: useCallback(
      (projectId, accountId) => {
        dispatch(
          trackMixpanel('frontend_dashboard_pro_click', {
            project_id: projectId,
            account_id: accountId
          })
        )
      },
      [dispatch]
    ),
    trackEventsPageEventClick: useCallback(
      (projectId, accountId) => {
        dispatch(
          trackMixpanel('frontend_events_page_click', {
            project_id: projectId,
            account_id: accountId
          })
        )
      },
      [dispatch]
    )
  }
}
