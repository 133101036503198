import React from 'react'
import { Spinner } from 'portal/lib/primitives'
import { useMountEffect } from 'portal/lib/hooks'

import { Details } from 'portal/components/RecordsModules'
import { PageContainer } from 'portal/pages/PageContainer'
import {
  useRecordDetailData,
  useRecordDetailsMixpanel
} from 'portal/components/RecordDetails'
import { InfractionsContext } from 'portal/store/infractions/InfractionsContext'
import { Sidebar } from 'portal/components/Sidebar'

export function DeliveryDetails() {
  const { loading, error, item: record, infractions } = useRecordDetailData()

  const { trackDetailsOpened } = useRecordDetailsMixpanel()
  useMountEffect(trackDetailsOpened)

  return (
    <PageContainer>
      <Sidebar />

      {loading && <Spinner />}
      {error && <div>Qflowing...</div>}

      {record && infractions && (
        <InfractionsContext infractionsByFieldName={infractions}>
          <Details.Delivery record={record} />
        </InfractionsContext>
      )}
    </PageContainer>
  )
}
