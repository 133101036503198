import { takeEvery, all, put, call } from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'

import {
  MASS_UPLOAD_START,
  massUploadSuccess,
  massUploadFailure,
  MASS_UPLOAD_CONFIRM_START,
  massUploadConfirmFailure,
  massUploadConfirmSuccess
} from './actions'
import { fileSizes } from 'portal/lib/constants'
import * as errors from './errors'

function getUploadURI(accountId, projectId, moduleName) {
  return (
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/modules/' +
    moduleName +
    '/mass-update'
  )
}

function getConfirmURI(accountId, projectId, moduleName, id) {
  return (
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/modules/' +
    moduleName +
    '/mass-update/' +
    id +
    '/confirm'
  )
}

function* uploadSaga(action) {
  /** @type {{ file: File }} */
  const { accountId, projectId, moduleName, recordId, file } = action
  if (!file?.size) {
    yield put(massUploadFailure(errors.NO_DATA))
    return
  }

  if (
    file.size &&
    file.size > fileSizes.MASSUPLOADLIMIT_MB * fileSizes.MEGABYTE
  ) {
    yield put(massUploadFailure(errors.TOO_LARGE))
    return
  }

  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = yield call(
      axios.put,
      getUploadURI(accountId, projectId, moduleName, recordId),
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: status => [200, 400].includes(status)
      }
    )

    if (response.status === 400 && !response.data?.summary) {
      throw response
    }

    const { id, fileName, outputs, summary } = response.data

    yield put(massUploadSuccess(id ?? fileName, outputs, summary))
  } catch (error) {
    console.error(error)
    yield put(massUploadFailure(errors.UPLOAD_ERROR))
  }
}

function* confirmSaga(action) {
  const { accountId, projectId, moduleName, id } = action

  try {
    yield call(
      axios.post,
      getConfirmURI(accountId, projectId, moduleName, id),
      null,
      {
        validateStatus: status => status === 200
      }
    )

    yield put(massUploadConfirmSuccess())
  } catch (error) {
    console.error(error)
    yield put(massUploadConfirmFailure(error))
  }
}

export function* watch() {
  yield all([takeEvery(MASS_UPLOAD_START, uploadSaga)])
  yield all([takeEvery(MASS_UPLOAD_CONFIRM_START, confirmSaga)])
}
