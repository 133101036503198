import styled, { css } from 'styled-components'

export const Padding = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  padding: 1rem 0.5rem;

  ${({ noVertical }) =>
    noVertical &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`

Padding.Modal = styled(Padding)`
  padding: 1rem 2rem;
  padding-bottom: 1.5rem;
`

Padding.Small = styled(Padding)`
  padding: 0.25rem;
`
