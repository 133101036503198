import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Monitors({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'} fillRule="evenodd">
        <rect width="4.5" height="4.5" x="1.5" y="9.75" rx="2.25" />
        <path
          fillRule="nonzero"
          d="M13.36 12.355c1.247-1.546 1.688-3.527 1.6-5.555-.183-4.245-4.145-7.21-8.226-6.8C4.758.2 2.89 1.113 1.642 2.658.396 4.204-.029 6.05.064 8.078c.004.071.224.745.739.745s.697-.47.697-.746c0-3.875 2.266-6.372 5.73-6.535 3.464-.162 6.007 3.004 6.13 5.387.124 2.382-.49 5.748-5.412 6.784-.267.056-.61.315-.61.719 0 .403.43.595.703.568 1.976-.2 4.073-1.099 5.32-2.645z"
        />
        <path
          fillRule="nonzero"
          d="M10.789 10.515c.813-.946 1.217-2.091 1.13-3.299-.086-1.206-.578-2.307-1.515-3.069-.937-.762-2.23-1.235-3.467-1.106-1.238.13-2.374.759-3.187 1.705-.813.946-1.244 2.194-1.157 3.402.006.075.143.569.58.569.438 0 .57-.491.577-.77.026-1.03.349-1.751.982-2.487.633-.736 1.307-1.007 2.269-1.108.963-.101 1.71.198 2.44.79.728.595 1.149 1.313 1.217 2.252.068.94-.252 1.62-.885 2.356-.633.736-1.354 1.04-2.37 1.325-.276.077-.447.353-.402.62.044.266.283.466.568.437 1.237-.13 2.406-.67 3.22-1.617z"
        />
      </g>
    </svg>
  )
}

Monitors.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
