import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import { Flex, SubHeading, Spacer } from 'portal/lib/primitives'
import {
  AttachmentUploadButton,
  AttachmentUploadError
} from './AttachmentsPanel'

export function AttachmentsHeader({ record }) {
  return (
    <Flex.Row amount={0} verticalCentre css={containerCss}>
      <SubHeading isChild>Attachments</SubHeading>

      <Spacer.Tiny />

      {record.canEdit && <AttachmentUploadButton record={record} />}
      <AttachmentUploadError />
    </Flex.Row>
  )
}

AttachmentsHeader.propTypes = {
  record: PropTypes.object.isRequired
}

const containerCss = css`
  margin-bottom: 0.5rem;
`
