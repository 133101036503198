import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { generatePath, useRouteMatch } from 'react-router'
import { useDispatch } from 'react-redux'

import {
  WASTE_RECORD_LINE_ITEM_EDIT,
  DELIVERY_RECORD_LINE_ITEM_EDIT
} from 'portal/routes'
import { NavButtonDecorator, Spacer } from 'portal/lib/primitives'
import { EditButton } from './EditButton'
import { RemoveButton } from './RemoveButton'
import { recordItemRemovedToggle } from 'portal/store/records/editing/actions'
import { useModuleIds, useUserProjectRole } from 'portal/lib/hooks'
import { AddButton } from './AddButton'
import { MODULES } from 'portal/lib/constants'

export function LineItemButtons({ record, index, isImperial = false }) {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()
  const { isReadOnly } = useUserProjectRole(accountId, projectId)
  const { params } = useRouteMatch()

  const items = record.data.items ?? record.data.products
  const lineItem = items[index]

  const editUri = useMemo(
    () =>
      MODULES.select(moduleName, {
        waste: () =>
          generatePath(WASTE_RECORD_LINE_ITEM_EDIT, {
            ...params,
            lineItem: index
          }),
        delivery: () =>
          generatePath(DELIVERY_RECORD_LINE_ITEM_EDIT, {
            ...params,
            lineItem: index
          })
      }),
    [index, moduleName, params]
  )

  const handleRemoval = useCallback(() => {
    dispatch(
      recordItemRemovedToggle(
        accountId,
        projectId,
        moduleName,
        record,
        index,
        isImperial,
        !lineItem.isRemoved
      )
    )
  }, [
    accountId,
    dispatch,
    index,
    isImperial,
    lineItem.isRemoved,
    moduleName,
    projectId,
    record
  ])

  return (
    <>
      {(!isReadOnly || record.canEdit) && (
        <NavButtonDecorator to={editUri}>
          <EditButton disabled={lineItem.isRemoved} />
        </NavButtonDecorator>
      )}

      <Spacer.Tiny />

      {(!isReadOnly || record.canEdit) &&
        (lineItem.isRemoved ? (
          <AddButton id="RemoveButton" onClick={handleRemoval}>
            Restore
          </AddButton>
        ) : (
          <RemoveButton id="RemoveButton" onClick={handleRemoval}>
            Remove
          </RemoveButton>
        ))}
    </>
  )
}

LineItemButtons.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
