import React, { useCallback, useEffect } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import * as routes from 'portal/routes'
import PropTypes from 'prop-types'
import { SideBar, icons } from 'portal/lib/primitives'

import { BIReportSideBarButton } from './BIReportSideBarButton'
import { projectsGetSaga } from 'portal/store/projects/actions'
import { ProjectSettingsSidebarButton } from './ProjectSettingsSidebarButton'
import { AccountSettingsSidebarButton } from './AccountSettingsSidebarButton'
import { useMixpanelEvents } from './Mixpanel'
import { useModuleIds } from 'portal/lib/hooks'
import * as moduleTags from 'portal/lib/constants/moduleConstants'

export function Sidebar() {
  const { accountId, projectId } = useParams()
  const dispatch = useDispatch()
  const { projects, isLoading } = useSelector(state => state.projects)
  const { moduleName } = useModuleIds()

  const lastUsedProjectId = sessionStorage.getItem('last-used-project-id')
  const lastUsedModuleName = sessionStorage.getItem('last-used-module-name')
  const currentModule = moduleName
    ? moduleName
    : lastUsedModuleName
      ? lastUsedModuleName
      : moduleTags.DELIVERY

  const {
    trackDashboardEventClick,
    trackDashboardProEventClick,
    trackEventsPageEventClick
  } = useMixpanelEvents()

  useEffect(() => {
    if (!projects && !isLoading) {
      dispatch(projectsGetSaga(accountId))
    }
  }, [projects, isLoading, dispatch, accountId])

  // If current page has a projectId and ModuleName save in session storage
  //  in case we need to reference from a page that does not have this, e.g.Dashboard
  useEffect(() => {
    if (projectId) {
      sessionStorage.setItem('last-used-project-id', projectId)
    }
    if (moduleName) {
      sessionStorage.setItem('last-used-module-name', moduleName)
    }
  }, [projectId, moduleName])

  const createUri = useCallback(
    (route, moduleName, tab) => {
      if (projects && projects.length > 0 && !isLoading) {
        return generatePath(route, {
          accountId,
          projectId: projectId
            ? projectId
            : lastUsedProjectId
              ? lastUsedProjectId
              : projects[0].projectId,
          moduleName,
          tab
        })
      }
      return ''
    },
    [accountId, isLoading, lastUsedProjectId, projectId, projects]
  )

  const createAccountSettingsUri = useCallback(
    route => {
      if (accountId) {
        return generatePath(route, {
          accountId
        })
      }
      return ''
    },
    [accountId]
  )

  const recordsUri = createUri(
    routes[currentModule.toUpperCase()],
    currentModule.toLowerCase(),
    'table'
  )
  const eventsUri = createUri(
    routes[currentModule.toUpperCase()],
    currentModule.toLowerCase(),
    'events'
  )

  const projectSettingsUri = createUri(routes.PROJECTSETTINGS, null, null)

  const accountSettingsUri = createAccountSettingsUri(routes.ACCOUNT_SETTINGS)

  const onDashboardClick = useCallback(() => {
    trackDashboardEventClick(projectId, accountId)
  }, [projectId, accountId, trackDashboardEventClick])

  const onDashboardProClick = useCallback(() => {
    trackDashboardProEventClick(projectId, accountId)
  }, [projectId, accountId, trackDashboardProEventClick])

  const onEventsPageClick = useCallback(() => {
    trackEventsPageEventClick(projectId, accountId)
  }, [projectId, accountId, trackEventsPageEventClick])

  return (
    <SideBar.Show>
      <BIReportSideBarButton
        reportName="Dashboard"
        reportType="Dashboard"
        onClick={onDashboardClick}
      />
      <BIReportSideBarButton
        reportName="Dashboard"
        reportType="Professional"
        btnTag="PRO"
        onClick={onDashboardProClick}
      />
      <SideBar.NavButton
        disabled={eventsUri == ''}
        id="Events"
        to={eventsUri}
        onClick={onEventsPageClick}
        Icon={icons.Bell}
      >
        Events
      </SideBar.NavButton>
      <SideBar.NavButton
        disabled={recordsUri == ''}
        id="Table"
        to={recordsUri}
        Icon={icons.Table}
      >
        Records
      </SideBar.NavButton>

      <div style={{ marginTop: 'auto' }}>
        <ProjectSettingsSidebarButton
          accountId={accountId}
          projectId={projectId}
          projectsTable={projectSettingsUri}
        />
        <AccountSettingsSidebarButton
          accountId={accountId}
          accountsTable={accountSettingsUri}
        />
      </div>
    </SideBar.Show>
  )
}

SideBar.propTypes = {
  dateDisabled: PropTypes.bool
}
