export const CONFIGURATION_LOAD = 'CONFIGURATION_LOAD'
export const loadConfiguration = (accountId, projectId, moduleName) => ({
  type: CONFIGURATION_LOAD,
  accountId,
  projectId,
  moduleName
})

export const CONFIGURATION_LOADED = 'CONFIGURATION_LOADED'
export const loadedConfiguration = (
  accountId,
  projectId,
  moduleName,
  configuration
) => ({
  type: CONFIGURATION_LOADED,
  accountId,
  projectId,
  moduleName,
  configuration
})

export const CONFIGURATION_LOAD_FAILURE = 'CONFIGURATION_LOAD_FAILURE'
export const loadConfigurationFailed = (
  accountId,
  projectId,
  moduleName,
  error
) => ({
  type: CONFIGURATION_LOAD_FAILURE,
  accountId,
  projectId,
  moduleName,
  error
})
