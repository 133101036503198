import {
  msalConfig,
  passwordResetAuthority,
  authenticationParameters
} from 'portal/lib/config/env-config'
import * as Msal from '@azure/msal-browser'
import { bugsnag } from 'portal/utils/bugsnag'
import { LOGIN_FAILED_ERRORS } from 'portal/lib/constants/loginFailedErrors'
import { LOGIN_FAILED } from 'portal/routes'

const MSAL_ERRORS = {
  INTERACTION_REQUIRED: 'InteractionRequiredAuthError',
  NOT_SIGNED_IN: 'ClientAuthError',
  RESET_PASSWORD_REQUESTED: 'AADB2C90118',
  AUTH_ERROR: 'BrowserAuthError'
}
export const msal = new Msal.PublicClientApplication(msalConfig)

export { authenticationParameters }

export const handleTokenAcquireError = async (
  err,
  { forceInteration = false } = {}
) => {
  switch (err.name) {
    case MSAL_ERRORS.INTERACTION_REQUIRED: {
      if (forceInteration) {
        console.warn(
          '[AcquireTokenSilent] User Credentials Expired. Redirecting'
        )
        msal.acquireTokenRedirect({
          ...authenticationParameters,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore migrated this file to ts and this needs fixing, but not a task for now (to preserve current behaviour)
          forceRefresh: true
        })
        await msal.handleRedirectPromise()
      } else {
        console.warn('[AcquireTokenSilent] User Credentials Expired')
      }
      return
    }
    case MSAL_ERRORS.AUTH_ERROR:
    case MSAL_ERRORS.NOT_SIGNED_IN:
      if (forceInteration) {
        console.warn('[AcquireTokenSilent] User Not Signed In. Redirecting')
        msal.loginRedirect(authenticationParameters)
        await msal.handleRedirectPromise()
      } else {
        console.warn('[AcquireTokenSilent] User Not Signed In')
      }
      return

    default: {
      console.warn('[AcquireTokenSilent] Unhandled Error:' + err.name, err)

      const loginFailedCallback = makeLoginFailedCallback(err)

      if (!bugsnag) {
        loginFailedCallback()
        return
      }

      bugsnag.notify(
        new Error('Unhandled MSAL Error during Token Acquire'),
        event => {
          event.severity = 'warning'
          event.context = 'handleTokenAcquireError'
          event.addMetadata('error', err)
        },
        loginFailedCallback
      )
      return
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.msal = msal

export const redirectPromise = msal
  .handleRedirectPromise()
  .then(tokenResponse => {
    if (!tokenResponse) {
      const accounts = msal.getAllAccounts()
      if (accounts.length === 0) {
        // No user signed in
      }
    }
  })
  .catch(err => {
    // Handle password reset
    if (String(err).includes(MSAL_ERRORS.RESET_PASSWORD_REQUESTED)) {
      msal.loginRedirect({
        ...authenticationParameters,
        authority: passwordResetAuthority
      })
    }

    if (
      Object.values(LOGIN_FAILED_ERRORS).some(errCode =>
        String(err).includes(errCode)
      )
    ) {
      handleFailedLogin(err)
    }
  })

function handleFailedLogin(err) {
  const loginFailedCallback = makeLoginFailedCallback(err)

  if (!bugsnag) {
    loginFailedCallback()
    return
  }

  bugsnag.notify(
    new Error('SSO user login has failed'),
    event => {
      event.severity = 'warning'
      event.context = 'ssoLoginFailed'
      event.addMetadata('error', err)
    },
    loginFailedCallback
  )
}

function makeLoginFailedCallback(err: { errorMessage: string } | undefined) {
  return () => {
    const searchParams = err ? new URLSearchParams(err).toString() : ''
    window.location.href = `${LOGIN_FAILED}?${searchParams}`
  }
}
