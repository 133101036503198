import cjs from 'color'

export const alpha = (hex, amount) => cjs(hex).alpha(amount).toString()

const CJS_WHITE = cjs('white')
export const lighten = (hex, amount) =>
  cjs(hex).mix(CJS_WHITE, amount).toString()

const CJS_BLACK = cjs('black')
export const darken = (hex, amount) =>
  cjs(hex).mix(CJS_BLACK, amount).toString()

// Black, Greys and White
export const BLACK = '#000000'
export const BLUEY_BLACK = '#092840'
export const DARK_GREY = '#252529'
export const CHARCOAL_GREY = '#3e3f42'
export const BATTLESHIP_GREY = '#7a7a7b'
export const BLUEY_GREY = '#9EA0A5'
export const SUSTAINABLE_GREY = '#99BFBA'
export const BORDER_GREY = '#cfd0d2'
export const CLOUD_GREY = '#D9D9D9'
export const WHITE_10 = '#e6e6e6'
export const LIGHT_GREY = '#EEEEEE'
export const PALE_GREY = '#efeff4'
export const CONTROL_PANEL_GREY = '#f8f8f8'
export const WHITE = '#ffffff'

// Blues
export const BLUE = '#0072EE'
export const DEEP_SKY_BLUE = BLUE
export const CORE_NAVY = '#0A2940'
export const HIGHLIGHTED_NAVY = '#374A57'
export const TEAL = '#22AEB5'
export const TECH_TEAL = TEAL
export const LIGHT_TEAL = '#DEEFF0'

// Greens
export const GREEN = '#33923C'
export const VIVID_GREEN = '#45d512'

// Reds, oranges and yellows
export const RED = '#E00000'
export const RED_DARKMODE = '#DE2E2E'
export const ERROR = '#FF5353'
export const ORANGE = '#DA5E30'
export const ORANGE_DARKMODE = '#DA5E30'
export const WARNING_BACKGROUND = '#FCEFE9'
export const WARNING_BACKGROUND_DARKMODE = '#41190C'
export const ERROR_BACKGROUND = '#FFE6E6'
export const ERROR_BACKGROUND_DARKMODE = '#420A0A'
export const CONSTRUCTION_ORANGE = '#F36E21'
export const AMBER = '#F3A203'
export const YELLOW = '#F9D43E'

// NOTE: Please keep this inline with \src\app\components\CommonStyles\_colors.scss
