import { css } from 'styled-components'
import { colours } from '@qflow/theme'

export const DatePickerCSSOverrides = css`
  & .DayPicker {
    box-shadow: none;
    border-radius: 0;

    background-color: transparent;
  }
  & .CalendarMonth {
    background-color: transparent;

    & .CalendarMonth_caption {
      font-size: 1rem;
      padding-bottom: 40px;
    }
  }
  & .CalendarMonthGrid {
    background-color: transparent;
  }

  /* Buttons */
  & .DayPickerNavigation_svg__horizontal {
    fill: ${colours.CORE_NAVY};
  }
  & .DayPickerNavigation_button__default {
    background-color: ${colours.WHITE};
    border: none;
    border-radius: 5px;
    outline: none;
  }
  & .DayPickerNavigation_button__default:hover {
    background-color: ${colours.LIGHT_TEAL};
    border: none;
  }
  & .DayPickerNavigation_button__default:focus {
    background-color: ${colours.LIGHT_TEAL};
    border: none;
  }

  /* Text */
  & .DayPicker_weekHeader {
    color: ${colours.BATTLESHIP_GREY};
  }
  & .CalendarMonth_caption {
    color: ${colours.CORE_NAVY};
  }

  /* Base day items */
  & .CalendarDay__default {
    background-color: ${colours.WHITE};
    color: ${colours.CORE_NAVY};
    border: none;
    border-radius: 5px;
    outline: none;
  }
  & .CalendarDay__default:hover {
    background-color: ${colours.LIGHT_TEAL};
    color: ${colours.CORE_NAVY};
    border: none;
    border-radius: 5px;
  }

  /* Start and end selection */
  & .CalendarDay__selected {
    background-color: ${colours.TEAL};
    color: ${colours.WHITE};
    border-radius: 5px;
  }
  & .CalendarDay__selected:hover {
    background-color: ${colours.LIGHT_TEAL};
    color: ${colours.CORE_NAVY};
  }

  /* In-between selection */
  & .CalendarDay__selected_span {
    background-color: ${colours.LIGHT_TEAL};
  }
  & .CalendarDay__selected_span:hover {
    background-color: ${colours.LIGHT_TEAL};
    color: ${colours.TEAL};
  }

  /* Disabled days */
  & .CalendarDay__blocked_out_of_range {
    opacity: 0.5;
    border: none;
    background-color: ${colours.WHITE};
  }
  & .CalendarDay__blocked_out_of_range:hover {
    opacity: 0.5;
    border: none;
    background-color: ${colours.WHITE};
    color: inherit;
  }
`
