import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { accountLoad } from 'portal/store/accountDetails/actions'
import { usersLoad } from 'portal/store/users/actions'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import {
  Table,
  Modal,
  Alert,
  Space,
  Input,
  Typography,
  Form,
  Switch,
  Select,
  Row,
  Col,
  Tooltip
} from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { colours } from '@qflow/theme'

import './Settings.css'
import details from 'portal/assets/RecordsTable/reportViewBlk.svg'

import {
  getIdFromRole,
  ROLE_INFORMATION,
  USER_ROLES
} from 'portal/lib/constants/UserRole'
import { matchesEmailFilter } from 'portal/lib/functions/userSettingsHelpers'
import { useUserAccountRole } from 'portal/lib/hooks/useUserAccountRole'
import {
  ColourButton,
  ClickableLink,
  SmallSpinner,
  UserCard,
  UserAvatar
} from 'portal/lib/primitives'
import { FORBIDDEN } from 'portal/routes'
import { accountProjectsGet } from 'portal/store/allAccountProjects/actions'
import {
  userEditStart,
  userUpdateStart
} from 'portal/store/userDetails/actions'
import { getKeyFromIds } from 'portal/store/users/reducer'

const { Search } = Input
const { Title, Paragraph } = Typography

const getTableData = usersState => {
  const data = [{ userId: '0' }]
  if (usersState.loading == null || typeof usersState.users == 'undefined') {
    return data
  }

  const minDate = moment.utc('0001-01-01')

  Object.values(usersState.users).forEach(user => {
    data.push({
      userId: user.userId,
      displayName: user.displayName,
      email: user.email,
      lastActivity:
        user.lastActivity && moment.utc(user.lastActivity).isAfter(minDate)
          ? moment(user.lastActivity).local().format('DD/MM/YYYY')
          : 'No activity',
      userData: user
    })
  })

  data.sort((a, b) => {
    if (a.displayName && b.displayName) {
      return a.displayName
        .toUpperCase()
        .localeCompare(b.displayName.toUpperCase())
    }
    return 0
  })

  return data
}

export function AccountSettingsTable() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { accountId } = useParams()

  const stateAccount = useSelector(state => state.accountDetails)
  const stateUsers = useSelector(state => state.users)
  const stateAccountProjects = useSelector(state => state.accountProjects)
  const auth = useSelector(state => state.auth)
  const accountUser = useSelector(state => state.userDetails)

  const { isAdmin, roleLoaded } = useUserAccountRole(accountId)

  useEffect(() => {
    dispatch(accountProjectsGet(accountId))
    dispatch(accountLoad(accountId))
  }, [accountId, dispatch])

  if (roleLoaded && !isAdmin) {
    history.push(FORBIDDEN)
  }

  const [form] = Form.useForm()

  const [modalOpen, setModalOpen] = useState(false)
  const [alert, setAlert] = useState(null)
  const [nameFilter, setNameFilter] = useState('')
  const [emailFilter, setEmailFilter] = useState('')

  useEffect(() => {
    if (!accountUser.loading && accountUser.error != null) {
      setAlert(
        <>
          {' '}
          <br />
          <Alert
            key="error"
            message={'Something Went Wrong'}
            description={'Please try submitting again.'}
            type="error"
            closable
          />
        </>
      )
    }
    if (!accountUser.loading && accountUser.error == null) {
      setAlert(null)
      setModalOpen(false)
      dispatch(usersLoad(accountId))
    }
  }, [dispatch, accountId, accountUser.loading, accountUser.error])

  const getFormData = user => {
    let formData = []
    stateAccountProjects?.projects?.forEach(project => {
      const userPermission = user.projectPermissions.filter(
        p => p.projectId == project.projectId
      )
      const userSetting = user.projectSettings.filter(
        p => p.projectId == project.projectId
      )

      let accessEnabled = false
      let role = 'Reader'
      if (userPermission && userPermission.length > 0) {
        accessEnabled = true
        role = USER_ROLES[userPermission[0].userRoleId]
      }

      formData.push({
        project: project.name,
        projectId: project.projectId,
        notificationsEnabled:
          user.notificationsEnabled && userSetting[0]?.notificationsEnabled,
        accessEnabled: accessEnabled,
        role: role
      })
    })
    return { projectPermissions: formData }
  }

  const handleFormSubmit = () => {
    form.validateFields().then(values => {
      const user =
        stateUsers.users[getKeyFromIds(accountId, accountUser.userId)]
      let projectPermissions = []
      let projectSettings = []
      let forceGlobalNotificationsEnabled = false
      user.projectPermissions.forEach(permission => {
        const permissionUpdated = values.projectPermissions.find(
          p => p.projectId == permission.projectId
        )

        if (!permissionUpdated) {
          projectPermissions.push(permission)
        }
      })

      user.projectSettings.forEach(setting => {
        const permissionUpdated = values.projectPermissions.find(
          p => p.projectId == setting.projectId
        )
        if (!permissionUpdated) {
          projectSettings.push(setting)
        }
      })

      values.projectPermissions.forEach(permission => {
        if (permission.accessEnabled) {
          projectPermissions.push({
            userId: accountUser.userId,
            projectId: permission.projectId,
            userRoleId: getIdFromRole(permission.role)
          })
        }

        if (permission.notificationsEnabled) {
          forceGlobalNotificationsEnabled = true
        }

        projectSettings.push({
          userId: accountUser.userId,
          projectId: permission.projectId,
          notificationsEnabled: permission.notificationsEnabled
        })
      })

      setAlert(null)
      dispatch(
        userUpdateStart(
          accountId,
          accountUser.userId,
          projectPermissions,
          accountUser.notificationsEnabled || forceGlobalNotificationsEnabled,
          projectSettings
        )
      )
    })
  }

  const handleCancel = () => {
    setModalOpen(false)
    setAlert(null)
    dispatch(userEditStart({}))
  }

  const columns = [
    {
      width: '2rem',
      render: (data, _, index) => {
        if (index == 0) {
          return
        }
        return (
          <UserAvatar displayName={data.displayName} displayTooltip={false} />
        )
      }
    },
    {
      title: 'User',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, user, index) => {
        if (index === 0) {
          return (
            <Search
              onChange={event => setNameFilter(event.target.value)}
              allowClear
            />
          )
        }
        return user.displayName
      }
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      render: (_, user, index) => {
        if (index === 0) {
          return (
            <Search
              onChange={event => setEmailFilter(event.target.value)}
              allowClear
            />
          )
        }
        return user.email
      }
    },
    {
      width: '18rem',
      title: 'Last activity',
      dataIndex: 'lastActivity',
      key: 'lastActivity'
    },
    {
      width: '2rem',
      render: (data, _, index) => {
        if (index == 0) {
          return
        }
        return (
          <ClickableLink
            id="navigateToDetailsBtn"
            onClick={() => {
              dispatch(userEditStart(data.userData))
              form.setFieldsValue(getFormData(data.userData))
              setModalOpen(true)
            }}
            to={'#'}
          >
            <img src={details} alt="" />
          </ClickableLink>
        )
      }
    }
  ]

  return (
    <Space direction="vertical" size="middle">
      <div>
        <Title level={3}>
          {!stateAccount.name && 'Account'} {stateAccount.name} Settings
        </Title>
        <Paragraph>
          Use this page to view users in the account across all projects. Use
          settings dialog to view and edit roles and notifications across
          projects.
        </Paragraph>
      </div>
      <Modal
        visible={modalOpen}
        onOk={handleFormSubmit}
        confirmLoading={accountUser.loading}
        onCancel={handleCancel}
        width={700}
        title={
          <UserCard
            displayName={accountUser?.displayName}
            email={accountUser?.email}
          />
        }
        footer={[
          <ColourButton.Basic
            key="back"
            type="button"
            onClick={handleCancel}
            style={{ display: 'inline-block', marginRight: '.75rem' }}
          >
            Cancel
          </ColourButton.Basic>,
          <ColourButton.Action
            key="submit"
            type="button"
            onClick={handleFormSubmit}
            style={{ display: 'inline-block' }}
          >
            OK
          </ColourButton.Action>
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          size="middle"
          requiredMark={false}
          onValuesChange={(changed, values) => {
            values.projectPermissions = values.projectPermissions.map(
              (value, i) => {
                if (changed.projectPermissions[i]) {
                  if (changed.projectPermissions[i].accessEnabled == false) {
                    value.notificationsEnabled = false
                  }
                  if (
                    changed.projectPermissions[i].notificationsEnabled == true
                  ) {
                    value.accessEnabled = true
                  }
                }
                return value
              }
            )

            form.setFieldsValue(values)
          }}
        >
          <div
            className="ant-table-content"
            data-testid="user-account-settings-table"
          >
            <ProjectsTable>
              <thead className="ant-table-thead">
                <tr>
                  <th id="project" className="ant-table-cell">
                    Project Name
                  </th>
                  <th id="accessEnabled" className="ant-table-cell">
                    Access Enabled
                  </th>
                  <th id="notificationsEnabled" className="ant-table-cell">
                    Notifications Enabled
                  </th>
                  <th id="access" className="ant-table-cell">
                    Role
                  </th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                <Form.List name="projectPermissions">
                  {fields => (
                    <>
                      {fields.map((field, index) => (
                        <tr
                          key={field.key}
                          className="ant-table-row ant-table-row-level-0"
                        >
                          <td
                            id="project"
                            key={field.key}
                            className="ant-table-cell"
                          >
                            {form.getFieldValue([
                              'projectPermissions',
                              field.name,
                              'project'
                            ])}
                          </td>
                          <td id="accessEnabled" className="ant-table-cell">
                            <Form.Item
                              name={[index, 'accessEnabled']}
                              valuePropName="checked"
                              style={{ marginBottom: '1px' }}
                            >
                              <Switch
                                loading={accountUser?.loading}
                                size="small"
                                style={{ verticalAlign: 'middle' }}
                              />
                            </Form.Item>
                          </td>
                          <td
                            id="notificationsEnabled"
                            className="ant-table-cell"
                          >
                            <Form.Item
                              name={[index, 'notificationsEnabled']}
                              valuePropName="checked"
                              style={{ marginBottom: '1px' }}
                            >
                              <Switch
                                loading={accountUser.loading}
                                size="small"
                                style={{
                                  verticalAlign: 'middle',
                                  textAlign: 'center'
                                }}
                              />
                            </Form.Item>
                          </td>
                          <td
                            id="access"
                            className="ant-table-cell"
                            data-testid="access-level"
                          >
                            <Form.Item
                              name={[index, 'role']}
                              style={{ marginBottom: '1px' }}
                            >
                              <Select
                                style={{
                                  verticalAlign: 'middle',
                                  width: '100%'
                                }}
                                disabled={
                                  !form.getFieldValue([
                                    'projectPermissions',
                                    field.name,
                                    'accessEnabled'
                                  ]) || accountUser.loading
                                }
                                loading={accountUser.loading}
                              >
                                {Object.values(USER_ROLES).map(value => (
                                  <Select.Option value={value} key={value}>
                                    <Row>
                                      <Col span={8}>{value}</Col>
                                      <Col span={1} offset={13}>
                                        <Tooltip
                                          title={ROLE_INFORMATION[value]}
                                          placement="right"
                                        >
                                          <QuestionCircleFilled
                                            style={{
                                              color: colours.TEAL
                                            }}
                                          />
                                        </Tooltip>
                                      </Col>
                                    </Row>
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </Form.List>
              </tbody>
            </ProjectsTable>
          </div>
          {alert}
        </Form>
      </Modal>
      <Table
        rowKey="userId"
        columns={columns}
        dataSource={getTableData(stateUsers).filter(
          user =>
            user.displayName == null ||
            (user.displayName
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) &&
              matchesEmailFilter(
                auth.isQualisFlowInternal,
                emailFilter,
                user.email
              ))
        )}
        pagination={false}
        rowClassName={(record, index) => {
          if (index === 0) {
            return 'table-row-filter'
          }
          return 'table-row-light'
        }}
        loading={{
          spinning: stateUsers.loading,
          indicator: (
            <div>
              <SmallSpinner />
            </div>
          )
        }}
      />
    </Space>
  )
}

const ProjectsTable = styled.table`
  table-layout: auto;
  th,
  td {
    width: 100%;
  }

  thead tr th,
  tbody tr td {
    height: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 15px;
  }

  #project {
    overflow-wrap: anywhere;
    width: 40%;
  }

  #accessEnabled {
    width: 15%;
  }

  #notificationsEnabled {
    width: 15%;
  }

  #access {
    width: 30%;
  }
`
