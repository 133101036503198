import React, { useState } from 'react'
import styled from 'styled-components'

import { Flex, icons, ColourButton, Spacer } from 'portal/lib/primitives'
import { colours, sizes } from '@qflow/theme'

export function RemovedFilter({ onToggle }) {
  const [showRemoved, setShowRemoved] = useState(
    localStorage.getItem('removed-filter')
  )

  const toggleFilter = () => {
    const newState = !showRemoved || showRemoved === 'false' ? 'true' : 'false'
    setShowRemoved(newState)
    localStorage.setItem('removed-filter', newState)
    onToggle(newState)
  }

  return (
    <ButtonContainer>
      <FilterButton
        id="RemovedFilter"
        onClick={toggleFilter}
        $showRemoved={showRemoved}
      >
        <icons.CircleTicked
          size={sizes.SMALL}
          colour={
            showRemoved === 'true' ? colours.ORANGE : colours.BATTLESHIP_GREY
          }
        />
        <Spacer.Fine />
        {showRemoved === 'true' ? 'Including Removed' : 'Include Removed'}
      </FilterButton>
    </ButtonContainer>
  )
}

const ButtonContainer = styled(Flex.Col)`
  flex: 0 0 auto;
  align-items: flex-end;
`
const FilterButton = styled(ColourButton.Basic)`
  border-radius: 25px;
  color: ${({ $showRemoved }) =>
    $showRemoved === 'true' ? colours.ORANGE : colours.DARK_GREY};
  background-color: ${({ $showRemoved }) =>
    $showRemoved === 'true' ? '#FFF2EB' : colours.WHITE};
  border-color: ${({ $showRemoved }) =>
    $showRemoved === 'true' ? colours.ORANGE : colours.BORDER_GREY};

  &:hover {
    background: transparent;
    color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? colours.ORANGE : colours.DARK_GREY};
    border-color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? colours.ORANGE : colours.DARK_GREY};
  }

  &:focus {
    color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? colours.ORANGE : colours.DARK_GREY};
    background-color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? '#FFF2EB' : colours.WHITE};
  }

  &:hover:focus {
    background: transparent;
  }

  > SVG {
    color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? colours.ORANGE : colours.BATTLESHIP_GREY};
  }

  &:hover > SVG {
    color: ${({ $showRemoved }) =>
      $showRemoved === 'true' ? colours.ORANGE : colours.CHARCOAL_GREY};
  }
`
