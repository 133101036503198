import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Graph({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
    >
      <g fill="none" fillRule="evenodd" {...props}>
        <path
          stroke={colour || 'currentColor'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".75"
          d="M1.563.313L1.563 14.688M.313 13.438L14.688 13.438"
        />
        <path
          fill={colour || 'currentColor'}
          fillRule="nonzero"
          d="M5.313 5.625H3.438c-.173 0-.313.14-.313.313v5.625c0 .172.14.312.313.312h1.874c.173 0 .313-.14.313-.313V5.939c0-.173-.14-.313-.313-.313zM12.813 3.75h-1.876c-.172 0-.312.14-.312.313v7.5c0 .172.14.312.313.312h1.874c.173 0 .313-.14.313-.313v-7.5c0-.172-.14-.312-.313-.312zM9.063.625H7.187c-.172 0-.312.14-.312.313v10.624c0 .173.14.313.313.313h1.875c.172 0 .312-.14.312-.313V.938c0-.173-.14-.313-.313-.313z"
        />
      </g>
    </svg>
  )
}

Graph.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
