import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

import { sizes } from '@qflow/theme'

export function Loading({ size = sizes.LARGER, colour, ...props }) {
  return (
    <Container colour={colour} {...props}>
      <CircularProgress color="inherit" size={size} />
    </Container>
  )
}

Loading.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;

  color: ${({ colour, theme }) => colour || theme.primary.fg};
`
