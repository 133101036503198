import produce from 'immer'

import { UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAILURE, UPLOAD_RESET } from './actions'

const initialState = {
  working: false,
  error: null,
  success: false,
  attachment: null
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case UPLOAD: {
        draft.working = true
        draft.error = null
        draft.success = false
        draft.attachment = null
        return
      }
      case UPLOAD_SUCCESS: {
        draft.working = false
        draft.error = null
        draft.success = true
        draft.attachment = action.attachment
        return
      }
      case UPLOAD_FAILURE: {
        draft.working = false
        draft.error = action.error
        draft.attachment = null
        return
      }
      case UPLOAD_RESET: {
        return initialState
      }
    }
  })
}
