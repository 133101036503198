import React, { forwardRef } from 'react'
import styled from 'styled-components'

export const TrayCardBase = forwardRef(({ children, ...props }, ref) => {
  return (
    <Card ref={ref} {...props}>
      <TBody>{children}</TBody>
    </Card>
  )
})

const Card = styled.table`
  min-width: 100%;
  overflow: hidden;
`

const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
`

TrayCardBase.propTypes = Card.propTypes
