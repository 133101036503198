import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function Library({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 0C10.9125 0 9.3375 0.3 8.25 1.125C7.1625 0.3 5.5875 0 4.125 0C3.0375 0 1.8825 0.165 0.915 0.5925C0.3675 0.84 0 1.3725 0 1.98V10.44C0 11.415 0.915 12.135 1.86 11.895C2.595 11.7075 3.375 11.625 4.125 11.625C5.295 11.625 6.54 11.82 7.545 12.315C7.995 12.54 8.505 12.54 8.9475 12.315C9.9525 11.8125 11.1975 11.625 12.3675 11.625C13.1175 11.625 13.8975 11.7075 14.6325 11.895C15.5775 12.1425 16.4925 11.4225 16.4925 10.44V1.98C16.4925 1.3725 16.125 0.84 15.5775 0.5925C14.6175 0.165 13.4625 0 12.375 0ZM15 9.5475C15 10.02 14.565 10.365 14.1 10.2825C13.5375 10.1775 12.9525 10.1325 12.375 10.1325C11.1 10.1325 9.2625 10.62 8.25 11.2575V2.625C9.2625 1.9875 11.1 1.5 12.375 1.5C13.065 1.5 13.7475 1.5675 14.4 1.71C14.745 1.785 15 2.0925 15 2.445V9.5475Z"
        fill={colour || theme.secondary.fg}
      />
      <path
        d="M9.735 4.8825C9.495 4.8825 9.2775 4.7325 9.2025 4.4925C9.105 4.2 9.27 3.8775 9.5625 3.7875C10.7175 3.4125 12.21 3.2925 13.5825 3.45C13.89 3.4875 14.115 3.765 14.0775 4.0725C14.04 4.38 13.7625 4.605 13.455 4.5675C12.24 4.425 10.9125 4.5375 9.9075 4.86C9.8475 4.8675 9.7875 4.8825 9.735 4.8825Z"
        fill={colour || theme.secondary.fg}
      />
      <path
        d="M9.735 6.8775C9.495 6.8775 9.2775 6.7275 9.2025 6.4875C9.105 6.195 9.27 5.8725 9.5625 5.7825C10.71 5.4075 12.21 5.2875 13.5825 5.445C13.89 5.4825 14.115 5.76 14.0775 6.0675C14.04 6.375 13.7625 6.6 13.455 6.5625C12.24 6.42 10.9125 6.5325 9.9075 6.855C9.8475 6.87 9.7875 6.8775 9.735 6.8775Z"
        fill={colour || theme.secondary.fg}
      />
      <path
        d="M9.735 8.8725C9.495 8.8725 9.2775 8.7225 9.2025 8.4825C9.105 8.19 9.27 7.8675 9.5625 7.7775C10.71 7.4025 12.21 7.2825 13.5825 7.44C13.89 7.4775 14.115 7.755 14.0775 8.0625C14.04 8.37 13.7625 8.5875 13.455 8.5575C12.24 8.415 10.9125 8.5275 9.9075 8.85C9.8475 8.865 9.7875 8.8725 9.735 8.8725Z"
        fill={colour || theme.secondary.fg}
      />
    </svg>
  )
}

Library.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
