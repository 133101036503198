import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import RcDropdown from 'rc-dropdown'
import 'rc-dropdown/assets/index.css'

import RcMenu, { Item, Divider } from 'rc-menu'

import { shadows, sizes } from '@qflow/theme'
import { Clickable } from './buttons'
import { ChevronDown } from './icons'
import { Spacer } from './layout'

export function PopCard({ children, overlay, closeOnOverlayClick = false }) {
  const [visible, setVisible] = useState(false)

  return (
    <RcDropdown
      trigger={['click']}
      overlay={overlay}
      animation="slide-up"
      destroyPopupOnHide
      closeOnSelect={false}
      visible={visible}
      onVisibleChange={setVisible}
      onOverlayClick={() => {
        if (closeOnOverlayClick) {
          setVisible(false)
        }
      }}
      getPopupContainer={trigger => trigger.parentNode}
    >
      {typeof children === 'string' ? (
        <Clickable contentAlignLeft>
          <ChevronDown />

          <Spacer.Fine />

          {children}
        </Clickable>
      ) : (
        children
      )}
    </RcDropdown>
  )
}

PopCard.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.node,
  closeOnOverlayClick: PropTypes.bool
}

const Menu = styled(RcMenu)`
  background-color: ${({ theme }) => theme.secondary.bg};

  ${shadows.LOW}

  .rc-dropdown-menu-item {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    position: relative;

    font-size: ${sizes.SMALL};
    cursor: pointer;

    color: ${({ theme }) => theme.secondary.fg};
    :hover {
      background-color: ${({ theme }) => theme.secondary.bgHover};
    }
    :active {
      background-color: ${({ theme }) => theme.secondary.bgFocused};
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      content: ' ';

      transition: 300ms all ease-in-out;
      width: 0;
      background-color: transparent;
    }
  }

  .rc-dropdown-menu-item-selected {
    color: ${({ theme }) => theme.primary.fg};
    background-color: ${({ theme }) => theme.primary.bg};

    :hover {
      background-color: ${({ theme }) => theme.primary.bgHover};
    }
    :active {
      background-color: ${({ theme }) => theme.primary.bgFocused};
    }

    &::before {
      width: 0.25rem;
      background-color: ${({ theme }) => theme.colours.TEAL};
    }

    &::after {
      display: none; // for now
    }
  }

  .rc-dropdown-menu-item-divider {
    border: none;
    border-bottom: solid 1px ${({ theme }) => theme.primary.border};
    background-color: transparent;
  }
`

export const PopCardItems = {
  Menu,
  Item,
  Divider
}
