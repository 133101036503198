import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function ProjectDocument({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <path
        fill={colour || 'currentColor'}
        fillRule="nonzero"
        d="M12.568 0c.477 0 .872.362.926.826l.006.11v13.302c0 .143-.023.68-.542.754-.07.017-3.567.007-10.49-.031a.935.935 0 0 1-.932-.935C1.516 7.162 1.504 2.93 1.5 1.332L1.5.722c0-.674.397-.719.637-.722h10.43zm-.819 12.188H2.937a.469.469 0 0 0-.084.93l.084.007h8.812a.469.469 0 1 0 0-.938zm0-2.813H2.937a.469.469 0 0 0-.084.93l.084.008h8.812a.469.469 0 1 0 0-.938zm.174-2.813H7.611a.469.469 0 0 0-.085.93l.085.008h4.312a.469.469 0 1 0 0-.938zM3.144 2.82a2.345 2.345 0 0 0 .701 4.17 2.336 2.336 0 0 0 2.89-1.607c-1.16-.147-1.805-.197-2.5-.265-.532-1.07-.602-1.226-1.09-2.298zm5.998-.007l-2 1.556.4.312L9.14 3.437l1.606 1.25.4-.31-.003-.005 1.249-.97-.4-.312-1.25.971-1.202-.935.002-.002-.4-.312zM3.75 2.305c.32 1.002.368 1.086.682 2.114 1.315.08 1.025.08 2.318.08a2.519 2.519 0 0 0-1.792-2.151 2.45 2.45 0 0 0-1.208-.043z"
      />
    </svg>
  )
}

ProjectDocument.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
