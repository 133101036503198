export const ACCOUNT_PROJS_GET_SUCCESS = 'ACCOUNT_PROJS_GET_SUCCESS'
export const ACCOUNT_PROJS_GET_FAIL = 'ACCOUNT_PROJS_GET_FAIL'
export const ACCOUNT_PROJS_GET = 'ACCOUNT_PROJS_GET'

export const accountProjectsGet = accountId => {
  return {
    type: ACCOUNT_PROJS_GET,
    accountId: accountId
  }
}

export const accountProjectsGetSuccess = projects => {
  return {
    type: ACCOUNT_PROJS_GET_SUCCESS,
    projects: projects
  }
}

export const accountProjectsGetFail = error => {
  return {
    type: ACCOUNT_PROJS_GET_FAIL,
    error: error
  }
}
