import { useParams } from 'react-router'

export const useModuleIds = () => {
  const { accountId, projectId, moduleName, ...params } = useParams()

  return {
    accountId,
    projectId,
    moduleName,
    ...params
  }
}
