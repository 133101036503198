import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useForm, FormProvider } from 'react-hook-form'
import styled from 'styled-components'
import produce from 'immer'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { projectUsersLoad } from 'portal/store/projectUsers/actions'
import { convertAssignedToArrayToString, imperial } from 'portal/lib/functions'
import { ColourButton, Flex, Spinner, Text } from 'portal/lib/primitives'

import { useRecordDetailData } from 'portal/components/RecordDetails'
import { FormItem } from 'portal/components/RecordEditing'

import {
  ApplyOrCancelControls,
  useMixpanelEvents
} from 'portal/components/RecordEditing'

import {
  useModuleIds,
  useMountEffect,
  useUserProjectRole
} from 'portal/lib/hooks'
import { useRecordEditing } from 'portal/store/records/editing/useRecordEditing'
import { InfractionsContext } from 'portal/store/infractions/InfractionsContext'
import { useConfiguration } from 'portal/store/configuration/useConfiguration'

import { ActionStrip } from './ActionStrip'
import { Delivery } from './Delivery'
import { Waste } from './Waste'

const MODULE_VIEWS = {
  delivery: Delivery,
  waste: Waste
}

const EDIT_TITLES = {
  delivery: 'Edit Record',
  waste: 'Edit Waste'
}

export const RecordEdit = ({ doneUri }) => {
  const dispatch = useDispatch()
  const { accountId, projectId } = useModuleIds()
  const { isProjectUser, isProjectAdmin, roleLoaded } = useUserProjectRole(
    accountId,
    projectId
  )
  const history = useHistory()

  const [actionStripRecordID, setActionStripRecordID] = useState([])
  const [assignedTo, setAssignedTo] = useState([])
  const [recordAssigneeMapping, setRecordAssigneeMapping] = useState({})

  const { moduleName } = useModuleIds()
  const moduleKey = moduleName.toLowerCase()
  const ModuleView = MODULE_VIEWS[moduleKey]

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )
  const projectCountry = useSelector(
    state => state.projectDetails.modules?.country
  )
  const isImperial = imperial(projectCountry)

  const config = useConfiguration()
  const editing = useRecordEditing()
  const recordDetail = useRecordDetailData()

  const loading = recordDetail.loading || editing.uploading || config.loading
  const error = recordDetail.error || editing.error || config.error

  const { form, trackEditCancel, trackEditSave } = useFormWithDataAndTracking(
    !recordDetail.loading && !recordDetail.error,
    recordDetail.item
  )

  const infractionsByName = recordDetail?.infractions ?? null

  const { onEditSaveComplete, cancelUri } = useEditCompleteRedirection(doneUri)

  const openActionStrip = () => {
    if (recordDetail.item && recordDetail.item.recordId) {
      setActionStripRecordID([recordDetail.item.recordId])
    }
  }

  const closeActionStrip = () => {
    setActionStripRecordID([])
  }

  const submitItem = useCallback(
    formItem => {
      let item = formItem
      if (typeof ModuleView.transformFromFormData === 'function') {
        item = ModuleView.transformFromFormData(formItem, projectTimeZone)
      }

      const record = produce(recordDetail.item, record => {
        _.merge(record.data, item.data)
      })

      trackEditSave()

      editing.submitRecord(record, onEditSaveComplete, isImperial)
    },
    [
      ModuleView,
      editing,
      isImperial,
      onEditSaveComplete,
      projectTimeZone,
      recordDetail.item,
      trackEditSave
    ]
  )

  // Load Project Users in to Redux state
  useEffect(() => {
    if (roleLoaded && isProjectUser) {
      dispatch(projectUsersLoad(accountId, projectId, false))
    }
  }, [accountId, dispatch, isProjectUser, projectId, roleLoaded])

  useEffect(() => {
    if (recordDetail.item && !recordDetail.item.canEdit) {
      history.push(doneUri)
    }

    if (recordDetail.item && recordDetail.item.assignedTo) {
      setAssignedTo(
        convertAssignedToArrayToString(recordDetail.item.assignedTo)
      )
      let newObject = {}
      newObject[recordDetail.item.recordId] = recordDetail.item.assignedTo

      setRecordAssigneeMapping(newObject)
    }
  }, [doneUri, history, recordDetail.item])

  return (
    <>
      {loading && <Spinner />}
      {error && (
        <Text error>Error! {typeof error === 'string' ? error : ''}</Text>
      )}

      {recordDetail.item && (
        <>
          <ActionStrip
            recordIds={actionStripRecordID}
            recordAssigneeMapping={recordAssigneeMapping}
            onClose={closeActionStrip}
            onApply={onEditSaveComplete}
          />

          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(submitItem)}>
              <Flex.Row amount={0} verticalCentre>
                <H2>{EDIT_TITLES[moduleKey]}</H2>

                <Flex.By amount={1} />

                <ApplyOrCancelControls
                  canApply={form.formState.isValid && form.formState.isDirty}
                  cancelUri={cancelUri}
                  onCancelled={trackEditCancel}
                />
              </Flex.Row>

              <FormItem label="Assigned To">
                <Flex.Row amount={0} verticalCentre>
                  {assignedTo}
                  <Flex.By amount={1} />
                  {isProjectAdmin && (
                    <ColourButton.Basic
                      css={{ width: 'fit-content' }}
                      type="button"
                      onClick={openActionStrip}
                    >
                      Edit assignees
                    </ColourButton.Basic>
                  )}
                </Flex.Row>
              </FormItem>

              <InfractionsContext infractionsByFieldName={infractionsByName}>
                <ModuleView config={config.value} />
              </InfractionsContext>
            </Form>
          </FormProvider>
        </>
      )}
    </>
  )
}

RecordEdit.propTypes = {
  doneUri: PropTypes.string.isRequired
}

function useEditCompleteRedirection(doneUri) {
  const history = useHistory()

  return {
    onEditSaveComplete: () => {
      history.push(doneUri)
    },
    cancelUri: doneUri ?? undefined
  }
}

function useFormWithDataAndTracking(ready, data) {
  const { moduleName } = useModuleIds()
  const moduleKey = moduleName.toLowerCase()
  const ModuleView = MODULE_VIEWS[moduleKey]

  const form = useForm({ defaultValues: {}, mode: 'onBlur' })
  const { reset } = form

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )

  const { trackEditOpen, trackEditCancel, trackEditSave, setInitialValues } =
    useMixpanelEvents(form)

  useMountEffect(trackEditOpen)

  useEffect(() => {
    if (ready && data) {
      let formData = data
      if (typeof ModuleView.transformToFormData === 'function') {
        formData = ModuleView.transformToFormData(data, projectTimeZone)
      }
      formData = _.mapValues(formData, value => (value === 0 ? '0' : value))

      reset(formData)
      setInitialValues(formData)
    }
  }, [ModuleView, data, projectTimeZone, ready, reset, setInitialValues])

  return {
    form,
    trackEditSave,
    trackEditCancel
  }
}

const Form = styled.form`
  width: 100%;
  max-width: 80rem;

  align-self: center;
`

const H2 = styled.h2`
  margin-bottom: 0;
`
