import produce from 'immer'
import _ from 'lodash'

import {
  USER_EDIT_START,
  USER_UPDATE_FAIL,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS
} from './actions'

const initialState = {
  loading: false,
  error: null,
  userId: null,
  displayName: null,
  email: null,
  projectPermissions: [],
  notificationsEnabled: false,
  projectSettings: []
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case USER_EDIT_START: {
        draft.loading = false
        draft.error = null
        _.assign(draft, action.userDetails)
        return
      }

      case USER_UPDATE_START: {
        draft.loading = true
        draft.error = null
        return
      }

      case USER_UPDATE_FAIL: {
        const { error } = action
        draft.loading = false
        draft.error = error
        return
      }

      case USER_UPDATE_SUCCESS: {
        const { memberships, userProfile } = action
        draft.loading = false
        draft.error = null
        draft.projectPermissions = memberships.projectPermissions
        draft.notificationsEnabled = userProfile.notificationsEnabled
        draft.projectSettings = userProfile.projectSettings
        return
      }
    }
  })
}
