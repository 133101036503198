import { splitOutUnassignedFilter } from 'portal/lib/functions'

type Param = {
  [s: string]: string | string[]
}

type ReportFilter = {
  name: string
  value: string | string[]
}

export const computeFilterParamsForExports = (
  filters: Param
): ReportFilter[] => {
  const map = {
    'deliveryId': 'deliveryId',
    'deliveryAddress': 'deliveryAddress',
    'tradeContractor': 'tradeContractor',
    'knownSupplierName': 'knownSupplierName',
    'consignmentId': 'consignmentId',
    'carrierName': 'carrierName',
    'originSiteAddress': 'originSiteAddress',
    'items[].wasteCode': 'wasteCode',
    'assignedTo': 'assignedTo'
  }

  const params: Param = {}
  for (const [key, value] of Object.entries(filters)) {
    if (key in map && value && value.length > 0) {
      params[map[key]] = value
    }
  }

  splitOutUnassignedFilter(params, 'assignedTo', 'includeUnassigned')

  const reportFilters: ReportFilter[] = []
  for (const [key, value] of Object.entries(params)) {
    const isArray = Array.isArray(value)

    reportFilters.push({
      name: key,
      value: isArray ? JSON.stringify(value) : value
    })
  }
  return reportFilters
}

export const getReportParameters = (
  moduleName: string,
  timespan: { startDate: any; endDate: any },
  useCreatedAtDate: boolean
): ReportFilter[] => {
  const reportParameters = [
    {
      name: useCreatedAtDate ? 'createdAtStartTime' : 'startTime',
      value: timespan.startDate
    },
    {
      name: useCreatedAtDate ? 'createdAtEndTime' : 'endTime',
      value: timespan.endDate
    },
    {
      name: useCreatedAtDate
        ? 'sortByCreatedAt'
        : moduleName === 'delivery'
          ? 'sortByDeliveryArrivalDate'
          : 'sortByRemovalDate',
      value: 'Ascending'
    }
  ]
  return reportParameters
}
