import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { snake } from 'change-case'

import { useResponsive } from 'portal/lib/hooks'
import { Text } from 'portal/lib/primitives'

export const RecordsColumn = (title, content, style, filter, lookups) => {
  let styling = {}
  if (style) {
    Object.keys(style).forEach(function (prop) {
      styling[prop] = style[prop]
    })
  }

  const lookupsObj = lookups
    ? {
        lookup: lookups.lookups,
        field: lookups.keyProp,
        customFilterAndSearch: lookups.func ?? undefined
      }
    : {}

  return {
    title: title,
    headerStyle: styling,
    field: content,
    trackingKey: snake(content),
    filtering: filter ?? undefined,
    ...lookupsObj,
    render: rowData => <CellComponent>{rowData[content]}</CellComponent>
  }
}

const CellComponent = ({ children }) => {
  const { isMobile } = useResponsive()
  const canWrap = isMobile

  return (
    <StyledText title={children} noWrap={!canWrap}>
      {children}
    </StyledText>
  )
}

CellComponent.propTypes = {
  children: PropTypes.any
}

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;

  /*
   * Works on all browsers but IE11, but this is for mobile only so that is acceptable
   * https://css-tricks.com/almanac/properties/l/line-clamp/  
   */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
