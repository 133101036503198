import { takeEvery, all, put, call } from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'

import {
  queueExportSuccess,
  queueExportFailure,
  QUEUE_EXPORT,
  queueOneClickExportSuccess,
  queueOneClickExportFailure,
  QUEUE_ONECLICK_EXPORT
} from './actions'
import { REPORTDEFINITION } from 'portal/lib/constants/reportDefinitions'
import {
  computeFilterParamsForExports,
  getReportParameters
} from 'portal/store/records/filterSortParams/filterSortParamsForExports'

function generateExportURI(accountId, projectId) {
  return (
    '/accounts/' + accountId + '/projects/' + projectId + '/reports/generate'
  )
}

function* generateExportSaga(action) {
  const {
    accountId,
    projectId,
    moduleName,
    title,
    timespan,
    filters,
    useCreatedAtDate,
    callbacks
  } = action
  try {
    yield call(
      axios.post,
      generateExportURI(accountId, projectId),
      {
        title: title,
        reportDefinitionId: REPORTDEFINITION.SUMMARY,
        modules: [moduleName],
        reportParameters: [
          ...getReportParameters(moduleName, timespan, useCreatedAtDate),
          ...computeFilterParamsForExports(filters)
        ]
      },
      {
        validateStatus: status => status === 201
      }
    )
    yield put(queueExportSuccess())
    yield call(() => callbacks?.onSuccess?.())
  } catch (error) {
    yield put(queueExportFailure(error))
  }
}

function* generateOneClickExportSaga(action) {
  const {
    accountId,
    projectId,
    recordIds,
    title,
    filterDescription,
    callbacks
  } = action
  try {
    let reportParameters = [{ name: 'recordIds', value: recordIds.join(',') }]

    if (filterDescription) {
      reportParameters.push({
        name: 'filterDescription',
        value: filterDescription
      })
    }

    yield call(
      axios.post,
      generateExportURI(accountId, projectId),
      {
        title: title,
        reportDefinitionId: REPORTDEFINITION.ONECLICK,
        modules: ['delivery'],
        reportParameters: reportParameters
      },
      {
        validateStatus: status => status === 201
      }
    )
    yield put(queueOneClickExportSuccess())
    yield call(() => callbacks?.onSuccess?.())
  } catch (error) {
    yield put(queueOneClickExportFailure(error))
  }
}

export function* watch() {
  yield all([
    takeEvery(QUEUE_EXPORT, generateExportSaga),
    takeEvery(QUEUE_ONECLICK_EXPORT, generateOneClickExportSaga)
  ])
}
