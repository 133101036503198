import { takeEvery, put } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import { USERS_LOAD, usersLoaded, usersLoadFailure } from './actions'

function getMembershipURI(accountId) {
  return `accounts/${accountId}/memberships`
}

function getUserDetailsURI(accountId) {
  return `accounts/${accountId}/user-details`
}

function* loadUsersSaga(action) {
  const { accountId } = action
  try {
    const [memberships, userDetails] = yield Promise.all([
      axios.get(getMembershipURI(accountId), {
        validateStatus: status => status === 200
      }),
      axios.get(getUserDetailsURI(accountId), {
        validateStatus: status => status == 200
      })
    ])

    yield put(usersLoaded(accountId, memberships.data, userDetails.data))
  } catch (error) {
    yield put(usersLoadFailure(accountId, error))
  }
}

export function* watch() {
  yield takeEvery(USERS_LOAD, loadUsersSaga)
}
