import { takeEvery, put } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import {
  PROJECT_USERS_LOAD,
  projectUsersLoaded,
  projectUsersLoadFailure
} from './actions'

function getProjectMembershipURI(accountId, projectId) {
  return `accounts/${accountId}/projects/${projectId}/memberships`
}

function getUserDetailsURI(accountId, projectId, includeLastSignIn) {
  return `accounts/${accountId}/projects/${projectId}/user-details?includeLastSignIn=${includeLastSignIn}`
}

function* loadProjectUsersSaga(action) {
  const { accountId, projectId, includeLastSignIn } = action
  try {
    const [memberships, userDetails] = yield Promise.all([
      axios.get(getProjectMembershipURI(accountId, projectId), {
        validateStatus: status => status === 200
      }),
      axios.get(getUserDetailsURI(accountId, projectId, includeLastSignIn), {
        validateStatus: status => status == 200
      })
    ])

    yield put(
      projectUsersLoaded(
        accountId,
        projectId,
        memberships.data,
        userDetails.data
      )
    )
  } catch (error) {
    yield put(projectUsersLoadFailure(accountId, projectId, projectId, error))
  }
}

export function* watch() {
  yield takeEvery(PROJECT_USERS_LOAD, loadProjectUsersSaga)
}
