import styled, { css } from 'styled-components'
import { colours, sizes, shadows, ie11CSS } from '@qflow/theme'

export const TakeCSSProp = css`
  ${({ css }) => css}
`

export const BaseCSS = css`
  display: flex;
  flex: ${({ flex = 0 }) => flex + ' ' + flex} auto;
  align-items: center;
  justify-content: center;

  outline: none;
  overflow: hidden;
  border: none;
  padding: 0.25rem 10px;
  margin: 0;
  margin-left: ${({ contentAlignLeft = false }) =>
    contentAlignLeft ? '-0.25rem' : 0};

  cursor: pointer;
  :disabled {
    cursor: default;
  }

  transition: all 0.1s ease-in-out;
  transition-property:
    fill,
    -webkit-box-shadow,
    -moz-box-shadow,
    box-shadow;
`

export const getColoursCSS = ({
  getBg = ({ theme }) => theme.primary.bg,
  getBgHover = ({ theme }) => theme.primary.bgHover,
  getBgActive = ({ theme }) => theme.primary.bgFocused,
  getFg = ({ theme }) => theme.primary.fg,
  getFgHover = ({ theme }) => theme.primary.bg,
  getFgActive = null,
  getFgDisabled = ({ theme }) => theme.primary.disabled,
  getBgDisabled = ({ theme }) => theme.primary.bg
} = {}) => css`
  background-color: ${getBg};
  color: ${getFg};

  :focus {
    background-color: ${getBg};
    color: ${getFg};
  }

  :hover {
    background-color: ${getBgHover};
    color: ${getFgHover ?? getFg};
  }

  :active {
    background-color: ${getBgActive};
    color: ${getFgActive ?? getFg};
  }

  :disabled {
    background-color: ${getBgDisabled};
    color: ${getFgDisabled};
  }
`

export const getShapeCSS = ({
  getHeight = () => '2rem',
  getHeightSmall = () => '1.5rem',
  getFontSize = () => sizes.SMALL_MEDIUM
} = {}) => css`
  min-width: 0;
  min-height: ${({ size }) => (size === 'small' ? getHeightSmall : getHeight)};
  max-height: ${({ size }) => (size === 'small' ? getHeightSmall : getHeight)};
  ${ie11CSS(css`
    height: ${({ size }) => (size === 'small' ? getHeightSmall : getHeight)};
  `)}

  font-size: ${getFontSize};
  border-radius: 5px;
`

export const getBorderCSS = ({
  getHighlightColour = ({ theme }) => theme.primary.highlight,
  getGlowColour = ({ theme }) => theme.button.glow,
  getBorderFg = () => 'transparent',
  getBorderFgDisabled = ({ theme }) => theme.primary.border,
  getBorderFgFocus = ({ theme }) => theme.primary.borderFgFocus,
  getBorderFgHover = ({ theme }) => theme.primary.borderFgHover,
  withGlow = true
} = {}) => css`
  border: 1px;
  border-style: solid;
  border-color: ${({ highlight }) =>
    highlight ? getBorderFgHover : getBorderFg};

  ${({ theme }) =>
    withGlow &&
    shadows.getGlow(theme.button.glowSize, getGlowColour({ theme }), 0)}

  ${({ active }) =>
    active &&
    css`
      border-color: ${getHighlightColour};
    `}

  :focus {
    border-color: ${getBorderFgFocus ?? colours.TEAL};
  }
  :hover {
    border-color: ${getBorderFgHover};
  }

  :disabled {
    ${({ theme }) => withGlow && shadows.getGlow(0, theme.primary.disabled, 0)}
    border-color: ${getBorderFgDisabled};
  }
`

export const ButtonBase = styled.button`
  ${BaseCSS}
  ${getColoursCSS()}
  ${getShapeCSS()}
  ${getBorderCSS()}
`
