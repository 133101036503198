import { takeEvery, all, put, call, select } from 'redux-saga/effects'

import {
  RECORDS_REMOVE_RECORD,
  removeRecordSuccess,
  removeRecordFailure
} from './actions'
import produce from 'immer'
import { putRecord } from 'portal/store/records/api'
import { RECORD_STATUS } from 'portal/lib/constants'

function* removeRecordSaga(action) {
  const {
    accountId,
    projectId,
    moduleName,
    recordId,
    callbacks: { onSuccess }
  } = action

  const record = yield select(state => state.records.details.item)
  if (record.recordId !== recordId) {
    yield put(
      removeRecordFailure(
        accountId,
        projectId,
        moduleName,
        recordId,
        'RECORD_DETAIL_NOT_LOADED'
      )
    )
    return
  }

  const updatedRecord = produce(record, draft => {
    draft.status = RECORD_STATUS.REMOVED
  })

  try {
    yield call(() => putRecord(accountId, projectId, moduleName, updatedRecord))
    yield put(removeRecordSuccess(accountId, projectId, moduleName, recordId))
    yield call(onSuccess)
  } catch (error) {
    yield put(
      removeRecordFailure(accountId, projectId, moduleName, recordId, error)
    )
  }
}

export function* watch() {
  yield all([takeEvery(RECORDS_REMOVE_RECORD, removeRecordSaga)])
}
