import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function Add({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.99998 1.33331C4.31998 1.33331 1.33331 4.31998 1.33331 7.99998C1.33331 11.68 4.31998 14.6666 7.99998 14.6666C11.68 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.68 1.33331 7.99998 1.33331ZM11.3333 8.66665H8.66665V11.3333H7.33331V8.66665H4.66665V7.33331H7.33331V4.66665H8.66665V7.33331H11.3333V8.66665Z"
        fill={colour || theme.secondary.fg}
      />
    </svg>
  )
}

Add.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
