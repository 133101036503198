export const PROJS_GET_START = 'PROJS_GET_START'
export const PROJS_GET_SUCCESS = 'PROJS_GET_SUCCESS'
export const PROJS_GET_FAIL = 'PROJS_GET_FAIL'
export const PROJS_CLEAR = 'PROJS_CLEAR'
export const PROJS_GET_SAGA = 'PROJS_GET_SAGA'

export const PROJ_PUT_SAGA = 'PROJ_PUT_SAGA'
export const PROJ_PUT_SUCCESS = 'PROJ_PUT_SUCCESS'
export const PROJ_PUT_FAIL = 'PROJ_PUT_FAIL'

export const TIMEZONES_GET_SAGA = 'TIMEZONES_GET_SAGA'
export const TIMEZONES_GET_SUCCESS = 'TIMEZONES_GET_SUCCESS'
export const TIMEZONES_GET_FAIL = 'TIMEZONES_GET_FAIL'

export const projectsGetStart = () => {
  return {
    type: PROJS_GET_START
  }
}

export const projectsGetSaga = accountId => {
  return {
    type: PROJS_GET_SAGA,
    accountId: accountId
  }
}

export const projectsClear = () => {
  return {
    type: PROJS_CLEAR
  }
}

export const projectsGetSuccess = projects => {
  return {
    type: PROJS_GET_SUCCESS,
    projects: projects
  }
}

export const projectsGetFail = error => {
  return {
    type: PROJS_GET_FAIL,
    error: error
  }
}

export const projectPutSaga = (accountId, projectId, payload) => {
  return {
    type: PROJ_PUT_SAGA,
    accountId,
    projectId,
    payload
  }
}

export const projectPutSuccess = projects => {
  return {
    type: PROJ_PUT_SUCCESS,
    projects
  }
}

export const projectPutFail = (accountId, projectId, error) => {
  return {
    type: PROJ_PUT_FAIL,
    accountId,
    projectId,
    error: error
  }
}

export const timeZonesGetSaga = () => {
  return {
    type: TIMEZONES_GET_SAGA
  }
}

export const timeZonesGetSuccess = timezones => {
  return {
    type: TIMEZONES_GET_SUCCESS,
    timezones: timezones
  }
}

export const timeZonesGetFail = error => {
  return {
    type: TIMEZONES_GET_FAIL,
    error: error
  }
}
