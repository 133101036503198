import { createSelector } from 'reselect'
import moment from 'moment'
import { initialState } from './reducer'

export const selectGlobalDate = (valueKey = 'global') =>
  createSelector(
    state => state.global?.dateRange[valueKey],
    dateRange => {
      const { startDate, endDate, ...rest } = dateRange ?? {
        ...initialState.dateRange.global
      }

      return {
        startDate: moment(new Date(startDate)),
        endDate: moment(new Date(endDate)),
        ...rest
      }
    }
  )

export const selectGlobalDateISO = (valueKey = 'global') =>
  createSelector(
    state => state.global?.dateRange[valueKey],
    dateRange => {
      const { startDate, endDate, ...rest } = dateRange ?? {
        ...initialState.dateRange.global
      }

      return {
        startDate,
        endDate,
        ...rest
      }
    }
  )
