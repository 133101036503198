import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Flex } from 'portal/lib/primitives'

export const AttachmentsPanel = ({ children = [] }) => {
  return <Container amount={0}>{children}</Container>
}

AttachmentsPanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
}

const Container = styled(Flex.Row)`
  flex-flow: wrap;

  overflow: hidden;
  max-width: 100%;
`
