import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase } from './ButtonBase'
import { useTitle } from 'portal/lib/hooks'

export function Button({ children, onClick, ...props }) {
  const { title, textRef } = useTitle(children)

  return (
    <StyledButton title={title} {...props} onClick={onClick}>
      {typeof children === 'string' ? (
        <Text ref={textRef}>{children}</Text>
      ) : (
        children
      )}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
}

const StyledButton = styled(ButtonBase)`
  max-width: 100%;
  width: auto;
  max-height: 2rem;

  background-color: ${({ theme }) => theme.button.bg};

  :active:enabled {
    background-color: ${({ theme }) => theme.primary.highlight};
    color: ${({ theme }) => theme.primary.highlightFg};
  }
`

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
