import { Hidden, Text, TextArea, Input as _Input } from './TextInputs'
import { TextInputCells } from './TextInputCells'
import { DateTimeInputCells } from './DateTimeInputCells'
import { SearchBox } from './SearchBox'
import { DatePicker } from './DatePicker'

export const Input = {
  Input: _Input,
  Text: Text,
  TextArea: TextArea,
  TextCells: TextInputCells,
  DateTimeCells: DateTimeInputCells,
  DatePicker,
  SearchBox,
  Hidden: Hidden
}
