import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Threshold({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={colour || 'currentColor'} strokeLinecap="square">
          <path
            d="M12.375 13.125L5.625 1.125M.375 11.625L5.625 1.125"
            transform="translate(1.5 .75)"
          />
        </g>
        <circle
          cx="2"
          cy="13"
          r="1.625"
          fill={colour || 'currentColor'}
          stroke={colour || 'currentColor'}
          strokeWidth=".75"
        />
        <circle
          cx="7.25"
          cy="2"
          r="1.625"
          fill={colour || 'currentColor'}
          stroke={colour || 'currentColor'}
          strokeWidth=".75"
        />
        <circle
          cx="13"
          cy="13"
          r="1.625"
          fill={colour || 'currentColor'}
          stroke={colour || 'currentColor'}
          strokeWidth=".75"
        />
        <path fill={colour || 'currentColor'} d="M0 6.75H15V8.25H0z" />
      </g>
    </svg>
  )
}

Threshold.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
