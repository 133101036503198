import styled, { css } from 'styled-components'

export const TrayContainer = styled.div`
  display: none;
  min-width: 100%;
  max-height: 0;
  overflow: visible;
  z-index: 10000;
  position: relative;
  margin-top: -0.75rem;

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`
