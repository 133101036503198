import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Spinner } from 'portal/lib/primitives'
import { redirectPromise } from 'portal/store/auth/msal'

export const SignInOidc = () => {
  const history = useHistory()

  useEffect(() => {
    let mounted = true

    async function checkSignin() {
      await redirectPromise

      setTimeout(() => {
        if (mounted) {
          history.replace('/')
        }
      }, 1000)
    }

    checkSignin()

    return () => {
      mounted = false
    }
  }, [history])

  return <Spinner />
}
