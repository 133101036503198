import { selfLoad } from 'portal/store/auth/actions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useUserAccountRole = accountId => {
  const dispatch = useDispatch()
  const selfState = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(selfLoad())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const account = selfState?.accounts?.find(a => a.accountId == accountId)

  const isAdmin = account != null && account.isAdmin
  const roleLoaded = account && !selfState.selfLoading

  return { isAdmin, roleLoaded }
}
