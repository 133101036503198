import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Typography } from 'antd'

import { LightThemeProvider } from '@qflow/theme'

import { RemovedFilter } from 'portal/components/Filters/RemovedFilter'
import { HorizontalDivider } from 'portal/components/Navigation/Main/Toolbar/Divider'
import { RecordsExport } from 'portal/components/RecordsExport/RecordsExport'
import { Records } from 'portal/components/RecordsModules'
import { Sidebar } from 'portal/components/Sidebar'

import { Flex, SideBar, Spinner } from 'portal/lib/primitives'
import { PageContainer } from 'portal/pages/PageContainer'
import { selectGlobalDate } from 'portal/store/global/selectors'
import { compose } from 'portal/utils/compose'
import { withMixpanel } from 'portal/utils/mixpanel'

const { Title } = Typography
const EV_RECORDS_OPEN = 'frontend_records_open'
const EV_RECORDS_GENERATE_REPORT = 'frontend_records_generate_report'

class Delivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filters: {},
      showRemovedRecords:
        localStorage.getItem('removed-filter') === 'true' ? true : false,
      useCreatedAtDate:
        localStorage.getItem('delivery-useCreatedAtDate') === 'true'
          ? true
          : false
    }
  }

  baseUrl =
    '/accounts/' +
    this.props.match.params.accountId +
    '/projects/' +
    this.props.match.params.projectId

  componentDidMount() {
    this.trackRecordsOpen()
  }

  trackRecordsOpen = () => {
    const { track } = this.props

    track(EV_RECORDS_OPEN, {
      module_type: 'delivery',
      record_type: 'delivery'
    })
  }

  trackDataExported = (params = {}) => {
    const { track } = this.props

    track(EV_RECORDS_GENERATE_REPORT, {
      module_type: 'delivery',
      record_type: 'delivery',
      ...params
    })
  }

  onFiltersUpdated = f => {
    this.setState({ filters: f })
  }

  onRemovedFilterToggle = newState => {
    this.setState({ showRemovedRecords: newState === 'true' ? true : false })
  }

  updateUseCreatedAtDate = newState => {
    localStorage.setItem('delivery-useCreatedAtDate', newState)
    this.setState({ useCreatedAtDate: newState })
  }

  render() {
    const { isLoading, globalDateRange } = this.props

    return (
      <LightThemeProvider>
        <Sidebar />
        <PageContainer
          style={{ paddingTop: '1.5rem', paddingBottom: '0.5rem' }}
        >
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <Flex.Row
                amount={0}
                style={{ width: '99%', alignItems: 'baseline' }}
              >
                <Title level={2} id="Heading" style={{ marginBottom: '0' }}>
                  Records
                </Title>
                <Flex.By amount={1} />
                <SideBar.GlobalDatePicker />
              </Flex.Row>
              <HorizontalDivider style={{ width: '99%' }} />
              <Flex.Row amount={0} style={{ width: '99%' }}>
                <RemovedFilter onToggle={this.onRemovedFilterToggle} />
                <Flex.By amount={1} />
                <RecordsExport
                  title={`Delivery_Export_${globalDateRange.startDate.format(
                    'YYYY-MM-DD'
                  )}_${globalDateRange.endDate.format('YYYY-MM-DD')}.csv`}
                  startDate={globalDateRange.startDate}
                  endDate={globalDateRange.endDate}
                  filters={this.state.filters}
                  trackDataExported={this.trackDataExported}
                  useCreatedAtDate={this.state.useCreatedAtDate}
                />
              </Flex.Row>
            </>
          )}

          <Records.Delivery
            startDate={globalDateRange.startDate}
            endDate={globalDateRange.endDate}
            onFiltersUpdated={this.onFiltersUpdated}
            showRemovedRecords={this.state.showRemovedRecords}
            useCreatedAtDate={this.state.useCreatedAtDate}
            updateUseCreatedAtDate={this.updateUseCreatedAtDate}
          />
        </PageContainer>
      </LightThemeProvider>
    )
  }
}

Delivery.propTypes = {
  isLoading: PropTypes.bool,
  areModulesReady: PropTypes.bool,
  exportRecords: PropTypes.func,

  ...withMixpanel.withProps
}

const selectGlobalDateFunc = selectGlobalDate()
const mapStateToProps = state => {
  return {
    isLoading: state.projectDetails.isLoading,
    globalDateRange: selectGlobalDateFunc(state)
  }
}

const ComposedDelivery = compose(
  connect(mapStateToProps),
  withMixpanel
)(Delivery)

export { ComposedDelivery as Delivery }
