import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Spin } from 'antd'

import { icons, ColourButton, Spacer } from 'portal/lib/primitives'
import { useModuleIds } from 'portal/lib/hooks'
import { sizes } from '@qflow/theme'

import { trackMixpanel } from 'portal/store/mixpanel/actions'
import {
  loadOneClickUnexportedRecordIds,
  getOneClickReportsBeingProcessed
} from 'portal/store/records/byModule/actions'
import { queueOneClickExport } from 'portal/store/records/exports/actions'

import { createFilterDescription } from './OneClickHelpers'

export function OneClickExport({ filters, onSuccess }) {
  const { oceWorking } = useSelector(state => state.records.exports)
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const [isOCEModalOpen, setIsOCEModalOpen] = useState(false)

  const {
    allRecordIds,
    count,
    unexportedRecordIds,
    retrievingReports,
    reportsBeingGenerated
  } = useSelector(state => state.records.byModule[moduleName])

  const { endDate, startDate } = useSelector(
    state => state.global.dateRange.global
  )

  useEffect(() => {
    if (allRecordIds && allRecordIds.length > 0) {
      dispatch(
        loadOneClickUnexportedRecordIds(
          accountId,
          projectId,
          moduleName,
          allRecordIds
        )
      )
    }
  }, [accountId, allRecordIds, dispatch, moduleName, onSuccess, projectId])

  const OCE_MODAL_OPEN = 'frontend_records_ocl_export_modal_open'
  const trackOCEModalOpened = useCallback(() => {
    dispatch(
      trackMixpanel(OCE_MODAL_OPEN, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  const OCE_ALL = 'frontend_records_ocl_export_all'
  const trackOCEAll = useCallback(() => {
    dispatch(
      trackMixpanel(OCE_ALL, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  const OCE_SELECTION = 'frontend_records_ocl_export_selection'
  const trackOCESelection = useCallback(() => {
    dispatch(
      trackMixpanel(OCE_SELECTION, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  const openOneClickExportModal = () => {
    setIsOCEModalOpen(true)
    checkReportsBeingGenerated()
    trackOCEModalOpened()
  }

  const checkReportsBeingGenerated = () => {
    dispatch(getOneClickReportsBeingProcessed(accountId, projectId, moduleName))
  }

  const handleOCECancel = () => {
    setIsOCEModalOpen(false)
  }

  const handleExportAll = useCallback(() => {
    const title = `OneClick_all-${count}_${startDate.substr(
      0,
      10
    )}_${endDate.substr(0, 10)}.xlsx`
    const filterDescription = createFilterDescription(filters)

    dispatch(
      queueOneClickExport(
        accountId,
        projectId,
        allRecordIds,
        title,
        filterDescription,
        {
          onSuccess
        }
      )
    )
    trackOCEAll()
  }, [
    count,
    startDate,
    endDate,
    filters,
    dispatch,
    accountId,
    projectId,
    allRecordIds,
    onSuccess,
    trackOCEAll
  ])

  const handleExportSome = useCallback(() => {
    const title = `OneClick_partial-${
      unexportedRecordIds.length
    }_${startDate.substr(0, 10)}_${endDate.substr(0, 10)}.csv`
    const filterDescription = createFilterDescription(filters)

    dispatch(
      queueOneClickExport(
        accountId,
        projectId,
        unexportedRecordIds,
        title,
        filterDescription,
        {
          onSuccess
        }
      )
    )

    trackOCESelection()
  }, [
    unexportedRecordIds,
    startDate,
    endDate,
    filters,
    dispatch,
    accountId,
    projectId,
    onSuccess,
    trackOCESelection
  ])

  if (!unexportedRecordIds) {
    return null
  }

  return (
    <>
      <OneClickExportButton
        id="OneClickExportButton"
        onClick={openOneClickExportModal}
        disabled={oceWorking}
      >
        {!oceWorking && <icons.Download size={sizes.SMALL} />}
        {oceWorking && <icons.Loading size={sizes.SMALL} />}
        <Spacer.Fine />
        OneClickLCA Export
      </OneClickExportButton>
      <Modal
        title="One Click LCA Export"
        visible={isOCEModalOpen}
        onCancel={handleOCECancel}
        footer={
          retrievingReports || reportsBeingGenerated.length > 0 ? (
            <p></p>
          ) : (
            [
              <ColourButton.Action
                key="submit"
                type="button"
                onClick={handleExportAll}
                style={{ display: 'inline-block', marginRight: '.75rem' }}
              >
                Export All Records
              </ColourButton.Action>,
              <ColourButton.Basic
                key="back"
                type="button"
                onClick={handleExportSome}
                style={{ display: 'inline-block' }}
                disabled={oceWorking || unexportedRecordIds.length < 1}
              >
                Export New Records
              </ColourButton.Basic>
            ]
          )
        }
      >
        {retrievingReports ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </div>
        ) : (
          <div>
            {reportsBeingGenerated.length > 0 ? (
              <div>
                <p>
                  Report {reportsBeingGenerated[0].name} is currently being
                  generated.
                </p>
                <p>Please wait and try again later.</p>
              </div>
            ) : (
              <div>
                <p>
                  Previously you have exported{' '}
                  {count - unexportedRecordIds.length} records.
                </p>
                <p>
                  There are {unexportedRecordIds.length} new records that have
                  not been exported yet.
                </p>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  )
}

const OneClickExportButton = styled(ColourButton.Basic)`
  min-width: 7rem;
`
