import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useQueryParam, StringParam } from 'use-query-params'

import { Spinner } from 'portal/lib/primitives'
import { loginStart } from 'portal/store/auth/actions'
import { isSignedIn, authIsChecked } from 'portal/store/auth/selectors'

export const FirstSignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const authChecked = useSelector(authIsChecked)
  const authenticated = useSelector(isSignedIn)
  const [signInHint] = useQueryParam('signin_hint', StringParam)

  useEffect(() => {
    if (!authChecked || authenticated) {
      return
    }

    dispatch(
      loginStart({
        firstSignIn: true,
        signInHint: signInHint
      })
    )
  }, [authChecked, authenticated, dispatch, history, signInHint])

  return <Spinner />
}
