import cjs from 'color'
import { BLACK } from './colours'
import { css } from 'styled-components'

const SHADE = cjs(BLACK).alpha(0.6).toString()

const HIGH_SHADE = cjs(BLACK).alpha(0.4).toString()

export const DISABLE = css`
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`

function getShadow(x, y, blur, spread = 0, shade = SHADE) {
  return css`
    -webkit-box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${shade};
    -moz-box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${shade};
    box-shadow: ${x}px ${y}px ${blur}px ${spread}px ${shade};
  `
}

export const LOW = getShadow(1, 2, 3)
export const MID = getShadow(2, 3, 10)
export const HIGH = getShadow(1, 5, 15, 5, HIGH_SHADE)

export const LOW_NO_OFFSET = getShadow(0, 0, 5, 1)
export const MID_NO_OFFSET = getShadow(0, 0, 10, 1)
export const HIGH_NO_OFFSET = getShadow(0, 0, 15, 1, HIGH_SHADE)

export const getGlow = (amount, colour = SHADE, spread = 2) => css`
  -webkit-box-shadow: 0px 0px ${amount}px ${spread}px ${colour};
  -moz-box-shadow: 0px 0px ${amount}px ${spread}px ${colour};
  box-shadow: 0px 0px ${amount}px ${spread}px ${colour};
`
