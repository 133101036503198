import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Table({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'}>
        <path d="M1.33333 7.66667H3C3.45833 7.66667 3.83333 7.29167 3.83333 6.83334V5.16667C3.83333 4.70834 3.45833 4.33334 3 4.33334H1.33333C0.875 4.33334 0.5 4.70834 0.5 5.16667V6.83334C0.5 7.29167 0.875 7.66667 1.33333 7.66667ZM1.33333 11.8333H3C3.45833 11.8333 3.83333 11.4583 3.83333 11V9.33334C3.83333 8.875 3.45833 8.5 3 8.5H1.33333C0.875 8.5 0.5 8.875 0.5 9.33334V11C0.5 11.4583 0.875 11.8333 1.33333 11.8333ZM1.33333 3.50001H3C3.45833 3.50001 3.83333 3.12501 3.83333 2.66667V1.00001C3.83333 0.541672 3.45833 0.166672 3 0.166672H1.33333C0.875 0.166672 0.5 0.541672 0.5 1.00001V2.66667C0.5 3.12501 0.875 3.50001 1.33333 3.50001ZM5.5 7.66667H14.6667C15.125 7.66667 15.5 7.29167 15.5 6.83334V5.16667C15.5 4.70834 15.125 4.33334 14.6667 4.33334H5.5C5.04167 4.33334 4.66667 4.70834 4.66667 5.16667V6.83334C4.66667 7.29167 5.04167 7.66667 5.5 7.66667ZM5.5 11.8333H14.6667C15.125 11.8333 15.5 11.4583 15.5 11V9.33334C15.5 8.875 15.125 8.5 14.6667 8.5H5.5C5.04167 8.5 4.66667 8.875 4.66667 9.33334V11C4.66667 11.4583 5.04167 11.8333 5.5 11.8333ZM4.66667 1.00001V2.66667C4.66667 3.12501 5.04167 3.50001 5.5 3.50001H14.6667C15.125 3.50001 15.5 3.12501 15.5 2.66667V1.00001C15.5 0.541672 15.125 0.166672 14.6667 0.166672H5.5C5.04167 0.166672 4.66667 0.541672 4.66667 1.00001Z" />
      </g>
    </svg>
  )
}

Table.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
