import { Input } from 'portal/lib/primitives'

const objectHasEmptyKeys = o => {
  return Object.keys(o).every(x => {
    return o[x] === '' || o[x] === null
  })
}

export const dateValidator = (
  getValues,
  path,
  required = false,
  label = ''
) => {
  return {
    validate: function () {
      const value = getValues(path)
      // Deep copy value object without 'rest' key
      const { rest, ...valueObject } = value

      if (required && objectHasEmptyKeys(valueObject)) {
        return `${label} is mandatory`
      }

      try {
        Input.DateTimeCells.yupValidatorNullable(path).validateSync(value)
        return true
      } catch (e) {
        return `Invalid Date/Time: ${String(e.message)}`
      }
    }
  }
}
