import styled from 'styled-components'
import * as ButtonBase from './ButtonBase'
import { colours, shadows } from '@qflow/theme'

const Basic = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: colours.WHITE,
    getBgHover: colours.CORE_NAVY,
    getBgActive: ({ theme }) => theme.colourButton.defaultBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.defaultFg,
    getFgHover: colours.WHITE,
    getFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    getBorderFg: ({ theme }) => theme.colourButton.borderFg,
    getBorderFgHover: ({ theme }) => theme.colourButton.borderFgHover,
    getBorderFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled,
    withGlow: false
  })}
  ${ButtonBase.TakeCSSProp}
`

const BasicBorderless = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.colourButton.defaultBg,
    getBgHover: ({ theme }) => theme.colourButton.defaultBgHover,
    getBgActive: ({ theme }) => theme.colourButton.defaultBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.defaultFg,
    getFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    getBorderFg: () => 'transparent',
    getBorderFgDisabled: () => 'transparent',
    getBorderFgHover: () => 'transparent',
    withGlow: false
  })}
  ${ButtonBase.TakeCSSProp}
`

const GanttFilterClear = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.gantt.filterClearButtonBg,
    getBgHover: ({ theme }) => theme.gantt.filterClearButtonBgHover,
    getBgActive: ({ theme }) => theme.gantt.filterClearButtonBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.defaultFg,
    getFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    getBorderFg: ({ theme }) => theme.gantt.filterClearButtonBorder,
    getBorderFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled,
    withGlow: false
  })}
  ${ButtonBase.TakeCSSProp}
  ${shadows.MID}
`

const Negative = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.colourButton.negativeBg,
    getBgHover: ({ theme }) => theme.colourButton.negativeBgHover,
    getBgActive: ({ theme }) => theme.colourButton.negativeBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.negativeFg,
    getFgDisabled: ({ theme }) => theme.colourButton.negativeFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    withGlow: false,
    getBorderFgHover: () => 'transparent'
  })}
  ${ButtonBase.TakeCSSProp}
`

const Action = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.colourButton.actionBg,
    getBgHover: ({ theme }) => theme.colourButton.actionBgHover,
    getBgActive: ({ theme }) => theme.colourButton.actionBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.actionFg,
    getFgHover: ({ theme }) => theme.colourButton.actionFg,
    getFgDisabled: ({ theme }) => theme.colourButton.defaultFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    withGlow: false,
    // Swap Border Focus and Hover colours around for Action button
    getBorderFgFocus: ({ theme }) => theme.colourButton.borderFgHover,
    getBorderFgHover: ({ theme }) => theme.colourButton.borderFgFocus
  })}
  ${ButtonBase.TakeCSSProp}
`

const Caution = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.colourButton.cautionBg,
    getBgHover: ({ theme }) => theme.colourButton.cautionBgHover,
    getBgActive: ({ theme }) => theme.colourButton.cautionBgActive,
    getBgDisabled: ({ theme }) => theme.colourButton.defaultBg,
    getFg: ({ theme }) => theme.colourButton.cautionFg,
    getFgHover: ({ theme }) => theme.colourButton.cautionFg,
    getFgDisabled: ({ theme }) => theme.colourButton.cautionFgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    withGlow: false,
    getBorderFg: ({ theme }) => theme.colourButton.cautionBorder,
    getBorderFgHover: ({ theme }) => theme.colourButton.cautionBorderHover
  })}
  ${ButtonBase.TakeCSSProp}
`

export const ColourButton = {
  Basic,
  BasicBorderless,
  GanttFilterClear,
  Negative,
  Action,
  Caution
}
