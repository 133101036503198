import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colours } from '@qflow/theme'

import { Calendar, ChevronDown } from 'portal/lib/primitives/icons'
import { PopupCard } from 'portal/lib/primitives/portals'
import { ButtonBase } from 'portal/lib/primitives/buttons/ButtonBase'
import { DateRangePickerInner } from './DateRangePickerInner'
import moment from 'moment'

import { useToggleState } from 'portal/utils/useToggleState'

const DEFAULT_RANGES = [
  { key: 7, label: 'Last 7 days' },
  { key: 14, label: 'Last 14 days' },
  { key: 30, label: 'Last 30 days' },
  { key: 60, label: 'Last 60 days' },
  { key: 90, label: 'Last 90 days' }
]

if (process.env.NODE_ENV === 'development') {
  DEFAULT_RANGES.push({ key: 365, label: 'Last year' })
}

export function DateRangePicker({
  id,
  value,
  onChange,
  onClosed,
  placeholder = 'Select date',
  disabled = false,
  visible = true
}) {
  const [isOpen, , close, toggleOpen] = useToggleState(false)
  const handleClose = useCallback(() => {
    close()
    onClosed?.()
  }, [close, onClosed])

  const label = useLabelCalculation(value, placeholder)

  const handleChange = useCallback(
    value => {
      onChange && onChange(value)
    },
    [onChange]
  )

  const anchorElement = useRef()

  return (
    <Container id={id} visible={visible}>
      <ComponentButton
        active={isOpen}
        onClick={toggleOpen}
        id="DateRangePickerButton"
        disabled={disabled}
        ref={anchorElement}
      >
        <CalendarIcon>
          <Calendar />
        </CalendarIcon>
        <ComponentLabel id="DateRangePickerLabel" dim={!value}>
          {label}
        </ComponentLabel>

        <ChevronDown rotate={isOpen ? '180' : '0'} />
      </ComponentButton>

      <PopupCard
        show={isOpen}
        awaitLaggyLayout
        anchorElementRef={anchorElement}
        onOutsideClick={handleClose}
        enabledMobileResponsiveness
      >
        <DateRangePickerInner
          onChange={handleChange}
          value={value}
          defaultRanges={DEFAULT_RANGES}
          onClose={handleClose}
        />
      </PopupCard>
    </Container>
  )
}

DateRangePicker.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any
  }),
  onChange: PropTypes.func,
  onClosed: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
}

const useLabelCalculation = (value, placeholder) => {
  return useMemo(() => {
    if (value && value.label) {
      return value.label
    }

    const { startDate, endDate } = value || {}
    if (!startDate && !endDate) {
      return placeholder
    }

    function format(date) {
      if (!date) {
        return ''
      }
      return moment(date).format('DD/MM/YYYY')
    }

    return `${format(startDate)}${endDate ? ' - ' : ''}${format(endDate)}`
  }, [placeholder, value])
}

// Styled components

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  overflow: visible;
`

const ComponentButton = styled(ButtonBase)`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${colours.BLUEY_BLACK};
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: none;
  padding: 4px 10px;
  min-width: 160px;

  :active,
  :focus,
  :hover {
    background-color: ${colours.LIGHT_GREY};
    border-color: transparent;
    color: ${colours.BLUEY_BLACK};
  }
`

const ComponentLabel = styled.div`
  display: flex;
  flex: 1;
  text-align: left;

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colours.BLUEY_BLACK};

  ${({ dim }) =>
    dim &&
    css`
      color: ${({ theme }) => theme.fgDim};
    `}
`
const CalendarIcon = styled.div`
  display: inline-block;
  margin-top: 2px;
`
