import { useEffect, useState } from 'react'
import { sizes } from '@qflow/theme'

import {
  AssignmentContainer,
  AssignmentStripCard,
  AssignmentButtonContainer,
  MaxThresholdMessage
} from 'portal/components/CommonStyles/AssignmentStripStyledComponents'

import { ColourButton, icons, Spacer, Text } from 'portal/lib/primitives'
import { AssignmentRow } from 'portal/lib/sharedComponents/AssignmentRow'
import 'portal/components/CommonStyles/antd-modal.scss'

export function UnassignmentStrip({
  users,
  onApply,
  recordCount,
  maxAssignment
}) {
  const emptyUnassignee = {
    user: ''
  }

  const [unassignees, setUnassignees] = useState([
    { id: 0, ...emptyUnassignee }
  ])
  const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(true)
  const [showMaxThresholdMessage, setShowMaxThresholdMessage] = useState(false)

  const userCount = Object.keys(users).length

  const handleApply = () => {
    onApply(unassignees)
  }

  const handleAddRow = () => {
    // Check if max assignment threshold would be exceeded
    if (
      maxAssignment &&
      recordCount * (unassignees.length + 1) > maxAssignment
    ) {
      setShowMaxThresholdMessage(true)
    } else {
      setShowMaxThresholdMessage(false)
      // Add new Assignee Row
      const ids = unassignees.map(assignee => assignee.id)
      const newId = Math.max(...ids, 0) + 1
      setUnassignees(currentUnassignees => [
        ...currentUnassignees,
        { id: newId, ...emptyUnassignee }
      ])
    }
  }

  const handleDeleteRow = id => {
    const newUnassignees = [...unassignees]
    const unassigneeIndex = newUnassignees.findIndex(
      unassignee => unassignee.id === id
    )
    newUnassignees.splice(unassigneeIndex, 1)
    setUnassignees(newUnassignees)

    setShowMaxThresholdMessage(false)
  }

  const handleUpdateUnassignee = (id, user) => {
    const newUnassignees = [...unassignees]
    const unassigneeIndex = newUnassignees.findIndex(
      unassignee => unassignee.id === id
    )
    newUnassignees[unassigneeIndex].user = user
    setUnassignees(newUnassignees)
  }

  useEffect(() => {
    // Check all assignees has a value for user stored
    // Disable "Apply" button if one or more values are bad (empty string or null)
    const badUnassignees = unassignees.filter(
      assignee => assignee.user === '' || assignee.user === null
    )
    if (badUnassignees.length > 0) {
      setIsApplyBtnDisabled(true)
    } else {
      setIsApplyBtnDisabled(false)
    }
  }, [unassignees])

  return (
    <AssignmentContainer>
      <AssignmentStripCard>
        {unassignees.map(unassignee => (
          <AssignmentRow
            key={unassignee.id}
            {...unassignee}
            onChange={handleUpdateUnassignee}
            onDelete={handleDeleteRow}
            disableRemoveRow={unassignees.length < 2}
            users={users}
            unassign={true}
          />
        ))}

        <AssignmentButtonContainer
          $remainingAssignees={userCount - unassignees.length}
        >
          {userCount > unassignees.length && (
            <ColourButton.Basic type="button" onClick={handleAddRow}>
              <icons.Add colour="currentColor" size={sizes.MEDIUM} />
              <Spacer.Fine />
              Add row
            </ColourButton.Basic>
          )}
          <ColourButton.Action
            type="button"
            onClick={handleApply}
            disabled={isApplyBtnDisabled}
          >
            Apply to {recordCount} record{recordCount > 1 && 's'}
          </ColourButton.Action>
        </AssignmentButtonContainer>
        {showMaxThresholdMessage && (
          <MaxThresholdMessage>
            <p>
              <Text error bold>
                Cannot add user row. You have hit the maximum number of changes
                that can be made in a single request.
              </Text>
            </p>
            <p>
              <Text error bold>
                Please apply to these users and then re-select to apply to
                additional users.
              </Text>
            </p>
          </MaxThresholdMessage>
        )}
      </AssignmentStripCard>
    </AssignmentContainer>
  )
}
