import React from 'react'
import { Avatar, Tooltip } from 'antd'

export function UserAvatar({
  displayName,
  displayTooltip = true,
  size = 'medium'
}) {
  return (
    <Tooltip
      title={displayName}
      placement="top"
      size="large"
      mouseLeaveDelay={0.5}
      visible={displayTooltip}
    >
      <Avatar
        style={{
          backgroundColor: userColourAllocation(displayName)
        }}
        size={size}
      >
        {getInitials(displayName)}
      </Avatar>
    </Tooltip>
  )
}

function getInitials(fullName) {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    acc = `${acc}${curr.charAt(0).toUpperCase()}`

    return acc
  }, '')
  return initials
}

// Takes an input string (username) and returns a colour allocation in the format HSL(hue saturation lightness)
function getHashOfInputString(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)
  return hash
}

function normaliseHash(hash, min, max) {
  return Math.floor((hash % (max - min)) + min)
}

// We set Hue to the standard range but can change if we want personalisation to Qflow theme
function generateColorHsl(username, saturationRange, lightnessRange) {
  const hash = getHashOfInputString(username)
  const h = normaliseHash(hash, 0, 360)
  const s = normaliseHash(hash, saturationRange[0], saturationRange[1])
  const l = normaliseHash(hash, lightnessRange[0], lightnessRange[1])
  const hsl = [h, s, l]
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
}

function userColourAllocation(username) {
  const saturationRange = [30, 100]
  const lightnessRange = [40, 90]
  const hashedColour = generateColorHsl(
    username,
    saturationRange,
    lightnessRange
  )
  return hashedColour
}
