import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { trackMixpanel } from 'portal/store/mixpanel/actions'
import { useModuleIds } from 'portal/lib/hooks'

const EV_RECORDS_DETAILS_OPEN = 'frontend_records_details_open'

export const useRecordDetailsMixpanel = () => {
  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()

  const trackDetailsOpened = useCallback(() => {
    dispatch(
      trackMixpanel(EV_RECORDS_DETAILS_OPEN, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  return {
    trackDetailsOpened
  }
}
