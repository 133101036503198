import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import './Settings.css'
import {
  Table,
  Modal,
  Alert,
  Select,
  Input,
  Form,
  Switch,
  Tooltip,
  Row,
  Col
} from 'antd'
import details from 'portal/assets/RecordsTable/reportViewBlk.svg'

import { accountLoad } from 'portal/store/accountDetails/actions'
import { matchesEmailFilter } from 'portal/lib/functions/userSettingsHelpers'
import {
  ColourButton,
  UserCard,
  UserAvatar,
  ClickableLink
} from 'portal/lib/primitives'
import {
  getIdFromRole,
  ROLE_INFORMATION,
  USER_ROLES
} from 'portal/lib/constants/UserRole'
import { QuestionCircleFilled } from '@ant-design/icons'
import { colours } from '@qflow/theme'
import { SmallSpinner } from 'portal/lib/primitives'
import { FORBIDDEN } from 'portal/routes'
import { projectUsersLoad } from 'portal/store/projectUsers/actions'
import { useUserProjectRole } from 'portal/lib/hooks'
import moment from 'moment'
import {
  projectUserEditStart,
  projectUsersUpdateStart
} from 'portal/store/projectUserDetails/actions'

const { Search } = Input

const areNotificationsEnabledForCurrentProject = user => {
  if (
    user.notificationsEnabled &&
    user.projectSettings.length > 0 &&
    user.projectSettings[0].notificationsEnabled
  ) {
    return true
  }
  return false
}

const getTableData = usersState => {
  const data = [{ userId: '0' }]
  if (usersState.loading == null) {
    return data
  }

  const minDate = moment.utc('0001-01-01')

  Object.values(usersState.users).forEach(user => {
    let role = 'No Access'
    if (user.projectPermissions.length > 0) {
      role = USER_ROLES[user.projectPermissions[0].userRoleId]
    }

    data.push({
      userId: user.userId,
      displayName: user.displayName,
      email: user.email,
      role: role,
      notificationsEnabled: areNotificationsEnabledForCurrentProject(user),
      lastActivity:
        user.lastActivity && moment.utc(user.lastActivity).isAfter(minDate)
          ? moment(user.lastActivity).local().format('DD/MM/YYYY')
          : 'No activity',
      userData: user
    })
  })

  data.sort((a, b) => {
    if (a.displayName && b.displayName) {
      return a.displayName
        .toUpperCase()
        .localeCompare(b.displayName.toUpperCase())
    }
    return 0
  })

  return data
}

export const ProjectSettingsTable = () => {
  const dispatch = useDispatch()
  const { accountId, projectId } = useParams()
  const history = useHistory()
  const users = useSelector(state => state.projectUsers)
  const projectUser = useSelector(state => state.projectUserDetails)
  const auth = useSelector(state => state.auth)

  const { isProjectAdmin, roleLoaded } = useUserProjectRole(
    accountId,
    projectId
  )

  const [form] = Form.useForm()
  const [formRoleFieldDisabled, setFormRoleFieldDisabled] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)
  const [alert, setAlert] = useState(null)

  const [nameFilter, setNameFilter] = useState('')
  const [emailFilter, setEmailFilter] = useState('')
  const [roleFilter, setRoleFilter] = useState('')

  useEffect(() => {
    dispatch(accountLoad(accountId))
  }, [accountId, dispatch, projectId])

  if (roleLoaded && !isProjectAdmin) {
    history.push(FORBIDDEN)
  }

  useEffect(() => {
    if (!projectUser.loading && projectUser.error != null) {
      setAlert(
        <Alert
          key="error"
          message={'Something Went Wrong'}
          description={'Please try submitting again.'}
          type="error"
          closable
        />
      )
    }
    if (!projectUser.loading && projectUser.error == null) {
      setAlert(null)
      setModalOpen(false)
      dispatch(projectUsersLoad(accountId, projectId, true))
    }
  }, [projectUser.loading, projectUser.error, dispatch, accountId, projectId])

  const getFormData = user => {
    let accessEnabled = false
    let role = 'Reader'
    if (user.projectPermissions.length > 0) {
      accessEnabled = true
      role = USER_ROLES[user.projectPermissions[0].userRoleId]
    }
    setFormRoleFieldDisabled(!accessEnabled)

    return {
      notificationsEnabled: areNotificationsEnabledForCurrentProject(user),
      accessEnabled: accessEnabled,
      role: role
    }
  }

  const handleFormSubmit = () => {
    form.validateFields().then(values => {
      let projectPermissions = []
      if (values.accessEnabled) {
        projectPermissions.push({
          userId: projectUser.userId,
          projectId: projectId,
          userRoleId: getIdFromRole(values.role)
        })
      }

      let notificationsEnabled =
        projectUser.notificationsEnabled || values.notificationsEnabled

      let projectSettings = [
        {
          userId: projectUser.userId,
          projectId: projectId,
          notificationsEnabled: values.notificationsEnabled
        }
      ]
      setAlert(null)
      dispatch(
        projectUsersUpdateStart(
          accountId,
          projectId,
          projectUser.userId,
          projectPermissions,
          notificationsEnabled,
          projectSettings
        )
      )
    })
  }

  const handleCancel = () => {
    setModalOpen(false)
    setAlert(null)
    dispatch(projectUserEditStart({}))
  }

  const columns = [
    {
      width: '2rem',
      render: (data, _, index) => {
        if (index == 0) {
          return
        }
        return (
          <UserAvatar displayName={data.displayName} displayTooltip={false} />
        )
      }
    },
    {
      title: 'User',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (_, user, index) => {
        if (index === 0) {
          return (
            <Search
              onChange={event => setNameFilter(event.target.value)}
              allowClear
            />
          )
        }
        return user.displayName
      }
    },
    {
      width: '10rem',
      title: () => {
        let roleDescriptions = 'The following options are:'
        Object.values(USER_ROLES).forEach(role => {
          roleDescriptions += `\n    • ${role}: ${ROLE_INFORMATION[role]}`
        })
        return (
          <>
            {'Role'}
            <Tooltip
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={`This corresponds to the permission type your user has. \n\n ${roleDescriptions}`}
              placement="right"
            >
              <QuestionCircleFilled
                style={{
                  color: colours.TEAL,
                  float: 'right',
                  marginTop: '3px'
                }}
              />
            </Tooltip>
          </>
        )
      },
      dataIndex: 'role',
      key: 'role',
      render: (_, user, index) => {
        if (index === 0) {
          return (
            <Search
              onChange={event => setRoleFilter(event.target.value)}
              allowClear
            />
          )
        }

        return user.role
      }
    },
    {
      width: '10rem',
      title: () => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {'Notifications'}
            <Tooltip
              overlayStyle={{ whiteSpace: 'pre-line' }}
              title={`This corresponds to whether the user receives project specific notifications. \n 
              If enabled, they will receive an email notification each time an event is raised for a record in this project.`}
              placement="right"
            >
              <QuestionCircleFilled
                style={{
                  color: colours.TEAL,
                  float: 'right',
                  marginTop: '3px'
                }}
              />
            </Tooltip>
          </div>
        )
      },
      dataIndex: 'notificationsEnabled',
      key: 'notificationsEnabled',
      render: (_, user, index) => {
        if (index === 0) {
          return
        }
        if (user.notificationsEnabled) {
          return 'Enabled'
        }
        return 'Disabled'
      }
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      render: (_, user, index) => {
        if (index === 0) {
          return (
            <Search
              onChange={event => setEmailFilter(event.target.value)}
              allowClear
            />
          )
        }
        return user.email
      }
    },
    {
      width: '18rem',
      title: 'Last activity',
      dataIndex: 'lastActivity',
      key: 'lastActivity'
    },
    {
      width: '2rem',
      render: (data, _, index) => {
        if (index == 0) {
          return
        }
        return (
          <ClickableLink
            id="navigateToDetailsBtn"
            onClick={() => {
              dispatch(projectUserEditStart(data.userData))
              form.setFieldsValue(getFormData(data.userData))
              setModalOpen(true)
            }}
            to={'#'}
          >
            <img src={details} alt="" />
          </ClickableLink>
        )
      }
    }
  ]

  return (
    <>
      <Modal
        visible={modalOpen}
        onOk={handleFormSubmit}
        onCancel={handleCancel}
        confirmLoading={projectUser.loading}
        title={
          <UserCard
            displayName={projectUser?.displayName}
            email={projectUser?.email}
          />
        }
        footer={[
          <ColourButton.Basic
            key="back"
            type="button"
            onClick={handleCancel}
            style={{ display: 'inline-block', marginRight: '.75rem' }}
          >
            Cancel
          </ColourButton.Basic>,
          <ColourButton.Action
            key="submit"
            type="button"
            onClick={handleFormSubmit}
            style={{ display: 'inline-block' }}
          >
            OK
          </ColourButton.Action>
        ]}
      >
        <Form
          form={form}
          name="userEdit"
          layout="vertical"
          requiredMark={false}
          onValuesChange={values => {
            if (values.accessEnabled === false) {
              form.setFieldsValue({ notificationsEnabled: false })
            }
            if (values.notificationsEnabled === true) {
              form.setFieldsValue({ accessEnabled: true })
            }
            setFormRoleFieldDisabled(!form.getFieldValue('accessEnabled'))
          }}
        >
          <Form.Item
            name="accessEnabled"
            label="Access Enabled"
            valuePropName="checked"
          >
            <Switch loading={projectUser.loading} />
          </Form.Item>
          <Form.Item
            name="notificationsEnabled"
            label="Notifications Enabled"
            valuePropName="checked"
          >
            <Switch loading={projectUser.loading} />
          </Form.Item>
          <Form.Item name="role" label="Role" style={{ marginTop: '-15px' }}>
            <Select
              style={{ width: '100%' }}
              disabled={formRoleFieldDisabled || projectUser.loading}
              loading={projectUser.loading}
            >
              {Object.values(USER_ROLES).map(value => (
                <Select.Option value={value} key={value}>
                  <Row>
                    <Col span={8}>{value}</Col>
                    <Col span={1} offset={15}>
                      <Tooltip
                        title={ROLE_INFORMATION[value]}
                        placement="right"
                      >
                        <QuestionCircleFilled
                          style={{
                            color: colours.TEAL
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        {alert}
      </Modal>
      <Table
        rowKey="userId"
        columns={columns}
        dataSource={getTableData(users).filter(
          user =>
            user.displayName == null ||
            (user.displayName
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) &&
              matchesEmailFilter(
                auth.isQualisFlowInternal,
                emailFilter,
                user.email
              ) &&
              user.role.toLowerCase().includes(roleFilter.toLowerCase()))
        )}
        pagination={false}
        rowClassName={(record, index) => {
          if (index === 0) {
            return 'table-row-filter'
          }
          return 'table-row-light'
        }}
        loading={{
          spinning: users.loading,
          indicator: (
            <div>
              <SmallSpinner />
            </div>
          )
        }}
      />
    </>
  )
}
