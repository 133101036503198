import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { Layout } from './Layout'

export const AppRoute = ({ component, title = 'Qflow', ...props }) => {
  useEffect(() => {
    document.title = title

    return () => {
      document.title = 'Qflow'
    }
  }, [title])

  return (
    <Route
      {...props}
      render={props => {
        const ScreenComponent = component
        return (
          <Layout>
            <ScreenComponent {...props} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  title: PropTypes.string,
  ...Route.propTypes
}
