import { takeEvery, put } from 'redux-saga/effects'

import { DASHBOARD_LOAD } from './actions'
import * as configActions from 'portal/store/configuration/actions'

function* mapToConfigurationAction(action) {
  yield put(
    configActions.loadConfiguration(
      action.accountId,
      action.projectId,
      action.moduleName
    )
  )
}

export function* watch() {
  yield takeEvery(DASHBOARD_LOAD, mapToConfigurationAction)
}
