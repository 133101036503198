import { useMemo } from 'react'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'

import { BI_REPORT } from 'portal/routes'
import { SideBar, icons } from 'portal/lib/primitives'
import { useResponsive } from 'portal/lib/hooks'
import { useParams } from 'react-router'

export function BIReportSideBarButton({
  reportName,
  reportType,
  btnTag = '',
  onClick
}) {
  const { isMobile } = useResponsive()

  const params = useParams()

  const uriReport = useMemo(() => {
    return generatePath(BI_REPORT, {
      ...params,
      reportType: reportType
    })
  }, [params, reportType])

  if (isMobile) {
    return null
  }

  return (
    <SideBar.NavButton
      id={reportType}
      to={uriReport}
      onClick={onClick}
      Icon={btnTag ? icons.Premium : icons.Dashboard}
      btnTag={btnTag}
    >
      {reportName}
    </SideBar.NavButton>
  )
}

BIReportSideBarButton.propTypes = {
  reportType: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  btnTag: PropTypes.string
}
