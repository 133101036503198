import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router'
import { css } from 'styled-components'

import { LightThemeProvider } from '@qflow/theme'
import { MODULES } from 'portal/lib/constants'
import {
  Modal,
  Padding,
  Text,
  Label,
  Input,
  Flex,
  ColourButton,
  Spacer,
  icons
} from 'portal/lib/primitives'
import { useModuleIds } from 'portal/lib/hooks'
import { WASTE, WASTE_TABS, DELIVERY, DELIVERY_TABS } from 'portal/routes'

import { removeRecord } from 'portal/store/records/actions'
import { trackRecordRemoved } from './mixpanel'

export function RemoveRecordModal({
  show = false,
  closeClick,
  removedSuccessUrl
}) {
  const [confirmation, setConfirmation] = useState('')
  const { working, error } = useSelector(state => state.records.removal)

  const handleRemoveRecord = useRemovalHandler(removedSuccessUrl)

  return (
    <LightThemeProvider>
      <Modal
        id="RemoveRecordModal"
        title="Remove Record"
        show={show}
        closeClick={closeClick}
        centred
        css={ModalCSS}
      >
        <Padding.Modal>
          <Text>
            Remove this record from reports and exports. You will still be able
            to access it from the summary table.
          </Text>

          <Label>{'Please type "REMOVE":'}</Label>
          <Input.Text
            placeholder="Confirm record removal"
            value={confirmation}
            onChange={setConfirmation}
            maxLines={1}
          />

          <Spacer.Medium />

          <Flex.Centred>
            <Flex.By />

            <ColourButton.Negative
              flex={1}
              onClick={handleRemoveRecord}
              disabled={working || confirmation.toUpperCase() !== 'REMOVE'}
            >
              {working ? <icons.Loading /> : 'Yes, REMOVE'}
            </ColourButton.Negative>

            <Flex.By />
          </Flex.Centred>

          {error && (
            <>
              <Spacer.Medium />
              <Flex.Centred>
                <Text error>
                  There was a problem removing this record, please try again.
                </Text>
              </Flex.Centred>
            </>
          )}
        </Padding.Modal>
      </Modal>
    </LightThemeProvider>
  )
}

RemoveRecordModal.propTypes = {
  show: PropTypes.bool,
  closeClick: PropTypes.func,
  removedSuccessUrl: PropTypes.string
}

const ModalCSS = css`
  max-width: 25rem;
`

function useRemovalHandler(removedSuccessUrl) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { accountId, projectId, moduleName, recordId } = useModuleIds()

  const successUri = useMemo(() => {
    if (removedSuccessUrl) {
      return removedSuccessUrl
    }

    return MODULES.select(moduleName, {
      delivery: () =>
        generatePath(DELIVERY, {
          accountId,
          projectId,
          moduleName,
          tab: DELIVERY_TABS.TABLE
        }),
      waste: () =>
        generatePath(WASTE, {
          accountId,
          projectId,
          moduleName,
          tab: WASTE_TABS.TABLE
        })
    })
  }, [accountId, moduleName, projectId, removedSuccessUrl])

  return useCallback(() => {
    dispatch(
      removeRecord(accountId, projectId, moduleName, recordId, {
        onSuccess: () => {
          history.replace(successUri)

          dispatch(
            trackRecordRemoved(recordId, moduleName, accountId, projectId)
          )
        }
      })
    )
  }, [
    accountId,
    dispatch,
    history,
    moduleName,
    projectId,
    recordId,
    successUri
  ])
}
