import { takeEvery, put, call } from 'redux-saga/effects'
import produce from 'immer'

import {
  RECORD_EDITED,
  recordEditUploaded,
  recordEditUploadFailed,
  RECORD_ITEM_REMOVED_TOGGLED
} from './actions'
import { putRecord } from 'portal/store/records/api'

function* recordEditedSaga(action) {
  const { accountId, projectId, moduleName, record, callbacks, isImperial } =
    action

  try {
    const response = yield call(() =>
      putRecord(accountId, projectId, moduleName, record, isImperial)
    )

    response.data.attachments = record.attachments
    yield put(
      recordEditUploaded(accountId, projectId, moduleName, response.data)
    )

    yield call(() => callbacks?.onSuccess?.())
  } catch (error) {
    yield put(recordEditUploadFailed(accountId, projectId, moduleName, error))
  }
}

function* lineItemRemovalSaga(action) {
  const { record, lineItemIndex, isRemoved } = action

  yield call(recordEditedSaga, {
    ...action,
    record: produce(record, draft => {
      const items = draft.data.items ?? draft.data.products
      items[lineItemIndex].isRemoved = isRemoved
    })
  })
}

export function* watch() {
  yield takeEvery(RECORD_EDITED, recordEditedSaga)
  yield takeEvery(RECORD_ITEM_REMOVED_TOGGLED, lineItemRemovalSaga)
}
