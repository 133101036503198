import { trackMixpanel } from 'portal/store/mixpanel/actions'

const EV_RECORDS_ATTACHMENT_DOWNLOAD = 'frontend_records_attachment_download'

export function trackAttachmentDownload(
  accountId,
  projectId,
  moduleName,
  moduleType,
  record
) {
  return trackMixpanel(EV_RECORDS_ATTACHMENT_DOWNLOAD, {
    module_type: moduleType,
    record_type: moduleName || moduleType,

    account_id: accountId,
    project_id: projectId,
    record_id: record.recordId
  })
}
