/* eslint-disable prettier/prettier */

export const NOT_FOUND = "/404"
export const FORBIDDEN = "/403"
export const SIGNIN_REDIRECT = "/signin-oidc"
export const FIRST_SIGNIN = "/first-signin"
export const LOGIN_FAILED = "/login-failed"
export const SIGN_OUT = "/sign-out"

export const ACCOUNTS =
  "/accounts"

export const PROJECTSETTINGS =
  "/accounts/:accountId/projects/:projectId/settings"

export const DELIVERY =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/:tab(table|events|dashboard)"
export const DELIVERY_ROOT =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)"
export const DELIVERY_DEFAULT =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/table"
export const DELIVERY_TABLE =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/:tab(table)"
export const DELIVERY_EVENTS =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/:tab(events)"
export const DELIVERY_DASHBOARD =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/:tab(dashboard)"
export const DELIVERY_RECORD_DETAILS =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/record/:recordId"
export const DELIVERY_RECORD_EDIT =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/record/:recordId/edit"
export const DELIVERY_RECORD_LINE_ITEM_EDIT =
  "/accounts/:accountId/projects/:projectId/:moduleName(delivery)/record/:recordId/edit/item/:lineItem(new|\\d+)"
export const DELIVERY_TABS = {
  EVENTS: 'events',
  TABLE: 'table',
  DASHBOARD: 'dashboard'
}

export const WASTE =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/:tab(table|events|dashboard)"
export const WASTE_ROOT =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)"
export const WASTE_DEFAULT =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/table"
export const WASTE_TABLE =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/:tab(table)"
export const WASTE_EVENTS =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/:tab(events)"
export const WASTE_DASHBOARD =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/:tab(dashboard)"
export const WASTE_RECORD_DETAILS =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/record/:recordId"
export const WASTE_RECORD_EDIT =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/record/:recordId/edit"
export const WASTE_RECORD_LINE_ITEM_EDIT =
  "/accounts/:accountId/projects/:projectId/:moduleName(waste)/record/:recordId/edit/item/:lineItem(new|\\d+)"
export const WASTE_TABS = {
  EVENTS: 'events',
  TABLE: 'table',
  DASHBOARD: 'dashboard'
}

export const BI_REPORT =
  "/accounts/:accountId/bireport/:reportType"

export const BASE_MODULE_NAV =
  "/accounts/:accountId/projects/:projectId/:moduleName"

export const ACCOUNT_SETTINGS =
  "/accounts/:accountId/accountSettings"

/** @enum {string} */
export const DESTINATION_TYPES = {
  RECORD: 'record',
  REPORT: 'report'
}
export const RECEIVED_DESTINATION =
  `/destination/:destinationType(${Object.values(DESTINATION_TYPES).join("|")})/:destinationId`
