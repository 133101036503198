import { initialConfigurationState } from './reducer'

/**
 * @returns {(Object) => initialConfigurationState}
 */
export function selectConfiguration(accountId, projectId, moduleName) {
  return function (state) {
    return (
      state.configuration[accountId]?.[projectId]?.[moduleName] ??
      initialConfigurationState
    )
  }
}
