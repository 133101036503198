import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Building({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      style={{ width: size }}
      {...props}
    >
      <path
        fill={colour || 'currentColor'}
        d="M10.5 7.90938V3.60104C10.5 3.15938 10.325 2.73438 10.0083 2.42604L8.58333 1.00104C8.25833 0.676044 7.73333 0.676044 7.40833 1.00104L5.99167 2.41771C5.675 2.73438 5.5 3.15938 5.5 3.60104V4.57604H2.16667C1.25 4.57604 0.5 5.32604 0.5 6.24271V14.576C0.5 15.4927 1.25 16.2427 2.16667 16.2427H13.8333C14.75 16.2427 15.5 15.4927 15.5 14.576V9.57604C15.5 8.65938 14.75 7.90938 13.8333 7.90938H10.5ZM3.83333 14.576H2.16667V12.9094H3.83333V14.576ZM3.83333 11.2427H2.16667V9.57604H3.83333V11.2427ZM3.83333 7.90938H2.16667V6.24271H3.83333V7.90938ZM8.83333 14.576H7.16667V12.9094H8.83333V14.576ZM8.83333 11.2427H7.16667V9.57604H8.83333V11.2427ZM8.83333 7.90938H7.16667V6.24271H8.83333V7.90938ZM8.83333 4.57604H7.16667V2.90938H8.83333V4.57604ZM13.8333 14.576H12.1667V12.9094H13.8333V14.576ZM13.8333 11.2427H12.1667V9.57604H13.8333V11.2427Z"
      />
    </svg>
  )
}

Building.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
