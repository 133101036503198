import { css } from 'styled-components'

export function mobileCSS(cssInstance) {
  return css`
    @media screen and (max-width: 500px) {
      ${cssInstance}
    }
  `
}

export function ie11CSS(cssInstance) {
  return css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ${cssInstance}
    }
  `
}
