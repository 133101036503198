import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { colours } from '@qflow/theme'
import _ from 'lodash'

import { AccessGrid } from 'portal/components/AccessGrid/AccessGrid'
import { PageContainer } from 'portal/pages/PageContainer'
import { accsGetSaga } from 'portal/store/accounts/actions'
import { projectsClear } from 'portal/store/projects/actions'
import { projectDetailsClear } from 'portal/store/projectDetails/actions'
import { Spinner, Heading, Text } from 'portal/lib/primitives'

import { BodyContainer, BodyInner } from './common'

class Accounts extends Component {
  componentDidMount() {
    const { onAccountsInit } = this.props
    // Clear Session Storage of ProjectId and Module
    sessionStorage.removeItem('last-used-project-id')
    sessionStorage.removeItem('last-used-module-name')

    onAccountsInit()
    this.maybeForwardToAccount()
  }

  componentDidUpdate() {
    this.maybeForwardToAccount()
  }

  maybeForwardToAccount = () => {
    const { accounts, history, match } = this.props
    if (accounts && accounts.length === 1) {
      history.replace(
        match.url + '/' + accounts[0].accountId + '/biReport/dashboard'
      )
    }
  }

  render() {
    const { isLoading } = this.props

    return (
      <PageContainer
        id="AccountsPage"
        style={{ backgroundColor: colours.CONTROL_PANEL_GREY }}
      >
        <div>
          <Heading>ACCOUNTS</Heading>

          {isLoading ? this.renderLoading() : this.renderBody()}
        </div>
      </PageContainer>
    )
  }

  renderLoading() {
    return <Spinner />
  }

  renderBody() {
    const { accounts } = this.props
    if (!accounts || accounts.length === 0) {
      return <Text>No items found!</Text>
    }

    return (
      <BodyContainer>
        <BodyInner>
          <AccessGrid
            avatarBG={colours.CONSTRUCTION_ORANGE}
            items={_(accounts)
              .map(account => {
                return {
                  gridItemId: account.accountId,
                  name: account.name,
                  urlLink:
                    '/accounts/' + account.accountId + '/biReport/dashboard',
                  avatar: account.name[0]
                }
              })
              .sortBy(account => account.name.toLowerCase())
              .value()}
          />
        </BodyInner>
      </BodyContainer>
    )
  }
}

Accounts.propTypes = {
  onAccountsInit: PropTypes.func,
  isLoading: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = state => {
  return {
    accounts: state.accounts.accounts,
    isLoading: state.accounts.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAccountsInit: () => {
      dispatch(projectsClear())
      dispatch(projectDetailsClear())
      dispatch(accsGetSaga())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
