import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { imperial } from 'portal/lib/functions'
import { useModuleIds } from 'portal/lib/hooks'
import { loadDetails, clearDetails } from 'portal/store/records/actions'
import { clearRecordInfractions } from 'portal/store/infractions/actions'

export function useRecordDetailData() {
  const { accountId, projectId, moduleName, recordId } = useModuleIds()

  const dispatch = useDispatch()
  const { loading, error, item, infractions } = useSelector(
    recordDetailsWithInfractionsSelector
  )

  const projectDetails = useSelector(state => state.projectDetails.modules)

  useEffect(() => {
    const projectCountry = projectDetails?.country
    const isImperial = imperial(projectCountry)

    if (!loading && !error && item?.recordId !== recordId && projectCountry) {
      dispatch(
        loadDetails(accountId, projectId, moduleName, recordId, isImperial)
      )
    }
  }, [
    accountId,
    dispatch,
    item,
    moduleName,
    projectId,
    recordId,
    infractions,
    loading,
    error,
    projectDetails?.country
  ])

  useEffect(() => {
    return () => {
      dispatch(clearDetails())
      dispatch(clearRecordInfractions())
    }
  }, [dispatch])

  return {
    loading,
    error,
    item,
    infractions
  }
}

const recordDetailsWithInfractionsSelector = createSelector(
  state => state.records.details,
  state => state.infractions,
  (details, infractions) => {
    return {
      item: details.item,
      loading: details.loading || infractions.loading,
      error: details.error || infractions.record.error,
      infractions: infractions.record.byFieldName
    }
  }
)
