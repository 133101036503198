import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { useModuleIds } from 'portal/lib/hooks'
import { Flag } from 'portal/lib/primitives/icons'
import { colours, sizes } from '@qflow/theme'

import { DetailRow, AttachmentsSection } from 'portal/components/RecordDetails'
import {
  convertAssignedToArrayToString,
  DateFormatFull
} from 'portal/lib/functions'
import {
  Flex,
  SubHeading,
  Spacer,
  ResponsiveTable
} from 'portal/lib/primitives'
import {
  RemoveRecordButton,
  EditRecordButton,
  LineItemButtons,
  AddLineItemButton,
  ViewInAdminButton
} from 'portal/components/RecordsModules/common'
import { AttachmentsHeader } from 'portal/components/RecordDetails/AttachmentsHeader'
import { useInfractionGetter } from 'portal/store/infractions/InfractionsContext'

export function Waste({ record }) {
  const { accountId, moduleName } = useModuleIds()
  const getInfraction = useInfractionGetter()

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )

  const projectIsProLicence = useSelector(
    state => state.projectDetails.modules?.isProLicence
  )

  let assignedTo = ''

  if (record.assignedTo) {
    assignedTo = convertAssignedToArrayToString(record.assignedTo)
  }

  return (
    <>
      <Flex.Row amount={0} verticalCentre>
        <SubHeading
          id="WasteDetailHeading"
          isChild
          style={{ fontSize: '1.5rem', color: colours.BLUEY_BLACK }}
        >
          Waste Record
        </SubHeading>

        <Flex.By amount={1} />
        {record.canEdit && (
          <Flex.By amount={0}>
            <ViewInAdminButton
              record={record}
              accountId={accountId}
              moduleName={moduleName}
            />
            <Spacer.Tiny />
            <EditRecordButton />
            <Spacer.Tiny />
            <RemoveRecordButton />
          </Flex.By>
        )}
      </Flex.Row>

      <DetailRow label="Assigned To" content={assignedTo} />

      <DetailRow
        label="Record Created"
        content={
          record.createdAt
            ? new Date(record.createdAt).toLocaleString(
                [],
                DateFormatFull(projectTimeZone)
              )
            : null
        }
      />

      <DetailRow
        label="Collection Arrival"
        infraction={getInfraction('data.removalDate')}
        content={new Date(record.data.removalDate).toLocaleString(
          [],
          DateFormatFull(projectTimeZone)
        )}
      />

      <DetailRow
        label="Collection Exit"
        infraction={getInfraction('data.removalExitDate')}
        content={
          record.data.removalExitDate
            ? new Date(record.data.removalExitDate).toLocaleString()
            : null
        }
      />

      <DetailRow
        label="Consignment Number (WTN)"
        content={record.data.consignmentId}
        infraction={getInfraction('data.consignmentId')}
      />

      <DetailRow
        label="Sender Reference"
        content={record.data.receiverReferenceNumber}
        infraction={getInfraction('data.receiverReferenceNumber')}
      />

      <DetailRow
        label="Subcontractor"
        content={record.data.subcontractorName}
        infraction={getInfraction('data.subcontractorName')}
      />

      <DetailRow
        label="Origin Site Address"
        content={record.data.originSiteAddress}
        infraction={getInfraction('data.originSiteAddress')}
      />

      <DetailRow
        label="SIC Code"
        content={record.data.classificationCode}
        infraction={getInfraction('data.classificationCode')}
      />

      <DetailRow
        label="Vehicle Reg"
        content={record.data.vehicleRegistration}
        infraction={getInfraction('data.vehicleRegistration')}
      />

      <DetailRow
        label="Carrier"
        content={record.data.carrierName}
        infraction={getInfraction('data.carrierName')}
      />

      <DetailRow
        label="Carrier Reference"
        content={record.data.supplierReferenceNumber}
        infraction={getInfraction('data.supplierReferenceNumber')}
      />

      <DetailRow
        label="Carrier Licence"
        content={record.data.carrierLicenceId}
        infraction={getInfraction('data.carrierLicenceId')}
      />

      <DetailRow
        label="Facility Operator"
        content={record.data.facilityOperatorName}
        infraction={getInfraction('data.facilityOperatorName')}
      />

      <DetailRow
        label="Facility Site Address"
        content={record.data.facilitySiteAddress}
        infraction={getInfraction('data.facilitySiteAddress')}
      />

      <DetailRow
        label="Facility Permit"
        content={record.data.facilityPermitId}
        infraction={getInfraction('data.facilityPermitId')}
      />

      <DetailRow
        label="Facility Permit Provided"
        content={record.data.facilityPermitPresented ? 'Yes' : 'No'}
        infraction={getInfraction('data.facilityPermitPresented')}
      />

      <DetailRow
        label="External Reference"
        content={record.data.clientReference}
        infraction={getInfraction('data.clientReference')}
      />

      <DetailRow
        label="Notes"
        content={record.data.notes}
        infraction={getInfraction('data.notes')}
      />

      <DetailRow
        label="Journey Distance (mi)"
        content={
          record.data.journey?.distanceMiles
            ? _.round(record.data.journey.distanceMiles, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.distanceMiles')}
        tooltip="Journey distance represents the laden journey only"
      />
      <DetailRow
        label={
          <span>
            Laden Journey Emissions (kg CO<sub>2</sub>e)
          </span>
        }
        content={
          record.data.journey?.ladenEmissionsKgCO2e
            ? _.round(record.data.journey.ladenEmissionsKgCO2e, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.ladenEmissionsKgCO2e')}
        upgradeToPro={!projectIsProLicence}
      />
      <DetailRow
        label={
          <span>
            Unladen Journey Emissions (kg CO<sub>2</sub>e)
          </span>
        }
        content={
          record.data.journey?.unladenEmissionsKgCO2e
            ? _.round(record.data.journey.unladenEmissionsKgCO2e, 2).toString()
            : null
        }
        infraction={getInfraction('data.journey.unladenEmissionsKgCO2e')}
        upgradeToPro={!projectIsProLicence}
      />

      <Spacer.Medium />
      <AttachmentsHeader record={record} />
      <AttachmentsSection record={record} attachments={record.attachments} />

      <Spacer.Medium />
      <Flex.Row amount={0} verticalCentre>
        <SubHeading isChild>Items</SubHeading>

        <Spacer.Tiny />
        {record.canEdit && <AddLineItemButton />}
      </Flex.Row>

      <ResponsiveTable.Table
        id="ItemsTable"
        headers={[
          { key: 'containerType', label: 'Container Type' },
          { key: 'wasteCode', label: 'Waste Code (EWC)' },
          {
            key: 'volumeEstimated',
            label: (
              <>
                Estimated Volume (m<sup>3</sup>)
              </>
            )
          },
          {
            key: 'volume',
            label: (
              <>
                Volume (m<sup>3</sup>)
              </>
            )
          },
          {
            key: 'weightEstimated',
            label: 'Estimated Weight (t)'
          },
          { key: 'weight', label: 'Weight (t)' },
          { key: 'quantity', label: 'Quantity' }
        ]}
        data={record.data.items ?? []}
        renderRow={({ item, index, headers }) =>
          !item.isDeleted ? (
            <ResponsiveTable.AutoRow
              id={'Item' + index}
              key={index}
              index={index}
              item={item}
              headers={headers}
              removed={item.isRemoved}
              renderIcon={key => {
                const infraction = getInfraction(`data.items[${index}].${key}`)
                return infraction ? <InfractionFlag /> : null
              }}
              controls={<LineItemButtons record={record} index={index} />}
            />
          ) : null
        }
        renderCard={({ item, headers, index }) => (
          <ResponsiveTable.AutoCard
            id={'Item' + index}
            key={index}
            index={index}
            headers={headers}
            item={item}
            titleKey="wasteCode"
            removed={item.isRemoved}
            renderIcon={key => {
              const infraction = getInfraction(`data.items[${index}].${key}`)
              return infraction ? <InfractionFlag /> : null
            }}
            controls={<LineItemButtons record={record} index={index} />}
          />
        )}
      />
    </>
  )
}

Waste.propTypes = {
  record: PropTypes.object.isRequired
}

const InfractionFlag = () => <Flag colour={colours.RED} size={sizes.MEDIUM} />
