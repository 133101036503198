export const QUEUE_EXPORT = 'QUEUE_EXPORT'
export const queueExport = (
  accountId,
  projectId,
  moduleName,
  title,
  timespan,
  filters,
  useCreatedAtDate,
  callbacks
) => {
  return {
    type: QUEUE_EXPORT,
    accountId,
    projectId,
    moduleName,
    title,
    timespan,
    filters,
    useCreatedAtDate,
    callbacks
  }
}

export const QUEUE_EXPORT_SUCCESS = 'QUEUE_EXPORT_SUCCESS'
export const queueExportSuccess = () => {
  return {
    type: QUEUE_EXPORT_SUCCESS
  }
}

export const QUEUE_EXPORT_FAILURE = 'QUEUE_EXPORT_FAILURE'
export const queueExportFailure = error => {
  return {
    type: QUEUE_EXPORT_FAILURE,
    error
  }
}

export const QUEUE_ONECLICK_EXPORT = 'QUEUE_ONECLICK_EXPORT'
export const queueOneClickExport = (
  accountId,
  projectId,
  recordIds,
  title,
  filterDescription,
  callbacks
) => {
  return {
    type: QUEUE_ONECLICK_EXPORT,
    accountId,
    projectId,
    recordIds,
    title,
    filterDescription,
    callbacks
  }
}

export const QUEUE_ONECLICK_EXPORT_SUCCESS = 'QUEUE_ONECLICK_EXPORT_SUCCESS'
export const queueOneClickExportSuccess = () => {
  return {
    type: QUEUE_ONECLICK_EXPORT_SUCCESS
  }
}

export const QUEUE_ONECLICK_EXPORT_FAILURE = 'QUEUE_ONECLICK_EXPORT_FAILURE'
export const queueOneClickExportFailure = error => {
  return {
    type: QUEUE_ONECLICK_EXPORT_FAILURE,
    error
  }
}
