import React, { useCallback, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import moment from 'moment'

import { isoUTCtoIsoWithOffset } from 'portal/lib/functions'
import { Flex } from 'portal/lib/primitives/layout'
import { TextInputCells, CELL_TYPES } from './TextInputCells'

const isUsLocale = Intl.DateTimeFormat().resolvedOptions().locale === 'en-US'

export const DateTimeInputCells = forwardRef(
  (
    {
      value = {},
      dataValidation = null,
      onChange,
      onBlur,
      usLocale = isUsLocale,
      ...props
    },
    ref
  ) => {
    const handleBlur = useCallback(
      e => {
        onBlur?.(e)
        dataValidation?.(e)
      },
      [dataValidation, onBlur]
    )

    const handleChange = useCallback(
      ({ value }) => {
        onChange(value)
      },
      [onChange]
    )

    const cellGroup = !usLocale ? cells : cellsUS

    return (
      <Flex.Row verticalCentre amount={0}>
        <TextInputCells
          {...props}
          cells={cellGroup}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          ref={ref}
        />
      </Flex.Row>
    )
  }
)

DateTimeInputCells.getDateTimeInputValuesFromISOString = function (
  value,
  projectTimeZone = 'Europe/London',
  usLocale = isUsLocale
) {
  // Some date values are stored in UTC/GMT
  // so we format value to correct time zone
  // Before spliting in to relevant parts
  // will will not do this for values ending in 'Z'
  let valueFormatedtoLocale = value
  if (value && typeof value === 'string') {
    valueFormatedtoLocale =
      value.slice(value.length - 1) === 'Z'
        ? value
        : isoUTCtoIsoWithOffset(value, projectTimeZone)

    const [
      ,
      year = '',
      month = '',
      day = '',
      hour = '',
      minute = '',
      rest = ''
    ] =
      valueFormatedtoLocale?.match(
        /^(.{0,4})-(.{0,2})-(.{0,2})T(.{0,2}):(.{0,2})(:.*)?$/
      ) ?? []

    // If US Locale adjust hours and set AM/PM
    if (usLocale) {
      let am_pm = 'am'
      let hour_12 = parseInt(hour)
      if (hour_12 > 11) {
        hour_12 = hour_12 - 12
        am_pm = 'pm'
      }
      if (hour_12 === 0 || isNaN(hour_12)) {
        hour_12 = 12
      }
      return {
        year,
        month,
        day,
        hour: hour_12.toString(),
        minute,
        am_pm,
        rest
      }
    }

    return {
      year,
      month,
      day,
      hour,
      minute,
      rest
    }
  }

  return { year: '', month: '', day: '', hour: '', minute: '', rest: '' }
}

const validatorBase = ({ permitNull = false } = {}) =>
  function (dateTestPath = '_date') {
    return yup
      .object({
        year: yup
          .number()
          .transform((val, originalVal) =>
            (originalVal?.trim() ?? '').length === 0 ? null : val
          )
          .nullable(permitNull)
          .label('Year')
          .min(1970)
          .max(2100)
          .typeError(
            'Must be a number from 1970-2100' +
              (permitNull ? ' (or nothing)' : '')
          ),
        month: yup
          .number()
          .transform((val, originalVal) =>
            (originalVal?.trim() ?? '').length === 0 ? null : val
          )
          .nullable(permitNull)
          .label('Month')
          .min(1)
          .max(12)
          .typeError(
            'Must be a number from 1-12' + (permitNull ? ' (or nothing)' : '')
          ),
        day: yup
          .number()
          .transform((val, originalVal) =>
            (originalVal?.trim() ?? '').length === 0 ? null : val
          )
          .nullable(permitNull)
          .label('Day')
          .min(1)
          .max(31)
          .typeError(
            'Must be a number from 1-31' + (permitNull ? ' (or nothing)' : '')
          ),
        hour: yup
          .number()
          .transform((val, originalVal) =>
            (originalVal?.trim() ?? '').length === 0 ? null : val
          )
          .nullable(permitNull)
          .label('Hour')
          .min(0)
          .max(23)
          .typeError(
            'Must be a number from 0-23' + (permitNull ? ' (or nothing)' : '')
          ),
        minute: yup
          .number()
          .transform((val, originalVal) =>
            (originalVal?.trim() ?? '').length === 0 ? null : val
          )
          .nullable(permitNull)
          .label('Minute')
          .min(0)
          .max(59)
          .typeError(
            'Must be a number from 0-59' + (permitNull ? ' (or nothing)' : '')
          )
      })
      .test('Check date validity', function (value) {
        const { year, month, day, hour, minute } = value

        const setParts = [year, month, day, hour, minute].filter(
          v => v != null && !isNaN(v)
        ).length
        if (setParts !== 0 && setParts !== 5) {
          return this.createError({
            path: dateTestPath,
            message: `Some date part was empty`
          })
        }

        if (setParts === 0) {
          return true
        }

        const monthDate = moment(new Date(year, month - 1, 1))
        const daysInMonth = monthDate.daysInMonth()
        const monthName = monthDate.format('MMMM')

        if (day > daysInMonth) {
          return this.createError({
            path: dateTestPath,
            message: `${monthName} ${year} has ${daysInMonth} days`
          })
        }

        return true
      })
  }

DateTimeInputCells.yupValidator = validatorBase({ permitNull: false })
DateTimeInputCells.yupValidatorNullable = validatorBase({ permitNull: true })

DateTimeInputCells.getISOStringFromValues = function (
  values,
  projectTimeZone = 'Europe/London',
  usLocale = isUsLocale
) {
  let tempValues = { ...values }
  // If US Locale adjust hours and drop AM/PM
  if (usLocale) {
    const hourInt = parseInt(values.hour)
    if (hourInt === 12 && values.am_pm === 'am') {
      tempValues.hour = '0'
    }
    if (hourInt < 12 && values.am_pm === 'pm') {
      tempValues.hour = (hourInt + 12).toString()
    }
    delete tempValues.am_pm
  }

  try {
    const { year, month, day, hour, minute } = validatorBase({
      permitNull: true
    })().validateSync(tempValues)
    if (!year && !month && !day && !hour && !minute) {
      return null
    }

    return DateTime.fromObject(
      { year, month, day, hour, minute },
      {
        zone: projectTimeZone
      }
    ).toString()
  } catch (e) {
    return 'Invalid Date'
  }
}

DateTimeInputCells.getLocaleStringFromValues = function (
  values,
  usLocale = isUsLocale
) {
  try {
    const { year, month, day, hour, minute } =
      DateTimeInputCells.yupValidator().validateSync(values)

    if (!year && !month && !day && !hour && !minute) {
      return null
    }

    return new Date(year, month - 1, day, hour, minute).toLocaleString(
      usLocale ? 'en-US' : 'en-GB'
    )
  } catch (e) {
    return 'Invalid Date'
  }
}

DateTimeInputCells.propTypes = {
  value: PropTypes.shape({
    year: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string,
    hour: PropTypes.string,
    minute: PropTypes.string,
    rest: PropTypes.string
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

DateTimeInputCells.displayName = 'DateTimeInputCells'

const cells = [
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'day',
    maxLength: 2,
    placeholder: 'dd'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: '/'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'month',
    maxLength: 2,
    placeholder: 'mm'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: '/'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'year',
    maxLength: 4,
    placeholder: 'yyyy'
  },
  {
    type: CELL_TYPES.TYPE_SPACER
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'hour',
    maxLength: 2,
    placeholder: 'hh'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: ':'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'minute',
    maxLength: 2,
    placeholder: 'mm'
  }
]

const cellsUS = [
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'month',
    maxLength: 2,
    placeholder: 'mm'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: '/'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'day',
    maxLength: 2,
    placeholder: 'dd'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: '/'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'year',
    maxLength: 4,
    placeholder: 'yyyy'
  },
  {
    type: CELL_TYPES.TYPE_SPACER
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'hour',
    maxLength: 2,
    placeholder: 'hh'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: ':'
  },
  {
    type: CELL_TYPES.TYPE_CELL,
    key: 'minute',
    maxLength: 2,
    placeholder: 'mm'
  },
  {
    type: CELL_TYPES.TYPE_SEPARATOR,
    value: ' '
  },
  {
    type: CELL_TYPES.TYPE_CELL_SELECTOR,
    key: 'am_pm',
    maxLength: 2,
    placeholder: 'am',
    options: ['am', 'pm']
  }
]
