import { useWindowDimensions } from './useWindowDimensions'

export function useResponsive() {
  const { width } = useWindowDimensions()

  return {
    isMobile: width <= 700,
    isSmall: width <= 1200
  }
}
