import produce from 'immer'

import {
  CONFIGURATION_LOAD,
  CONFIGURATION_LOADED,
  CONFIGURATION_LOAD_FAILURE
} from './actions'

const initialState = {}
export const initialConfigurationState = {
  loading: false,
  error: false,
  value: null
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case CONFIGURATION_LOAD: {
        return produceConfiguration(draft, action, draft => {
          draft.loading = true
          draft.error = false
        })
      }
      case CONFIGURATION_LOADED: {
        return produceConfiguration(draft, action, draft => {
          const { configuration } = action

          draft.loading = false
          draft.error = false
          draft.value = configuration
        })
      }
      case CONFIGURATION_LOAD_FAILURE: {
        return produceConfiguration(draft, action, draft => {
          const { error } = action

          draft.loading = false
          draft.error = error ?? true
          draft.value = null
        })
      }
    }
  })
}

function produceConfiguration(draft, action, callback) {
  const { accountId, projectId, moduleName } = action

  if (!draft[accountId]) {
    draft[accountId] = {}
  }
  if (!draft[accountId][projectId]) {
    draft[accountId][projectId] = {}
  }
  if (!draft[accountId][projectId][moduleName]) {
    draft[accountId][projectId][moduleName] = { ...initialConfigurationState }
  }

  const response = callback(draft[accountId][projectId][moduleName])
  if (response !== undefined) {
    draft[accountId][projectId][moduleName] = response
  }
}
