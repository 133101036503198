import * as actions from './actions'
import produce from 'immer'

const initialState = {
  loading: false,
  error: null,
  accountId: null,
  name: null,
  createdAt: null,
  members: []
}

const accountLoad = draft => {
  draft.loading = true
  draft.error = null
  draft.accountId = null
  draft.name = null
  draft.createdAt = null
  draft.members = []
}

const accountLoaded = (draft, action) => {
  draft.loading = false
  draft.error = null
  draft.accountId = action.accountId
  draft.name = action.name
  draft.createdAt = action.createdAt
  draft.members = action.members
}

const accountLoadFail = (draft, action) => {
  draft.loading = false
  draft.error = action.error
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.ACCOUNT_LOAD:
        return accountLoad(draft, action)
      case actions.ACCOUNT_LOADED:
        return accountLoaded(draft, action)
      case actions.ACCOUNT_LOAD_FAILURE:
        return accountLoadFail(draft, action)
      default:
        return state
    }
  })
}

export { reducer }
