import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { trackMixpanel } from 'portal/store/mixpanel/actions'
import { useModuleIds } from 'portal/lib/hooks'

export const useMixpanelEvents = () => {
  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()

  return {
    trackImportClick: useCallback(
      () =>
        dispatch(
          trackMixpanel('frontend_records_import_click', {
            record_type: moduleName,
            project_id: projectId,
            account_id: accountId
          })
        ),
      [accountId, dispatch, moduleName, projectId]
    ),

    trackImportFileSelected: useCallback(
      () =>
        dispatch(
          trackMixpanel('frontend_records_import_file_select', {
            record_type: moduleName,
            project_id: projectId,
            account_id: accountId
          })
        ),
      [accountId, dispatch, moduleName, projectId]
    ),

    trackImportValidated: useCallback(
      (can_update, messages_count) =>
        dispatch(
          trackMixpanel('frontend_records_import_validated', {
            record_type: moduleName,
            can_update,
            messages_count,
            project_id: projectId,
            account_id: accountId
          })
        ),
      [accountId, dispatch, moduleName, projectId]
    ),

    trackImportConfirmed: useCallback(
      messages_count =>
        dispatch(
          trackMixpanel('frontend_records_import_confirmed', {
            record_type: moduleName,
            messages_count,
            project_id: projectId,
            account_id: accountId
          })
        ),
      [accountId, dispatch, moduleName, projectId]
    )
  }
}
