import produce from 'immer'

import {
  RECORD_DETAILS_LOAD,
  RECORD_DETAILS_LOAD_SUCCESS,
  RECORD_DETAILS_LOAD_FAILURE,
  RECORD_DETAILS_CLEAR
} from './actions'
import * as editActions from 'portal/store/records/editing/actions'

const initialState = {
  loading: false,
  error: null,
  moduleName: null,
  item: null
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case RECORD_DETAILS_LOAD: {
        draft.loading = true
        draft.error = null
        draft.item = null
        draft.moduleName = action.moduleName
        return
      }
      case editActions.RECORD_EDIT_UPLOADED:
      case RECORD_DETAILS_LOAD_SUCCESS: {
        draft.loading = false
        draft.error = null
        draft.item = action.record
        return
      }
      case RECORD_DETAILS_LOAD_FAILURE: {
        draft.loading = false
        draft.error = action.error
        return
      }
      case RECORD_DETAILS_CLEAR: {
        draft.loading = false
        draft.error = null
        draft.item = null
        draft.moduleName = null
        return
      }
      default:
        return state
    }
  })
}

export { reducer }
