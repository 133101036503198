import React from 'react'
import PropTypes from 'prop-types'

export function ManageSearch({ size = '20px', colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5.83329 7.08337H1.66663V5.41671H5.83329V7.08337ZM5.83329 9.58337H1.66663V11.25H5.83329V9.58337ZM17.1583 15.4167L13.9666 12.225C13.3 12.6584 12.5166 12.9167 11.6666 12.9167C9.36663 12.9167 7.49996 11.05 7.49996 8.75004C7.49996 6.45004 9.36663 4.58337 11.6666 4.58337C13.9666 4.58337 15.8333 6.45004 15.8333 8.75004C15.8333 9.60004 15.575 10.3834 15.1416 11.0417L18.3333 14.2417L17.1583 15.4167ZM14.1666 8.75004C14.1666 7.37504 13.0416 6.25004 11.6666 6.25004C10.2916 6.25004 9.16663 7.37504 9.16663 8.75004C9.16663 10.125 10.2916 11.25 11.6666 11.25C13.0416 11.25 14.1666 10.125 14.1666 8.75004ZM1.66663 15.4167H9.99996V13.75H1.66663V15.4167Z"
        fill={colour || 'currentColor'}
      />
    </svg>
  )
}

ManageSearch.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
