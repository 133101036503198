import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { useModuleIds, useUserProjectRole } from 'portal/lib/hooks'
import {
  eventsCountLoad,
  eventsCountReload,
  eventsLoad,
  eventsReload
} from 'portal/store/events/actions'
import { SmallSpinner } from 'portal/lib/primitives'

const arr = []

export const useEventsLoader = (
  startDate,
  endDate,
  statuses = arr,
  sourceTypes = arr,
  descriptions = arr,
  tradeContractors = arr,
  suppliers = arr,
  assigneeIds = arr,
  subcontractors = arr,
  wasteCarriers = arr,
  pageSize = 30
) => {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const { isReadOnly } = useUserProjectRole(accountId, projectId)

  const reload = useCallback(() => {
    const filterSourceTypes = isReadOnly ? ['Infraction'] : sourceTypes
    let filter = {
      startDate: startDate,
      endDate: endDate,
      statuses,
      sourceTypes: filterSourceTypes,
      descriptions,
      tradeContractors,
      suppliers,
      assigneeIds,
      subcontractors,
      wasteCarriers
    }
    dispatch(
      eventsLoad(accountId, projectId, moduleName, pageSize, null, [], filter)
    )
    dispatch(eventsCountLoad(accountId, projectId, moduleName, filter))
  }, [
    isReadOnly,
    sourceTypes,
    startDate,
    endDate,
    statuses,
    descriptions,
    tradeContractors,
    suppliers,
    assigneeIds,
    subcontractors,
    wasteCarriers,
    dispatch,
    accountId,
    projectId,
    moduleName,
    pageSize
  ])

  useEffect(() => {
    reload()
  }, [reload])

  const [isFocused, setIsFocused] = useState(true)
  const onFocus = () => {
    setIsFocused(true)
  }
  const onBlur = () => {
    setIsFocused(false)
  }
  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    onFocus()
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  const {
    loading,
    error,
    items,
    continuationToken,
    loadingCount,
    errorCount,
    count
  } = useSelector(state => {
    return state.events[moduleName]
  })

  function showTotalRecords() {
    if (loadingCount) {
      return <SmallSpinner />
    }
    if (errorCount !== null) {
      return 'Error fetching total events'
    }
    return (
      <ShowingRecordsText data-testid="totalInPage">
        Showing {items.length} out of {count} total event
        {count === 1 ? '' : 's'}
      </ShowingRecordsText>
    )
  }

  const silent_reload = useCallback(() => {
    const filterSourceTypes = isReadOnly ? ['Infraction'] : sourceTypes
    let filter = {
      startDate: startDate,
      endDate: endDate,
      statuses,
      sourceTypes: filterSourceTypes,
      descriptions,
      tradeContractors,
      suppliers,
      assigneeIds,
      subcontractors,
      wasteCarriers
    }
    dispatch(
      eventsReload(accountId, projectId, moduleName, pageSize, items, filter)
    )
    dispatch(eventsCountReload(accountId, projectId, moduleName, filter))
  }, [
    isReadOnly,
    sourceTypes,
    startDate,
    endDate,
    statuses,
    descriptions,
    tradeContractors,
    suppliers,
    assigneeIds,
    subcontractors,
    wasteCarriers,
    dispatch,
    accountId,
    projectId,
    moduleName,
    pageSize,
    items
  ])

  useEffect(() => {
    const id = setInterval(() => {
      if (isFocused) {
        silent_reload()
      }
    }, 10000)
    return () => clearInterval(id)
  }, [isFocused, silent_reload])

  function handlePageChange() {
    if (continuationToken === null) {
      return
    }
    let filter = {
      startDate: startDate,
      endDate: endDate,
      statuses,
      sourceTypes,
      descriptions,
      tradeContractors,
      suppliers,
      assigneeIds,
      subcontractors,
      wasteCarriers
    }
    dispatch(
      eventsLoad(
        accountId,
        projectId,
        moduleName,
        pageSize,
        continuationToken,
        items,
        filter
      )
    )
  }

  let hasNextPage = continuationToken != null

  return {
    loading,
    error,
    items,
    count,
    reload,
    handlePageChange,
    hasNextPage,
    showTotalRecords,
    readOnly: isReadOnly
  }
}

const ShowingRecordsText = styled.div`
  margin: 1rem 0.5rem 0;
  text-align: center;
`
