import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Upload({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
      style={{ width: size, height: size }}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={colour || 'currentColor'}
        strokeLinecap="round"
      >
        <path
          strokeWidth="1.4"
          d="M10.133 13.467V4.133M5.067 7.4l4.827-4.824a.14.14 0 0 1 .194-.004L15.333 7.4"
        />
        <path strokeWidth="1.54" d="M1.908 19.084l16.549-.017" />
      </g>
    </svg>
  )
}

Upload.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
