import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { sizes } from '@qflow/theme'
import {
  Container,
  InnerContainer,
  ActionStripCard,
  AssignmentButtonGroup
} from 'portal/components/CommonStyles/ActionStripStyledComponents'

import {
  icons,
  Clickable,
  Text,
  Padding,
  ColourButton,
  Spacer,
  Spinner
} from 'portal/lib/primitives'
import { assignRecords, unassignRecords } from 'portal/store/events/actions'
import { useModuleIds, useUserProjectRole } from 'portal/lib/hooks'

import { AssignmentStrip } from 'portal/lib/sharedComponents/AssignmentStrip'
import { UnassignmentStrip } from 'portal/lib/sharedComponents/UnassignmentStrip'
import { AssignmentLongRunningOperationModal } from 'portal/lib/sharedComponents/AssignmentLongRunningOperationModal'

export function ActionStrip({
  recordIds,
  recordAssigneeMapping,
  selectedRecordsContainUnloaded,
  onClose,
  onApply
}) {
  const LONG_RUNNING_WARNING_THRESHOLD = 1000
  const MAX_ASSIGNMENT_LIMIT = 10000

  const dispatch = useDispatch()
  const { accountId, projectId } = useModuleIds()
  const [showAssignmentStrip, setShowAssignmentStrip] = useState(false)
  const [showUnassignmentStrip, setShowUnassignmentStrip] = useState(false)
  const [showLongRunningOperationModal, setShowLongRunningOperationModal] =
    useState(false)

  const [reloadCount, setReloadCount] = useState(0)
  const { isProjectAdmin } = useUserProjectRole(accountId, projectId)

  const projectUsers = useSelector(state => state.projectUsers)
  const assign = useSelector(state => state.events.assign)
  const unassign = useSelector(state => state.events.unassign)
  const dismissal = useSelector(state => state.events.dismissal)

  // Use selected Records to create a set of unique users who are assigned to 1 or more events/records
  const relatedAssignees = useMemo(() => {
    const assignedUsers = {}
    recordIds.forEach(record => {
      if (recordAssigneeMapping[record]) {
        recordAssigneeMapping[record].forEach(assignee => {
          assignedUsers[assignee.userId] = {
            userId: assignee.userId,
            displayName: assignee.userName,
            email: projectUsers.users[`${projectId}_${assignee.userId}`]?.email
          }
        })
      }
    })
    if (Object.keys(assignedUsers).length === 0) {
      setShowUnassignmentStrip(false)
      setShowAssignmentStrip(true)
    }
    return assignedUsers
  }, [projectId, projectUsers.users, recordAssigneeMapping, recordIds])

  const handleAssignBtnClick = () => {
    setShowUnassignmentStrip(false)
    setShowAssignmentStrip(!showAssignmentStrip)
  }

  const handleUnassignBtnClick = () => {
    setShowAssignmentStrip(false)
    setShowUnassignmentStrip(!showUnassignmentStrip)
  }

  const triggerReload = useCallback(() => {
    const newReloadCount = reloadCount + 1
    onApply('reloadCount', newReloadCount)
    setReloadCount(newReloadCount)
  }, [onApply, reloadCount])

  const handleRecordsAssign = useCallback(
    assignTo => {
      dispatch(
        assignRecords(accountId, projectId, recordIds, assignTo, () => {
          onClose?.(true)
          setShowLongRunningOperationModal(false)
          triggerReload()
        })
      )
      setShowAssignmentStrip(false)
      if (recordIds.length * assignTo.length > LONG_RUNNING_WARNING_THRESHOLD) {
        setShowLongRunningOperationModal(true)
      }
    },
    [accountId, dispatch, onClose, projectId, recordIds, triggerReload]
  )

  const handleRecordsUnassign = useCallback(
    unassignUsers => {
      dispatch(
        unassignRecords(accountId, projectId, recordIds, unassignUsers, () => {
          onClose?.(true)
          setShowLongRunningOperationModal(false)
          triggerReload()
        })
      )
      setShowUnassignmentStrip(false)
      if (
        recordIds.length * unassignUsers.length >
        LONG_RUNNING_WARNING_THRESHOLD
      ) {
        setShowLongRunningOperationModal(true)
      }
    },
    [accountId, dispatch, onClose, projectId, recordIds, triggerReload]
  )

  const working = dismissal.loading || assign.loading || unassign.loading
  const visible = recordIds?.length > 0
  const showUnassignBtn =
    Object.keys(relatedAssignees).length > 0 || selectedRecordsContainUnloaded

  return (
    <Container $visible={visible}>
      {working && <Spinner />}
      <InnerContainer>
        <ActionStripCard>
          <Padding noVertical>
            <Text bold size={sizes.MEDIUM}>
              {recordIds.length} selected
            </Text>
          </Padding>

          {isProjectAdmin && (
            <AssignmentButtonGroup>
              <ColourButton.Basic
                type="button"
                onClick={handleAssignBtnClick}
                disabled={working}
                data-testid="Assign"
                className={showAssignmentStrip ? 'selected' : ''}
                style={{
                  borderRadius: showUnassignBtn ? '5px 0 0 5px' : '5px'
                }}
              >
                <icons.AddPerson colour="currentColor" size={sizes.MEDIUM} />
                <Spacer.Fine />
                Assign
              </ColourButton.Basic>

              {showUnassignBtn && (
                <ColourButton.Basic
                  type="button"
                  data-testid="Unassign"
                  onClick={handleUnassignBtnClick}
                  disabled={working}
                  className={showUnassignmentStrip ? 'selected' : ''}
                  style={{ borderLeft: 'none', borderRadius: '0 5px 5px 0' }}
                >
                  <icons.RemovePerson
                    colour="currentColor"
                    size={sizes.MEDIUM}
                  />
                  <Spacer.Fine />
                  Unassign
                </ColourButton.Basic>
              )}
            </AssignmentButtonGroup>
          )}

          <Spacer.Small />

          <Clickable
            size="small"
            title="Close"
            onClick={() => onClose(false)}
            disabled={working}
          >
            <icons.Cross size={sizes.X_SMALL} colour="inherit" />
          </Clickable>
        </ActionStripCard>

        {isProjectAdmin && showAssignmentStrip && (
          <AssignmentStrip
            users={projectUsers.users}
            onApply={handleRecordsAssign}
            recordCount={recordIds.length}
            maxAssignment={MAX_ASSIGNMENT_LIMIT}
          />
        )}

        {isProjectAdmin && showUnassignmentStrip && (
          <UnassignmentStrip
            users={
              selectedRecordsContainUnloaded
                ? projectUsers.users
                : relatedAssignees
            }
            onApply={handleRecordsUnassign}
            recordCount={recordIds.length}
            maxAssignment={MAX_ASSIGNMENT_LIMIT}
          />
        )}

        {showLongRunningOperationModal && (
          <AssignmentLongRunningOperationModal />
        )}
      </InnerContainer>
    </Container>
  )
}

ActionStrip.propTypes = {
  recordIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func
}
