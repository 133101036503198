import { trackMixpanel } from 'portal/store/mixpanel/actions'

const PROJECT_SWITCHER_SELECT = 'frontend_project_switcher_select'

export const trackSwitcherSelect = (
  currentProject,
  nextProject,
  accountId,
  projectId
) =>
  trackMixpanel(PROJECT_SWITCHER_SELECT, {
    current_project: currentProject,
    next_project: nextProject,
    project_id: projectId,
    account_id: accountId
  })
