export const RECORD_INFRACTIONS_LOAD = 'RECORD_INFRACTIONS_LOAD'
export const loadRecordInfractions = (
  accountId,
  projectId,
  moduleName,
  recordId
) => ({
  type: RECORD_INFRACTIONS_LOAD,
  accountId,
  projectId,
  moduleName,
  recordId
})

export const RECORD_INFRACTIONS_LOAD_SUCCESS = 'RECORD_INFRACTIONS_LOAD_SUCCESS'
export const loadRecordInfractionsSuccess = (recordId, infractions) => ({
  type: RECORD_INFRACTIONS_LOAD_SUCCESS,
  recordId,
  infractions
})

export const RECORD_INFRACTIONS_LOAD_FAILURE = 'RECORD_INFRACTIONS_LOAD_FAILURE'
export const loadRecordInfractionsFailure = (recordId, error) => ({
  type: RECORD_INFRACTIONS_LOAD_FAILURE,
  recordId,
  error
})

export const RECORD_INFRACTIONS_CLEAR = 'RECORD_INFRACTIONS_CLEAR'
export const clearRecordInfractions = recordId => {
  return {
    type: RECORD_INFRACTIONS_CLEAR,
    recordId
  }
}
