import { Delivery } from './Delivery'
import { Waste } from './Waste'

// Reprocessed Material icons
// https://material-ui.com/components/material-icons/
import { MuiIconWrapperHOC } from './MuiIconWrapperHOC'
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
  MenuRounded,
  HomeRounded,
  ExitToAppRounded,
  ArrowForwardRounded,
  FlagRounded,
  RemoveRedEyeRounded,
  Help as MuiHelp,
  ClearAllRounded
} from '@material-ui/icons'

import Icon from '@ant-design/icons'

export * from './Add'
export * from './AddPerson'
export * from './RemovePerson'
export * from './Bell'
export * from './Building'
export * from './Calendar'
export * from './Spreadsheet'
export * from './ChevronDown'
export * from './ChevronRight'
export * from './CircleTicked'
export * from './Cross'
export * from './Dashboard'
export * from './Download'
export * from './FaceMask'
export * from './GoldBadge'
export * from './Info'
export * from './QuestionMark'
export * from './Settings'
export * from './StatusPending'
export * from './StatusSuccess'
export * from './StatusFailed'
export * from './Tick'
export * from './Upload'
export * from './Library'
export * from './Loading'
export * from './ExternalLink'
export * from './TrashCan'
export * from './Plus'
export * from './SortArrows'
export * from './Edit'
export * from './EditPencil'
export * from './EditError'
export * from './MagnifyingGlass'
export * from './ManageSearch'
export * from './NewTabIcon'
export * from './Graph'
export * from './Table'
export * from './Reports'
export * from './Premium'
export * from './ProjectDocument'

export * from './Monitors'
export * from './Units'
export * from './Brush'
export * from './Threshold'
export * from './AvgPeriod'
export const Modules = {
  Delivery,
  Waste
}

export * from './WelcomeDesk'
export * from './Workers'
export * from './QflowLogo'
export * from './QLogo'

export const CheckCircle = MuiIconWrapperHOC(CheckCircleOutlineRounded)
export const CrossCircle = MuiIconWrapperHOC(HighlightOffRounded)
export const Menu = MuiIconWrapperHOC(MenuRounded)
export const Home = MuiIconWrapperHOC(HomeRounded)
export const Exit = MuiIconWrapperHOC(ExitToAppRounded)
export const Link = MuiIconWrapperHOC(ArrowForwardRounded)
export const Flag = MuiIconWrapperHOC(FlagRounded)
export const Eye = MuiIconWrapperHOC(RemoveRedEyeRounded)
export const Help = MuiIconWrapperHOC(MuiHelp)
export const ClearAll = MuiIconWrapperHOC(ClearAllRounded)

export const AccountSettingsSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.14294 9.44685L10.1856 11.4855C10.4709 11.8386 10.7066 13.5739 10.9012 14.0102C11.7068 15.8159 14.0898 15.6125 14.5429 15.5347C14.5831 15.5289 14.6211 15.5129 14.6533 15.4882C14.6855 15.4635 14.7108 15.431 14.7268 15.3937C14.7407 15.3598 14.7434 15.3224 14.7347 15.2869C14.7261 15.2513 14.7064 15.2194 14.6785 15.1957L13.1742 14.1666C13.1742 14.1666 12.5559 13.7585 12.8587 13.2067L13.2728 12.4535C13.2728 12.4535 13.6004 11.797 14.1489 12.085L15.8996 13.0986C15.918 13.0783 15.9338 13.0557 15.9465 13.0315C15.9809 12.9685 15.9992 12.898 15.9999 12.8262C16.0006 12.7545 15.9837 12.6836 15.9506 12.62C15.727 12.179 14.9316 10.7686 13.7797 10.4719C12.9976 10.2705 12.0209 10.4806 10.7374 9.19646L9.56404 8.02441L8.14294 9.44685Z"
      fill="#EFEFF4"
    />
    <path
      d="M6.14929 7.45998L5.26253 6.57792C3.98106 5.29243 3.00301 5.50388 2.2203 5.30249C1.06838 5.00512 0.273592 3.59476 0.0493852 3.15441C0.016271 3.09074 -0.000681386 3.01991 2.09679e-05 2.94816C0.000723321 2.8764 0.0190588 2.80592 0.0534128 2.74291C0.0662107 2.71866 0.0819968 2.69611 0.100402 2.67578L1.85177 3.69143C2.39953 3.97873 2.72443 3.32223 2.72443 3.32223L3.14196 2.56637C3.44404 2.01323 2.82579 1.61047 2.82579 1.61047L1.32213 0.578041C1.29336 0.554228 1.2731 0.521723 1.26438 0.485409C1.25567 0.449095 1.25897 0.410934 1.2738 0.376658C1.28976 0.339342 1.31508 0.306768 1.34729 0.282077C1.37951 0.257386 1.41754 0.241411 1.45773 0.23569C1.91084 0.157821 4.29253 -0.0455757 5.09874 1.76016C5.29408 2.19649 5.5297 3.93242 5.81499 4.28484L7.57038 6.0409L6.14929 7.45998Z"
      fill="#EFEFF4"
    />
    <path
      d="M15.0913 0.523112C14.7563 0.188167 14.302 0 13.8283 0C13.3546 0 12.9003 0.188167 12.5653 0.523112C12.2834 0.805049 12.1075 1.10981 12.0618 1.52466C11.9987 2.17714 10.9509 3.22501 10.9509 3.22501L4.35155 9.82434C3.99912 10.1096 2.2632 10.3452 1.82687 10.5406C0.0204611 11.3441 0.225201 13.7285 0.302398 14.1816C0.308353 14.2217 0.324502 14.2596 0.349302 14.2917C0.374101 14.3238 0.40672 14.349 0.444038 14.3649C0.477883 14.379 0.51536 14.3819 0.550991 14.3732C0.586621 14.3645 0.618535 14.3447 0.642064 14.3165L1.67113 12.8129C1.67113 12.8129 2.07927 12.1939 2.63106 12.4967L3.38423 12.9115C3.38423 12.9115 4.04007 13.2391 3.75277 13.7842L2.73847 15.5389C2.75879 15.5573 2.78134 15.5731 2.80559 15.5859C2.8686 15.6203 2.93908 15.6386 3.01084 15.6393C3.08259 15.64 3.15342 15.6231 3.21709 15.5899C3.65812 15.3664 5.06914 14.5709 5.36518 13.419C5.56656 12.6363 5.35645 11.6596 6.6406 10.3768L12.4807 4.53668C12.4807 4.53668 13.3635 3.59219 14.0878 3.54588C14.0878 3.54588 14.6013 3.55662 15.0947 3.04712C15.426 2.7104 15.6113 2.25679 15.6107 1.78443C15.6101 1.31206 15.4235 0.858945 15.0913 0.523112ZM14.7483 2.47385L14.461 2.53359L14.3348 2.79807L14.0535 2.71685L13.8186 2.89272L13.6065 2.69134L13.3171 2.73766L13.2238 2.45975L12.9459 2.36644L12.9922 2.07712L12.7909 1.865L12.9667 1.63005L12.8855 1.34878L13.154 1.22258L13.2124 0.935276L13.5058 0.946688L13.6917 0.719796L13.9461 0.866135L14.2146 0.751346L14.3717 0.999048L14.6637 1.02321L14.6879 1.31522L14.9356 1.4723L14.8208 1.74081L14.9671 1.99522L14.7402 2.18117L14.7483 2.47385Z"
      fill="#EFEFF4"
    />
  </svg>
)

export const AccountSettingsIcon = () => <Icon component={AccountSettingsSvg} />
