import { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useModuleIds } from 'portal/lib/hooks'
import { recordEdited, recordEditStarted } from './actions'

export function useRecordEditing() {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const { uploading, error } = useSelector(state => state.records.editing)

  useEffect(() => {
    dispatch(recordEditStarted(accountId, projectId, moduleName))
  }, [accountId, dispatch, moduleName, projectId])

  const submitRecord = useCallback(
    (record, onSuccess = null, isImperial = false) => {
      if (uploading) {
        return
      }

      dispatch(
        recordEdited(
          accountId,
          projectId,
          moduleName,
          record,
          onSuccess,
          isImperial
        )
      )
    },
    [accountId, dispatch, moduleName, projectId, uploading]
  )

  return useMemo(
    () => ({
      submitRecord,
      uploading,
      error
    }),
    [error, submitRecord, uploading]
  )
}
