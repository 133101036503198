import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Waste({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      {...props}
      style={{ height: size }}
    >
      <g fill={colour || 'currentColor'} fillRule="evenodd">
        <path d="M2.984 15h8.992a446.34 446.34 0 0 1 2.629-4.794c.263-1.19.395-1.843.395-1.956H0c.115.708.229 1.454.341 2.237A72.005 72.005 0 0 1 2.984 15zM6.038 3.068a2.25 2.25 0 0 1 2.189 1.726c.439.228.745.68.77 1.205H.002l.003-.069a1.425 1.425 0 0 1 1.571-1.285 1.654 1.654 0 0 1 2.581-.56 2.244 2.244 0 0 1 1.88-1.017z" />
        <path d="M11.683 2.25c1.046 0 1.894.855 1.894 1.91 0 .165-.02.326-.06.48l.06-.004c.762 0 1.384.605 1.42 1.364H6l.004-.07a1.425 1.425 0 0 1 1.571-1.285 1.654 1.654 0 0 1 2.237-.79 1.898 1.898 0 0 1 1.87-1.605z" />
      </g>
    </svg>
  )
}

Waste.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
