import { all, put, call, takeLatest } from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'

import {
  eventsLoadSuccess,
  eventsReloadSuccess,
  eventsLoadFailure,
  eventsCountLoadSuccess,
  eventsCountLoadFailure,
  eventsFilterLoadSuccess,
  eventsFilterLoadFailure,
  EVENTS_LOAD,
  EVENTS_RELOAD,
  EVENTS_COUNT_LOAD,
  EVENTS_COUNT_RELOAD,
  ASSIGN_RECORDS,
  assignRecordsFailure,
  assignRecordsSuccess,
  UNASSIGN_RECORDS,
  unassignRecordsFailure,
  unassignRecordsSuccess,
  DISMISS_EVENTS,
  EVENTS_FILTER_LOAD,
  dismissEventsFailure,
  dismissEventsSuccess
} from 'portal/store/events/actions'
import { splitOutUnassignedFilter } from 'portal/lib/functions'

function* eventsLoadSaga(action) {
  const {
    accountId,
    projectId,
    moduleName,
    pageSize,
    continuationToken,
    loadedEvents,
    filter
  } = action

  try {
    let events = []
    let newContinuationToken = null
    const response = yield call(() =>
      axios.get(
        '/accounts/' + accountId + '/projects/' + projectId + '/events',
        {
          params: {
            'moduleName': moduleName,
            'page[size]': pageSize,
            ...extractFilter(filter)
          },
          headers: { continuationToken: continuationToken }
        }
      )
    )
    newContinuationToken = response.data.continuationToken
    events = [...loadedEvents, ...response.data.events]

    yield put(
      eventsLoadSuccess(
        accountId,
        projectId,
        moduleName,
        events,
        newContinuationToken
      )
    )
  } catch (error) {
    console.error(error)
    yield put(eventsLoadFailure(accountId, projectId, moduleName, error))
  }
}

function* eventsCountLoadSaga(action) {
  const { accountId, projectId, moduleName, filter } = action

  try {
    const response = yield call(() =>
      axios.get(
        '/accounts/' +
          accountId +
          '/projects/' +
          projectId +
          '/events/events-count',
        {
          params: {
            'moduleName': moduleName,
            'page[size]': 1000,
            ...extractFilter(filter)
          }
        }
      )
    )
    let count = response.data

    yield put(eventsCountLoadSuccess(accountId, projectId, moduleName, count))
  } catch (error) {
    console.error(error)
    yield put(eventsCountLoadFailure(accountId, projectId, moduleName, error))
  }
}

function* eventsReloadSaga(action) {
  const { accountId, projectId, moduleName, pageSize, loadedEvents, filter } =
    action

  try {
    let events = []
    let continuationToken = null
    for (let i = 0; i < loadedEvents.length; i += pageSize) {
      const response = yield call(() =>
        axios.get(
          '/accounts/' + accountId + '/projects/' + projectId + '/events',
          {
            params: {
              'moduleName': moduleName,
              'page[size]': pageSize,
              ...extractFilter(filter)
            },
            headers: { continuationToken: continuationToken }
          }
        )
      )
      continuationToken = response.data.continuationToken
      events.push(...response.data.events)
    }

    yield put(
      eventsReloadSuccess(
        accountId,
        projectId,
        moduleName,
        events,
        continuationToken
      )
    )
  } catch (error) {
    console.error('silent reload failed ', error)
  }
}

function* eventsFilterLoadSaga(action) {
  const { accountId, projectId, moduleName } = action

  try {
    const response = yield call(() =>
      axios.get(
        '/accounts/' +
          accountId +
          '/projects/' +
          projectId +
          '/events/filter-options',
        {
          params: {
            module: moduleName
          }
        }
      )
    )

    yield put(
      eventsFilterLoadSuccess(accountId, projectId, moduleName, response.data)
    )
  } catch (error) {
    console.error(error)
    yield put(eventsFilterLoadFailure(accountId, projectId, moduleName, error))
  }
}

/*
 * assignRecordsSaga
 * Example Request Body
   [
    {
      "userId": "string",
      "recordId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "projectId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "userRole": 1
    }
  ]
 */
function* assignRecordsSaga(action) {
  const { accountId, projectId, relatedRecords, assignTo, callbacks } = action

  // Create array of recordsAssignUsers from relatedRecords and AssignTo
  const recordsAssignUsers = []
  relatedRecords.forEach(record => {
    assignTo.forEach(assignee => {
      recordsAssignUsers.push({
        userId: assignee.user,
        recordId: record,
        projectId: projectId,
        userRole: assignee.role
      })
    })
  })

  try {
    yield call(() =>
      axios.post(
        '/accounts/' +
          accountId +
          '/projects/' +
          projectId +
          '/recordassignments/assign',
        recordsAssignUsers,
        {
          validateStatus: status => status === 200
        }
      )
    )

    yield call(() => callbacks.onSuccess?.())

    yield put(assignRecordsSuccess(accountId, projectId, relatedRecords))
  } catch (error) {
    console.error(error)

    yield put(assignRecordsFailure(accountId, projectId, relatedRecords, error))
  }
}

/*
 * unassignRecordsSaga
 * Example Request Body
   [
    {
      "userId": "string",
      "recordId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    }
  ]
 */
function* unassignRecordsSaga(action) {
  const { accountId, projectId, relatedRecords, unassignUsers, callbacks } =
    action

  // DeDup array of unassignUsers
  const uniqueUnassignUsers = Array.from(
    new Set(unassignUsers.map(a => a.user))
  )

  // Create array of recordsUnassignUsers from relatedRecords and unassignUsers
  const recordsUnassignUsers = []
  relatedRecords.forEach(record => {
    uniqueUnassignUsers.forEach(user => {
      recordsUnassignUsers.push({
        userId: user,
        recordId: record
      })
    })
  })

  try {
    yield call(() =>
      axios.delete(
        '/accounts/' +
          accountId +
          '/projects/' +
          projectId +
          '/recordassignments/unassign',
        { data: recordsUnassignUsers },
        {
          validateStatus: status => status === 200
        }
      )
    )

    yield call(() => callbacks.onSuccess?.())

    yield put(unassignRecordsSuccess(accountId, projectId, relatedRecords))
  } catch (error) {
    console.error(error)

    yield put(
      unassignRecordsFailure(accountId, projectId, relatedRecords, error)
    )
  }
}

function* dismissEventsSaga(action) {
  const { accountId, projectId, eventKeys, callbacks } = action

  try {
    yield call(() =>
      axios.post(
        '/accounts/' + accountId + '/projects/' + projectId + '/events/dismiss',
        { eventKeys },
        {
          validateStatus: status => status === 200
        }
      )
    )

    yield call(() => callbacks.onSuccess?.())

    yield put(dismissEventsSuccess(accountId, projectId, eventKeys))
  } catch (error) {
    console.error(error)

    yield put(dismissEventsFailure(accountId, projectId, eventKeys, error))
  }
}

function extractFilter(filter) {
  let result = {}
  if (filter == null) {
    return result
  }

  if (filter.startDate != null || filter.endDate != null) {
    result['filter[CreatedAt]'] =
      'gt:' +
      filter?.startDate.toISOString() +
      ',lt:' +
      filter?.endDate.toISOString()
  }

  if (filter.statuses && filter.statuses.length > 0) {
    result['filter[Status]'] = filter.statuses
  }

  if (filter.sourceTypes && filter.sourceTypes.length > 0) {
    result['filter[SourceType]'] = filter.sourceTypes
  }

  if (filter.descriptions && filter.descriptions.length > 0) {
    result['filter[Description]'] = filter.descriptions
  }

  if (filter.tradeContractors && filter.tradeContractors.length > 0) {
    result['filter[TradeContractor]'] = filter.tradeContractors
  }

  if (filter.suppliers && filter.suppliers.length > 0) {
    result['filter[Supplier]'] = filter.suppliers
  }

  if (filter.assigneeIds && filter.assigneeIds.length > 0) {
    result['filter[AssigneeIds]'] = filter.assigneeIds
    splitOutUnassignedFilter(
      result,
      'filter[AssigneeIds]',
      'filter[AssigneeIncludeUnassigned]'
    )
  }

  if (filter.subcontractors && filter.subcontractors.length > 0) {
    result['filter[Subcontractor]'] = filter.subcontractors
  }

  if (filter.wasteCarriers && filter.wasteCarriers.length > 0) {
    result['filter[WasteCarrier]'] = filter.wasteCarriers
  }
  return result
}

export function* watch() {
  yield all([takeLatest(EVENTS_LOAD, eventsLoadSaga)])
  yield all([takeLatest(EVENTS_RELOAD, eventsReloadSaga)])
  yield all([takeLatest(EVENTS_COUNT_LOAD, eventsCountLoadSaga)])
  yield all([takeLatest(EVENTS_COUNT_RELOAD, eventsCountLoadSaga)])
  yield all([takeLatest(ASSIGN_RECORDS, assignRecordsSaga)])
  yield all([takeLatest(UNASSIGN_RECORDS, unassignRecordsSaga)])
  yield all([takeLatest(DISMISS_EVENTS, dismissEventsSaga)])
  yield all([takeLatest(EVENTS_FILTER_LOAD, eventsFilterLoadSaga)])
}
