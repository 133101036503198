import { useEffect, useState } from 'react'
import { Select } from 'antd'
import styled from 'styled-components'
import { ColourButton, icons, Text } from 'portal/lib/primitives'
import { ProjectUser } from 'portal/lib/interfaces'
import { sizes } from '@qflow/theme'

export function AssignmentRow(props) {
  const [assignableUsers, setAssignableUsers] = useState<ProjectUser[]>([])

  const [user, setUser] = useState(props.user)
  const [role, setRole] = useState(props.role)

  const [previousActiveOption, setPreviousActiveOption] = useState('')

  const { Option } = Select

  const handleUserChange = value => {
    setUser(value)
    props.onChange(props.id, value, role)
  }

  const handleRoleChange = value => {
    setRole(value)
    props.onChange(props.id, user, parseInt(value))
  }

  const handleDelete = () => {
    props.onDelete(props.id)
  }

  useEffect(() => {
    const assignableUserArray: ProjectUser[] = Object.values(props.users)

    assignableUserArray.sort((a, b) => {
      const fa = a.displayName.toLowerCase()
      const fb = b.displayName.toLowerCase()

      if (fa < fb) {
        return -1
      } else if (fa > fb) {
        return 1
      }
      return 0
    }) as ProjectUser[]
    setAssignableUsers(assignableUserArray)
  }, [props.users])

  useEffect(() => {
    if (assignableUsers.length === 1 && props.user === '') {
      setUser(assignableUsers[0].userId)
      props.onChange(props.id, assignableUsers[0].userId, role)
    }
  }, [assignableUsers, props, role])

  const handleScroll = () => {
    // Get element from dropdown that currently is active
    const activeOption = document.querySelector(
      '.ant-select-dropdown:not(.ant-select-dropdown-hidden) .ant-select-item-option-active'
    )

    // If this value has changed (i.e from an arrow key press) then scroll the element into view
    if (activeOption) {
      if (activeOption.textContent !== previousActiveOption) {
        setTimeout(() => {
          activeOption.scrollIntoView()
        }, 1)
      }
      setPreviousActiveOption(activeOption.textContent || '')
    }
  }

  return (
    <AssignmentRowContainer>
      <Text>{props.unassign ? 'Unassign' : 'Assign'}</Text>
      <AssignmentSelect
        placeholder="Select user..."
        onChange={handleUserChange}
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        value={user ? user : null}
        onPopupScroll={handleScroll}
        virtual={false}
      >
        {assignableUsers.length > 0 &&
          assignableUsers.map(user => (
            <Option
              key={user.userId}
              value={user.userId}
              label={`${user.displayName} ${user.email}`}
            >
              <div>
                {user.displayName}
                <br />
                <Text size={sizes.X_SMALL} style={{ wordBreak: 'break-all' }}>
                  {user.email}
                </Text>
              </div>
            </Option>
          ))}
      </AssignmentSelect>
      {!props.unassign && (
        <>
          <Text>as a</Text>
          <RoleSelect
            placeholder="role"
            onChange={handleRoleChange}
            defaultValue="2"
          >
            <Option value="2">Contributor</Option>
            <Option value="1">Reader</Option>
          </RoleSelect>
        </>
      )}

      <ColourButton.BasicBorderless
        id={`RemoveButton${props.id}`}
        title="Remove row"
        type="button"
        onClick={handleDelete}
        style={{ display: 'inline-flex' }}
        disabled={props.disableRemoveRow}
      >
        <icons.TrashCan
          size={sizes.SMALL}
          colour={props.disableRemoveRow ? '#9ea0a5' : '#c22323'}
        />
      </ColourButton.BasicBorderless>
    </AssignmentRowContainer>
  )
}

const AssignmentRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;
  padding: 0.5rem;
`

const AssignmentSelect = styled(Select)`
  width: 258px;

  &.ant-select-single .ant-select-selector {
    align-items: center;
    border-radius: 5px;
    height: auto;
    min-height: 45px;

    & .ant-select-selection-item {
      margin: 4px 0;
      line-height: 1.25;
    }

    & .ant-select-selection-item::after {
      content: none;
    }
  }
`

const RoleSelect = styled(Select)`
  width: 134px;

  &.ant-select-single .ant-select-selector {
    align-items: center;
    border-radius: 5px;
    height: auto;
    min-height: 45px;

    & .ant-select-selection-item::after {
      content: none;
    }
  }
`
