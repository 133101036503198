import { takeEvery, put, call } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import {
  DESTINATION_ACTION_QUERY_REQUIRED,
  queryActionSuccess,
  queryActionFailed
} from './actions'

function getURI(destinationType, destinationId) {
  return `/destination/${destinationType}/${destinationId}`
}

function* actionQuerySaga(action) {
  const { destinationType, destinationId, destinationUri } = action

  try {
    const response = yield call(() =>
      axios.get(destinationUri ?? getURI(destinationType, destinationId), {
        validateStatus: status => status === 200
      })
    )

    yield put(queryActionSuccess(destinationType, destinationId, response.data))
  } catch (error) {
    console.warn('Failed to load configuration:', error)
    yield put(queryActionFailed(destinationType, destinationId, error))
  }
}

export function* watch() {
  yield takeEvery([DESTINATION_ACTION_QUERY_REQUIRED], actionQuerySaga)
}
