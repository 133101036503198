import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { trackMixpanel } from 'portal/store/mixpanel/actions'

const EV_DESTINATION_OPEN = 'frontend_destination_open'
const EV_RECORD_ACTION_REACHED = 'frontend_destination_action_reached'

export const useMixpanelEvents = () => {
  const dispatch = useDispatch()
  const { accountId, projectId } = useParams()

  const trackDestinationOpened = useCallback(
    destination_type => {
      dispatch(
        trackMixpanel(EV_DESTINATION_OPEN, {
          destination_type: destination_type?.toLowerCase(),
          project_id: projectId,
          account_id: accountId
        })
      )
    },
    [accountId, dispatch, projectId]
  )

  const trackRecordActionReached = useCallback(
    record_type => {
      dispatch(
        trackMixpanel(EV_RECORD_ACTION_REACHED, {
          destination_type: 'record',
          record_type: record_type?.toLowerCase(),
          project_id: projectId,
          account_id: accountId
        })
      )
    },
    [accountId, dispatch, projectId]
  )

  return {
    trackDestinationOpened,
    trackRecordActionReached
  }
}
