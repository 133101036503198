import { takeEvery, all } from 'redux-saga/effects'

import { AUTH_CHECK_SUCCESS } from 'portal/store/auth/actions'
import { setUserData, bugsnag } from 'portal/utils/bugsnag'

function* reduxBreadcrumbs(action) {
  if (!bugsnag) {
    return
  }
  yield bugsnag.leaveBreadcrumb('Redux: ' + action.type)
}

function* userDataPickup(action) {
  const { userUuid } = action
  yield setUserData({
    userUuid
  })
}

export function* watch() {
  yield all([
    takeEvery([AUTH_CHECK_SUCCESS], userDataPickup),
    takeEvery('*', reduxBreadcrumbs)
  ])
}
