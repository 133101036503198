import React from 'react'

// https://docs.bugsnag.com/platforms/javascript/react/
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { env } from 'portal/lib/config/env-config'

/**
 * @typedef {Object} rvalue
 * @property {Object} wrapper
 * @property {import("@bugsnag/js").Client} client
 * @return {rvalue}
 * **/
function getBugsnagWrapper() {
  const API_KEY = env.BUGSNAG_API_KEY
  if (!API_KEY || API_KEY.length <= 2) {
    global._TEST_ || console.warn('Qflow Warning: Bugsnag API key is not set')
    return { BugsnagErrorBoundary: React.Fragment, client: null }
  }

  const bugsnagClient = Bugsnag.start({
    apiKey: API_KEY,
    appVersion: env.CI_BUILDNUMBER,
    metadata: {
      CI_BUILDNUMBER: env.CI_BUILDNUMBER,
      CI_BUILDID: env.CI_BUILDID,
      CI_GIT_SHA: env.CI_GIT_SHA
    },
    plugins: [new BugsnagPluginReact()],
    onError: report => {
      report.breadcrumbs = report.breadcrumbs.filter(crumb => {
        const status = crumb.metadata.status
        const uri = crumb.metadata.request || ''
        if (uri.includes('api.mixpanel.com') && status >= 200 && status < 300) {
          return false
        }

        return true
      })
    }
  })

  return {
    BugsnagErrorBoundary: Bugsnag.getPlugin('react').createErrorBoundary(React),
    client: bugsnagClient
  }
}

const { BugsnagErrorBoundary, client } = getBugsnagWrapper()

export { BugsnagErrorBoundary, client as bugsnag }

window.testBugsnag = () => {
  console.log('Testing bugsnag!')
  if (!client) {
    console.warn('Qflow Warning: Bugsnag client not initialised')
    return
  }

  client.notify(new Error('Test error'))
}

export const setUserData = (userData = {}) => {
  if (!client) {
    console.warn('Qflow Warning: Bugsnag client not initialised')
    return
  }

  client.user = userData
}
