import React from 'react'
import PropTypes from 'prop-types'

import { sizes } from '@qflow/theme'
import { Label } from './Label'

export function Heading({ children, isChild = false, ...props }) {
  return (
    <Label
      id="Heading"
      size={sizes.X_LARGE}
      isChild={isChild}
      colour="inherit"
      {...props}
    >
      {children}
    </Label>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  isChild: PropTypes.bool
}
