export const oneClickEnabledProjects = [
  '7b001559-12ea-4042-ab43-55e2d63e9d54',
  '651857df-0c72-4bdb-8613-09324c639cb3', // Imaad Local test
  '3605ee97-ca9b-4896-b0d6-fbdb0d8c7111', // Prod Test Project
  // '1cf76830-40d4-4152-b558-1d6e509c03a9', // Prod - Demo - 1 Main Street
  'c3a4c204-3af6-4fb9-8aa7-effa6a4cb3e5', // Dev - UAT Account - UAT Project 1
  '4cebaa8b-2377-4a83-a53a-665b5efc339d', //  System-Tests - Delivery Reports Seed Data - 'Reports-Project'
  'dcbfa047-5646-4936-bf1d-217a2a469562', // Skyscanner Limited- Blackwater client project
  'a3924660-f54b-4187-86e3-b8d6f674af18' // Orms Account Project
]

export const createFilterDescription = filters => {
  if (filters) {
    const filterStringArray: string[] = []
    if (filters.assignedTo && filters.assignedTo.length > 0) {
      filterStringArray.push('Assigned To')
    }
    if (filters.deliveryAddress && filters.deliveryAddress.length > 0) {
      filterStringArray.push('Delivery Address')
    }
    if (filters.deliveryId && filters.deliveryId.length > 0) {
      filterStringArray.push('Delivery ID')
    }
    if (filters.knownSupplierName && filters.knownSupplierName.length > 0) {
      filterStringArray.push('Supplier Company')
    }
    if (filters.tradeContractor && filters.tradeContractor.length > 0) {
      filterStringArray.push('Trade Contractor')
    }

    if (filterStringArray.length > 0) {
      return filterStringArray.join(', ')
    }
  }

  return ''
}
