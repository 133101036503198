import { useEffect } from 'react'
import { Modal } from 'antd'

export function AssignmentLongRunningOperationModal() {
  const [modal, contextHolder] = Modal.useModal()

  useEffect(() => {
    modal.info({
      title: 'Long running operation',
      visible: true,
      zIndex: 6001,
      content: (
        <p>
          This request is now being processed, this may take a few minutes.
          Please wait...
        </p>
      )
    })
  }, [modal])

  return <div>{contextHolder}</div>
}
