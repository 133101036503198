import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function Spreadsheet({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ width: size, height: size }}
    >
      <g fill={colour || colours.WHITE} fillRule="nonzero" {...props}>
        <path d="M19.898 1.345a.302.302 0 0 0-.204-.103L15.273.875l-.08-.62a.298.298 0 0 0-.335-.253l-4.102.502a.305.305 0 0 0-.05-.008L5.068.026a.3.3 0 0 0-.323.266l-.084.958-4.367.534a.296.296 0 0 0-.26.325l2.23 17.254c.01.078-.008.154.025.23a.302.302 0 0 0 .276.178h14.679c.165 0 .3-.13.3-.291v-.38l.578.049a.3.3 0 0 0 .324-.265l1.52-17.327a.286.286 0 0 0-.068-.212zM2.266 14.713L.666 2.325l1.6-.195v12.583zM5.316.632l2.618.217-2.666.327.048-.544zm9.32-.016l.153 1.183H4.965L14.636.616zm3.24 17.928l-.333-.028V10.01a.295.295 0 0 0-.3-.291c-.164 0-.298.13-.298.29v9.18H2.865V2.383h14.08V8.65c0 .161.134.291.299.291.165 0 .3-.13.3-.29V2.09a.295.295 0 0 0-.3-.292h-1.852l-.043-.333 3.996.332-1.47 16.746z" />
        <path d="M4 6.095h3.2v1H4zM8.16 6.095h1v1h-1zM10.4 6.095h3.2v1h-3.2zM14.56 6.095h1v1h-1zM4 7.365h3.2v1H4zM8.16 7.365h1v1h-1zM10.4 7.365h3.2v1h-3.2zM14.56 7.365h1v1h-1zM4 8.635h3.2v1H4zM8.16 8.635h1v1h-1zM10.4 8.635h3.2v1h-3.2zM14.56 8.635h1v1h-1zM4 9.905h3.2v1H4zM8.16 9.905h1v1h-1zM10.4 9.905h3.2v1h-3.2zM14.56 9.905h1v1h-1zM4 11.81h3.2v1H4zM8.16 11.81h1v1h-1zM10.4 11.81h3.2v1h-3.2zM14.56 11.81h1v1h-1zM4 13.08h3.2v1H4zM8.16 13.08h1v1h-1zM10.4 13.08h3.2v1h-3.2zM14.56 13.08h1v1h-1zM4 14.35h3.2v1H4zM8.16 14.35h1v1h-1zM10.4 14.35h3.2v1h-3.2zM14.56 14.35h1v1h-1zM4 15.62h3.2v1H4zM8.16 15.62h1v1h-1zM10.4 15.62h3.2v1h-3.2zM14.56 15.62h1v1h-1z" />
      </g>
    </svg>
  )
}

Spreadsheet.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
