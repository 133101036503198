import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function EditPencil({ size = sizes.SMALL, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      {...props}
      style={{ width: size, height: size }}
      //
      // WTF IE11
      focusable="false"
    >
      <path
        fill={colour || 'currentColor'}
        fillRule="nonzero"
        d="M.097 7.43A.336.336 0 0 0 0 7.668v2C0 9.85.15 10 .333 10h2a.336.336 0 0 0 .236-.097l5.098-5.098-2.472-2.47L.097 7.43zm9.806-5.333l-2-2a.334.334 0 0 0-.472 0L5.667 1.861 8.14 4.333l1.764-1.764c.13-.13.13-.34 0-.472z"
      />
    </svg>
  )
}

EditPencil.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
