import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function QuestionMark({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.587173 3.02667C0.0538395 2.8 -0.172827 2.14 0.147173 1.66C0.79384 0.7 1.87384 0 3.30051 0C4.86717 0 5.94051 0.713333 6.48717 1.60667C6.95384 2.37333 7.22717 3.80667 6.50717 4.87333C5.70717 6.05333 4.94051 6.41333 4.52717 7.17333C4.42717 7.35333 4.36717 7.5 4.32717 7.8C4.26717 8.28667 3.86717 8.66667 3.37384 8.66667C2.79384 8.66667 2.32051 8.16667 2.38717 7.58667C2.42717 7.24667 2.50717 6.89333 2.69384 6.56C3.20717 5.63333 4.19384 5.08667 4.76717 4.26667C5.37384 3.40667 5.03384 1.8 3.31384 1.8C2.53384 1.8 2.02717 2.20667 1.71384 2.69333C1.48051 3.07333 0.99384 3.19333 0.587173 3.02667ZM4.64051 11.3333C4.64051 12.0667 4.04051 12.6667 3.30717 12.6667C2.57384 12.6667 1.97384 12.0667 1.97384 11.3333C1.97384 10.6 2.57384 10 3.30717 10C4.04051 10 4.64051 10.6 4.64051 11.3333Z"
        fill={colour || theme.secondary.fg}
      />
    </svg>
  )
}

QuestionMark.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
