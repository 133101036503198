import produce from 'immer'

import { DASHBOARD_LOAD } from './actions'
import * as configActions from 'portal/store/configuration/actions'

const initialState = {}

export const initialKeyState = Object.freeze({
  loading: false,
  loaded: false,
  error: null,
  uri: null
})

export function getKeyFromIds(accountId, projectId, moduleName) {
  return `${accountId}_${projectId}_${moduleName}`.toLowerCase()
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    function produceDashboard(mutator) {
      const { accountId, projectId, moduleName } = action
      const key = getKeyFromIds(accountId, projectId, moduleName)
      if (!draft[key]) {
        draft[key] = { ...initialKeyState }
      }

      mutator(draft[key])
    }

    switch (action.type) {
      case DASHBOARD_LOAD: {
        produceDashboard(draft => {
          draft.loading = true
          draft.loaded = false
          draft.error = null
          draft.uri = null
        })
        return
      }

      case configActions.CONFIGURATION_LOADED: {
        produceDashboard(draft => {
          draft.loading = false
          draft.loaded = true
          draft.error = null
          draft.uri = action.configuration?.dashboardIframeURI ?? null
        })
        return
      }

      case configActions.CONFIGURATION_LOAD_FAILURE: {
        produceDashboard(draft => {
          draft.loading = false
          draft.loaded = true
          draft.error = action.error
          draft.uri = null
        })
        return
      }
    }
  })
}
