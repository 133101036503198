import React from 'react'
import PropTypes from 'prop-types'

export function WelcomeDesk({ size = 700, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="733"
      height="242"
      viewBox="0 0 733 242"
      style={{ width: size, height: size }}
      {...props}
    >
      <defs>
        <path id="a" d="M0 205.792h129.998V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#252529"
          d="M0 219h733v1H0zM156 206h349v1H156z"
          opacity=".805"
        />
        <path fill="#252529" d="M156 241h350v1H156z" opacity=".211" />
        <g strokeWidth="2">
          <path
            stroke="#3E3F42"
            d="M456.144 111.43L161.993 191.18M204.3 111.137l295.229 80.045"
          />
          <g stroke="#444" strokeLinecap="round" strokeLinejoin="round">
            <path d="M141 95.876h379.104v-6.111H141zM141 89.765L146.769 79h367.566l5.769 10.765z" />
          </g>
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M156.866 111.137h109V95.876h-109zM395.238 111.137h109.097V95.876H395.238z"
          />
          <g stroke="#444" strokeLinecap="round" strokeLinejoin="round">
            <path d="M148.013 95.876v122.087M156.77 217.963V95.876M156.77 217.963h-8.757" />
          </g>
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M161.244 206.068v-94.93M161.244 206.068h-4.475"
          />
          <g stroke="#444" strokeLinecap="round" strokeLinejoin="round">
            <path d="M513.09 95.876v122.087M504.335 217.963V95.876M504.335 217.963h8.756" />
          </g>
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M499.86 206.068v-94.93M499.86 206.068h4.474M265.866 111.137l8.178-15.26M395.238 111.137l-8.178-15.26"
          />
        </g>
        <path fill="#DFDFDF" d="M545.564 217.996h50.167V133h-50.167z" />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M545.564 179.534h50.167v-38.462h-50.167zM545.564 217.996h50.167v-38.462h-50.167z"
        />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M565.897 147.04h9.5v-1.062h-9.5zM565.897 185.503h9.5v-1.062h-9.5zM592.199 137.036a.531.531 0 1 1-1.063-.001.531.531 0 0 1 1.063 0z"
        />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M545.564 141.07h50.167V133h-50.167z"
        />
        <path
          fill="#FEFEFE"
          d="M437.09 60.718c-.36 0-.65-.29-.65-.65 0-.36.29-.66.65-.66.37 0 .66.3.66.66 0 .36-.29.65-.66.65zm-4.67-5.33v6.34h-60.73v17.33h10.89v-8h48.58v8h10.61v-23.67h-9.35z"
        />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M437.09 60.718c-.36 0-.65-.29-.65-.65 0-.36.29-.66.65-.66.37 0 .66.3.66.66 0 .36-.29.65-.66.65zm-4.67-5.33v6.34h-60.73v17.33h10.89v-8h48.58v8h10.61v-23.67h-9.35z"
        />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M382.579 79.059h48.58v-8h-48.58zM437.75 60.068c0 .36-.29.65-.66.65-.36 0-.65-.29-.65-.65 0-.36.29-.66.65-.66.37 0 .66.3.66.66z"
        />
        <path fill="#FEFEFE" d="M387.289 55.389h38.88v-8.42h-38.88z" />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M387.289 55.389h38.88v-8.42h-38.88z"
        />
        <path fill="#595959" d="M382.579 79.059h48.58v-8h-48.58z" />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M437.75 60.068c0 .36-.29.65-.66.65-.36 0-.65-.29-.65-.65 0-.36.29-.66.65-.66.37 0 .66.3.66.66zM382.579 79.059h48.58v-8h-48.58z"
        />
        <path fill="#FEFEFE" d="M371.689 61.728h60.73v-6.34h-60.73z" />
        <path
          stroke="#444"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M371.689 61.728h60.73v-6.34h-60.73zM374.607 58.557h10.75"
        />
        <g transform="translate(450 27)">
          <path
            fill="#8A8A8F"
            d="M14.604 39.923h30.41a5 5 0 1 1 0 10h-30.41a5 5 0 1 1 0-10z"
          />
          <path
            fill="#9EA0A5"
            fillRule="nonzero"
            d="M23.567 25.5l7.923-7.874h4.494v8.724H24.69z"
          />
          <path
            fill="#ECF0F1"
            d="M18.5 23.5l10.397-10.74h11.906v22.168H18.5z"
          />
          <path fill="#C6CAD2" d="M14.403 34.584h30.733v5.34H14.403z" />
          <path
            fill="#ECF0F1"
            d="M24.228 17L7.65.734h-5.1v11.18h1.94l1.923-4.851 14.018 13.515z"
          />
          <circle cx="19.5" cy="45.5" r="2.5" fill="#D8D8D8" />
          <circle cx="29.5" cy="45.5" r="2.5" fill="#D8D8D8" />
          <circle cx="39.5" cy="45.5" r="2.5" fill="#D8D8D8" />
          <g fill="#444" fillRule="nonzero">
            <path d="M35.74 17h-4.688a.85.85 0 0 0-.601.25l-7.212 7.212a.851.851 0 0 0-.25.6v1.288c0 .47.382.85.85.85h11.9c.47 0 .85-.38.85-.85v-8.5a.85.85 0 0 0-.85-.85zm-.85 8.5h-10.2v-.085l6.715-6.715h3.485v6.8z" />
            <path d="M45.94 39.168V34.85a.85.85 0 0 0-.85-.85h-3.4V12.75a.85.85 0 0 0-.85-.85h-11.9a.852.852 0 0 0-.601.249l-4.074 4.074L8.29.249A.85.85 0 0 0 7.69 0h-5.1a.85.85 0 0 0-.85.85V11.9c0 .47.38.85.85.85v5.95c0 .47.38.85.85.85a1.7 1.7 0 1 1-1.7 1.7.85.85 0 1 0-1.7 0 3.4 3.4 0 1 0 4.25-3.28v-5.22a.849.849 0 0 0 .79-.538l1.574-3.988 12.805 12.805-1.32 1.32a.85.85 0 0 0-.25.6V34h-3.4a.85.85 0 0 0-.85.85v4.32A5.95 5.95 0 0 0 14.49 51h30.6a5.95 5.95 0 0 0 .85-11.832zm-39-33.062a.85.85 0 0 0-1.392.288L3.71 11.05h-.27V1.7h3.897l15.725 15.725-2.402 2.402L6.939 6.106zm12.65 17.196l9.702-9.702H39.99V34h-20.4V23.302zM15.34 35.7h28.9v3.4h-28.9v-3.4zm29.75 13.6h-30.6a4.25 4.25 0 1 1 0-8.5h30.6a4.25 4.25 0 0 1 0 8.5z" />
            <path d="M29.79 41.65a3.4 3.4 0 1 0 0 6.8 3.4 3.4 0 0 0 0-6.8zm0 5.1a1.7 1.7 0 1 1 0-3.4 1.7 1.7 0 0 1 0 3.4zM39.99 41.65a3.4 3.4 0 1 0 0 6.8 3.4 3.4 0 0 0 0-6.8zm0 5.1a1.7 1.7 0 1 1 0-3.4 1.7 1.7 0 0 1 0 3.4zM19.4 42a3.4 3.4 0 1 0 0 6.8 3.4 3.4 0 0 0 0-6.8zm0 5.1a1.7 1.7 0 1 1 0-3.4 1.7 1.7 0 0 1 0 3.4z" />
          </g>
        </g>
        <g>
          <path fill="#DFDFDF" d="M463.263 218.053h28.14v-58.08h-28.14z" />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M463.263 218.053h28.14v-58.08h-28.14z"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M463.263 164.686h28.14v-4.713h-28.14zM463.263 169.4h28.14v-4.714h-28.14zM463.263 218.053h28.14v-29.04h-28.14z"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M479.791 203.534a2.458 2.458 0 1 1-4.916 0 2.458 2.458 0 0 1 4.916 0zM463.263 179.207h28.14V169.4h-28.14z"
          />
        </g>
        <g>
          <path fill="#DFDFDF" d="M401.06 218.987h59.25V180.09h-59.25z" />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M401.06 218.987h59.25V180.09h-59.25z"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M401.06 189.383h59.25v-9.294h-59.25z"
          />
          <path fill="#FEFEFE" d="M410.56 191.758h4.75v-4.75h-4.75z" />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M410.56 191.758h4.75v-4.75h-4.75z"
          />
          <path fill="#FEFEFE" d="M446.06 191.758h4.75v-4.75h-4.75z" />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M446.06 191.758h4.75v-4.75h-4.75zM425.31 180.16h10.75v-4.587h-10.75zM405.643 180.16v38.827M455.726 180.16v38.827"
          />
        </g>
        <g>
          <path
            fill="#DFDFDF"
            d="M227.834 83.028h18.832V71.963h-18.832zM189.384 71.96h95.76V59.05h-95.76z"
          />
          <path fill="#444" d="M189.384 59.05h95.76V1h-95.76z" />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M189.384 59.05h95.76V1h-95.76z"
          />
          <path fill="#595959" d="M192.807 56.666h88.908V4.425h-88.908z" />
          <path fill="#8A8A8F" d="M192.807 56.666h88.908V4.425h-88.908z" />
          <g fill="#444" fillRule="nonzero" opacity=".85">
            <path d="M252.241 51.027c-3.497-1.84-6.172-2.862-8.547-3.265l.259-.356a.584.584 0 0 0 .094-.483l-.542-2.165c1.45-2.044 2.307-4.598 2.307-7.02v-2.332H247.561a.583.583 0 1 0 0-1.166h-.583v-.582a9.989 9.989 0 0 0-1.068-4.514l-5.233 1.006a.586.586 0 0 1-.522-.16.585.585 0 0 1-.16-.522l.955-5.019a11.29 11.29 0 0 0-1.55-.568v-.123A1.76 1.76 0 0 0 237.644 22h-2.313a1.76 1.76 0 0 0-1.758 1.758v.124c-.54.153-1.055.345-1.549.567l.956 5.019a.586.586 0 0 1-.683.682l-5.233-1.006a9.99 9.99 0 0 0-1.068 4.514v.582h-.583a.583.583 0 1 0 0 1.166h1.748v2.332c0 2.422.858 4.976 2.307 7.02l-.54 2.165a.582.582 0 0 0 .094.484l.259.355c-2.376.403-5.05 1.425-8.548 3.265a3.234 3.234 0 0 0-1.732 2.87v2.493c0 .322.261.583.583.583H233.792l5.364-.006c.01.001.017.006.027.006h14.207a.583.583 0 0 0 .583-.583v-2.493a3.234 3.234 0 0 0-1.732-2.87zm-23.915-13.29v-2.33h16.32v2.33c0 2.625-.928 4.935-2.301 6.668-.02.02-.047.032-.065.054-.2.257-.408.499-.621.726l-.004.004c-.141.15-.289.283-.436.42-1.424 1.285-3.116 2.038-4.733 2.038-1.615 0-3.308-.753-4.733-2.038-.146-.137-.293-.27-.435-.42l-.004-.004a10.62 10.62 0 0 1-.622-.726c-.017-.022-.043-.035-.064-.054a10.714 10.714 0 0 1-2.302-6.667zm1.798 9.204l.261-1.046.004.004c1.41 1.533 3.202 2.612 5.222 2.857l.003.001-1.582 3.558-3.908-5.374zm4.16 8.865l.349-1.748h3.707l.149.739.2 1.005-4.405.004zm4.657-3.49l-1.582-3.559h.003c2.02-.246 3.813-1.325 5.222-2.858l.004-.004.262 1.046-3.91 5.374z" />
            <path d="M242.029 25.015l-.73 3.827 3.99-.767a10.18 10.18 0 0 0-3.26-3.06zM230.944 25.015a10.18 10.18 0 0 0-3.26 3.06l3.99.767-.73-3.827z" />
          </g>
          <path
            fill="#444"
            d="M237.264 67.508a2.188 2.188 0 1 1 2.187-2.188 2.187 2.187 0 0 1-2.187 2.188"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M237.264 71.963h8.929l1 11.064h-19.86l1.002-11.064h8.929M224.28 83.028h25.968M189.384 71.96h95.76V59.05h-95.76z"
          />
        </g>
        <g transform="translate(266 36)">
          <path
            fill="#595959"
            d="M28.25 200.444a3.281 3.281 0 1 1-6.563 0 3.281 3.281 0 0 1 6.562 0zM68.28 201.51a3.281 3.281 0 1 1-6.562 0 3.281 3.281 0 0 1 6.562 0M101.75 201.51a3.281 3.281 0 1 1-6.563 0 3.281 3.281 0 0 1 6.562 0"
          />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#595959"
            d="M56.001 133.68h18v-9.75h-18z"
            mask="url(#b)"
          />
          <path fill="#DFDFDF" d="M60.501 157.34h9v-23.66h-9z" mask="url(#b)" />
          <path
            fill="#DFDFDF"
            d="M58.251 182.51h13.5v-25.17h-13.5zM101.751 188.43v5.62l-30-2.81-3.94 2.81h-5.62l-3.94-2.81-30 2.81v-5.62l30-5.92h13.5z"
            mask="url(#b)"
          />
          <path
            fill="#595959"
            d="M106.901 116.85v2.21c0 2.69-2.18 4.87-4.86 4.87h-74.08c-2.69 0-4.86-2.18-4.86-4.87 0-5.11.18-10.21.54-15.31l6.81-96.33a6.914 6.914 0 0 1 6.9-6.42h55.46c3.63 0 6.64 2.8 6.89 6.42l6.66 94.12c.36 5.1.54 10.2.54 15.31M109.901 88.352h19.097v-6.167h-19.097zM1 88.352h19.097v-6.167H1z"
            mask="url(#b)"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.82 104.018h3.457V88.351H8.82zM117.721 104.018h3.457V88.351h-3.457z"
            mask="url(#b)"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M74 128.177h35.935a9.515 9.515 0 0 0 9.515-9.515v-14.644M10.548 104.018v14.644a9.515 9.515 0 0 0 9.515 9.515H56"
            mask="url(#b)"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M56.001 133.68h18v-9.75h-18zM60.501 157.34h9v-23.66h-9z"
            mask="url(#b)"
          />
          <path
            stroke="#444"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M58.251 182.51h13.5v-25.17h-13.5zM101.751 188.43v5.62l-30-2.81-3.94 2.81h-5.62l-3.94-2.81-30 2.81v-5.62l30-5.92h13.5zM65 194.052v4.178M28.25 194.052v6.392M101.75 194.052v7.459M28.25 200.444a3.281 3.281 0 1 1-6.563 0 3.281 3.281 0 0 1 6.562 0zM68.28 201.51a3.281 3.281 0 1 1-6.562 0 3.281 3.281 0 0 1 6.562 0zM101.75 201.51a3.281 3.281 0 1 1-6.563 0 3.281 3.281 0 0 1 6.562 0zM106.901 116.85v2.21c0 2.69-2.18 4.87-4.86 4.87h-74.08c-2.69 0-4.86-2.18-4.86-4.87 0-5.11.18-10.21.54-15.31l6.81-96.33a6.914 6.914 0 0 1 6.9-6.42h55.46c3.63 0 6.64 2.8 6.89 6.42l6.66 94.12c.36 5.1.54 10.2.54 15.31zM109.901 88.352h19.097v-6.167h-19.097zM1 88.352h19.097v-6.167H1zM74 131.344h18.666M92.666 131.344l8.208 2.333"
            mask="url(#b)"
          />
        </g>
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path
            fill="#EFEFF4"
            stroke="#444"
            d="M167.463 85.61l1.261-18.266H151l1.261 18.266z"
          />
          <path stroke="#444" d="M168.724 67.344a6.85 6.85 0 0 1 0 13.7" />
          <path
            stroke="#3E3F42"
            d="M155.894 63.193a9.938 9.938 0 0 1 3.49-7.596M164.441 63.193a9.938 9.938 0 0 1 3.49-7.596M162.873 48a9.938 9.938 0 0 1-3.49 7.596"
          />
        </g>
      </g>
    </svg>
  )
}

WelcomeDesk.propTypes = {
  size: PropTypes.string
}
