import styled from 'styled-components'

import * as ButtonBase from 'portal/lib/primitives/buttons/ButtonBase'
import { colours, sizes } from '@qflow/theme'

export const OptionButton = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getBorderCSS({
    withGlow: false,
    getBorderFg: 'transparent',
    getBorderFgHover: 'transparent'
  })}
  ${ButtonBase.getShapeCSS({
    getFontSize: sizes.SMALL,
    getHeight: 'unset'
  })}

  flex: 1 1 auto;
  justify-content: flex-start;

  padding: 0.5rem;

  color: ${colours.CORE_NAVY};
  background-color: transparent;

  :hover,
  :focus,
  :active {
    color: ${colours.TEAL};
  }

  :disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.primary.disabled};
  }

  ${ButtonBase.TakeCSSProp}
`
