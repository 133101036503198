import { combineReducers } from 'redux'

import { reducer as byModule } from './byModule/reducer'
import { reducer as details } from './details/reducer'
import { reducer as editing } from './editing/reducer'
import { reducer as removal } from './removal/reducer'
import { reducer as attachmentUpload } from './attachmentUpload/reducer'
import { reducer as massUpload } from './massUpload/reducer'
import { reducer as exports } from './exports/reducer'

export const reducer = combineReducers({
  byModule,
  details,
  editing,
  removal,
  attachmentUpload,
  massUpload,
  exports
})
