import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router'

export const NavButtonDecorator = ({ to, children }) => {
  const history = useHistory()
  const child = React.Children.only(children)

  const onClick = child.props.onClick

  const openInNewTab = to.startsWith('http://') || to.startsWith('https://')

  const handleClick = useCallback(() => {
    onClick?.()
    history.push(to)
  }, [history, onClick, to])

  const handleNewTabClick = useCallback(() => {
    onClick?.()
    window.open(to, '_blank')
  }, [onClick, to])

  return React.cloneElement(child, {
    'onClick': openInNewTab ? handleNewTabClick : handleClick,

    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_link_role
    'role': 'link',
    'data-href': to
  })
}

NavButtonDecorator.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.any.isRequired
}
