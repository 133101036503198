import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { NotFound } from './components/NotFound/NotFound'
import Accounts from './pages/Accounts/Accounts'
import {
  Waste,
  WasteDetails,
  WasteEdit,
  WasteEventLog,
  WasteLineItemEdit,
  WasteDashboard
} from './pages/Waste'
import {
  Delivery,
  DeliveryDetails,
  DeliveryLineItemEdit,
  DeliveryEdit,
  DeliveryDashboard,
  DeliveryEventLog
} from './pages/Delivery'
import { BIReport } from './pages/BIReports'
import AccountSettings from './pages/Settings/AccountSettings'
import { DestinationLandingPage, Login } from './pages/Core'
import { SignInOidc, FirstSignIn, SignOut } from './pages/Auth'

import * as ROUTES from './routes'
import { Main } from './components/Navigation'
import { ProjectSettings } from './pages/ProjectSettings'
import { Forbidden } from './components/Forbidden/Forbidden'
import { LoginFailedPage } from './pages/Auth/LoginFailed'

import axios from 'portal/utils/axios-msal'

axios.interceptors.response.use(
  response => response,

  error => {
    if (error.response.status === 403) {
      // redirect to 403 page
      window.location = ROUTES.FORBIDDEN
    }
  }
)

export function App() {
  return (
    <Switch>
      <Main.UnauthenticatedRoute
        checkAuth={false}
        path={ROUTES.SIGNIN_REDIRECT}
        component={SignInOidc}
        title="Please wait..."
      />
      <Main.UnauthenticatedRoute
        checkAuth
        redirectOnAuthenticated
        path={ROUTES.FIRST_SIGNIN}
        component={FirstSignIn}
        title="Welcome to Qflow"
      />
      <Main.UnauthenticatedRoute
        checkAuth
        redirectOnAuthenticated
        path={ROUTES.LOGIN_FAILED}
        component={LoginFailedPage}
        title="Login Failed"
      />
      <Main.UnauthenticatedRoute
        checkAuth={false}
        path={ROUTES.SIGN_OUT}
        component={SignOut}
        title="Please wait..."
      />

      {/* Notification Destination */}
      <Main.UnauthenticatedRoute
        exact
        path={ROUTES.RECEIVED_DESTINATION}
        component={DestinationLandingPage}
      />

      {/* Delivery */}
      <Redirect
        exact
        from={ROUTES.DELIVERY_ROOT}
        to={ROUTES.DELIVERY_DEFAULT}
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_EVENTS}
        component={DeliveryEventLog}
        title="Delivery Events"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_TABLE}
        component={Delivery}
        title="Delivery Records"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_DASHBOARD}
        component={DeliveryDashboard}
        title="Delivery Dashboard"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_RECORD_DETAILS}
        component={DeliveryDetails}
        title="Delivery Record"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_RECORD_EDIT}
        component={DeliveryEdit}
        title="Delivery Record Edit"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.DELIVERY_RECORD_LINE_ITEM_EDIT}
        component={DeliveryLineItemEdit}
        title="Delivery Product Edit"
      />

      {/* Waste */}
      <Redirect exact from={ROUTES.WASTE_ROOT} to={ROUTES.WASTE_DEFAULT} />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_RECORD_DETAILS}
        component={WasteDetails}
        title="Waste Record"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_RECORD_EDIT}
        component={WasteEdit}
        title="Waste Record Edit"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_RECORD_LINE_ITEM_EDIT}
        component={WasteLineItemEdit}
        title="Waste Item Edit"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_TABLE}
        component={Waste}
        title="Waste Records"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_EVENTS}
        component={WasteEventLog}
        title="Waste Events"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.WASTE_DASHBOARD}
        component={WasteDashboard}
        title="Waste Dashboard"
      />

      <Main.AuthenticatedRoute
        exact
        path={ROUTES.PROJECTSETTINGS}
        component={ProjectSettings}
        title="ProjectSettings"
      />

      {/* Account Navigation */}
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.ACCOUNTS}
        component={Accounts}
        title="Accounts"
      />

      {/*BI Reports*/}
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.BI_REPORT}
        component={BIReport}
        title="Report"
      />
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.ACCOUNTS}
        component={Accounts}
        title="Accounts"
      />

      {/* Settings Navigation */}
      <Main.AuthenticatedRoute
        exact
        path={ROUTES.ACCOUNT_SETTINGS}
        component={AccountSettings}
        title="AccountSettings"
      />

      <Main.UnauthenticatedRoute
        exact
        path="/"
        component={Login}
        redirectOnAuthenticated
      />
      <Main.UnauthenticatedRoute
        exact
        path={ROUTES.NOT_FOUND}
        component={NotFound}
      />
      <Main.UnauthenticatedRoute
        exact
        path={ROUTES.FORBIDDEN}
        component={Forbidden}
      />
      <Main.UnauthenticatedRoute exact path="*" component={NotFound} />
    </Switch>
  )
}
