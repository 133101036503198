import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Tick({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        d="M5.86332 10.5834L3.08332 7.80336L2.13666 8.74336L5.86332 12.47L13.8633 4.47003L12.9233 3.53003L5.86332 10.5834Z"
        fill={'currentColor'}
      ></path>
    </svg>
  )
}

Tick.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
