import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Input } from 'portal/lib/primitives/forms'
import * as Icons from 'portal/lib/primitives/icons'
import { TrayItemContainer } from './primitives'

export const SearchBox = forwardRef(
  ({ value, onChange, Icon = Icons.MagnifyingGlass, ...props }, ref) => {
    return (
      <TrayItemContainer>
        <Input.SearchBox
          {...props}
          value={value}
          onChange={onChange}
          ref={ref}
          autoComplete="off"
          Icon={Icon}
        />
      </TrayItemContainer>
    )
  }
)

SearchBox.propTypes = {
  ...Input.SearchBox.propTypes,
  value: PropTypes.string,
  onChange: PropTypes.func
}
