import styled from 'styled-components'
import { colours } from '@qflow/theme'

export const Divider = styled.hr`
  display: flex;
  flex: 0 0 0;
  align-self: stretch;
  margin: 0.5rem 0;

  border: none;
  border-left: 1px solid ${({ theme }) => theme.primary.fgDim};
`

export const HorizontalDivider = styled.hr`
  display: flex;
  flex: 0 0 0;
  align-self: stretch;
  margin: 14px 0 24px 0;

  border: none;
  border-top: 1px solid ${colours.CLOUD_GREY};
`
