import * as uuid from 'uuid'
import { Buffer } from 'buffer'

const createLoggingContext = () => {
  const Guid = uuid.v1()
  const OriginatorUtcTimestamp = new Date().toUTCString()

  const correlationHeader = {
    CallChainId: Guid,
    OriginatorUtcTimestamp: OriginatorUtcTimestamp,
    ExtraHeaders: {
      CallChainFrontendId: Guid
    }
  }

  let obj = Buffer.from(JSON.stringify(correlationHeader), 'utf8')
  return btoa(String.fromCharCode.apply(null, obj))
}

export default createLoggingContext
