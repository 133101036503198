import { MODULES } from 'portal/lib/constants'
import { splitOutUnassignedFilter } from 'portal/lib/functions'

export const getModuleSpecificQueryParams = (
  moduleName: string,
  useCreatedAtDate = false
) => {
  if (useCreatedAtDate) {
    return { sort: '-CreatedAt' }
  }

  return MODULES.select(moduleName, {
    waste: {
      sort: '-Data.RemovalDate'
    },
    delivery: {
      sort: '-Data.DeliveryArrivalDate'
    }
  })
}

export const computeFilterParams = (
  filterState: { [x: string]: any },
  useCreatedAtDate = false
) => {
  const map = {
    'deliveryId': 'search[DeliveryId]',
    'deliveryAddress': 'filter[DeliveryAddress]',
    'tradeContractor': 'filter[TradeContractor]',
    'knownSupplierName': 'filter[KnownSupplierName]',
    'consignmentId': 'search[ConsignmentId]',
    'carrierName': 'filter[CarrierName]',
    'originSiteAddress': 'filter[OriginSiteAddress]',
    'items[].wasteCode': 'filter[WasteCode]',
    'assignedTo': 'filter[AssigneeIds][]'
  }
  const params = {}
  for (const [key, value] of Object.entries(filterState || {})) {
    if (key in map) {
      params[map[key]] = value
    }
  }

  function dateValue(date: {
    startDate: { toISOString: () => string }
    endDate: { toISOString: () => string }
  }) {
    if (
      date.startDate &&
      date.endDate &&
      date.startDate.toISOString &&
      date.endDate.toISOString
    ) {
      return (
        'ge:' +
        date.startDate.toISOString() +
        ', le:' +
        date.endDate.toISOString()
      )
    }

    return ''
  }

  if (filterState) {
    if (filterState['deliveryDate'] !== undefined) {
      params[useCreatedAtDate ? 'filter[CreatedAt]' : 'filter[DeliveryDate]'] =
        dateValue(filterState['deliveryDate'])
    }
    if (filterState['removalDate'] !== undefined) {
      params[useCreatedAtDate ? 'filter[CreatedAt]' : 'filter[RemovalDate]'] =
        dateValue(filterState['removalDate'])
    }
  }

  splitOutUnassignedFilter(
    params,
    'filter[AssigneeIds][]',
    'filter[AssigneeIncludeUnassigned]'
  )

  return params
}
