function getFontSize() {
  return window.getComputedStyle?.(document.documentElement)?.fontSize ?? '16px'
}

const REM1_PX = parseInt(getFontSize().replace('px', ''))

export const TINY_X = 0.6
export const TINY = TINY_X + 'rem'
export const TINY_PX = TINY_X * REM1_PX

export const X_SMALL_X = 0.75
export const X_SMALL = X_SMALL_X + 'rem'
export const X_SMALL_PX = X_SMALL_X * REM1_PX

export const SMALL_X = 0.8
export const SMALL = SMALL_X + 'rem'
export const SMALL_PX = SMALL_X * REM1_PX

export const SMALL_MEDIUM_X = 0.875
export const SMALL_MEDIUM = SMALL_MEDIUM_X + 'rem'
export const SMALL_MEDIUM_PX = SMALL_MEDIUM_X * REM1_PX

export const MEDIUM_X = 1.0
export const MEDIUM = MEDIUM_X + 'rem'
export const MEDIUM_PX = MEDIUM_X * REM1_PX

export const LARGER_X = 1.2
export const LARGER = LARGER_X + 'rem'
export const LARGER_PX = LARGER_X * REM1_PX

export const X_LARGE_X = 1.9
export const X_LARGE = X_LARGE_X + 'rem'
export const X_LARGE_PX = X_LARGE_X * REM1_PX
