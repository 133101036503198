import { takeLatest, all, put, call } from 'redux-saga/effects'
import * as actions from './actions'
import axios from 'portal/utils/axios-msal'
import { COUNTRY } from '@qflow/constants'

function* projectGetDetailsSaga(action) {
  yield put(actions.projectGetDetailsStart())
  if (action.accountId === '' || action.accountId === null) {
    yield put(actions.projectGetDetailsFail('No Account Id was set'))
  }
  if (action.projectId === '' || action.projectId === null) {
    yield put(actions.projectGetDetailsFail('No Project Id was set'))
  }
  try {
    const response = yield call(
      axios.get,
      '/accounts/' + action.accountId + '/projects/' + action.projectId
    )
    const data = response.data
    if (!data.timeZone || data.timeZone.startsWith('GMT')) {
      data.timeZone = 'Europe/London'
    }
    if (!data.country) {
      data.country = COUNTRY.UK
    }
    yield put(actions.projectGetDetailsSuccess(data))
  } catch (error) {
    yield put(actions.projectGetDetailsFail(error))
  }
}

export function* watch() {
  yield all([takeLatest(actions.PROJ_GET_DETAILS_SAGA, projectGetDetailsSaga)])
}
