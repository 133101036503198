import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function TrashCan({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      viewBox="3 0 14 20"
      {...props}
    >
      <g fill={colour || theme.secondary.fg} fillRule="nonzero">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M4.99996 15.8333C4.99996 16.75 5.74996 17.5 6.66663 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V5.83333H4.99996V15.8333ZM6.66663 7.5H13.3333V15.8333H6.66663V7.5ZM12.9166 3.33333L12.0833 2.5H7.91663L7.08329 3.33333H4.16663V5H15.8333V3.33333H12.9166Z"
          fill={colour}
        />
      </g>
    </svg>
  )
}

TrashCan.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
