import { SideBar, icons } from 'portal/lib/primitives'
import { useResponsive, useUserProjectRole } from 'portal/lib/hooks'

export function ProjectSettingsSidebarButton({
  accountId,
  projectId,
  projectsTable
}) {
  const { isMobile } = useResponsive()

  const { isProjectAdmin } = useUserProjectRole(accountId, projectId)

  if (!isProjectAdmin || isMobile) {
    return null
  }

  return (
    <SideBar.NavButton
      disabled={projectsTable == ''}
      id="ProjectSettings"
      to={projectsTable}
      Icon={icons.Settings}
    >
      Project Settings
    </SideBar.NavButton>
  )
}
