import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router'
import styled from 'styled-components'

import { MODULES } from 'portal/lib/constants'
import { ClickableLink } from 'portal/lib/primitives'
import * as ROUTES from 'portal/routes'
import { useModuleIds } from 'portal/lib/hooks'
import details from 'portal/assets/RecordsTable/reportViewBlk.svg'
import resetFilter from 'portal/assets/RecordsTable/resetFilterBlk.svg'

export const RecordLink = (setCellId, filters, updateFilters) => {
  const handleResetFilters = () => {
    for (let key in filters) {
      if (typeof filters[key] === 'object' && filters[key].length > 0) {
        updateFilters(key, [])
      } else if (typeof filters[key] === 'string') {
        updateFilters(key, '')
      }
    }
  }

  return {
    title: '',
    width: '4rem',
    render: (rowData, _, index) => {
      if (
        index === 0 ||
        !rowData.record ||
        !rowData.record.recordId ||
        rowData.record.recordId.length < 3
      ) {
        return (
          <ResetFilters title="Reset filters" onClick={handleResetFilters}>
            <img src={resetFilter} alt="" />
          </ResetFilters>
        )
      }
      return <RecordLinkRenderer rowData={rowData} record={rowData.record} />
    },
    onCell: setCellId
  }
}

/** @param {{accountId: string, projectId: string, moduleName?: string, module: string}} params */
export const getRecordLinkUri = (params, moduleName, recordId) => {
  let route = MODULES.select(moduleName, {
    waste: ROUTES.WASTE_RECORD_DETAILS,
    delivery: ROUTES.DELIVERY_RECORD_DETAILS
  })

  return generatePath(route, {
    ...params,
    recordId: recordId
  })
}

const RecordLinkRenderer = ({ rowData, record }) => {
  const params = useParams()
  const { moduleName } = useModuleIds()
  const { recordId } = record

  const uri = useMemo(() => {
    return getRecordLinkUri(params, moduleName, recordId)
  }, [moduleName, params, recordId])

  return (
    <ClickableLink
      id="navigateToDetailsBtn"
      to={uri}
      disabled={rowData.removed}
    >
      {rowData.removed ? <div /> : <img src={details} alt="" />}
    </ClickableLink>
  )
}

RecordLinkRenderer.propTypes = {
  rowData: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
}

const ResetFilters = styled.button`
  height: 32px;
  width: 32px;
  padding: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #dddddd;
  }
`
