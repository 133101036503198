import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { SPACING } from '@qflow/theme'
import {
  Heading,
  ColourButton,
  icons,
  Spacer,
  Flex
} from 'portal/lib/primitives'
import { loginStart } from 'portal/store/auth/actions'
import { useResponsive } from 'portal/lib/hooks'

export function Login() {
  const dispatch = useDispatch()
  const handleSignIn = useCallback(() => {
    dispatch(loginStart())
  }, [dispatch])

  const { isMobile } = useResponsive()

  return (
    <Container id="LoginPage">
      <InnerContainer>
        <HeadingContainer>
          <Heading isChild>Welcome to</Heading>
          <Spacer.Tiny />
          <QflowLogo />
        </HeadingContainer>

        <Spacer.Medium />

        <WelcomeDesk />

        <Spacer.Medium />
        <Flex.By amount={1} />

        {isMobile && <LoginButton onClick={handleSignIn}>Sign In</LoginButton>}
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;

  align-self: center;
  justify-content: center;

  margin-bottom: 2rem;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  width: 100%;
  max-width: 60rem;

  height: 100%;
  max-height: 30rem;

  box-sizing: border-box;
  padding: ${SPACING.SMALL};
  padding-top: ${SPACING.MEDIUM};
  padding-bottom: ${SPACING.MEDIUM};
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2rem;
`

const WelcomeDesk = styled(icons.WelcomeDesk).attrs(() => ({
  style: undefined
}))`
  display: flex;
  flex: 0 0 auto;

  width: 90%;
  height: auto;
`

const QflowLogo = styled(icons.QflowLogo).attrs(() => ({ style: undefined }))`
  height: 1.55rem;
  width: 5rem;
`

const LoginButton = styled(ColourButton.Action)`
  width: 8rem;
`
