import React, { useCallback } from 'react'
import { useLocation } from 'react-router'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { colours } from '@qflow/theme'
import * as ROUTES from 'portal/routes'
import {
  Spacer,
  Flex,
  icons,
  Clickable,
  SideBar,
  ControlPanel
} from 'portal/lib/primitives'

import { useMixpanel } from 'portal/utils/mixpanel'

import { LoggedInControl } from './LoggedInControl'
import { LoginButton } from './LoginButton'
import { ModuleSwitcher } from './ModuleSwitcher'
import { HelpLink } from './HelpLink'
import { ProjectSwitcher } from './ProjectSwitcher'
import { SupportLink } from './SupportLink'
import { useResponsive } from 'portal/lib/hooks'

const EV_NAVIGATION_LOGO_CLICK = 'frontend_navigation_logo_click'

export function Toolbar() {
  const { track } = useMixpanel()
  const { pathname } = useLocation()
  const trackLogoClick = useCallback(() => {
    track(EV_NAVIGATION_LOGO_CLICK, { from_path: pathname })
  }, [pathname, track])
  const isAuthenticated = useSelector(state => !!state.auth.userId)

  const { isMobile } = useResponsive()

  const SideBarState = SideBar.useContext()
  const ControlPanelState = ControlPanel.useContext()

  const sidebarEnabled = isAuthenticated && !ControlPanelState.mobileShow
  const controlPanelEnabled =
    ControlPanelState.isOnPage && !SideBarState.mobileShow

  const matchedRoute = useRouteMatch().path
  const invertedLogoPaths = [
    '/',
    ROUTES.SIGNIN_REDIRECT,
    ROUTES.ACCOUNTS,
    ROUTES.DELIVERY_RECORD_EDIT,
    ROUTES.DELIVERY_RECORD_LINE_ITEM_EDIT,
    ROUTES.WASTE_RECORD_EDIT,
    ROUTES.WASTE_RECORD_LINE_ITEM_EDIT
  ]
  const invertLogo = invertedLogoPaths.includes(matchedRoute)

  return (
    <Container isMobile={isMobile}>
      {isMobile ? (
        <>
          <SideBarButton
            onClick={() => SideBarState.setMobileShow(show => !show)}
            active={SideBarState.mobileShow}
            disabled={!sidebarEnabled}
          >
            <icons.Menu />
          </SideBarButton>

          <Flex.Row amount={1} css={FlexFillCSS} horizontalCentre>
            <MobileProjectSwitcher />
            <MobileModuleSwitcher />
          </Flex.Row>

          <SideBarButton
            onClick={() => ControlPanelState.setMobileShow(show => !show)}
            active={ControlPanelState.mobileShow}
            disabled={!controlPanelEnabled}
          >
            <icons.Settings />
          </SideBarButton>
        </>
      ) : (
        <>
          <LogoButton
            id="homeBtn"
            to={'/accounts'}
            onClick={trackLogoClick}
            $invertLogo={invertLogo}
          >
            <LogoIcon />
          </LogoButton>

          <Spacer.Small />

          <Flex.Row amount={1} css={FlexFillCSS}>
            <ProjectSwitcher />
            <ModuleSwitcher />
          </Flex.Row>

          <Flex.Row
            amount={0}
            css={FlexFillCSS}
            style={{ marginRight: '10px' }}
          >
            {isAuthenticated && <SupportLink />}
          </Flex.Row>

          <Flex.Row amount={0} css={FlexFillCSS}>
            {isAuthenticated && <HelpLink />}
          </Flex.Row>
          <Spacer.Fine />

          <Flex.Row amount={0} css={FlexFillCSS}>
            {isAuthenticated ? <LoggedInControl /> : <LoginButton />}
          </Flex.Row>

          <Spacer.Small />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 4rem;
  max-height: 4rem;
  z-index: 5000;

  ${({ isMobile }) =>
    isMobile &&
    css`
      min-height: 3rem;
      max-height: 3rem;
    `}

  background-color: ${colours.WHITE};
  color: ${colours.BLUEY_GREY};

  overflow: visible;
`

const LogoButton = styled(Link)`
  display: flex;
  align-items: center;

  height: 4rem;
  min-width: 14rem;
  max-width: 14rem;

  border: none;
  outline: none;
  cursor: pointer;

  color: ${({ $invertLogo }) =>
    $invertLogo ? colours.CORE_NAVY : colours.WHITE};
  background-color: ${({ $invertLogo }) =>
    $invertLogo ? colours.WHITE : colours.CORE_NAVY};

  &:hover {
    color: ${({ $invertLogo }) =>
      $invertLogo ? colours.CORE_NAVY : colours.WHITE};
  }
`

const LogoIcon = styled(icons.QflowLogo).attrs(() => ({
  style: {}
}))`
  margin-left: 20px;
  width: 40%;
  height: 40%;
  display: block;
`

const FlexFillCSS = css`
  align-self: stretch;
`

const SideBarButton = styled(Clickable)`
  width: 3rem;

  ${({ active, theme }) =>
    active &&
    css`
      :hover:enabled {
        background-color: ${theme.primary.highlightHover};
      }

      :active:enabled {
        background-color: ${theme.primary.highlightHover};
      }

      background-color: ${theme.primary.highlightHover};
    `}

  height: 100%;
  height: -webkit-fill-available;

  :disabled {
    opacity: 0.4;
  }

  border-radius: 0;
`

const MobileBarNavSegment = css`
  flex: 1 1 0;

  align-items: stretch;
`

const MobileProjectSwitcher = styled(ProjectSwitcher)`
  ${MobileBarNavSegment}

  justify-content: flex-end;

  max-width: 10rem;
`
const MobileModuleSwitcher = styled(ModuleSwitcher)`
  ${MobileBarNavSegment}

  justify-content: flex-start;

  max-width: 10rem;
`
