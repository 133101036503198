export const WASTE = 'Waste'
export const DELIVERY = 'Delivery'

interface SelectValuesObject {
  waste: any
  delivery: any
  default?: any
}

export function select(
  moduleName: string,
  values: SelectValuesObject = {
    waste: null,
    delivery: null,
    default: undefined
  }
) {
  const modulename = moduleName.toLowerCase()

  const match = values[modulename]

  if (match == null) {
    if (typeof values.default === 'undefined') {
      console.error('Unknown module ' + moduleName)
    }
    return values.default
  }

  if (typeof match === 'function') {
    return match()
  } else {
    return match
  }
}

export function isValidModule(moduleName: string | null | undefined) {
  if (!moduleName) {
    return false
  }
  return [WASTE, DELIVERY].includes(moduleName)
}

export function getModulePriority(moduleName = '') {
  const priorityList = ['delivery', 'waste']

  const priorityNumber = priorityList.indexOf(moduleName.toLowerCase())

  return priorityNumber > -1 ? priorityNumber : priorityList.length
}
