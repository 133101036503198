export const RECORDS_LOAD = 'RECORDS_LOAD'
export const loadRecords = (
  accountId,
  projectId,
  moduleName,
  continuationToken,
  recordsLoaded,
  pageSize,
  filterState,
  showRemovedRecords,
  useCreatedAtDate
) => {
  return {
    type: RECORDS_LOAD,
    accountId,
    projectId,
    moduleName,
    continuationToken,
    recordsLoaded,
    pageSize,
    filterState,
    showRemovedRecords,
    useCreatedAtDate
  }
}

export const RECORDS_ABORT = 'RECORDS_ABORT'

export const RECORDS_LOAD_SUCCESS = 'RECORDS_LOAD_SUCCESS'
export const loadRecordsSuccess = (
  moduleName,
  records,
  continuationToken,
  hasNextPage,
  projectId
) => {
  return {
    type: RECORDS_LOAD_SUCCESS,
    moduleName,
    records,
    continuationToken,
    hasNextPage,
    projectId
  }
}

export const RECORDS_LOAD_FAILURE = 'RECORDS_LOAD_FAILURE'
export const loadRecordsFailure = (moduleName, error) => {
  return {
    type: RECORDS_LOAD_FAILURE,
    moduleName,
    error
  }
}

export const RECORDS_COUNT_LOAD = 'RECORDS_COUNT_LOAD'
export const loadRecordsCount = (
  accountId,
  projectId,
  moduleName,
  filterState,
  showRemovedRecords,
  useCreatedAtDate
) => {
  return {
    type: RECORDS_COUNT_LOAD,
    accountId,
    projectId,
    moduleName,
    filterState,
    showRemovedRecords,
    useCreatedAtDate
  }
}

export const RECORDS_COUNT_LOAD_SUCCESS = 'RECORDS_COUNT_LOAD_SUCCESS'
export const loadRecordsCountSuccess = (moduleName, recordsCount) => {
  return {
    type: RECORDS_COUNT_LOAD_SUCCESS,
    moduleName,
    recordsCount
  }
}

export const RECORDS_COUNT_LOAD_FAILURE = 'RECORDS_COUNT_LOAD_FAILURE'
export const loadRecordsCountFailure = (moduleName, error) => {
  return {
    type: RECORDS_COUNT_LOAD_FAILURE,
    moduleName,
    error
  }
}

export const RECORDS_OPTIONS_LOAD = 'RECORDS_OPTIONS_LOAD'
export const loadOptions = (fieldName, accountId, projectId, moduleName) => {
  return {
    type: RECORDS_OPTIONS_LOAD,
    fieldName,
    accountId,
    projectId,
    moduleName
  }
}

export const RECORDS_OPTIONS_LOAD_SUCCESS = 'RECORDS_OPTIONS_LOAD_SUCCESS'
export const loadOptionsSuccess = (
  fieldName,
  moduleName,
  options,
  projectId
) => {
  return {
    type: RECORDS_OPTIONS_LOAD_SUCCESS,
    fieldName,
    moduleName,
    options,
    projectId
  }
}

export const RECORDS_OPTIONS_LOAD_FAILURE = 'RECORDS_OPTIONS_LOAD_FAILURE'
export const loadOptionsFailure = (fieldName, moduleName, error, projectId) => {
  return {
    type: RECORDS_OPTIONS_LOAD_FAILURE,
    fieldName,
    moduleName,
    error,
    projectId
  }
}

export const RECORDS_ASSIGNTO_OPTIONS = 'RECORDS_ASSIGNTO_OPTIONS'
export const assignToOptions = (
  fieldName,
  accountId,
  projectId,
  moduleName,
  options
) => {
  return {
    type: RECORDS_OPTIONS_LOAD_SUCCESS,
    fieldName,
    accountId,
    projectId,
    moduleName,
    options
  }
}

export const RECORDS_UNEXPORTED_RECORD_IDS_LOAD =
  'RECORDS_UNEXPORTED_RECORD_IDS_LOAD'
export const loadOneClickUnexportedRecordIds = (
  accountId,
  projectId,
  moduleName,
  recordIds
) => {
  return {
    type: RECORDS_UNEXPORTED_RECORD_IDS_LOAD,
    accountId,
    projectId,
    moduleName,
    recordIds
  }
}

export const RECORDS_UNEXPORTED_RECORD_IDS_LOAD_SUCCESS =
  'RECORDS_UNEXPORTED_RECORD_IDS_LOAD_SUCCESS'
export const loadOneClickUnexportedRecordIdsSuccess = (
  moduleName,
  unexportedRecordIds
) => {
  return {
    type: RECORDS_UNEXPORTED_RECORD_IDS_LOAD_SUCCESS,
    moduleName,
    unexportedRecordIds
  }
}

export const RECORDS_UNEXPORTED_RECORD_IDS_LOAD_FAILURE =
  'RECORDS_UNEXPORTED_RECORD_IDS_LOAD_FAILURE'
export const loadOneClickUnexportedRecordIdsFailure = (moduleName, error) => {
  return {
    type: RECORDS_UNEXPORTED_RECORD_IDS_LOAD_FAILURE,
    moduleName,
    error
  }
}

export const GET_ONECLICK_REPORTS_BEING_PROCESSED =
  'GET_ONECLICK_REPORTS_BEING_PROCESSED'
export const getOneClickReportsBeingProcessed = (
  accountId,
  projectId,
  moduleName
) => {
  return {
    type: GET_ONECLICK_REPORTS_BEING_PROCESSED,
    accountId,
    projectId,
    moduleName
  }
}

export const GET_ONECLICK_REPORTS_BEING_PROCESSED_SUCCESS =
  'GET_ONECLICK_REPORTS_BEING_PROCESSED_SUCCESS'
export const getOneClickReportsBeingProcessedSuccess = (
  moduleName,
  reportsBeingGenerated
) => {
  return {
    type: GET_ONECLICK_REPORTS_BEING_PROCESSED_SUCCESS,
    moduleName,
    reportsBeingGenerated
  }
}

export const GET_ONECLICK_REPORTS_BEING_PROCESSED_FAILURE =
  'GET_ONECLICK_REPORTS_BEING_PROCESSED_FAILURE'
export const getOneClickReportsBeingProcessedFailure = (moduleName, error) => {
  return {
    type: GET_ONECLICK_REPORTS_BEING_PROCESSED_FAILURE,
    moduleName,
    error
  }
}
