import { useRouteMatch, generatePath } from 'react-router'

import { WASTE_RECORD_DETAILS } from 'portal/routes'
import { PageContainer } from 'portal/pages/PageContainer'
import { LineItemEdit } from 'portal/components/RecordsModules'

export const WasteLineItemEdit = () => {
  const doneUri = useDoneRedirect()

  return (
    <PageContainer limitWidth>
      <LineItemEdit doneUri={doneUri} />
    </PageContainer>
  )
}

function useDoneRedirect() {
  const { params } = useRouteMatch()

  return generatePath(WASTE_RECORD_DETAILS, params)
}
