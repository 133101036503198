import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryParamProvider } from 'use-query-params'

import { App } from './app'
import { BugsnagErrorBoundary } from './app/utils/bugsnag'
import { initialiseMixpanel } from './app/utils/mixpanel'
import { initialiseStore } from './app/store'
import { StylesProvider as MuiStylesProvider } from '@material-ui/styles'
import { DarkThemeProvider } from '@qflow/theme'
import { PortalRootNode, SpinnerProvider } from 'portal/lib/primitives'

initialiseMixpanel()
const store = initialiseStore()

const app = (
  <BugsnagErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiStylesProvider injectFirst>
            <DarkThemeProvider>
              <SpinnerProvider>
                <>
                  <App />
                  <PortalRootNode />
                </>
              </SpinnerProvider>
            </DarkThemeProvider>
          </MuiStylesProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>
  </BugsnagErrorBoundary>
)

ReactDOM.render(app, document.getElementById('root'))
