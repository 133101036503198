import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'antd'
import * as Icons from '@ant-design/icons'

import {
  ColourButton,
  Flex,
  Label,
  Clickable,
  Spacer
} from 'portal/lib/primitives'
import DocIcon from './doc-icon.svg'
import ErrorIcon from './error-icon.svg'
import PdfIcon from './pdf-icon.svg'

import { trackAttachmentDownload, trackAttachmentPreview } from './mixpanel'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export const AttachmentsItem = ({ label, record, attachment, moduleType }) => {
  const [isPDF, setIsPDF] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()

  const [pdfPages, setPDFPages] = useState(1)
  const [pdfCurrentPage, setPDFCurrentPage] = useState(1)

  const [imgDimensions, setImgDimensions] = useState(null)
  const [imgContainerStyles, setImgContainerStyles] = useState(null)

  const [previewRotation, setPreviewRotation] = useState(0)
  const [zoomedIn, setZoomedIn] = useState(false)

  const rotationDegrees = [0, 90, 180, 270]

  const onPreviewed = useCallback(() => {
    dispatch(
      trackAttachmentPreview(
        params.accountId,
        params.projectId,
        params.module,
        moduleType,
        record
      )
    )
  }, [
    dispatch,
    params.accountId,
    params.projectId,
    params.module,
    moduleType,
    record
  ])

  const onDownloaded = useCallback(() => {
    dispatch(
      trackAttachmentDownload(
        params.accountId,
        params.projectId,
        params.module,
        moduleType,
        record
      )
    )
  }, [
    dispatch,
    params.accountId,
    params.projectId,
    params.module,
    moduleType,
    record
  ])

  const getFileIcon = (filename = '') => {
    if (!filename) {
      return ErrorIcon
    }

    const match = filename.match(/.*(\..*)$/)
    if (
      match &&
      ['.png', '.jpg', '.jpeg', '.gif', '.bmp'].includes(match[1].toLowerCase())
    ) {
      return filename
    } else if (match && ['.pdf'].includes(match[1].toLowerCase())) {
      setIsPDF(true)
      return PdfIcon
    }

    return DocIcon
  }

  const fileIcon = useMemo(() => {
    return getFileIcon(attachment.readUri)
  }, [attachment.readUri])

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const getImgSize = e => {
    const img = e.target
    setImgDimensions({
      width: img.width,
      height: img.height,
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight
    })
  }

  const handlePDFLoadSuccess = pdf => {
    setPDFPages(pdf?.numPages)
  }

  const handleRotationChange = turnDirection => {
    if (turnDirection === 1) {
      const rotationIndex = previewRotation + 1
      setPreviewRotation(
        rotationIndex >= rotationDegrees.length ? 0 : rotationIndex
      )
    } else if (turnDirection === -1) {
      const rotationIndex = previewRotation - 1
      setPreviewRotation(
        rotationIndex < 0 ? rotationDegrees.length - 1 : rotationIndex
      )
    }
  }

  const handleZoom = () => {
    setZoomedIn(prev => !prev)
  }

  const changePage = val => {
    setPDFCurrentPage(prev => prev + val)
  }

  useEffect(() => {
    if (imgDimensions) {
      let styleProps = { maxWidth: '752px' }

      if (!zoomedIn) {
        const biggestSide =
          imgDimensions.width > imgDimensions.height
            ? imgDimensions.width + 20
            : imgDimensions.height + 20
        styleProps.width = biggestSide
        styleProps.height = biggestSide
      } else {
        // ZoomedIn Styles
        const biggestSide =
          imgDimensions.naturalWidth > imgDimensions.naturalHeight
            ? imgDimensions.naturalWidth + 20
            : imgDimensions.naturalHeight + 20
        styleProps.maxWidth = 'calc(100vw - 90px)'
        styleProps.width = biggestSide
        styleProps.height = biggestSide
      }
      setImgContainerStyles(styleProps)
    }
  }, [imgDimensions, previewRotation, zoomedIn])

  const calcImgMarginTop = () => {
    if (imgDimensions && (previewRotation === 1 || previewRotation === 3)) {
      if (!zoomedIn) {
        const calc = (imgDimensions.width - imgDimensions.height) / 2
        return `${calc}px`
      } else {
        // ZoomedIn Styles
        const calc =
          (imgDimensions.naturalWidth - imgDimensions.naturalHeight) / 2
        return `${calc}px`
      }
    }

    return '0'
  }

  return (
    <>
      <Clickable
        style={{ height: 'auto' }}
        onClick={() => {
          setIsModalOpen(true)
          onPreviewed()
        }}
      >
        <Inner horizontalCenter>
          <img src={fileIcon} alt="thumbnail" />

          <Spacer.Tiny />

          <Label isChild>{label}</Label>
        </Inner>
      </Clickable>
      <Modal
        title={`Attachment ${label} Preview`}
        visible={isModalOpen}
        width={
          zoomedIn
            ? imgContainerStyles
              ? imgContainerStyles.width + 40
              : '66.7vw'
            : 800
        }
        bodyStyle={{ textAlign: 'center' }}
        onOk={() => {
          location.replace(attachment.readUri)
          onDownloaded()
        }}
        onCancel={handleCancel}
        footer={[
          <StyledControls key={`previewControls-${label}`}>
            <div>
              <ColourButton.Basic
                type="button"
                onClick={() => handleRotationChange(-1)}
                style={{ display: 'inline-block', marginRight: '.75rem' }}
                title="Rotate left"
              >
                <Icons.RotateLeftOutlined />
              </ColourButton.Basic>

              <ColourButton.Basic
                type="button"
                onClick={() => handleRotationChange(1)}
                style={{ display: 'inline-block', marginRight: '.75rem' }}
                title="Rotate right"
              >
                <Icons.RotateRightOutlined />
              </ColourButton.Basic>

              <ColourButton.Basic
                type="button"
                onClick={handleZoom}
                style={{ display: 'inline-block' }}
                title="Zoom in/out"
              >
                {!zoomedIn && <Icons.ZoomInOutlined />}
                {zoomedIn && <Icons.ZoomOutOutlined />}
              </ColourButton.Basic>
            </div>
            {pdfPages > 1 && (
              <div>
                <ColourButton.Basic
                  type="button"
                  onClick={() => changePage(-1)}
                  style={{ display: 'inline-block', marginRight: '.75rem' }}
                  title="Previous page"
                  disabled={pdfCurrentPage <= 1}
                >
                  <Icons.CaretLeftOutlined />
                </ColourButton.Basic>
                {pdfCurrentPage} of {pdfPages}
                <ColourButton.Basic
                  type="button"
                  onClick={() => changePage(1)}
                  style={{ display: 'inline-block', marginLeft: '.75rem' }}
                  title="Next page"
                  disabled={pdfCurrentPage >= pdfPages}
                >
                  <Icons.CaretRightOutlined />
                </ColourButton.Basic>
              </div>
            )}
            <ColourButton.Action
              type="button"
              onClick={() => {
                location.replace(attachment.readUri)
                onDownloaded()
              }}
              style={{ display: 'inline-block' }}
            >
              Download
            </ColourButton.Action>
          </StyledControls>
        ]}
      >
        {isPDF && (
          <StyledDocument
            file={attachment.readUri}
            rotate={rotationDegrees[previewRotation]}
            onLoadSuccess={handlePDFLoadSuccess}
          >
            <StyledPDFPage
              pageNumber={pdfCurrentPage}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              $zoomedIn={zoomedIn}
            />
          </StyledDocument>
        )}
        {!isPDF && (
          <ImageContainer style={imgContainerStyles}>
            <img
              src={fileIcon}
              alt="Attachment Preview"
              style={{
                maxWidth: zoomedIn ? 'fit-content' : '752px',
                maxHeight: zoomedIn ? 'fit-content' : 'calc(100vh - 285px)',
                rotate: `${rotationDegrees[previewRotation]}deg`,
                marginTop: calcImgMarginTop()
              }}
              onLoad={getImgSize}
            />
            {fileIcon.startsWith('data') && <div>Preview not available</div>}
          </ImageContainer>
        )}
      </Modal>
    </>
  )
}

AttachmentsItem.propTypes = {
  label: PropTypes.number,
  attachment: PropTypes.shape({
    readUri: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired,
  moduleType: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired
}

const Inner = styled(Flex.Col)`
  padding: 0.25rem 0;

  > img {
    max-height: 64px;
  }
`

const ImageContainer = styled.div`
  max-height: calc(100vh - 285px);
  margin: 4px auto 0;
  overflow: auto;
`

const StyledDocument = styled(Document)`
  max-height: ${({ rotate }) =>
    rotate === 0 || rotate === 180 ? 'calc(100vh - 285px)' : '800px'};
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledPDFPage = styled(Page)`
  position: relative;
  max-height: calc(100vh - 285px);
  max-width: ${({ $zoomedIn }) => ($zoomedIn ? '66.7vw' : '752px')};
  min-width: ${({ $zoomedIn }) =>
    $zoomedIn ? '66.7vw !important' : '752px !important'};
  margin-top: 4px;
  overflow: auto;

  & CANVAS {
    height: ${({ $zoomedIn }) =>
      $zoomedIn ? '100%  !important' : 'calc(100vh - 289px) !important'};
    width: ${({ $zoomedIn }) =>
      $zoomedIn ? 'calc(66.7vw - 40px) !important' : '100% !important'};
    object-fit: ${({ $zoomedIn }) => ($zoomedIn ? 'contain' : 'scale-down')};
  }
`

const StyledControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
