export const UPLOAD = 'RECORD_ATTACHMENT_UPLOAD'
export const uploadStart = (
  accountId,
  projectId,
  moduleName,
  recordId,
  file
) => ({
  type: UPLOAD,
  accountId,
  projectId,
  moduleName,
  recordId,
  file
})

export const UPLOAD_RESET = 'RECORD_ATTACHMENT_UPLOAD_RESET'
export const uploadReset = () => ({
  type: UPLOAD_RESET
})

export const UPLOAD_SUCCESS = 'RECORD_ATTACHMENT_UPLOAD_SUCCESS'
export const uploadSuccess = (
  accountId,
  projectId,
  moduleName,
  recordId,
  attachment
) => ({
  type: UPLOAD_SUCCESS,
  accountId,
  projectId,
  moduleName,
  recordId,
  attachment
})

export const UPLOAD_FAILURE = 'RECORD_ATTACHMENT_UPLOAD_FAILURE'
export const uploadFailure = (
  accountId,
  projectId,
  moduleName,
  recordId,
  error
) => ({
  type: UPLOAD_FAILURE,
  accountId,
  projectId,
  moduleName,
  recordId,
  error
})
