import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { colours, SPACING, mobileCSS, sizes } from '@qflow/theme'
import { Text, Spacer, Flex, icons } from 'portal/lib/primitives'
import { UpgradeToPro } from './UpgradeToPro'

const DetailBase = styled.div`
  border: 0px;
  border-bottom: 1px;
  border-style: ${({ divider = true }) => (divider ? 'solid' : 'none')};
  border-color: ${colours.WHITE_10};

  margin: 0;
  padding: 0 0.5rem;
  max-width: none;
`
const DetailEntry = styled(DetailBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  ${mobileCSS(css`
    flex-direction: column;
    align-items: flex-start;
  `)}

  padding-bottom: ${SPACING.TINY};
  padding-top: ${SPACING.TINY};
`

const DetailRowChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;

  ${mobileCSS(css`
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;

    margin-top: ${SPACING.FINE};
  `)}
`

const HeaderText = styled(Text)`
  display: flex;
  gap: 4px;
  color: ${colours.BLUEY_BLACK};
  font-weight: 500;
  width: 16rem;

  ${mobileCSS(css`
    width: auto;
  `)}
`

const InfractionIcon = styled(Text)`
  width: 1rem;
  ${mobileCSS(css`
    width: auto;
  `)}

  margin-right: ${SPACING.FINE};
`
const InfractionDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${mobileCSS(css`
    align-items: start;
    flex-direction: column;
  `)}
`

export const DetailRow = ({
  label,
  content,
  infraction,
  children,
  tooltip = null,
  upgradeToPro = false,
  divider = true
}) => {
  const tooltipStyles = {
    borderRadius: '5px',
    padding: '11px 13px',
    color: colours.CORE_NAVY,
    boxShadow: '0px 2px 8px 0px #00000038'
  }

  return (
    <DetailEntry divider={divider}>
      <HeaderText
        style={upgradeToPro ? { color: colours.BATTLESHIP_GREY } : {}}
      >
        <div>{label}</div>
        {tooltip && (
          <Tooltip
            title={tooltip}
            overlayInnerStyle={tooltipStyles}
            color={colours.WHITE}
            placement="right"
          >
            <icons.Info size={sizes.MEDIUM} />
          </Tooltip>
        )}
        {upgradeToPro && <UpgradeToPro />}
      </HeaderText>

      {!children && !content && infraction && !infraction.details && (
        <InfractionIcon>
          <icons.Flag colour={colours.RED} size={sizes.MEDIUM} />
        </InfractionIcon>
      )}

      {content && (
        <DetailRowChildrenContainer>
          <Flex.Row verticalCentre>
            <InfractionIcon>
              {infraction && !infraction.details && (
                <icons.Flag colour={colours.RED} size={sizes.MEDIUM} />
              )}
            </InfractionIcon>

            <Text>{content}</Text>
          </Flex.Row>
        </DetailRowChildrenContainer>
      )}

      {children && !content && (
        <DetailRowChildrenContainer>
          <Flex.Row>
            <InfractionIcon>
              {infraction && !infraction.details && (
                <icons.Flag colour={colours.RED} size={sizes.MEDIUM} />
              )}
            </InfractionIcon>

            <InfractionDetails>
              {children}
              {infraction && infraction.details && (
                <Flex.Row>
                  <Spacer.Fine />

                  <InfractionIcon>
                    <icons.Flag colour={colours.RED} size={sizes.MEDIUM} />
                  </InfractionIcon>

                  <Spacer.Fine />

                  <Text error title={infraction.details}>
                    {infraction.details}
                  </Text>
                </Flex.Row>
              )}
            </InfractionDetails>
          </Flex.Row>
        </DetailRowChildrenContainer>
      )}
    </DetailEntry>
  )
}

DetailRow.propTypes = {
  label: PropTypes.node,
  content: PropTypes.string,
  infraction: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  divider: PropTypes.bool
}
