import React from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import {
  icons,
  ColourButton,
  Label,
  NavButtonDecorator,
  Spacer
} from 'portal/lib/primitives'

import * as ADMIN_ROUTES from 'portal/routes-to-admin'

export const ViewInAdminButton = ({ record, accountId, moduleName }) => {
  const selfState = useSelector(state => state.auth)

  if (selfState && selfState.isQualisFlowInternal) {
    return (
      <NavButtonDecorator
        to={generatePath(ADMIN_ROUTES.RECORD.EDIT, {
          accountId: accountId,
          projectId: record.projectId,
          moduleName:
            moduleName.toLowerCase() === 'delivery' ? 'Delivery' : 'Waste',
          recordId: record.recordId
        })}
      >
        <ColourButton.Basic>
          <icons.NewTabIcon colour="currentColor" />
          <Spacer.Fine />
          <Label isChild colour="inherit">
            View in Admin Portal
          </Label>
        </ColourButton.Basic>
      </NavButtonDecorator>
    )
  }

  return null
}
