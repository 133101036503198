import {
  takeEvery,
  takeLeading,
  all,
  put,
  call,
  cancelled,
  take,
  race
} from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'

import {
  RECORDS_LOAD,
  RECORDS_ABORT,
  loadRecords,
  loadRecordsFailure,
  loadRecordsSuccess,
  loadOptionsSuccess,
  loadOptionsFailure,
  RECORDS_OPTIONS_LOAD,
  RECORDS_COUNT_LOAD,
  loadRecordsCountSuccess,
  loadRecordsCountFailure,
  RECORDS_UNEXPORTED_RECORD_IDS_LOAD,
  loadOneClickUnexportedRecordIdsSuccess,
  loadOneClickUnexportedRecordIdsFailure,
  getOneClickReportsBeingProcessedSuccess,
  getOneClickReportsBeingProcessedFailure,
  GET_ONECLICK_REPORTS_BEING_PROCESSED
} from './actions'
import {
  computeFilterParams,
  getModuleSpecificQueryParams
} from 'portal/store/records/filterSortParams/filterSortParams'
import * as removalActions from 'portal/store/records/removal/actions'
import { RECORD_STATUS } from 'portal/lib/constants'
import { REPORTDEFINITION } from 'portal/lib/constants/reportDefinitions'
import { stringify } from 'query-string'

function* loadRecordsSaga(action) {
  const {
    accountId,
    projectId,
    moduleName,
    continuationToken,
    recordsLoaded,
    pageSize,
    filterState,
    showRemovedRecords,
    useCreatedAtDate
  } = action
  let records = []
  let hasNextPage = false
  let newContinuationToken = null
  const abortController = new AbortController()
  try {
    let statusFilter = `${RECORD_STATUS.PUBLISHED},${RECORD_STATUS.AUTOPUBLISHED}`

    if (showRemovedRecords) {
      statusFilter = `${RECORD_STATUS.PUBLISHED},${RECORD_STATUS.AUTOPUBLISHED},${RECORD_STATUS.REMOVED}`
    }

    const response = yield call(() =>
      axios({
        method: 'GET',
        url: `/accounts/${accountId}/projects/${projectId}/modules/${moduleName}/records`,
        signal: abortController.signal,
        params: {
          'page[size]': pageSize,
          'filter[status]': statusFilter,
          ...getModuleSpecificQueryParams(moduleName, useCreatedAtDate),
          ...computeFilterParams(filterState, useCreatedAtDate)
        },
        paramsSerializer: params => {
          return stringify(params)
        },
        headers: { continuationToken: continuationToken },
        validateStatus: status => status === 200
      })
    )
    records = [...recordsLoaded, ...response.data.records]
    newContinuationToken = response.data.continuationToken
    hasNextPage = response.data.hasNextPage
  } catch (error) {
    yield put(loadRecordsFailure(moduleName, error))
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
  yield put(
    loadRecordsSuccess(moduleName, records, newContinuationToken, hasNextPage)
  )
}

function* loadRecordsCountSaga(action) {
  const {
    accountId,
    projectId,
    moduleName,
    filterState,
    showRemovedRecords,
    useCreatedAtDate
  } = action
  let recordsCount = 0
  const abortController = new AbortController()
  try {
    let statusFilter = `${RECORD_STATUS.PUBLISHED},${RECORD_STATUS.AUTOPUBLISHED}`

    if (showRemovedRecords) {
      statusFilter = `${RECORD_STATUS.PUBLISHED},${RECORD_STATUS.AUTOPUBLISHED},${RECORD_STATUS.REMOVED}`
    }

    const response = yield call(() =>
      axios({
        method: 'GET',
        url: `/accounts/${accountId}/projects/${projectId}/modules/${moduleName}/records/records-count`,
        signal: abortController.signal,
        params: {
          'page[size]': 1,
          'filter[status]': statusFilter,
          ...computeFilterParams(filterState, useCreatedAtDate)
        },
        paramsSerializer: params => {
          return stringify(params)
        },
        validateStatus: status => status === 200
      })
    )
    recordsCount = response.data
  } catch (error) {
    yield put(loadRecordsCountFailure(moduleName, error))
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
  yield put(loadRecordsCountSuccess(moduleName, recordsCount))
}

function* loadOneClickUnexportedRecordIdsSaga(action) {
  const { accountId, projectId, moduleName, recordIds } = action

  const abortController = new AbortController()
  let unexportedRecordIds = []
  try {
    const response = yield call(() =>
      axios({
        method: 'GET',
        url: `/accounts/${accountId}/projects/${projectId}/integrations/oneclick/viewrecordsalreadyexported`,
        signal: abortController.signal,
        headers: {
          recordIds: recordIds
        },
        validateStatus: status => status === 200
      })
    )
    unexportedRecordIds = response.data.nonExportedRecordIds
  } catch (error) {
    yield put(loadOneClickUnexportedRecordIdsFailure(moduleName, error))
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
  yield put(
    loadOneClickUnexportedRecordIdsSuccess(moduleName, unexportedRecordIds)
  )
}

function* loadOptionsSaga(action) {
  const { fieldName, accountId, projectId, moduleName } = action
  let options = []
  try {
    const response = yield call(() =>
      axios({
        method: 'GET',
        url: `/accounts/${accountId}/projects/${projectId}/modules/${moduleName}/records/fieldoptions`,
        params: {
          fieldName: 'data.' + fieldName,
          statuses: [
            RECORD_STATUS.PUBLISHED,
            RECORD_STATUS.REMOVED,
            RECORD_STATUS.AUTOPUBLISHED
          ]
        },
        paramsSerializer: params => {
          return stringify(params)
        },
        validateStatus: status => status === 200
      })
    )
    options = response.data.options
  } catch (error) {
    yield put(loadOptionsFailure(fieldName, moduleName, error, projectId))
  }

  yield put(loadOptionsSuccess(fieldName, moduleName, options, projectId))
}

function* reloadRecordsSaga(action) {
  const { accountId, projectId, moduleName } = action
  yield put(loadRecords(accountId, projectId, moduleName, null))
}

function getCreatedInLastHourFilterString() {
  const currentDateMinusHour = new Date()
  currentDateMinusHour.setHours(currentDateMinusHour.getHours() - 1)
  return (
    'ge:' +
    currentDateMinusHour.toISOString() +
    ', le:' +
    new Date().toISOString()
  )
}

function* getOneClickReportsBeingProcessedSaga(action) {
  const { accountId, projectId, moduleName } = action

  let reportsBeingGenerated = []
  try {
    const response = yield call(() =>
      axios.get(`/accounts/${accountId}/projects/${projectId}/reports`, {
        params: {
          'filter[ReportDefinitionId]': REPORTDEFINITION.ONECLICK,
          'filter[Status]': 'Processing,Pending',
          'filter[CreatedAt]': getCreatedInLastHourFilterString(),
          'page[size]': 1,
          'page[number]': 1
        }
      })
    )
    reportsBeingGenerated = response.data.reports
  } catch (error) {
    yield put(getOneClickReportsBeingProcessedFailure(moduleName, error))
  }

  yield put(
    getOneClickReportsBeingProcessedSuccess(moduleName, reportsBeingGenerated)
  )
}

export function* watch() {
  yield all([
    takeEvery(RECORDS_LOAD, function* (...args) {
      yield race({
        task: loadRecordsSaga(...args),
        cancel: take(RECORDS_ABORT)
      })
    }),
    takeEvery(RECORDS_COUNT_LOAD, function* (...args) {
      yield race({
        task: loadRecordsCountSaga(...args),
        cancel: take(RECORDS_ABORT)
      })
    }),
    takeEvery(RECORDS_OPTIONS_LOAD, loadOptionsSaga),
    takeEvery(
      RECORDS_UNEXPORTED_RECORD_IDS_LOAD,
      loadOneClickUnexportedRecordIdsSaga
    ),
    takeEvery(
      GET_ONECLICK_REPORTS_BEING_PROCESSED,
      getOneClickReportsBeingProcessedSaga
    ),
    takeLeading(removalActions.RECORDS_REMOVE_RECORD_SUCCESS, reloadRecordsSaga)
  ])
}
