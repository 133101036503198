import produce from 'immer'

import {
  QUEUE_EXPORT,
  QUEUE_EXPORT_SUCCESS,
  QUEUE_EXPORT_FAILURE,
  QUEUE_ONECLICK_EXPORT,
  QUEUE_ONECLICK_EXPORT_SUCCESS,
  QUEUE_ONECLICK_EXPORT_FAILURE
} from './actions'

const initialState = {
  working: false,
  oceWorking: false,
  success: false,
  error: null
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case QUEUE_EXPORT: {
        draft.working = true
        draft.oceWorking = false
        draft.success = false
        draft.error = null
        return
      }
      case QUEUE_EXPORT_SUCCESS: {
        draft.working = false
        draft.oceWorking = false
        draft.success = true
        draft.error = null
        return
      }
      case QUEUE_EXPORT_FAILURE: {
        draft.working = false
        draft.oceWorking = false
        draft.success = false
        draft.error = action.error
        return
      }
      case QUEUE_ONECLICK_EXPORT: {
        draft.working = false
        draft.oceWorking = true
        draft.success = false
        draft.error = null
        return
      }
      case QUEUE_ONECLICK_EXPORT_SUCCESS: {
        draft.working = false
        draft.oceWorking = false
        draft.success = true
        draft.error = null
        return
      }
      case QUEUE_ONECLICK_EXPORT_FAILURE: {
        draft.working = false
        draft.oceWorking = false
        draft.success = false
        draft.error = action.error
        return
      }
      default:
        return state
    }
  })
}

export { reducer }
