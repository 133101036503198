import { createSelector } from 'reselect'
import _ from 'lodash/fp'

export function selectProjectsForSwitcherFactory(
  currentModuleTagDefinitionName
) {
  return createSelector(
    state => state.projects.projects,
    projects => {
      if (!projects) {
        return {
          loading: false,
          error: false,
          projects: []
        }
      }

      return {
        loading: projects.isLoading,
        error: projects.error,
        projects: _.flow(
          _.filter(project => project.moduleTags.some(tag => tag.isTagged)),
          _.map(project => {
            const moduleIsAvailable = project.moduleTags.some(
              tag =>
                tag.isTagged &&
                tag.moduleTagDefinitionName.toLowerCase() ===
                  currentModuleTagDefinitionName?.toLowerCase()
            )

            let moduleName = currentModuleTagDefinitionName
            if (!moduleIsAvailable) {
              moduleName = project.moduleTags.find(
                moduleTag => moduleTag.isTagged
              ).moduleTagDefinitionName
            }

            moduleName = moduleName.toLowerCase()

            return {
              key: project.projectId,
              label: project.name,
              moduleName
            }
          }),
          _.sortBy(project => project.label)
        )(projects)
      }
    }
  )
}
