import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { biReportLoad } from 'portal/store/biReports/actions'
import { biReportByAccountAndName } from 'portal/store/biReports/selectors'

export function useBIReportLoader() {
  const dispatch = useDispatch()
  const { accountId, reportType } = useParams()

  const biReport = useSelector(biReportByAccountAndName(accountId, reportType))
  useEffect(() => {
    if (!biReport.loading && !biReport.loaded && !biReport.biReport) {
      dispatch(biReportLoad(accountId, reportType))
    }
  }, [
    accountId,
    biReport.biReport,
    biReport.loaded,
    biReport.loading,
    biReport.status,
    dispatch,
    reportType
  ])

  return biReport
}
