import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ColourButton } from 'portal/lib/primitives'
import { PageContainer } from 'portal/pages/PageContainer'
import Title from 'antd/lib/typography/Title'
import { Text } from 'portal/lib/primitives'

const goBackTwoPagesHandler = history => {
  history.go(-2)
}

export const Forbidden = props => {
  return (
    <Container limitWidth>
      <Title level={3}>You do not have permission to view this page.</Title>
      <Text>
        If you think you should be able to access this page, please contact your
        account administrator or email us at{' '}
        <a href="mailto:support@qualisflow.com">support@qualisflow.com</a>.
      </Text>
      <Button
        id="forbiddenBackBtn"
        onClick={() => goBackTwoPagesHandler(props.history)}
      >
        Go Back
      </Button>
    </Container>
  )
}

const Container = styled(PageContainer)`
  align-items: center;
  justify-content: center;
`

const Button = styled(ColourButton.Action)`
  margin-top: 5rem;

  min-width: 4rem;
  width: 25%;
  max-width: 15rem;
`

Forbidden.propTypes = {
  history: PropTypes.object
}
