import { useRef, useState, useEffect } from 'react'
import { debounce } from 'lodash'

function calculateDimensions(ref) {
  return ref.current
    ? ref.current.getBoundingClientRect()
    : { width: 0, height: 0 }
}

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const dimensionsRef = useRef()

  const { width, height } = calculateDimensions(dimensionsRef)
  useEffect(() => {
    setDimensions({ width, height })
  }, [height, width])

  useEffect(() => {
    const handleLayout = debounce(
      () => {
        const { width, height } = calculateDimensions(dimensionsRef)
        setDimensions({ width, height })
      },
      50,
      { trailing: true }
    )

    window.addEventListener('resize', handleLayout)
    return () => window.removeEventListener('resize', handleLayout)
  }, [])

  return {
    width: dimensions.width,
    height: dimensions.height,
    dimensionsRef
  }
}
