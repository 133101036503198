import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import * as Inputs from 'portal/lib/primitives/forms/TextInputs'
import * as Icons from 'portal/lib/primitives/icons'

export const SearchBox = forwardRef(
  ({ value, onChange, Icon = Icons.MagnifyingGlass, ...props }, ref) => {
    return (
      <Container>
        <Inputs.Text
          autoComplete="off"
          {...props}
          value={value}
          onChange={onChange}
          css={TextCSS}
          ref={ref}
        />

        <IconContainer>
          <Icon />
        </IconContainer>
      </Container>
    )
  }
)

SearchBox.propTypes = {
  ...Inputs.Text.propTypes,
  value: PropTypes.string,
  onChange: PropTypes.func
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;

  margin-bottom: 0.5rem;
`

const iconWidth = '2rem'

const TextCSS = css`
  padding-left: ${iconWidth};
`

const IconContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${iconWidth};

  display: flex;
  align-items: center;
  justify-content: center;
`
