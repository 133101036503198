import produce from 'immer'

import {
  EVENTS_LOAD,
  EVENTS_RELOAD,
  EVENTS_RELOAD_SUCCESS,
  EVENTS_LOAD_SUCCESS,
  EVENTS_LOAD_FAILURE,
  EVENTS_COUNT_LOAD,
  EVENTS_COUNT_RELOAD,
  EVENTS_COUNT_LOAD_SUCCESS,
  EVENTS_COUNT_LOAD_FAILURE,
  ASSIGN_RECORDS,
  ASSIGN_RECORDS_FAILURE,
  ASSIGN_RECORDS_SUCCESS,
  UNASSIGN_RECORDS,
  UNASSIGN_RECORDS_FAILURE,
  UNASSIGN_RECORDS_SUCCESS,
  DISMISS_EVENTS,
  DISMISS_EVENTS_FAILURE,
  DISMISS_EVENTS_SUCCESS,
  EVENTS_FILTER_LOAD,
  EVENTS_FILTER_LOAD_SUCCESS,
  EVENTS_FILTER_LOAD_FAILURE
} from './actions'

const DEFAULT_MODULE_STATE = {
  loading: false,
  error: null,
  items: [],
  continuationToken: null,
  count: 0,
  loadingCount: true,
  errorCount: null
}
const DEFAULT_MODULE_FILTER_STATE = {
  loading: false,
  error: null,
  description: [],
  tradeContractor: [],
  wasteCarrier: [],
  subcontractor: [],
  supplier: [],
  assigneeIds: []
}

const initialState = {
  other: DEFAULT_MODULE_STATE,
  waste: DEFAULT_MODULE_STATE,
  wasteFilter: DEFAULT_MODULE_FILTER_STATE,
  delivery: DEFAULT_MODULE_STATE,
  deliveryFilter: DEFAULT_MODULE_FILTER_STATE,
  assign: {
    loading: false,
    error: null
  },
  unassign: {
    loading: false,
    error: null
  },
  dismissal: {
    loading: false,
    error: null
  }
}

export const reducer = (state = initialState, action) => {
  const { moduleName } = action

  return produce(state, draft => {
    switch (action.type) {
      case EVENTS_LOAD: {
        draft[moduleName].loading = true
        draft[moduleName].error = null
        return
      }
      case EVENTS_RELOAD: {
        draft[moduleName].error = null
        return
      }
      case EVENTS_RELOAD_SUCCESS: {
        if (action.events.length >= draft[moduleName].items.length) {
          draft[moduleName].items = action.events
          draft[moduleName].continuationToken = action.continuationToken
        }
        return
      }
      case EVENTS_LOAD_SUCCESS: {
        draft[moduleName].loading = false
        draft[moduleName].error = null

        draft[moduleName].items = action.events
        draft[moduleName].continuationToken = action.continuationToken
        return
      }
      case EVENTS_LOAD_FAILURE: {
        draft[moduleName].loading = false
        draft[moduleName].error = action.error
        return
      }
      case EVENTS_COUNT_LOAD: {
        draft[moduleName].loadingCount = true
        draft[moduleName].errorCount = null
        return
      }
      case EVENTS_COUNT_RELOAD: {
        draft[moduleName].errorCount = null
        return
      }
      case EVENTS_COUNT_LOAD_SUCCESS: {
        draft[moduleName].loadingCount = false
        draft[moduleName].errorCount = null

        draft[moduleName].count = action.count.total
        draft[moduleName].allEvents = action.count.eventCountEntries
        return
      }
      case EVENTS_COUNT_LOAD_FAILURE: {
        draft[moduleName].loadingCount = false
        draft[moduleName].errorCount = action.error
        return
      }
      case ASSIGN_RECORDS: {
        draft.assign.loading = true
        draft.assign.error = null
        return
      }
      case ASSIGN_RECORDS_FAILURE: {
        draft.assign.loading = false
        draft.assign.error = action.error
        return
      }
      case ASSIGN_RECORDS_SUCCESS: {
        draft.assign.loading = false
        draft.assign.error = null
        return
      }
      case UNASSIGN_RECORDS: {
        draft.unassign.loading = true
        draft.unassign.error = null
        return
      }
      case UNASSIGN_RECORDS_FAILURE: {
        draft.unassign.loading = false
        draft.unassign.error = action.error
        return
      }
      case UNASSIGN_RECORDS_SUCCESS: {
        draft.unassign.loading = false
        draft.unassign.error = null
        return
      }
      case DISMISS_EVENTS: {
        draft.dismissal.loading = true
        draft.dismissal.error = null
        return
      }
      case DISMISS_EVENTS_FAILURE: {
        draft.dismissal.loading = false
        draft.dismissal.error = action.error
        return
      }
      case DISMISS_EVENTS_SUCCESS: {
        draft.dismissal.loading = false
        draft.dismissal.error = null
        return
      }
      case EVENTS_FILTER_LOAD: {
        draft[`${moduleName}Filter`].loading = true
        draft[`${moduleName}Filter`].error = null
        return
      }
      case EVENTS_FILTER_LOAD_SUCCESS: {
        draft[`${moduleName}Filter`].loading = false
        draft[`${moduleName}Filter`].error = null
        draft[`${moduleName}Filter`].description =
          action.filterOptions.description
        draft[`${moduleName}Filter`].tradeContractor =
          action.filterOptions.tradeContractor
        draft[`${moduleName}Filter`].supplier = action.filterOptions.supplier
        draft[`${moduleName}Filter`].wasteCarrier =
          action.filterOptions.wasteCarrier
        draft[`${moduleName}Filter`].subcontractor =
          action.filterOptions.subcontractor
        draft[`${moduleName}Filter`].assigneeIds =
          action.filterOptions.assigneeIds
        return
      }
      case EVENTS_FILTER_LOAD_FAILURE: {
        draft[`${moduleName}Filter`].loading = false
        draft[`${moduleName}Filter`].error = action.error
        return
      }
    }
  })
}
