import { trackMixpanel } from 'portal/store/mixpanel/actions'

const EV_RECORDS_ATTACHMENT_UPLOAD = 'frontend_records_attachment_upload'

export function trackAttachmentUpload(
  accountId,
  projectId,
  moduleName,
  record
) {
  return trackMixpanel(EV_RECORDS_ATTACHMENT_UPLOAD, {
    account_id: accountId,
    project_id: projectId,
    module: moduleName,
    record_id: record.recordId
  })
}
