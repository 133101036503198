import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function Cross({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      style={{ width: size, height: size }}
      {...props}
    >
      <defs>
        <path
          id="a"
          xmlns="http://www.w3.org/2000/svg"
          d="M11.8333 1.34166L10.6583 0.166656L5.99996 4.82499L1.34163 0.166656L0.166626 1.34166L4.82496 5.99999L0.166626 10.6583L1.34163 11.8333L5.99996 7.17499L10.6583 11.8333L11.8333 10.6583L7.17496 5.99999L11.8333 1.34166Z"
        />
      </defs>
      <use fill={colour || colours.WHITE} fillRule="nonzero" xlinkHref="#a" />
    </svg>
  )
}

Cross.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
