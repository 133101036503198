import React from 'react'
import PropTypes from 'prop-types'

/**
 * @param {import('@material-ui/icons').SvgIconComponent} Icon
 */
export function MuiIconWrapperHOC(Icon) {
  const HOC = function ({ colour, size }) {
    return <Icon style={{ fontSize: size, color: colour ?? 'currentColor' }} />
  }

  HOC.propTypes = {
    colour: PropTypes.string,
    size: PropTypes.string
  }

  return HOC
}
