export const ACCOUNT_LOAD = 'ACCOUNT_LOAD'
export const accountLoad = accountId => ({
  type: ACCOUNT_LOAD,
  accountId
})

export const ACCOUNT_LOADED = 'ACCOUNT_LOADED'
export const accountLoaded = (accountId, createdAt, name, members) => ({
  type: ACCOUNT_LOADED,
  accountId,
  createdAt,
  name,
  members
})

export const ACCOUNT_LOAD_FAILURE = 'ACCOUNT_LOAD_FAILURE'
export const accountLoadFailure = error => ({
  type: ACCOUNT_LOAD_FAILURE,
  error
})
