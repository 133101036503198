import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModuleIds } from 'portal/lib/hooks'

import { dashboardLoad } from 'portal/store/dashboard/actions'
import { dashboardByIds } from 'portal/store/dashboard/selectors'

export function useDashboardLoader() {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const dash = useSelector(dashboardByIds(accountId, projectId, moduleName))
  useEffect(() => {
    if (!dash.loading && !dash.loaded && !dash.uri) {
      dispatch(dashboardLoad(accountId, projectId, moduleName))
    }
  }, [
    accountId,
    dash.loaded,
    dash.loading,
    dash.uri,
    dispatch,
    moduleName,
    projectId
  ])

  return dash
}
