import React from 'react'
import PropTypes from 'prop-types'
import { SPACING, sizes } from '@qflow/theme'
import {
  Flex,
  ClickableLink,
  Spacer,
  icons,
  Label
} from 'portal/lib/primitives'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'

import { style } from './AccessGrid-style'

const useStyles = makeStyles(() => style)

export const AccessGrid = ({ items, avatarBG }) => {
  const classes = useStyles()

  return (
    <Flex.Col>
      {items.map(gridItem => {
        return (
          <ClickableLink
            key={gridItem.gridItemId}
            id={gridItem.gridItemId}
            to={gridItem.urlLink}
            style={{ marginBottom: SPACING.TINY, padding: SPACING.FINE }}
          >
            <Flex.Row verticalCentre>
              <Avatar
                className={classes.gridItemAvatar}
                style={{ backgroundColor: avatarBG }}
              >
                {gridItem.avatar}
              </Avatar>

              <Spacer.Small />

              <Flex.By amount={1}>
                <Label isChild size={sizes.MEDIUM}>
                  {gridItem.name}
                </Label>
              </Flex.By>

              <Spacer.Small />

              <icons.ChevronRight size={sizes.MEDIUM} />
            </Flex.Row>
          </ClickableLink>
        )
      })}
    </Flex.Col>
  )
}

AccessGrid.propTypes = {
  avatarBG: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}
