import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Clickable } from 'portal/lib/primitives/buttons/Clickable'
import { Text } from './Text'

/**
 * @deprecated you should use TextLink instead which extends <Link /> with Qflow styles
 */
export const ClickableText = forwardRef(
  ({ active = false, onClick, children, ...props }, ref) => {
    return (
      <ComponentButton
        id="ClickableText"
        active={active}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        <ComponentLabel id="ClickableTextLabel">{children}</ComponentLabel>
      </ComponentButton>
    )
  }
)

ClickableText.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}

const ComponentButton = styled(Clickable)`
  display: inline-block;
  height: auto;
`

const ComponentLabel = styled(Text)`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.primary.highlight};

  margin: 0 0.25rem;
`
