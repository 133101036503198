import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function AddPerson({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      style={{ width: size, height: size, marginRight: '4px' }}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.3333 5.99999V3.99999H12V5.99999H9.99996V7.33332H12V9.33332H13.3333V7.33332H15.3333V5.99999H13.3333ZM5.99996 7.99999C7.47329 7.99999 8.66663 6.80666 8.66663 5.33332C8.66663 3.85999 7.47329 2.66666 5.99996 2.66666C4.52663 2.66666 3.33329 3.85999 3.33329 5.33332C3.33329 6.80666 4.52663 7.99999 5.99996 7.99999ZM5.99996 3.99999C6.73329 3.99999 7.33329 4.59999 7.33329 5.33332C7.33329 6.06666 6.73329 6.66666 5.99996 6.66666C5.26663 6.66666 4.66663 6.06666 4.66663 5.33332C4.66663 4.59999 5.26663 3.99999 5.99996 3.99999ZM10.26 9.70666C9.13996 9.13332 7.68663 8.66666 5.99996 8.66666C4.31329 8.66666 2.85996 9.13332 1.73996 9.70666C1.07329 10.0467 0.666626 10.7333 0.666626 11.48V13.3333H11.3333V11.48C11.3333 10.7333 10.9266 10.0467 10.26 9.70666ZM9.99996 12H1.99996V11.48C1.99996 11.2267 2.13329 11 2.34663 10.8933C3.13996 10.4867 4.41996 9.99999 5.99996 9.99999C7.57996 9.99999 8.85996 10.4867 9.65329 10.8933C9.86662 11 9.99996 11.2267 9.99996 11.48V12Z"
        fill={colour || theme.secondary.fg}
      />
    </svg>
  )
}

AddPerson.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
