import { NavButton } from './NavButton'
import { Divider } from './Divider'
import {
  PortalProvider,
  PortalExit,
  Show,
  useContext
} from './ControlPanelPortal'

export const ControlPanel = {
  NavButton,
  Divider,
  PortalProvider,
  PortalExit,
  Show,
  useContext
}
