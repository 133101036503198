/**
 * @returns {{ key: string, label: string }}
 */
export function item(key, label = null) {
  return {
    key,
    label: label ?? key
  }
}

export const STATUS = {
  Closed: item('Closed'),
  Open: item('Open')
}

export const SOURCE_TYPE = {
  MassUpdate: item('MassUpdate', 'Data Import'),
  Infraction: item('Infraction', 'Issue'),
  Report: item('Report')
}
