import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function Edit({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="10"
      viewBox="0 0 9 10"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        fill={colour || theme.secondary.fg}
        fillRule="nonzero"
        d="M2.636 1.667h3.471c.107 0 .193-.094.193-.209v-.416c0-.345-.26-.625-.579-.625h-.684A.763.763 0 0 0 4.371 0a.763.763 0 0 0-.665.417H3.02c-.319 0-.578.28-.578.625v.416c0 .115.086.209.193.209zm5.11 3.42a.212.212 0 0 0 .097-.18V1.458c0-.344-.26-.625-.579-.625H6.88c-.107 0-.193.094-.193.209v.416c0 .345-.26.625-.579.625H2.636c-.32 0-.579-.28-.579-.625v-.416c0-.115-.086-.209-.193-.209H1.48c-.32 0-.579.28-.579.625v7.917c0 .345.26.625.579.625h2.7c.106 0 .192-.093.192-.208v-.834c0-.164.062-.325.17-.442l3.085-3.333a.596.596 0 0 1 .12-.096zm1.198 1.224l-.772-.833a.183.183 0 0 0-.273 0l-.634.685 1.044 1.128.635-.686a.22.22 0 0 0 0-.294zm-4.13 2.5a.219.219 0 0 0-.057.147v.834c0 .115.086.208.193.208h.771c.052 0 .1-.022.137-.06l2.178-2.353-1.044-1.128L4.813 8.81z"
      />
    </svg>
  )
}

Edit.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
