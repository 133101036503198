import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import styled from 'styled-components'

import {
  loadOptions,
  loadRecords,
  loadRecordsCount,
  RECORDS_ABORT
} from 'portal/store/records/actions'

import RecordsTable from 'portal/components/RecordsTable/RecordsTable'
import { otherAssignedToFilters } from 'portal/lib/functions'
import { useModuleIds, useResponsive } from 'portal/lib/hooks'
import { icons, SmallSpinner } from 'portal/lib/primitives'

export function recordsComponentFactory({
  ModuleName,
  dateField,
  buildRecordsForDisplay,
  buildColumnsForTable,
  initialFilterState,
  pageSize = 100
}) {
  const modulename = ModuleName.toLowerCase()

  function SubComponent({
    startDate,
    endDate,
    onFiltersUpdated,
    showRemovedRecords,
    useCreatedAtDate,
    updateUseCreatedAtDate
  }) {
    const { isMobile, isSmall } = useResponsive()
    const dispatch = useDispatch()
    const recordsState = useSelector(state => {
      return state.records.byModule[modulename]
    })
    const projectState = useSelector(state => state.projectDetails)
    const projectUsers = useSelector(state => state.projectUsers)

    const checkForStoredFilters = () => {
      // Return stored filters or default to initialFilterState
      const retrievedFilters = JSON.parse(
        sessionStorage.getItem(`${modulename}-filters`)
      )
      return retrievedFilters !== null ? retrievedFilters : initialFilterState
    }

    const [filters, setFilters] = useState(checkForStoredFilters())
    const [assignableUsers, setAssignableUsers] = useState([])

    const updateFilters = useCallback((field, value) => {
      setFilters(
        produce(draft => {
          draft[field] = value
        })
      )
    }, [])

    useEffect(() => {
      updateFilters(dateField, { startDate: startDate, endDate: endDate })
    }, [startDate, endDate, updateFilters])

    useEffect(() => {
      sessionStorage.setItem(`${modulename}-filters`, JSON.stringify(filters))
    }, [filters])

    useEffect(() => {
      onFiltersUpdated(filters)
    }, [filters, onFiltersUpdated])

    // Reformat ProjectUsers for use in AssigneeIds filter
    useEffect(() => {
      const tempArray = Object.values(projectUsers.users)
        .map(({ userId: key, displayName: label }) => ({
          key,
          label
        }))
        .sort((a, b) => {
          const fa = a.label.toLowerCase()
          const fb = b.label.toLowerCase()

          if (fa < fb) {
            return -1
          } else if (fa > fb) {
            return 1
          }
          return 0
        })

      setAssignableUsers([...otherAssignedToFilters, ...tempArray])
    }, [projectUsers])

    const loading = recordsState.loading || projectState.isLoading
    const continuationToken = recordsState.continuationToken
    const recordsLoaded = recordsState.items

    const { accountId, projectId } = useModuleIds()

    const loadFieldOption = useCallback(
      name => {
        dispatch(loadOptions(name, accountId, projectId, modulename))
      },
      [accountId, dispatch, projectId]
    )

    const assignedToOptions = useCallback(() => {
      dispatch({
        type: 'RECORDS_ASSIGNTO_OPTIONS',
        accountId,
        projectId,
        fieldName: 'assignedTo',
        moduleName: modulename,
        options: assignableUsers
      })
    }, [accountId, assignableUsers, dispatch, projectId])

    useEffect(() => {
      if (projectState.modules) {
        dispatch({ type: RECORDS_ABORT })
        dispatch(
          loadRecords(
            accountId,
            projectId,
            modulename,
            null,
            [],
            pageSize,
            filters,
            showRemovedRecords,
            useCreatedAtDate
          )
        )
        dispatch(
          loadRecordsCount(
            accountId,
            projectId,
            modulename,
            filters,
            showRemovedRecords,
            useCreatedAtDate
          )
        )
      }
    }, [
      accountId,
      dispatch,
      projectState,
      projectState.modules,
      projectId,
      filters,
      showRemovedRecords,
      useCreatedAtDate
    ])

    if (recordsState.error || projectState.error) {
      return <div>Error, please try reloading the page</div>
    }

    const tableId = `${ModuleName}RecordsTable`
    const columns = buildColumnsForTable(
      {
        isMobile,
        isSmall,
        size: (function () {
          if (isMobile) return 1
          if (isSmall) return 2
          return 3
        })()
      },
      recordsState,
      loadFieldOption,
      assignedToOptions,
      filters,
      updateFilters,
      projectId,
      tableId,
      accountId,
      useCreatedAtDate,
      updateUseCreatedAtDate
    ).filter(Boolean)

    function handlePageChange() {
      if (continuationToken === null) {
        return
      }

      dispatch(
        loadRecords(
          accountId,
          projectId,
          modulename,
          continuationToken,
          recordsLoaded,
          pageSize,
          filters,
          showRemovedRecords,
          useCreatedAtDate
        )
      )
    }

    function showTotalRecords() {
      if (recordsState.countLoading || recordsState.count === null) {
        return <SmallSpinner />
      }
      if (recordsState.countError !== null) {
        return 'Error fetching total records'
      }
      return (
        <ShowingRecordsText data-testid="totalInPage">
          Showing {recordsState.items.length} out of {recordsState.count} total
          record{recordsState.count === 1 ? '' : 's'}
        </ShowingRecordsText>
      )
    }

    const builtRecords = buildRecordsForDisplay(
      recordsLoaded,
      projectState?.modules?.timeZone
    )

    return (
      <RecordsTable
        id={tableId}
        columns={columns}
        data={addFilterRow(builtRecords.data)}
        handlePageChange={handlePageChange}
        loading={loading}
        count={recordsState.count}
        showTotal={showTotalRecords}
        updateFilters={updateFilters}
        hasNextPage={recordsState.hasNextPage}
        footer={
          recordsState && recordsState.count <= 0 ? (
            <ZeroRecordsFooter>
              <icons.ManageSearch />
              <div>
                No records matched your search. Try increasing the date range.
              </div>
            </ZeroRecordsFooter>
          ) : null
        }
      />
    )
  }

  SubComponent.displayName = `${ModuleName}Records`

  SubComponent.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object
  }

  return SubComponent
}

function addFilterRow(data) {
  return [{ recordIdRaw: 1 }, ...data]
}

const ShowingRecordsText = styled.div`
  margin: 1rem 0.5rem;
  text-align: center;
`

const ZeroRecordsFooter = styled.div`
  display: flex;
  justify-content: center;
`
