import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { SPACING, sizes } from '@qflow/theme'
import * as ButtonBase from 'portal/lib/primitives/buttons/ButtonBase'
import { Flex } from 'portal/lib/primitives/layout/Flex'
import * as icons from 'portal/lib/primitives/icons'
import { Spacer } from './layout'

export const PillBox = styled(Flex.Row)`
  flex-flow: wrap;

  flex: 0 1 auto;

  margin-top: -${SPACING.FINE};
  > * {
    margin-top: ${SPACING.FINE} !important;
  }

  ${ButtonBase.getShapeCSS()}
  max-height: none;
`

export function Pill({ children, onClick, size = 'small', ...props }) {
  const closable = !!onClick

  return (
    <Container
      {...props}
      size={size}
      onClick={onClick}
      disabled={!closable}
      title={children}
    >
      <Span>{children}</Span>

      {closable && (
        <>
          <Spacer.Tiny />
          <Cross size={sizes.TINY} />
        </>
      )}
    </Container>
  )
}

Pill.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string
}

const Container = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: ({ theme }) => theme.pill.bg,
    getBgHover: ({ theme }) => theme.pill.bgHover,
    getBgActive: ({ theme }) => theme.pill.bgActive,
    getBgDisabled: ({ theme }) => theme.pill.bg,
    getFg: ({ theme }) => theme.pill.fg,
    getFgDisabled: ({ theme }) => theme.pill.fgDisabled
  })}
  ${ButtonBase.getShapeCSS()}
  ${ButtonBase.getBorderCSS({
    withGlow: false,
    getBorderFgHover: () => 'transparent'
  })}
  ${ButtonBase.TakeCSSProp}

  flex: 0 0 auto;
  margin-right: ${SPACING.FINE};
  :last-child {
    margin-right: 0;
  }

  text-transform: uppercase;

  max-width: 12rem;
`

const Span = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Cross = styled(icons.Cross)`
  ${({ size }) =>
    size &&
    css`
      min-width: ${size};
      max-width: ${size};
    `}
`
