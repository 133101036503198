import { trackMixpanel } from 'portal/store/mixpanel/actions'

const EV_RECORDS_ATTACHMENT_PREVIEW = 'frontend_records_attachment_preview'

export function trackAttachmentPreview(
  accountId,
  projectId,
  moduleName,
  moduleType,
  record
) {
  return trackMixpanel(EV_RECORDS_ATTACHMENT_PREVIEW, {
    module_type: moduleType,
    record_type: moduleName || moduleType,

    account_id: accountId,
    project_id: projectId,
    record_id: record.recordId
  })
}
