import * as actions from './actions'
import produce from 'immer'

const initialState = {
  accounts: null,
  error: null,
  isLoading: false
}

const accsGetStart = draft => {
  draft.error = null
  draft.accounts = null
  draft.isLoading = true
}

const accsGetSuccess = (draft, action) => {
  draft.accounts = action.accounts
  draft.error = null
  draft.isLoading = false
}

const accsGetFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.ACCS_GET_START:
        return accsGetStart(draft, action)
      case actions.ACCS_GET_SUCCESS:
        return accsGetSuccess(draft, action)
      case actions.ACCS_GET_FAIL:
        return accsGetFail(draft, action)
      default:
        return state
    }
  })
}

export { reducer }
