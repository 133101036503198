import React, { useState, useRef, useCallback, useMemo } from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment'

import { DatePickerCSSOverrides } from './DatePickerCSSOverrides'
import { useResponsive } from 'portal/lib/hooks'
import { PopupCard } from 'portal/lib/primitives/portals'

import * as TextInputs from './TextInputs'
import { useDateSupport } from 'portal/lib/hooks/useDateSupport'

const HTML_PICKER_FORMAT = 'YYYY-MM-DD'

export function DatePicker({
  id = 'DatePicker',
  date = '',
  onChange,
  minDate = null,
  maxDate = null,
  placeholder = 'Date',
  disabled = false,
  forceNativePicker = false,
  forceNoNativeSupport = false,
  ...props
}) {
  const { isMobile } = useResponsive()
  const nativeDateInputSupported =
    useDateSupport().nativeDateInputSupported && !forceNoNativeSupport
  const displayNativePicker =
    (isMobile && nativeDateInputSupported) || forceNativePicker

  const [open, setOpen] = useState(false)
  const anchorElement = useRef()

  const handleDateEntryChange = useCallback(
    e => {
      const value = e.target.value
      if (typeof value === 'string' && value.length > 0) {
        onChange?.(moment(value, HTML_PICKER_FORMAT).toISOString(true))
      } else {
        onChange?.(null)
      }
    },
    [onChange]
  )

  const handleDatePickerSelect = useCallback(
    momentDate => {
      setOpen(false)
      onChange?.(momentDate.toISOString(true))
    },
    [onChange]
  )

  const datePickerDate = useMemo(() => {
    if (!date) {
      return null
    }

    return moment(date, HTML_PICKER_FORMAT)
  }, [date])

  const valueDateStr = useMemo(() => {
    if (displayNativePicker) {
      if (!date) {
        return ''
      }

      return moment(date).format(HTML_PICKER_FORMAT)
    } else {
      if (!date) {
        return ''
      }

      return moment(date).toDate().toLocaleDateString()
    }
  }, [date, displayNativePicker])

  const minDateStr = useMemo(
    () =>
      minDate && minDate.length > 0
        ? moment(minDate).format(HTML_PICKER_FORMAT)
        : null,
    [minDate]
  )

  const maxDateStr = useMemo(
    () =>
      maxDate && maxDate.length > 0
        ? moment(maxDate).format(HTML_PICKER_FORMAT)
        : null,
    [maxDate]
  )

  const handlePickerIsOutsideRange = useMemo(() => {
    const minDate = moment(minDateStr)
    const maxDate = moment(maxDateStr)

    const minDateValid = minDate.isValid()
    const maxDateValid = maxDate.isValid()

    return day => {
      if (minDateValid && maxDateValid) {
        return !day.isBetween(minDate, maxDate, 'day', '[]')
      }
      if (maxDateValid) {
        return day.isAfter(maxDate)
      }
      if (minDateValid) {
        return day.isBefore(minDate)
      }
      return false
    }
  }, [maxDateStr, minDateStr])

  return (
    <Container
      id={id}
      {...props}
      onKeyDown={e => {
        if (e.key === 'Escape' && open) {
          setOpen(false)
        }
      }}
    >
      <StyledTextInput
        id="DateInput"
        type={displayNativePicker ? 'date' : 'text'}
        value={valueDateStr}
        placeholder={placeholder}
        min={minDateStr}
        max={maxDateStr}
        onChange={handleDateEntryChange}
        readOnly={!displayNativePicker}
        onFocus={() => {
          if (!displayNativePicker) {
            setOpen(true)
          }
        }}
        disabled={disabled}
        ref={anchorElement}
      />

      <StyledPopupCard
        id="DatePickerCard"
        show={open}
        awaitLaggyLayout
        anchorElementRef={anchorElement}
        onOutsideClick={() => setOpen(false)}
      >
        {open && (
          <DayPickerSingleDateController
            date={datePickerDate}
            focused
            firstDayOfWeek={1}
            isOutsideRange={handlePickerIsOutsideRange}
            onDateChange={handleDatePickerSelect}
            hideKeyboardShortcutsPanel
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
              <MonthYearContainer>
                <div>
                  <Select
                    defaultValue={month.month()}
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                      onMonthSelect(month, value)
                    }}
                    options={moment
                      .months()
                      .map((label, value) => ({ value, label }))}
                    dropdownStyle={{ zIndex: '10001' }}
                  />
                </div>
                <div>
                  <Select
                    defaultValue={month.year()}
                    dropdownMatchSelectWidth={false}
                    onChange={value => {
                      onYearSelect(month, value)
                    }}
                    options={Array.from(
                      { length: moment().year() - 2018 + 1 },
                      (_, i) => 2018 + i
                    ).map(value => ({ value, label: value }))}
                    dropdownStyle={{ zIndex: '10001' }}
                  />
                </div>
              </MonthYearContainer>
            )}
          />
        )}
      </StyledPopupCard>
    </Container>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string,
  date: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func,
  displayFormat: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  forceNativePicker: PropTypes.bool,
  forceNoNativeSupport: PropTypes.bool
}

const Container = styled.div`
  display: flex;
  flex: 1 0 0;
`

const StyledTextInput = styled(TextInputs.Input)`
  white-space: nowrap;
`

const StyledPopupCard = styled(PopupCard)`
  ${DatePickerCSSOverrides}
`

const MonthYearContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: -5px;
`

PopupCard.propTypes = {
  y: PropTypes.number,
  x: PropTypes.number
}
