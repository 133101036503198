import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selfLoad } from 'portal/store/auth/actions'

export const useUserProjectRole = (accountId, projectId) => {
  const dispatch = useDispatch()
  const selfState = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(selfLoad())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const account = selfState?.accounts?.find(a => a.accountId == accountId)

  const projectPermission = account?.projectPermissions?.find(
    p => p.projectId == projectId
  )

  // Assume lowest privilege until proven otherwise
  let isProjectUser =
    projectPermission &&
    projectPermission.userRoleId &&
    projectPermission.userRoleId > 0

  let isReadOnly =
    !projectPermission ||
    selfState.selfLoading ||
    selfState.selfError ||
    projectPermission.userRoleId == 1

  let isProjectAdmin =
    projectPermission != null && projectPermission.userRoleId == 3

  const roleLoaded = projectPermission && !selfState.selfLoading

  return {
    isProjectUser,
    isReadOnly,
    isProjectAdmin,
    userRoleId: projectPermission?.userRoleId,
    roleLoaded
  }
}
