import _ from 'lodash'
import * as allTags from 'portal/lib/constants/moduleConstants'

function arrayToKeyValuePairs(arr) {
  return arr.reduce((aggr, key) => ({ ...aggr, [key]: key }), {})
}

const TAG_GROUPS = {
  ...arrayToKeyValuePairs(Object.values(allTags))
}

export function topLevelModules(state) {
  const { moduleTags } = state.projectDetails.modules || {}
  if (!moduleTags) {
    return []
  }

  const groups = _.groupBy(
    moduleTags.filter(tag => !!TAG_GROUPS[tag.moduleTagDefinitionName]),
    tag => TAG_GROUPS[tag.moduleTagDefinitionName]
  )

  return _.map(Object.keys(groups), groupTagName => ({
    name: groupTagName,
    tags: groups[groupTagName],
    isTagged: groups[groupTagName].some(tag => tag.isTagged)
  }))
}
