import React from 'react'
import { useSelector } from 'react-redux'

import { LightThemeProvider } from '@qflow/theme'
import { PageContainer } from 'portal/pages/PageContainer'

import { selectGlobalDate } from 'portal/store/global/selectors'
import { EventLog } from 'portal/components/EventLog'
import { Sidebar } from 'portal/components/Sidebar'

const globalDateSelector = selectGlobalDate()

export function WasteEventLog() {
  const { startDate, endDate } = useSelector(globalDateSelector)

  return (
    <LightThemeProvider>
      <Sidebar />

      <PageContainer>
        <EventLog startDate={startDate} endDate={endDate} />
      </PageContainer>
    </LightThemeProvider>
  )
}
