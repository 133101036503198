import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { css } from 'styled-components'
import { ColourButton } from 'portal/lib/primitives'
import { loginStart } from 'portal/store/auth/actions'

export function LoginButton() {
  const dispatch = useDispatch()
  const handleLogin = useCallback(() => {
    dispatch(loginStart())
  }, [dispatch])

  return (
    <LoginContainer>
      <ColourButton.Action
        id="LoginBtn"
        onClick={handleLogin}
        css={ClickableWidthCSS}
        primary
      >
        Sign In
      </ColourButton.Action>
    </LoginContainer>
  )
}

const ClickableWidthCSS = css`
  width: 6rem;
  display: flex;
`
const LoginContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex: 0 0 auto;
  flex-direction: initial;
  align-items: center;
`
