/* 
https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/

When supported, the date input type will discard any value that’s not part of a valid date.
We can use this to test support by creating an input with type="date" and trying to set its 
value to an invalid date, then checking to see if it was discarded or not.
*/
const isNativeDateInputSupported = function () {
  const input = document.createElement('input')
  const value = 'a'
  input.setAttribute('type', 'date')
  input.setAttribute('value', value)
  return input.value !== value
}

const nativeDateInputSupported = isNativeDateInputSupported()

export const useDateSupport = () => {
  return {
    nativeDateInputSupported
  }
}
