import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { RECORD_STATUS, MODULES } from 'portal/lib/constants'
import { ColourButton, NavButtonDecorator } from 'portal/lib/primitives'
import { WASTE_RECORD_EDIT, DELIVERY_RECORD_EDIT } from 'portal/routes'
import { useModuleIds } from 'portal/lib/hooks'

import { EditButton } from './EditButton'

export function EditRecordButton({ editUrl, ...props }) {
  const disabled = useSelector(
    state =>
      state.records.details.item?.status === RECORD_STATUS.REMOVED ?? true
  )

  const params = useParams()
  const { moduleName, recordId } = useModuleIds()

  const navUrl = useMemo(() => {
    if (editUrl) {
      return editUrl
    }

    return MODULES.select(moduleName, {
      waste: () =>
        generatePath(WASTE_RECORD_EDIT, {
          ...params,
          recordId
        }),
      delivery: () =>
        generatePath(DELIVERY_RECORD_EDIT, {
          ...params,
          recordId
        })
    })
  }, [editUrl, moduleName, params, recordId])

  return (
    <NavButtonDecorator to={navUrl}>
      <EditButton id="EditRecordButton" disabled={disabled} {...props} />
    </NavButtonDecorator>
  )
}

EditRecordButton.propTypes = {
  ...ColourButton.Action.propTypes,
  editUrl: PropTypes.string
}
