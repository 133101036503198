import React from 'react'

import { FormInput, FormRules, FormItem } from 'portal/components/RecordEditing'
import {
  CheckContainerType,
  CheckQuantity,
  CheckVolume,
  CheckWasteEWC,
  CheckWeight
} from 'portal/lib/functions/SimpleDataValidation'

export function Waste({ isImperial = false }) {
  return (
    <>
      <FormInput.Hidden name="id" />
      <FormItem label="Container Type">
        <FormInput.Text
          label="Container Type"
          name="containerType"
          simpleDataValidationRules={CheckContainerType}
          autoFocus
        />
      </FormItem>

      <FormItem label="Waste Code (EWC)">
        <FormInput.Text
          label="Waste Code"
          name="wasteCode"
          simpleDataValidationRules={CheckWasteEWC}
        />
      </FormItem>

      <FormItem label={VolumeLabelBody}>
        <FormInput.Text
          label="Volume"
          name="volume"
          type="number"
          rules={FormRules.OptionalPositiveDecimal()}
          simpleDataValidationRules={v =>
            CheckVolume(v, isImperial ? 'CubicYard' : 'CubicMeter')
          }
        />
      </FormItem>

      <FormItem label="Weight (t)">
        <FormInput.Text
          label="Weight"
          name="weight"
          type="number"
          rules={FormRules.OptionalPositiveDecimal()}
          simpleDataValidationRules={v =>
            CheckWeight(v, isImperial ? 'Pound' : 'Tonne')
          }
        />
      </FormItem>

      <FormItem label="Quantity">
        <FormInput.Text
          label="Quantity"
          name="quantity"
          type="number"
          rules={FormRules.OptionalPositiveDecimal()}
          simpleDataValidationRules={CheckQuantity}
        />
      </FormItem>
    </>
  )
}

const VolumeLabelBody = (
  <>
    Volume (m<sup>3</sup>)
  </>
)
