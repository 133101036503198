import React from 'react'
import PropTypes from 'prop-types'

import { colours, sizes } from '@qflow/theme'
import { Text, icons, Spacer } from 'portal/lib/primitives'

export function FormInfraction({ infraction }) {
  return (
    <>
      <icons.Flag colour={colours.RED} size={sizes.MEDIUM} />

      <Spacer.Fine />

      <Text error>{infraction.eventDescriptor ?? 'Missing or incorrect'}</Text>
    </>
  )
}

FormInfraction.propTypes = {
  infraction: PropTypes.object.isRequired
}
