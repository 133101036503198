import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider, ThemeContext } from 'styled-components'

import {
  alpha,
  lighten,
  darken,
  AMBER,
  BLACK,
  CORE_NAVY,
  PALE_GREY,
  DEEP_SKY_BLUE,
  WHITE,
  BLUEY_GREY,
  BORDER_GREY,
  CHARCOAL_GREY,
  DARK_GREY,
  BATTLESHIP_GREY,
  CONTROL_PANEL_GREY,
  RED,
  TEAL,
  YELLOW
} from './colours'
import * as colours from './colours'

export type ThemeType = typeof DARK_THEME

export const DARK_THEME = {
  colours,
  general: {
    bg: CORE_NAVY
  },
  button: {
    bg: BLACK,
    glow: WHITE,
    glowSize: 1
  },
  primary: {
    bg: BLACK,
    bgFocused: lighten(BLACK, 0.3),
    bgHover: lighten(BLACK, 0.23),
    fg: PALE_GREY,
    fgDim: alpha(PALE_GREY, 0.5),
    fgHover: alpha(PALE_GREY, 0.9),
    border: BLUEY_GREY,
    highlight: TEAL,
    highlightHover: alpha(TEAL, 0.8),
    highlightFocused: TEAL,
    highlightBg: alpha(YELLOW, 0.25),
    highlightFg: WHITE,
    disabled: BLUEY_GREY,
    error: RED
  },
  secondary: {
    bg: CHARCOAL_GREY,
    bgFocused: lighten(CHARCOAL_GREY, 0.2),
    bgHover: lighten(CHARCOAL_GREY, 0.1),
    border: BLUEY_GREY
  },
  calendar: {
    bg: CHARCOAL_GREY,
    daySelected: alpha(DEEP_SKY_BLUE, 0.5),
    daySelectedHover: alpha(alpha(DEEP_SKY_BLUE, 0.8), 0.7),
    daySelectedFg: WHITE
  },
  modal: {
    backfill: alpha(BLACK, 0.7),
    border: darken(BLUEY_GREY, 0.5),
    headerBg: CHARCOAL_GREY
  },
  colourButton: {
    negativeBg: RED,
    negativeBgHover: darken(RED, 0.1),
    negativeBgActive: darken(RED, 0.2),
    negativeFg: PALE_GREY,
    negativeFgDisabled: darken(PALE_GREY, 0.4),
    actionBg: TEAL,
    actionBgHover: darken(TEAL, 0.1),
    actionBgActive: darken(TEAL, 0.2),
    actionFg: WHITE,
    actionFgDisabled: darken(PALE_GREY, 0.4),
    cautionBg: lighten(AMBER, 0.9),
    cautionBgHover: lighten(AMBER, 0.8),
    cautionBgActive: lighten(AMBER, 0.8),
    cautionBorder: AMBER,
    cautionBorderHover: darken(AMBER, 0.1),
    cautionFg: CHARCOAL_GREY,
    cautionFgDisabled: lighten(CHARCOAL_GREY, 0.4),
    defaultBg: 'transparent',
    defaultBgHover: alpha(WHITE, 0.1),
    defaultBgActive: alpha(WHITE, 0.2),
    defaultFg: WHITE,
    defaultFgDisabled: darken(WHITE, 0.3)
  },
  textInput: {
    bg: CHARCOAL_GREY,
    fg: WHITE,
    fgPlaceholder: darken(WHITE, 0.2)
  },
  sidebar: {
    bg: DARK_GREY,
    navButtonBgActive: alpha(BATTLESHIP_GREY, 0.4),
    navButtonBgHover: alpha(BATTLESHIP_GREY, 0.3),
    navButtonFg: alpha(WHITE, 0.8),
    navButtonFgActive: WHITE,
    navButtonFgUnavailable: alpha(WHITE, 0.2)
  },
  controlPanel: {
    bg: CONTROL_PANEL_GREY,
    border: darken(CONTROL_PANEL_GREY, 0.2),
    navButtonFgUnavailable: alpha(BLACK, 0.2),
    selectionItemBlockDefault: darken(CONTROL_PANEL_GREY, 0.3),
    selectionItemBlockSelected: DEEP_SKY_BLUE,
    selectionItemBlockPulse: DEEP_SKY_BLUE
  },
  pill: {
    bg: TEAL,
    bgHover: lighten(TEAL, 0.1),
    bgActive: lighten(TEAL, 0.2),
    fg: WHITE,
    fgDisabled: WHITE
  }
}

export const LIGHT_THEME = {
  general: {
    bg: CONTROL_PANEL_GREY
  },
  button: {
    bg: PALE_GREY,
    glow: BLACK,
    glowSize: 2
  },
  primary: {
    bg: PALE_GREY,
    bgFocused: darken(PALE_GREY, 0.2),
    bgHover: darken(PALE_GREY, 0.1),
    borderFgFocus: TEAL,
    borderFgHover: CORE_NAVY,
    fg: CORE_NAVY,
    fgDim: BATTLESHIP_GREY,
    fgHover: lighten(BATTLESHIP_GREY, 0.2),
    border: lighten(CHARCOAL_GREY, 0.8)
  },
  secondary: {
    bg: WHITE,
    bgFocused: darken(WHITE, 0.3),
    bgHover: darken(WHITE, 0.2),
    borderFgFocus: TEAL,
    borderFgHover: CORE_NAVY,
    fg: lighten(CHARCOAL_GREY, 0.3),
    fgDim: lighten(CHARCOAL_GREY, 0.5),
    fgHover: lighten(CHARCOAL_GREY, 0.4),
    border: CHARCOAL_GREY
  },
  calendar: {
    bg: PALE_GREY
  },
  modal: {
    border: CHARCOAL_GREY,
    headerBg: darken(PALE_GREY, 0.05)
  },
  colourButton: {
    defaultBgHover: CONTROL_PANEL_GREY,
    defaultBgActive: PALE_GREY,
    defaultFg: CORE_NAVY,
    borderFg: BORDER_GREY,
    borderFgFocus: TEAL,
    borderFgHover: CORE_NAVY,
    fgHover: WHITE
  },
  textInput: {
    bg: WHITE,
    bgDisabled: darken(WHITE, 0.15),
    fg: CHARCOAL_GREY,
    fgDisabled: lighten(CHARCOAL_GREY, 0.2),
    fgPlaceholder: lighten(CHARCOAL_GREY, 0.2)
  }
}

export const useTheme = () => useContext<ThemeType>(ThemeContext)

const useMergedTheme = theme => {
  const parentTheme = useTheme() || DARK_THEME

  return Object.keys(parentTheme).reduce((final, key) => {
    final[key] = {
      ...parentTheme[key],
      ...theme[key]
    }

    return final
  }, {})
}

export function DarkThemeProvider({ children }) {
  const theme = useMergedTheme(DARK_THEME)
  return <ThemeProvider theme={theme as ThemeType}>{children}</ThemeProvider>
}

DarkThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export function LightThemeProvider({ children }) {
  const theme = useMergedTheme(LIGHT_THEME)
  return <ThemeProvider theme={theme as ThemeType}>{children}</ThemeProvider>
}

LightThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}
