import { useMemo } from 'react'
import { generatePath, useRouteMatch } from 'react-router'

import { MODULES } from 'portal/lib/constants'
import { NavButtonDecorator } from 'portal/lib/primitives'

import {
  WASTE_RECORD_LINE_ITEM_EDIT,
  DELIVERY_RECORD_LINE_ITEM_EDIT
} from 'portal/routes'
import { AddButton } from './AddButton'
import { useModuleIds } from 'portal/lib/hooks'

export function AddLineItemButton() {
  const { moduleName } = useModuleIds()
  const { params } = useRouteMatch()

  const uri = useMemo(() => {
    return MODULES.select(moduleName, {
      delivery: () =>
        generatePath(DELIVERY_RECORD_LINE_ITEM_EDIT, {
          ...params,
          lineItem: 'new'
        }),
      waste: () =>
        generatePath(WASTE_RECORD_LINE_ITEM_EDIT, {
          ...params,
          lineItem: 'new'
        })
    })
  }, [moduleName, params])

  return (
    <NavButtonDecorator to={uri}>
      <AddButton id="AddLineItemButton" />
    </NavButtonDecorator>
  )
}
