import React, {
  createContext,
  useState,
  useContext as useReactContext,
  useLayoutEffect,
  useRef,
  useCallback,
  useEffect
} from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Fade from '@material-ui/core/Fade'

import { DarkThemeProvider } from '@qflow/theme'
import { Flex, Spacer } from 'portal/lib/primitives/layout'
import * as icons from 'portal/lib/primitives/icons'
import { SIDEBAR_HORIZONTAL_PADDING } from './constants'
import { useResponsive } from 'portal/lib/hooks'
import { NavButton } from './NavButton'
import { SideBarHelpLink } from './SideBarHelpLink'
import * as authActions from 'portal/store/auth/actions'

const SideBarContext = createContext()

export function PortalProvider(props) {
  const [element, setElement] = useState(null)
  const [mobileShow, setMobileShow] = useState(false)
  const [isOnPage, setIsOnPage] = useState(false)

  const location = useLocation()
  const lastLocation = useRef(location)
  useEffect(() => {
    if (location !== lastLocation.current) {
      lastLocation.current = location
      setMobileShow(false)
    }
  }, [location, mobileShow])

  return (
    <SideBarContext.Provider
      {...props}
      value={{
        element,
        setElement,
        mobileShow,
        setMobileShow,
        isOnPage,
        setIsOnPage
      }}
    />
  )
}

export function useContext() {
  return useReactContext(SideBarContext)
}

export function PortalExit() {
  const { isMobile } = useResponsive()
  const { setElement, isOnPage, mobileShow, setMobileShow } = useContext()

  const dispatch = useDispatch()
  const loggedIn = useSelector(state => !!state.auth.userId)
  const handleLogout = useCallback(() => {
    dispatch(authActions.logoutStart())

    return true
  }, [dispatch])

  const ref = useRef()
  useLayoutEffect(() => {
    setElement(ref.current)
  }, [setElement])

  return (
    <DarkThemeProvider>
      <Fade in={isMobile && mobileShow} enter exit>
        <Backdrop onClick={() => setMobileShow(false)} />
      </Fade>

      <SideBarContainer
        visible={isMobile || isOnPage}
        isMobile={isMobile}
        mobileShow={mobileShow}
        id="SideBar"
      >
        {isMobile && (
          <>
            <NavButton Icon={icons.Home} to="/accounts">
              Home
            </NavButton>
            <Spacer.Tiny />
          </>
        )}

        <PortalExitDiv id="SideBarPortalRoot" ref={ref} />

        {isMobile && loggedIn && (
          <>
            <Flex.By amount={1} />
            <SideBarHelpLink />
            <NavButton Icon={icons.Exit} onClick={handleLogout} to="/">
              Sign Out
            </NavButton>
          </>
        )}
      </SideBarContainer>
    </DarkThemeProvider>
  )
}

export function Show({ children }) {
  const { element, setIsOnPage } = useContext()

  useLayoutEffect(() => {
    if (!element) {
      return
    }

    setIsOnPage(true)
    return () => setIsOnPage(false)
  }, [element, setIsOnPage])

  if (!element) {
    return null
  }

  return createPortal(
    <DarkThemeProvider>{children}</DarkThemeProvider>,
    element
  )
}

Show.propTypes = {
  children: PropTypes.node
}

const PortalExitDiv = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const SideBarContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;

  min-width: 14rem;
  max-width: 14rem;

  /* TODO: is this needed for a browser bug? */
  /* overflow: hidden;
  overflow-y: auto; */

  background-color: ${({ theme }) => theme.general.bg};

  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: ${SIDEBAR_HORIZONTAL_PADDING};
  padding-left: ${SIDEBAR_HORIZONTAL_PADDING};
  box-sizing: border-box;

  ${({ isMobile, mobileShow }) =>
    isMobile &&
    css`
      position: absolute;
      z-index: 5000;
      top: 0;
      bottom: 0;
      left: ${mobileShow ? 0 : '-14rem'};

      min-width: auto;
      width: 90%;
      max-width: 14rem;

      transition: all 0.3s ease-in-out;
      transition-property: left;

      padding-top: ${SIDEBAR_HORIZONTAL_PADDING};
    `}
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.modal.backfill};

  z-index: 4999;
`
