import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authCheckState } from 'portal/store/auth/actions'

import { AppRoute } from './AppRoute'

export const AuthenticatedRoute = ({ ...props }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authCheckState({ interactOnUnauthorised: true }))
  }, [dispatch])

  return <AppRoute {...props} />
}

AuthenticatedRoute.propTypes = {
  ...AppRoute.propTypes
}
