export const RECORD_EDIT_STARTED = 'RECORD_EDIT_STARTED'
export const recordEditStarted = (accountId, projectId, moduleName) => ({
  type: RECORD_EDIT_STARTED,
  accountId,
  projectId,
  moduleName
})

export const RECORD_EDITED = 'RECORD_EDITED'
export const recordEdited = (
  accountId,
  projectId,
  moduleName,
  record,
  onSuccess = null,
  isImperial = false
) => ({
  type: RECORD_EDITED,
  accountId,
  projectId,
  moduleName,
  record,
  callbacks: {
    onSuccess
  },
  isImperial
})

export const RECORD_ITEM_REMOVED_TOGGLED = 'RECORD_ITEM_REMOVED_TOGGLED'
export const recordItemRemovedToggle = (
  accountId,
  projectId,
  moduleName,
  record,
  lineItemIndex,
  isImperial = false,
  isRemoved = false
) => ({
  type: RECORD_ITEM_REMOVED_TOGGLED,
  accountId,
  projectId,
  moduleName,
  record,
  lineItemIndex,
  isImperial,
  isRemoved
})

export const RECORD_EDIT_UPLOADED = 'RECORD_EDIT_UPLOADED'
export const recordEditUploaded = (
  accountId,
  projectId,
  moduleName,
  record
) => ({
  type: RECORD_EDIT_UPLOADED,
  accountId,
  projectId,
  moduleName,
  record
})

export const RECORD_EDIT_UPLOAD_FAILED = 'RECORD_EDIT_UPLOAD_FAILED'
export const recordEditUploadFailed = (
  accountId,
  projectId,
  moduleName,
  error
) => ({
  type: RECORD_EDIT_UPLOAD_FAILED,
  accountId,
  projectId,
  moduleName,
  error
})
