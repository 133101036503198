export const RECORDS_REMOVE_RECORD = 'RECORDS_REMOVE_RECORD'
export const removeRecord = (
  accountId,
  projectId,
  moduleName,
  recordId,
  { onSuccess } = {}
) => {
  return {
    type: RECORDS_REMOVE_RECORD,
    accountId,
    projectId,
    moduleName,
    recordId,
    callbacks: {
      onSuccess
    }
  }
}

export const RECORDS_REMOVE_RECORD_SUCCESS = 'RECORDS_REMOVE_RECORD_SUCCESS'
export const removeRecordSuccess = (
  accountId,
  projectId,
  moduleName,
  recordId
) => {
  return {
    type: RECORDS_REMOVE_RECORD_SUCCESS,
    accountId,
    projectId,
    moduleName,
    recordId
  }
}

export const RECORDS_REMOVE_RECORD_FAILURE = 'RECORDS_REMOVE_RECORD_FAILURE'
export const removeRecordFailure = (
  accountId,
  projectId,
  moduleName,
  recordId,
  error
) => {
  return {
    type: RECORDS_REMOVE_RECORD_FAILURE,
    accountId,
    projectId,
    moduleName,
    recordId,
    error
  }
}
