import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function EditError({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        fill={colour || theme.primary.error}
        fillRule="nonzero"
        d="M6 0a6 6 0 0 1 6 6 6 6 0 0 1-6 6 6 6 0 0 1-6-6 6 6 0 0 1 6-6zm1.61 3.855L5.997 5.468l-1.589-1.59a.375.375 0 1 0-.53.532l1.589 1.586-1.6 1.601a.38.38 0 0 0 0 .537.38.38 0 0 0 .534 0l1.6-1.602 1.589 1.59a.376.376 0 1 0 .53-.532L6.532 6.004 8.145 4.39a.38.38 0 0 0 0-.536.38.38 0 0 0-.535 0z"
      />
    </svg>
  )
}

EditError.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
