import React, { useRef, useCallback } from 'react'

/**
 *
 * @param {string} acceptTypes
 * @param {(files: FileList) => void} onFileSelected
 */
export function useFileInput(
  acceptTypes,
  onFileSelected,
  { inputId = 'FileInput', multiple = false } = {}
) {
  const uploadRef = useRef()
  const handleUploadClick = useCallback(() => {
    uploadRef.current.click()
  }, [])

  const hiddenInput = (
    <input
      id={inputId}
      type="file"
      hidden
      accept={acceptTypes}
      ref={uploadRef}
      onChange={e => {
        e.stopPropagation()
        e.preventDefault()

        if (e.target.value != null && (e.target.files?.length ?? 0) > 0) {
          onFileSelected(e.target.files, e)
          e.target.value = null
        }
      }}
      multiple={multiple}
    />
  )

  return {
    handleUploadClick,
    hiddenInput
  }
}
