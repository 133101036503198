export const ACCS_GET_START = 'ACCS_GET_START'
export const accsGetStart = () => {
  return {
    type: ACCS_GET_START
  }
}

export const ACCS_GET_SAGA = 'ACCS_GET_SAGA'
export const accsGetSaga = () => {
  return {
    type: ACCS_GET_SAGA
  }
}

export const ACCS_GET_SUCCESS = 'ACCS_GET_SUCCESS'
export const accsGetSuccess = accounts => {
  return {
    type: ACCS_GET_SUCCESS,
    accounts: accounts
  }
}

export const ACCS_GET_FAIL = 'ACCS_GET_FAIL'
export const accsGetFail = error => {
  return {
    type: ACCS_GET_FAIL,
    error: error
  }
}
