import * as actions from './actions'
import produce from 'immer'

const initialState = {
  accountId: null,
  projects: null,
  error: null,
  isLoading: false
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.ACCOUNT_PROJS_GET:
        draft.accountId = action.accountId
        draft.error = null
        draft.projects = null
        draft.isLoading = true
        break
      case actions.ACCOUNT_PROJS_GET_SUCCESS:
        draft.error = null
        draft.projects = action.projects
        draft.isLoading = false
        break
      case actions.ACCOUNT_PROJS_GET_FAIL:
        draft.error = action.error
        draft.projects = null
        draft.isLoading = false
        break
      default:
        return state
    }
  })
}

export { reducer }
