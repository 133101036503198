export const DESTINATION_ACTION_QUERY_REQUIRED =
  'DESTINATION_ACTION_QUERY_REQUIRED'
export const queryActionRequired = (destinationType, destinationId) => ({
  type: DESTINATION_ACTION_QUERY_REQUIRED,
  destinationType,
  destinationId
})

export const DESTINATION_ACTION_QUERY_SUCCESS =
  'DESTINATION_ACTION_QUERY_SUCCESS'
export const queryActionSuccess = (destinationType, destinationId, data) => ({
  type: DESTINATION_ACTION_QUERY_SUCCESS,
  destinationType,
  destinationId,
  data
})

export const DESTINATION_ACTION_QUERY_FAILED = 'DESTINATION_ACTION_QUERY_FAILED'
export const queryActionFailed = (destinationType, destinationId, error) => ({
  type: DESTINATION_ACTION_QUERY_FAILED,
  destinationType,
  destinationId,
  error
})
