import { takeEvery, put } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import { USER_UPDATE_START, userUpdateSuccess, userUpdateFail } from './actions'

function getPutUserURI(accountId, userId) {
  return `accounts/${accountId}/memberships/${userId}`
}

function getPutUserDetailsURI(accountId, userId) {
  return `accounts/${accountId}/user-details/${userId}`
}

function* putUserSaga(action) {
  const {
    accountId,
    userId,
    projectPermissions,
    notificationsEnabled,
    projectSettings
  } = action
  try {
    const [memberships, userProfile] = yield Promise.all([
      axios.put(getPutUserURI(accountId, userId), {
        projectPermissions: projectPermissions,
        validateStatus: status => status === 200
      }),
      axios.put(getPutUserDetailsURI(accountId, userId), {
        userId: userId,
        notificationsEnabled: notificationsEnabled,
        projectSettings: projectSettings,
        validateStatus: status => status === 200
      })
    ])
    yield put(
      userUpdateSuccess(accountId, userId, memberships.data, userProfile.data)
    )
  } catch (error) {
    yield put(userUpdateFail(accountId, userId, error))
  }
}

export function* watch() {
  yield takeEvery(USER_UPDATE_START, putUserSaga)
}
