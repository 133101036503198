import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useModuleIds } from 'portal/lib/hooks'
import { trackMixpanel } from 'portal/store/mixpanel/actions'

export function useMixpanelEvents() {
  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()

  return {
    trackOpen: useCallback(() => {
      dispatch(
        trackMixpanel('frontend_events_open', {
          record_type: moduleName,
          project_id: projectId,
          account_id: accountId
        })
      )
    }, [accountId, dispatch, moduleName, projectId]),

    trackEventClick: useCallback(
      (source_id, source_type) => {
        dispatch(
          trackMixpanel('frontend_event_click', {
            record_type: moduleName,
            source_id,
            source_type,
            project_id: projectId,
            account_id: accountId
          })
        )
      },
      [accountId, dispatch, moduleName, projectId]
    )
  }
}
