import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'

import { sizes } from '@qflow/theme'
import { Spacer } from 'portal/lib/primitives/layout'
import * as ButtonBase from 'portal/lib/primitives/buttons/ButtonBase'

export function NavButton({ id = '', children, to, Icon, disabled }) {
  const location = useLocation()
  const isActive = location.pathname.startsWith(to)

  return (
    <StyledNavLink
      id={id + 'ControlPanelNavButton'}
      to={to}
      isActive={() => isActive}
      disabled={disabled}
    >
      <ButtonBody disabled={isActive} optionUnavailable={disabled}>
        {Icon && (
          <>
            <Icon size={sizes.MEDIUM} colour="currentColor" />
            <Spacer.Tiny />
          </>
        )}
        {children}
      </ButtonBody>
    </StyledNavLink>
  )
}

NavButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.elementType,
  disabled: PropTypes.bool
}

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  position: relative;

  text-decoration: none;

  margin-top: 0.5rem;

  ${({ isActive, disabled }) =>
    (isActive?.() || disabled) &&
    css`
      pointer-events: none;
    `}
`

const ButtonBody = styled.button`
  ${ButtonBase.BaseCSS}
  ${ButtonBase.getColoursCSS({
    getBg: () => 'transparent',
    getFgDisabled: ({ theme }) => theme.primary.highlight
  })}
  ${ButtonBase.getShapeCSS({
    getHeight: () => '2rem'
  })}

  :disabled {
    font-weight: 700;
  }

  ${({ optionUnavailable, theme }) =>
    optionUnavailable &&
    css`
      background-color: 'transparent' !important;
      color: ${theme.controlPanel.navButtonFgUnavailable} !important;
      font-weight: 300 !important;
      pointer-events: none;
    `}

  width: 100%;
  justify-content: flex-start;
  border-radius: 0;

  padding-left: 1rem;

  font-size: ${sizes.SMALL};
`
