import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'

/**
 *
 * @returns {[checks: { list: string[], byKey: Record<string, boolean> }, setCheck: (string, boolean) => void, clearChecks: () => void]}
 */
export const useCheckboxStates = () => {
  const [checksByKey, setChecks] = useState({})
  const list = useMemo(() => {
    return _.reduce(
      checksByKey,
      (list, val, key) => {
        if (val) {
          list.push(key)
        }
        return list
      },
      []
    )
  }, [checksByKey])

  const handleUpdate = useCallback((key, value) => {
    setChecks(checks => ({
      ...checks,
      [key]: value
    }))
  }, [])

  const handleBulkUpdate = useCallback((keyArray, value) => {
    const tempObject = {}
    keyArray.forEach(key => {
      tempObject[key] = value
    })

    setChecks(checks => ({
      ...checks,
      ...tempObject
    }))
  }, [])

  const clearAll = useCallback(() => {
    setChecks({})
  }, [])

  return [
    { byKey: checksByKey, list },
    handleUpdate,
    handleBulkUpdate,
    clearAll
  ]
}
