import React, { useContext, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const Context = React.createContext()

export function InfractionsContext({ infractionsByFieldName, ...props }) {
  return <Context.Provider {...props} value={infractionsByFieldName} />
}

InfractionsContext.propTypes = {
  infractionsByFieldName: PropTypes.object
}

/**
 *
 * @returns {object | null}
 */
export function useInfraction(fieldPath) {
  const infractionsByFieldName = useContext(Context)

  return useMemo(() => {
    return infractionsByFieldName?.[fieldPath] ?? null
  }, [fieldPath, infractionsByFieldName])
}

/**
 * @returns {(fieldPath: string, { isPrefix: boolean }) => null | Object | Object[]}
 */
export function useInfractionGetter() {
  const infractionsByFieldName = useContext(Context)

  return useCallback(
    (fieldPath, { isPrefix = false } = {}) => {
      if (isPrefix) {
        return _(infractionsByFieldName)
          .filter(infraction => infraction.fieldName.startsWith(fieldPath))
          .value()
      } else {
        return infractionsByFieldName?.[fieldPath] ?? null
      }
    },
    [infractionsByFieldName]
  )
}
