import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { ClickableCSS } from 'portal/lib/primitives/buttons'

/**
 * @param {import('react-router-dom').LinkProps} props
 */
export function ClickableLink({ children, disabled = false, to, ...props }) {
  return (
    <StyledLink {...props} to={disabled ? '#' : to} disabled={disabled}>
      {children}
    </StyledLink>
  )
}

ClickableLink.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  ...Link.propTypes
}

const StyledLink = styled(Link)`
  ${ClickableCSS}

  text-decoration: none;
  font-size: inherit;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
