import produce from 'immer'

import { USERS_LOAD, USERS_LOADED, USERS_LOAD_FAILURE } from './actions'

const initialState = {}

export const initialUserState = {
  loading: false,
  uploading: false,
  loaded: false,
  error: null,
  userId: null,
  firstName: null,
  lastName: null,
  displayName: null,
  email: null,
  lastActivity: null,
  projectPermissions: [],
  notificationsEnabled: false,
  projectSettings: []
}

export function getKeyFromIds(projectId, userId) {
  return `${projectId}_${userId}`.toLowerCase()
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case USERS_LOAD: {
        draft.loading = true
        draft.error = null
        return
      }

      case USERS_LOADED: {
        const { accountId, memberships, userProfiles } = action
        draft.loading = false
        draft.users = {}

        memberships?.forEach(membership => {
          const userId = membership.userId
          const userProfile = userProfiles.find(p => p.userId == userId)

          const key = getKeyFromIds(accountId, userId)
          if (userProfile) {
            draft.users[key] = {
              userId: userId,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              displayName: userProfile.displayName,
              email: userProfile.email,
              lastActivity: userProfile.lastActivity,
              projectPermissions: membership.projectPermissions,
              notificationsEnabled: userProfile.notificationsEnabled,
              projectSettings: userProfile.projectSettings
            }
          }
        })

        return
      }

      case USERS_LOAD_FAILURE: {
        draft.loading = false
        draft.error = action.error
        return
      }
    }
  })
}
