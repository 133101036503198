import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { NavDropdown, NavDropdownOption, icons } from 'portal/lib/primitives'
import { useModuleIds, useResponsive } from 'portal/lib/hooks'
import * as ROUTES from 'portal/routes'
import { trackSwitcherSelect } from './trackSwitcherSelect'
import { trackSwitcherOpen } from './trackSwitcherOpen'
import { selectProjectsForSwitcherFactory } from './selectProjectsForSwitcherFactory'
import { projectsGetSaga } from 'portal/store/projects/actions'

export function ProjectSwitcher(props) {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()
  const [open, setOpen] = useState(false)
  const { isMobile } = useResponsive()

  const { projects } = useSelector(
    useMemo(() => selectProjectsForSwitcherFactory(moduleName), [moduleName])
  )

  useEffect(() => {
    if (accountId) {
      dispatch(projectsGetSaga(accountId))
    }
  }, [accountId, dispatch])

  const { pathname } = useLocation()

  const items = useMemo(() => {
    const append = pathname.endsWith('/events') ? '/events' : ''

    return projects
      .map(({ key, label, moduleName }) => ({
        key,
        label,
        enabled: true,
        icon: null,
        selected: key === projectId,
        uri:
          accountId &&
          `${generatePath(ROUTES.BASE_MODULE_NAV, {
            accountId,
            projectId: key,
            moduleName
          })}${append}`
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
      )
  }, [accountId, pathname, projectId, projects])

  const [selectedItemKey, selectedItem] = useMemo(() => {
    const selectedItem = items.find(item => item.selected) || {}
    const selectedModuleKey = selectedItem.key

    return [selectedModuleKey, selectedItem]
  }, [items])

  const handleSetOpen = useCallback(
    nextOpen => {
      setOpen(nextOpen)

      if (nextOpen && !open) {
        dispatch(trackSwitcherOpen(selectedItemKey))
      }
    },
    [dispatch, open, selectedItemKey]
  )

  const handleClick = useCallback(
    item => {
      setOpen(false)
      setTimeout(() => {
        dispatch(
          trackSwitcherSelect(selectedItemKey, item.key, accountId, projectId)
        )
      }, 0)
    },
    [accountId, dispatch, projectId, selectedItemKey]
  )

  const loaded = !!projectId && !!selectedItemKey

  if (!projectId) {
    return <Container {...props} />
  }

  return (
    <Container {...props} data-testid="project-switcher">
      <NavDropdown
        id="ProjectNavDropdown"
        open={open}
        setOpen={handleSetOpen}
        Icon={icons.Building}
        label={isMobile ? 'Project' : selectedItem.label}
        title={selectedItem.label}
        disabled={items.length <= 1}
        loading={!loaded}
      >
        {items.map(
          item =>
            !item.selected && (
              <NavDropdownOption
                id={`NavigateTo_${item.label}`}
                data-testid={`NavigateTo_${item.label}`}
                key={item.key}
                Icon={item.icon}
                label={item.label}
                uri={item.uri}
                onClick={handleClick}
                onClickPassbackValue={item}
              />
            )
        )}
      </NavDropdown>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 0 0 auto;
`
