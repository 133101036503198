import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function Calendar({
  size = sizes.SMALL,
  colour = 'currentColor',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ width: size, height: size }}
    >
      <path
        fill={colour || colours.WHITE}
        fillRule="nonzero"
        d="M9.333 15h1.334v1.25H9.333V15zm0 2.5h1.334c.736 0 1.333-.56 1.333-1.25V15c0-.69-.597-1.25-1.333-1.25H9.333C8.597 13.75 8 14.31 8 15v1.25c0 .69.597 1.25 1.333 1.25zM4 15h1.333v1.25H4V15zm0 2.5h1.333c.736 0 1.334-.56 1.334-1.25V15c0-.69-.598-1.25-1.334-1.25H4c-.736 0-1.333.56-1.333 1.25v1.25c0 .69.597 1.25 1.333 1.25zM9.333 10h1.334v1.25H9.333V10zm0 2.5h1.334c.736 0 1.333-.56 1.333-1.25V10c0-.69-.597-1.25-1.333-1.25H9.333C8.597 8.75 8 9.31 8 10v1.25c0 .69.597 1.25 1.333 1.25zM4 10h1.333v1.25H4V10zm0 2.5h1.333c.736 0 1.334-.56 1.334-1.25V10c0-.69-.598-1.25-1.334-1.25H4c-.736 0-1.333.56-1.333 1.25v1.25c0 .69.597 1.25 1.333 1.25zM18.667 5H1.333V3.75c0-.69.598-1.25 1.334-1.25h2.666v.625c0 .346.298.625.667.625.369 0 .667-.28.667-.625V2.5h6.666v.625c0 .346.298.625.667.625.369 0 .667-.28.667-.625V2.5h2.666c.736 0 1.334.56 1.334 1.25V5zm0 12.5c0 .69-.598 1.25-1.334 1.25H2.667c-.736 0-1.334-.56-1.334-1.25V6.25h17.334V17.5zM17.333 1.25h-2.666V.625C14.667.28 14.369 0 14 0c-.369 0-.667.28-.667.625v.625H6.667V.625C6.667.28 6.369 0 6 0c-.369 0-.667.28-.667.625v.625H2.667C1.194 1.25 0 2.37 0 3.75V17.5C0 18.88 1.194 20 2.667 20h14.666C18.806 20 20 18.88 20 17.5V3.75c0-1.38-1.194-2.5-2.667-2.5zM14.667 10H16v1.25h-1.333V10zm0 2.5H16c.736 0 1.333-.56 1.333-1.25V10c0-.69-.597-1.25-1.333-1.25h-1.333c-.736 0-1.334.56-1.334 1.25v1.25c0 .69.598 1.25 1.334 1.25zm0 2.5H16v1.25h-1.333V15zm0 2.5H16c.736 0 1.333-.56 1.333-1.25V15c0-.69-.597-1.25-1.333-1.25h-1.333c-.736 0-1.334.56-1.334 1.25v1.25c0 .69.598 1.25 1.334 1.25z"
        {...props}
      />
    </svg>
  )
}

Calendar.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
