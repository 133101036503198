// From recompose: https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js
// No need to bring in entire package for just this function

export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    arg => arg
  )
