import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { SPACING, ie11CSS } from '@qflow/theme'
import { ControlPanel } from 'portal/lib/primitives'
import { useResponsive } from 'portal/lib/hooks'

export function PageContainer({ limitWidth = false, ...props }) {
  const { isMobile, isSmall } = useResponsive()
  const { isOnPage } = ControlPanel.useContext()

  const Container = limitWidth ? MaxWidth : Standard

  return (
    <Container
      {...props}
      isMobile={isMobile}
      isSmall={isSmall}
      padForControlPanel={isOnPage}
    />
  )
}

PageContainer.propTypes = {
  limitWidth: PropTypes.bool
}

const Standard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  ${ie11CSS(css`
    width: 100%;
  `)}
  height: auto;

  overflow-x: hidden;

  ${({ isMobile, isSmall, padForControlPanel }) => {
    if (isMobile) {
      return css`
        padding-top: ${SPACING.SMALL};
        padding-bottom: ${SPACING.MEDIUM};
        padding-left: ${SPACING.TINY};
        padding-right: ${SPACING.TINY};
      `
    } else if (isSmall) {
      return css`
        padding-top: ${SPACING.MEDIUM};
        padding-bottom: ${SPACING.MEDIUM};
        padding-left: ${SPACING.MEDIUM};
        padding-right: ${padForControlPanel ? SPACING.LARGE : SPACING.MEDIUM};
      `
    } else {
      return css`
        padding-top: ${SPACING.MEDIUM};
        padding-bottom: ${SPACING.MEDIUM};
        padding-left: ${SPACING.LARGER};
        padding-right: ${SPACING.LARGER};
      `
    }
  }}
`

const MaxWidth = styled(PageContainer)`
  max-width: 60rem;

  /* IE11 has a major problem centering this element horizontally, 
      so we just don't bother limiting width, because F IE */
  ${ie11CSS(css`
    max-width: none;
  `)}
`
