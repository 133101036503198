import React from 'react'
import styled from 'styled-components'

export function TrayItemContainer(props) {
  return (
    <TableRow>
      <TableCell {...props} />
    </TableRow>
  )
}

const TableRow = styled.tr`
  width: 100%;
  display: flex;
`

const TableCell = styled.td`
  width: 100%;
  display: flex;
`
