import { useEffect } from 'react'
import { useHistory, useParams, generatePath } from 'react-router'

import { DELIVERY_DEFAULT, WASTE_DEFAULT } from 'portal/routes'
import { useResponsive, useModuleIds } from 'portal/lib/hooks'
import { select } from 'portal/lib/constants/moduleConstants'

export function useDashboardRedirect(shouldRedirect) {
  const history = useHistory()
  const { moduleName } = useModuleIds()
  const params = useParams()
  const { isMobile } = useResponsive()

  useEffect(() => {
    if (isMobile || shouldRedirect) {
      history.replace(
        generatePath(
          select(moduleName, {
            delivery: DELIVERY_DEFAULT,
            waste: WASTE_DEFAULT
          }),
          params
        )
      )
    }
  }, [history, isMobile, moduleName, params, shouldRedirect])
}
