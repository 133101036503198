import React, {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { colours } from '@qflow/theme'

import * as Icons from 'portal/lib/primitives/icons'
import { PopupCard } from 'portal/lib/primitives/portals'

import { DropdownOption } from './DropdownOption'
import { DropdownButton } from './DropdownButton'
import { SearchBox } from './SearchBox'

function preventEnterKeyDefault(e) {
  if (e.key === 'Enter') {
    e.preventDefault()
  }
}

export const Dropdown = forwardRef(
  (
    {
      items = [],
      value,
      onChange,
      placeholder = '',
      disabled = false,
      ButtonComponent = DropdownButton,
      mode = MODES.NORMAL,
      unknownValue = UNKNOWN_VALUE.SHOW,
      ...props
    },
    ref
  ) => {
    const buttonRef = useRef()
    const searchable = useSearchable(mode)

    // State
    // ------------------

    const [open, setOpen] = useState(false)
    const [softSelectedIndex, setSoftSelectedIndex] = useState()

    const itemsByKey = useMemo(() => _.keyBy(items, item => item.key), [items])
    const itemsByLabel = useMemo(
      () => _.keyBy(items, item => item.label.toLowerCase()),
      [items]
    )

    const processedItems = useMemo(() => {
      const processed = searchable.maybeFilterItems(items).map((item, i) => ({
        ...item,
        softSelected: i === softSelectedIndex,
        selected: item.key === value
      }))

      if (
        mode === MODES.FREETEXT &&
        searchable.value?.length > 0 &&
        !itemsByLabel[searchable.value.toLowerCase()]
      ) {
        processed.push({
          key: searchable.value,
          label: searchable.value,
          softSelected: processed.length === softSelectedIndex,
          selected: searchable.value === value
        })
      }

      return processed
    }, [items, itemsByLabel, mode, searchable, softSelectedIndex, value])

    const selectedItem = useMemo(
      () =>
        itemsByKey[value] ?? {
          none: true,
          key:
            unknownValue === UNKNOWN_VALUE.SHOW && !!value
              ? value
              : placeholder,
          label:
            unknownValue === UNKNOWN_VALUE.SHOW && !!value ? value : placeholder
        },
      [itemsByKey, value, unknownValue, placeholder]
    )

    // Handlers
    // ------------------

    const handleClose = useCallback(() => {
      setSoftSelectedIndex(undefined)
      searchable.setValue(undefined)

      buttonRef.current.focus()

      setOpen(false)
    }, [searchable])

    const handleOpen = useCallback(() => {
      setOpen(true)
    }, [])

    useImperativeHandle(ref, () => ({
      focus: () => void handleOpen()
    }))

    useEffect(() => {
      if (open && searchable.ref.current) {
        searchable.ref.current.focus?.()
      }
    }, [open, searchable.ref])

    const handleButtonEnterKey = useCallback(
      e => {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleOpen()
        }
      },
      [handleOpen]
    )

    const handleSelect = useCallback(
      item => {
        onChange?.(item.key)
        handleClose()
      },
      [handleClose, onChange]
    )

    const handleKeyboardFlow = useCallback(
      e => {
        e.stopPropagation()

        switch (e.key) {
          case 'ArrowUp':
          case 'ArrowDown': {
            if (processedItems.length === 0) {
              return
            }

            let nextIndex = softSelectedIndex
            if (e.key === 'ArrowUp') {
              nextIndex = nextIndex == null ? -1 : (nextIndex -= 1)
              if (nextIndex < 0) {
                nextIndex = processedItems.length - 1
              }
            } else {
              nextIndex = nextIndex == null ? 0 : (nextIndex += 1)
              if (nextIndex >= processedItems.length) {
                nextIndex = 0
              }
            }

            setSoftSelectedIndex(nextIndex)
            if (!open) {
              onChange?.(processedItems[nextIndex].key)
            }

            break
          }

          case 'Enter': {
            const softSelectedItem = processedItems[softSelectedIndex]
            if (softSelectedItem && open) {
              handleSelect(softSelectedItem)
            } else if (
              mode === MODES.FREETEXT &&
              searchable.value?.length > 0 &&
              open &&
              processedItems.length === 0
            ) {
              handleSelect({ key: searchable.value })
            }
            break
          }

          case 'Tab':
          case 'Escape': {
            if (open) {
              handleClose()
            }
            break
          }
        }
      },
      [
        handleClose,
        handleSelect,
        mode,
        onChange,
        open,
        processedItems,
        searchable.value,
        softSelectedIndex
      ]
    )

    return (
      <Container {...props} onKeyUp={handleKeyboardFlow}>
        <ButtonComponent
          id="DropdownButton"
          open={open}
          onClick={e => {
            e.preventDefault()
            open ? handleClose() : handleOpen()
          }}
          itemSelected={!selectedItem.none}
          disabled={disabled}
          ref={buttonRef}
          onKeyDown={preventEnterKeyDefault}
          onKeyUp={handleButtonEnterKey}
        >
          {selectedItem.label}
        </ButtonComponent>

        <StyledPopupCard
          id="DropdownDrawer"
          show={open}
          anchorElementRef={buttonRef}
          onOutsideClick={handleClose}
          enabledMobileResponsiveness
        >
          <Table>
            <tbody>
              {searchable.isSearchable && (
                <SearchBox
                  id="DropdownSearchbox"
                  value={searchable.value}
                  onChange={searchable.setValue}
                  ref={searchable.ref}
                  onKeyUp={handleKeyboardFlow}
                  Icon={
                    mode === MODES.FREETEXT
                      ? Icons.EditPencil
                      : Icons.MagnifyingGlass
                  }
                />
              )}

              {processedItems.map(
                item =>
                  !item.hide && (
                    <DropdownOption
                      id={'DropdownOption_' + item.key}
                      key={item.key}
                      item={item}
                      highlight={item.softSelected}
                      selected={item.selected}
                      onSelect={handleSelect}
                    />
                  )
              )}
            </tbody>
          </Table>
        </StyledPopupCard>
      </Container>
    )
  }
)

export const MODES = {
  NORMAL: 'normal',
  SEARCHABLE: 'searchable',
  FREETEXT: 'freetext'
}

export const UNKNOWN_VALUE = {
  SHOW: 'show',
  HIDE: 'hide'
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  ButtonComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(MODES)),
  unknownValue: PropTypes.oneOf(Object.values(UNKNOWN_VALUE))
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  overflow: visible;

  ${({ inline }) =>
    inline &&
    css`
      flex: 0 0 auto;
      display: inline-flex;
    `}
`

const StyledPopupCard = styled(PopupCard)`
  background-color: ${colours.WHITE};
  border-radius: 5px;
  width: 361px;
`

const Table = styled.table`
  width: 100%;
`

function useSearchable(mode) {
  const isSearchable = mode === MODES.SEARCHABLE || mode === MODES.FREETEXT

  const ref = useRef()
  const [value, setValue] = useState()

  const maybeFilterItems = useCallback(
    items =>
      isSearchable
        ? items.filter(
            item =>
              value == null ||
              ('' + item.label).toLowerCase().includes(value?.toLowerCase())
          )
        : items,
    [isSearchable, value]
  )

  return useMemo(
    () => ({
      isSearchable,
      ref,
      value,
      setValue,
      maybeFilterItems
    }),
    [isSearchable, maybeFilterItems, setValue, value]
  )
}
