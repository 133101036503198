import { takeLatest, all, put, call } from 'redux-saga/effects'
import * as actions from './actions'
import axios from 'portal/utils/axios-msal'

function* projectsGetSaga(action) {
  yield put(actions.projectsGetStart())
  if (action.accountId === '' || action.accountId === null) {
    yield put(actions.projectsGetFail('No Account Id was set'))
  }
  try {
    const response = yield call(
      axios.get,
      '/accounts/' + action.accountId + '/projects/'
    )
    yield put(actions.projectsGetSuccess(response.data))
  } catch (error) {
    yield put(actions.projectsGetFail(error))
  }
}

function* projectPutSaga(action) {
  if (action.accountId === '' || action.accountId === null) {
    yield put(actions.projectPutFail('No Account Id was set'))
  }
  if (action.projectId === '' || action.projectId === null) {
    yield put(actions.projectPutFail('No Project Id was set'))
  }

  try {
    const response = yield call(() =>
      axios.put(
        `/accounts/${action.accountId}/projects/${action.projectId}`,
        action.payload,
        {
          validateStatus: status => status === 200
        }
      )
    )
    yield put(actions.projectPutSuccess(response.data))
  } catch (error) {
    yield put(actions.projectPutFail(action.accountId, action.projectId, error))
  }
}

function* timeZonesGetSaga() {
  try {
    const response = yield call(() => axios.get('/presentation/timezones'))
    yield put(actions.timeZonesGetSuccess(response.data))
  } catch (error) {
    yield put(actions.timeZonesGetFail(error))
  }
}

export function* watch() {
  yield all([takeLatest(actions.PROJS_GET_SAGA, projectsGetSaga)])
  yield all([takeLatest(actions.PROJ_PUT_SAGA, projectPutSaga)])
  yield all([takeLatest(actions.TIMEZONES_GET_SAGA, timeZonesGetSaga)])
}
