import styled, { css } from 'styled-components'

export const BlurPanel = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`
