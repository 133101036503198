import React from 'react'
import PropTypes from 'prop-types'

import { icons, ColourButton, Spacer, Label } from 'portal/lib/primitives'

export function RemoveButton({ children = 'Remove', ...props }) {
  return (
    <ColourButton.Negative id="RemoveButton" {...props}>
      <icons.TrashCan colour="currentColor" />

      <Spacer.Fine />

      <Label isChild colour="inherit">
        {children}
      </Label>
    </ColourButton.Negative>
  )
}

RemoveButton.propTypes = {
  ...ColourButton.Negative.propTypes,
  children: PropTypes.string
}
