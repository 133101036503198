import React from 'react'
import PropTypes from 'prop-types'

import { icons, ColourButton, Spacer, Label } from 'portal/lib/primitives'

export function EditButton({ children = 'Edit', ...props }) {
  return (
    <ColourButton.Action id="EditButton" {...props}>
      <icons.Edit colour="currentColor" />

      <Spacer.Fine />

      <Label isChild colour="inherit">
        {children}
      </Label>
    </ColourButton.Action>
  )
}

EditButton.propTypes = {
  ...ColourButton.Action.propTypes,
  children: PropTypes.string
}
