import produce from 'immer'

import {
  RECORDS_LOAD,
  RECORDS_LOAD_SUCCESS,
  RECORDS_LOAD_FAILURE,
  RECORDS_OPTIONS_LOAD,
  RECORDS_OPTIONS_LOAD_SUCCESS,
  RECORDS_OPTIONS_LOAD_FAILURE,
  RECORDS_ASSIGNTO_OPTIONS,
  RECORDS_COUNT_LOAD,
  RECORDS_COUNT_LOAD_SUCCESS,
  RECORDS_COUNT_LOAD_FAILURE,
  RECORDS_UNEXPORTED_RECORD_IDS_LOAD,
  RECORDS_UNEXPORTED_RECORD_IDS_LOAD_SUCCESS,
  RECORDS_UNEXPORTED_RECORD_IDS_LOAD_FAILURE,
  GET_ONECLICK_REPORTS_BEING_PROCESSED,
  GET_ONECLICK_REPORTS_BEING_PROCESSED_SUCCESS,
  GET_ONECLICK_REPORTS_BEING_PROCESSED_FAILURE
} from './actions'

const DEFAULT_MODULE_STATE = {
  loading: false,
  error: null,
  items: [],
  continuationToken: null,
  hasNextPage: false,
  options: {},
  countLoading: false,
  countError: null,
  count: null,
  unexportedRecordIdsLoading: false,
  unexportedRecordIdsError: null,
  unexportedRecordIds: [],
  retrievingReports: false,
  retrievedReportsSuccessfully: false,
  retrievedReportsError: null,
  reportsBeingGenerated: []
}

const DEFAULT_OPTIONS_STATE = {
  loading: true,
  error: null,
  options: []
}

const initialState = {
  other: DEFAULT_MODULE_STATE,
  waste: DEFAULT_MODULE_STATE,
  delivery: DEFAULT_MODULE_STATE
}

const reducer = (state = initialState, action) => {
  const { moduleName } = action

  return produce(state, draft => {
    switch (action.type) {
      case RECORDS_LOAD: {
        draft[moduleName].loading = true
        return
      }
      case RECORDS_LOAD_SUCCESS: {
        draft[moduleName].loading = false
        draft[moduleName].error = null

        draft[moduleName].items = action.records
        draft[moduleName].continuationToken = action.continuationToken
        draft[moduleName].hasNextPage = action.hasNextPage
        draft[moduleName].projectId = action.projectId
        return
      }
      case RECORDS_LOAD_FAILURE: {
        draft[moduleName].loading = false
        draft[moduleName].error = action.error
        return
      }
      case RECORDS_COUNT_LOAD: {
        draft[moduleName].countLoading = true
        return
      }
      case RECORDS_COUNT_LOAD_SUCCESS: {
        draft[moduleName].countLoading = false
        draft[moduleName].countError = null
        draft[moduleName].count = action.recordsCount.total
        draft[moduleName].allRecordIds = action.recordsCount.recordIds
        return
      }
      case RECORDS_COUNT_LOAD_FAILURE: {
        draft[moduleName].countLoading = false
        draft[moduleName].countError = action.error
        return
      }
      case RECORDS_UNEXPORTED_RECORD_IDS_LOAD: {
        draft[moduleName].unexportedRecordIdsLoading = true
        return
      }
      case RECORDS_UNEXPORTED_RECORD_IDS_LOAD_SUCCESS: {
        draft[moduleName].unexportedRecordIdsLoading = false
        draft[moduleName].unexportedRecordIdsError = null
        draft[moduleName].unexportedRecordIds = action.unexportedRecordIds
        return
      }
      case RECORDS_UNEXPORTED_RECORD_IDS_LOAD_FAILURE: {
        draft[moduleName].unexportedRecordIdsLoading = false
        draft[moduleName].unexportedRecordIdsError = action.error
        return
      }
      case RECORDS_OPTIONS_LOAD: {
        const { fieldName, projectId } = action
        if (draft[moduleName].options[projectId] === undefined) {
          draft[moduleName].options[projectId] = {}
        }
        if (draft[moduleName].options[projectId][fieldName] === undefined) {
          draft[moduleName].options[projectId][fieldName] =
            DEFAULT_OPTIONS_STATE
          return
        }
        draft[moduleName].options[projectId][fieldName].loading = true
        draft[moduleName].options[projectId][fieldName].error = null
        return
      }
      case RECORDS_OPTIONS_LOAD_SUCCESS: {
        const { fieldName, projectId } = action
        draft[moduleName].options[projectId][fieldName].loading = false
        draft[moduleName].options[projectId][fieldName].error = null

        draft[moduleName].options[projectId][fieldName].options = action.options
        return
      }
      case RECORDS_OPTIONS_LOAD_FAILURE: {
        const { fieldName, projectId } = action
        draft[moduleName].options[projectId][fieldName].loading = false
        draft[moduleName].options[projectId][fieldName].error = action.error
        return
      }
      case RECORDS_ASSIGNTO_OPTIONS: {
        const { fieldName, projectId } = action
        if (draft[moduleName].options[projectId] === undefined) {
          draft[moduleName].options[projectId] = {}
        }

        draft[moduleName].options[projectId][fieldName] = {}
        draft[moduleName].options[projectId][fieldName].loading = false
        draft[moduleName].options[projectId][fieldName].error = null
        draft[moduleName].options[projectId][fieldName].options = action.options

        return
      }
      case GET_ONECLICK_REPORTS_BEING_PROCESSED: {
        draft[moduleName].retrievingReports = true
        draft[moduleName].reportsBeingGenerated = []
        draft[moduleName].retrievedReportsSuccessfully = false
        draft[moduleName].retrievedReportsError = null
        return
      }
      case GET_ONECLICK_REPORTS_BEING_PROCESSED_SUCCESS: {
        draft[moduleName].reportsBeingGenerated = action.reportsBeingGenerated
        draft[moduleName].retrievingReports = false
        draft[moduleName].retrievedReportsSuccessfully = true
        return
      }
      case GET_ONECLICK_REPORTS_BEING_PROCESSED_FAILURE: {
        draft[moduleName].retrievingReports = false
        draft[moduleName].retrievedReportsError = action.error
        return
      }
    }
  })
}

export { reducer }
