import React, { useCallback, forwardRef, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colours, shadows, sizes } from '@qflow/theme'
import { useState } from 'react'

const NEW_LINE_REGEX = /\r\n|\r|\n/

export function _getLinesCount(value = '') {
  if (value === null) {
    return 1
  }
  return value.split(NEW_LINE_REGEX).length
}

export function limitLines(value = '', maxLines = null) {
  if (maxLines == null) {
    return value
  }

  const lines = value.split(NEW_LINE_REGEX)
  return lines.slice(0, maxLines).join('\n')
}

const BaseCSS = css`
  /* Ensure we have styling control on iOS */
  -webkit-appearance: none;

  display: flex;
  flex: 1 1 auto;
  box-sizing: border-box;

  min-height: 2rem;

  resize: none;
  overflow: hidden;

  margin: 0;
  padding: 0.5rem;
  border-radius: 5px;
  border: solid 1px ${colours.BORDER_GREY};
  :hover {
    border-color: ${colours.TEAL};
  }
  :focus {
    border-color: ${colours.TEAL};
    ${({ theme }) => shadows.getGlow(5, colours.TEAL, 0)}
  }

  outline: none;
  background-color: ${colours.WHITE};
  color: ${colours.CORE_NAVY};
  ::placeholder {
    color: ${colours.BORDER_GREY};
  }

  font-size: ${sizes.SMALL};

  :disabled {
    background-color: ${({ theme }) => theme.textInput.bgDisabled};
    color: ${({ theme }) => theme.textInput.fgDisabled};
    border-color: ${({ theme }) => theme.textInput.fgDisabled};
  }

  ${({ css }) => css}
`

export const Select = styled.select`
  ${BaseCSS}
`

const StyledTextArea = styled.textarea`
  ${BaseCSS}

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`

export const Input = styled.input`
  ${BaseCSS}
`

export const Selector = forwardRef(
  ({ value, onChange, options = [], ...props }, ref) => {
    const handleChange = useCallback(
      e => {
        onChange?.(e.target.value)
      },
      [onChange]
    )

    return (
      <Select {...props} value={value ?? ''} onChange={handleChange} ref={ref}>
        {options.map(option => (
          <option key={option} value={option}>
            {option}&nbsp;
          </option>
        ))}
      </Select>
    )
  }
)

Selector.propTypes = {
  ...Select.propTypes,
  value: PropTypes.any,
  onChange: PropTypes.func,
  cols: PropTypes.number
}

export const Text = forwardRef(
  (
    {
      value,
      dataValidation = null,
      onBlur,
      onChange,
      cols = null,
      inputUnit = null,
      ...props
    },
    ref
  ) => {
    const handleBlur = useCallback(
      e => {
        onBlur?.(e.target.value)
        dataValidation?.(e.target.value)
      },
      [dataValidation, onBlur]
    )

    const handleChange = useCallback(
      e => {
        onChange?.(e.target.value)
      },
      [onChange]
    )

    return (
      <>
        <Input
          type="text"
          {...props}
          size={cols}
          value={value ?? ''}
          onBlur={handleBlur}
          onChange={handleChange}
          ref={ref}
        />
        {inputUnit && <InputUnit>{inputUnit}</InputUnit>}
      </>
    )
  }
)

Text.propTypes = {
  ...Input.propTypes,
  value: PropTypes.any,
  onChange: PropTypes.func,
  cols: PropTypes.number
}

export const TextArea = forwardRef(
  (
    {
      value,
      dataValidation = null,
      onBlur,
      onChange,
      maxLines = null,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef()
    ref = ref ?? internalRef

    const [height, setHeight] = useState()
    useLayoutEffect(() => {
      setHeight(ref.current.scrollHeight + 'px')
    }, [ref])

    const handleBlur = useCallback(
      e => {
        onBlur?.(e.target.value)
        dataValidation?.(e.target.value)
      },
      [dataValidation, onBlur]
    )

    const handleChange = useCallback(
      e => {
        const value = limitLines(e.target.value, maxLines)
        onChange?.(value)
        setHeight(ref.current.scrollHeight + 'px')
      },
      [maxLines, onChange, ref]
    )

    return (
      <StyledTextArea
        {...props}
        value={value ?? ''}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
        height={height}
      />
    )
  }
)

TextArea.propTypes = {
  ...StyledTextArea.propTypes,
  value: PropTypes.any,
  onChange: PropTypes.func,
  maxLines: PropTypes.number
}

export const Hidden = forwardRef(({ value }, ref) => {
  const internalRef = useRef()
  ref = ref ?? internalRef

  return <Input type="hidden" value={value ?? ''} ref={ref} />
})

Hidden.propTypes = {
  ...Input.propTypes,
  value: PropTypes.any
}

export const InputUnit = styled.div`
  margin: 0 1rem 0 0.5rem;
`
