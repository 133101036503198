import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useForm, FormProvider } from 'react-hook-form'
import styled from 'styled-components'
import produce from 'immer'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as uuid from 'uuid'

import { useRecordDetailData } from 'portal/components/RecordDetails'
import {
  ApplyOrCancelControls,
  useLineItemEditMixpanelEvents
} from 'portal/components/RecordEditing'

import { useModuleIds, useMountEffect } from 'portal/lib/hooks'
import { imperial } from 'portal/lib/functions'
import { Flex, Spinner, SubHeading, Text } from 'portal/lib/primitives'

import { useRecordEditing } from 'portal/store/records/editing/useRecordEditing'
import { InfractionsContext } from 'portal/store/infractions/InfractionsContext'

import { Waste } from './Waste'
import { Delivery } from './Delivery'

const MODULE_VIEWS = {
  delivery: Delivery,
  waste: Waste
}

const NEW_TITLES = {
  waste: 'New Item',
  delivery: 'New Product'
}

const EDIT_TITLES = {
  waste: 'Edit Item',
  delivery: 'Edit Product'
}

export const LineItemEdit = ({ doneUri }) => {
  const { moduleName, lineItem } = useModuleIds()
  const moduleKey = moduleName.toLowerCase()
  const ModuleView = MODULE_VIEWS[moduleKey]

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )
  const projectCountry = useSelector(
    state => state.projectDetails.modules?.country
  )
  const isImperial = imperial(projectCountry)

  const isNewItem = lineItem.toLowerCase() === 'new'

  const editing = useRecordEditing()
  const recordDetail = useRecordDetailData()
  const lineItemData = useLineItemDataTransform(
    isNewItem,
    recordDetail.item,
    lineItem,
    isImperial,
    moduleKey
  )
  const loading = recordDetail.loading || editing.uploading
  const error = recordDetail.error || editing.error

  const { form, trackLineItemEditSave, trackLineItemEditCancel } =
    useFormWithDataAndTracking(
      isNewItem,
      !recordDetail.loading && !recordDetail.error,
      lineItemData
    )

  const infractionsByName = useMemo(() => {
    if (isNewItem || !recordDetail?.infractions) {
      return null
    }

    return _.mapKeys(recordDetail?.infractions, (value, key) => {
      const [, index, keyFragment] = key.match(/data\.\w+\[(\d+)\]\.(.*)/) ?? []

      if (!index) {
        return '_notarrayitem_'
      }

      if (index != lineItem) {
        return '_wrongindex_'
      }

      return keyFragment
    })
  }, [isNewItem, lineItem, recordDetail?.infractions])

  const { onEditSaveComplete, cancelUri } = useEditCompleteRedirection(doneUri)

  const submitItem = useCallback(
    formItem => {
      let item = formItem
      if (typeof ModuleView.transformFromFormData === 'function') {
        item = ModuleView.transformFromFormData(
          formItem,
          projectTimeZone,
          isImperial
        )
      }

      const record = produce(recordDetail.item, record => {
        const items = record.data.items ?? record.data.products
        if (isNewItem) {
          item.id = uuid.v4()
          items.push(item)
        } else {
          items[lineItem] = item
        }
      })

      trackLineItemEditSave()

      editing.submitRecord(record, onEditSaveComplete, isImperial)
    },
    [
      ModuleView,
      editing,
      isImperial,
      isNewItem,
      lineItem,
      onEditSaveComplete,
      projectTimeZone,
      recordDetail.item,
      trackLineItemEditSave
    ]
  )

  return (
    <>
      {loading && <Spinner />}
      {error && (
        <Text error>Error! {typeof error === 'string' ? error : ''}</Text>
      )}

      {recordDetail.item && (
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(submitItem)}>
            <Flex.Row amount={0} verticalCentre>
              <SubHeading isChild>
                {isNewItem ? NEW_TITLES[moduleKey] : EDIT_TITLES[moduleKey]}
              </SubHeading>

              <Flex.By amount={1} />

              <ApplyOrCancelControls
                canApply={form.formState.isValid && form.formState.isDirty}
                cancelUri={cancelUri}
                onCancelled={trackLineItemEditCancel}
              />
            </Flex.Row>

            <InfractionsContext infractionsByFieldName={infractionsByName}>
              <ModuleView isImperial={isImperial} />
            </InfractionsContext>
          </Form>
        </FormProvider>
      )}
    </>
  )
}

LineItemEdit.propTypes = {
  doneUri: PropTypes.string.isRequired
}

function useEditCompleteRedirection(doneUri) {
  const history = useHistory()

  return {
    onEditSaveComplete: () => {
      history.push(doneUri)
    },
    cancelUri: doneUri ?? undefined
  }
}

function useLineItemDataTransform(
  isNewItem,
  record,
  lineItemIndex,
  isImperial,
  moduleKey
) {
  return useMemo(() => {
    const data = record?.data
    if (!data) {
      return {}
    }

    const items = data.items ?? data.products
    const lineItem = { ...items[lineItemIndex] }
    // If isImperial and module is 'delivery' break Height, Width and Length Arrays in to seperate fields
    if (isImperial && moduleKey === 'delivery') {
      lineItem.heightFoot = lineItem.height[0]?.value
      lineItem.heightInch = lineItem.height[1]?.value

      lineItem.widthFoot = lineItem.width[0]?.value
      lineItem.widthInch = lineItem.width[1]?.value

      lineItem.lengthFoot = lineItem.length[0]?.value
      lineItem.lengthInch = lineItem.length[1]?.value

      // Just return values for Volume and Weight fields
      if (
        lineItem.calculatedVolume &&
        Array.isArray(lineItem.calculatedVolume) &&
        lineItem.calculatedVolume.length > 0
      ) {
        lineItem.calculatedVolume = lineItem.calculatedVolume[0].value
      } else {
        lineItem.calculatedVolume = null
      }

      if (
        lineItem.givenVolume &&
        Array.isArray(lineItem.givenVolume) &&
        lineItem.givenVolume.length > 0
      ) {
        lineItem.givenVolume = lineItem.givenVolume[0].value
      } else {
        lineItem.givenVolume = null
      }

      if (
        lineItem.estimatedWeight &&
        Array.isArray(lineItem.estimatedWeight) &&
        lineItem.estimatedWeight.length > 0
      ) {
        lineItem.estimatedWeight = lineItem.estimatedWeight[0].value
      } else {
        lineItem.estimatedWeight = null
      }

      if (
        lineItem.weight &&
        Array.isArray(lineItem.weight) &&
        lineItem.weight.length > 0
      ) {
        lineItem.weight = lineItem.weight[0].value
      } else {
        lineItem.weight = null
      }
    }

    return isNewItem ? {} : (lineItem ?? {})
  }, [isImperial, isNewItem, lineItemIndex, moduleKey, record?.data])
}

function useFormWithDataAndTracking(isNewItem, ready, data) {
  const { moduleName } = useModuleIds()
  const moduleKey = moduleName.toLowerCase()
  const ModuleView = MODULE_VIEWS[moduleKey]

  const form = useForm({ defaultValues: {}, mode: 'all' })
  const { reset } = form

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )

  const {
    setInitialValues,
    trackLineItemEditSave,
    trackLineItemEditOpen,
    trackLineItemEditCancel
  } = useLineItemEditMixpanelEvents(form, isNewItem)

  useMountEffect(trackLineItemEditOpen)

  useEffect(() => {
    if (ready && data) {
      let formData = data
      if (typeof ModuleView.transformToFormData === 'function') {
        formData = ModuleView.transformToFormData(data, projectTimeZone)
      }
      formData = _.mapValues(formData, value => (value === 0 ? '0' : value))

      reset(formData)
      setInitialValues(formData)
    }
  }, [ModuleView, data, projectTimeZone, ready, reset, setInitialValues])

  return {
    form,
    trackLineItemEditSave,
    trackLineItemEditCancel
  }
}

const Form = styled.form`
  width: 100%;
  max-width: 80rem;

  align-self: center;
`
