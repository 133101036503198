import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Fade from '@material-ui/core/Fade'

import { colours, shadows, SPACING } from '@qflow/theme'
import { Portal } from './Portal'
import { useModalOffset, modalOffsetCSS, useResponsive } from 'portal/lib/hooks'

export function PopupCard({
  show,
  awaitLaggyLayout = false,
  anchorElementRef,
  children,
  onOutsideClick = null,
  enabledMobileResponsiveness = false,
  ...props
}) {
  const { isMobile } = useResponsive()
  const fillScreen = isMobile && enabledMobileResponsiveness

  const [xy, setXY] = useState([0, 0])

  useLayoutEffect(() => {
    if (show && anchorElementRef?.current) {
      const { y, x, height } = getElementPositionCompat(
        anchorElementRef.current
      )
      const nextXy = [x, y + height + 5]

      if (xy[0] !== nextXy[0] || xy[1] !== nextXy[1]) {
        setXY(nextXy)
      }
    }
  }, [anchorElementRef, show, xy])

  const { modalRef, offset } = useModalOffset(show, {
    awaitLaggyLayout
  })

  if (!show) {
    return null
  }

  return (
    <Portal>
      <Fade in={show} enter exit>
        <BlurPanel id="PopupCardBlurPanel" onClick={onOutsideClick} />
      </Fade>

      <Fade in={show} enter exit>
        <PositionedCard
          {...props}
          ref={modalRef}
          x={xy[0]}
          y={xy[1]}
          modalOffset={offset}
          fillScreen={fillScreen}
        >
          {children}
        </PositionedCard>
      </Fade>
    </Portal>
  )
}

PopupCard.propTypes = {
  show: PropTypes.bool,
  awaitLaggyLayout: PropTypes.bool,
  anchorElementRef: PropTypes.shape({
    current: PropTypes.any
  }).isRequired,
  enabledMobileResponsiveness: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  children: PropTypes.node
}

//
// Styled Components

const BlurPanel = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: inherit;
`

const PositionedCard = styled.div`
  position: absolute;
  top: ${({ y = 0 }) => y}px;
  left: ${({ x = 0 }) => x}px;
  max-width: 95%;
  ${modalOffsetCSS}

  ${({ fillScreen }) =>
    fillScreen &&
    css`
      top: ${SPACING.SMALL};
      left: ${SPACING.SMALL};
      right: ${SPACING.SMALL};
      max-width: none;
      transform: none;
    `}

  border-radius: 5px;
  background-color: ${colours.WHITE};
  overflow: hidden;

  ${shadows.MID}

  z-index: inherit;
`

PositionedCard.propTypes = {
  y: PropTypes.number,
  x: PropTypes.number
}

//
// Helpers

function getElementPositionCompat(element) {
  const { width, height, x, y, left, top } = element.getBoundingClientRect()

  return {
    width,
    height,
    x: x === undefined ? left : x,
    y: y === undefined ? top : y
  }
}
