import React from 'react'

import { LightThemeProvider } from '@qflow/theme'

import { Dashboard } from 'portal/components/Dashboard'
import { Sidebar } from 'portal/components/Sidebar'

export function DeliveryDashboard() {
  return (
    <LightThemeProvider>
      <Sidebar />

      <Dashboard />
    </LightThemeProvider>
  )
}
