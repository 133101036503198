import _ from 'lodash'

/**
 * Dotenv in Create React App, a brief guide.
 *
 * - Dotenv is applied at build time
 * - Anything in the environment starting with NX_ will be loaded
 * - Anything in .env starting with NX_ will be loaded, and override the outer environment if there's a conflict
 * - Loaded variables are used at build time to replace process.env.SOME_NAME with the actual value, process.env does not exist in the bundle
 */

export const env = {
  // From .env
  API_URL: process.env.NX_API_URL,
  B2C_AUTHORITY: process.env.NX_B2C_AUTHORITY,
  B2C_SIGNIN_POLICY: process.env.NX_B2C_SIGNIN_POLICY,
  B2C_RESET_PASSWORD_POLICY: process.env.NX_B2C_RESET_PASSWORD_POLICY,
  B2C_EDIT_PROFILE_POLICY: process.env.NX_B2C_EDIT_PROFILE_POLICY,
  CLIENT_ID: process.env.NX_CLIENT_ID,
  SCOPES: process.env.NX_SCOPES,
  BUGSNAG_API_KEY: process.env.NX_BUGSNAG_API_KEY,
  MIXPANEL_API_TOKEN: process.env.NX_MIXPANEL_API_TOKEN,

  // From CI environment
  CI_BUILDNUMBER: process.env.NX_CI_BUILDNUMBER || 'UNSET',
  CI_BUILDID: process.env.NX_CI_BUILDID || 'UNSET',
  CI_GIT_SHA: process.env.NX_CI_GIT_SHA || 'UNSET'
}

window.env = env

/**
 * @type {import("@azure/msal-browser").Configuration}
 */
export const msalConfig = {
  auth: {
    authority: env.B2C_AUTHORITY + '/' + env.B2C_SIGNIN_POLICY,
    knownAuthorities: [env.B2C_AUTHORITY],
    clientId: env.CLIENT_ID,
    redirectUri: location.origin + '/signin-oidc',
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: location.origin + '/'
  },
  cache: {
    cacheLocation: 'sessionStorage'
  },
  system: {
    allowRedirectInIframe: true,
    iframeHashTimeout: 8000
    // loggerOptions: {
    //   logLevel: 1,
    //   piiLoggingEnabled: true,
    //   loggerCallback: (level, message) => {
    //     console.log(message)
    //   }
    // }
  }
}

export const passwordResetAuthority =
  env.B2C_AUTHORITY + '/' + env.B2C_RESET_PASSWORD_POLICY

export const authenticationParameters = {
  scopes: _.uniq(['openid', ...env.SCOPES.split(' ')])
}

export const apiURL = env.API_URL

export const correlationHeader = 'zametek-utility-trackingcontext'
