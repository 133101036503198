import React, { useEffect } from 'react'

import { Spinner } from 'portal/lib/primitives'
import { msal } from 'portal/store/auth/msal'

export const SignOut = () => {
  useEffect(() => {
    msal.logoutRedirect()
  }, [])

  return <Spinner />
}
