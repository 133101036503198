import produce from 'immer'

import {
  RECORD_EDIT_STARTED,
  RECORD_EDITED,
  RECORD_EDIT_UPLOADED,
  RECORD_EDIT_UPLOAD_FAILED
} from './actions'

const initialState = {
  uploading: false,
  error: false
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case RECORD_EDIT_STARTED: {
        return { ...initialState }
      }
      case RECORD_EDITED: {
        draft.uploading = true
        draft.error = false
        return
      }
      case RECORD_EDIT_UPLOADED: {
        draft.uploading = false
        draft.error = false
        return
      }
      case RECORD_EDIT_UPLOAD_FAILED: {
        const { error } = action
        draft.uploading = false
        draft.error = error
        return
      }
    }
  })
}
