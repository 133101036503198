import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { sizes } from '@qflow/theme'
import { Label } from './Label'

export function SubHeading({
  IconComponent,
  children,
  isChild = false,
  style = {},
  ...props
}) {
  return (
    <SubHeadingContainer {...props} isChild={isChild}>
      {IconComponent ? (
        <IconContainer>
          <IconComponent colour="currentColor" />
        </IconContainer>
      ) : (
        <IconContainer noIcon>
          <NoIcon />
        </IconContainer>
      )}

      <Label size={sizes.SMALL_MEDIUM} isChild colour="inherit" style={style}>
        {children}
      </Label>
    </SubHeadingContainer>
  )
}

SubHeading.propTypes = {
  IconComponent: PropTypes.func,
  children: PropTypes.node.isRequired,
  isChild: PropTypes.bool
}

const SubHeadingContainer = styled.h2`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;

  height: 2rem;
  max-height: 2rem;
  padding: 0;

  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  :first-child {
    margin-top: 0;
  }

  align-items: center;

  ${({ isChild }) =>
    isChild &&
    css`
      margin: 0;
    `}

  color: inherit;
  font-weight: 500;
`

const IconContainer = styled.div`
  padding-right: 0.5rem;
  ${({ noIcon }) =>
    noIcon &&
    css`
      padding-right: 0;
    `}
`

const NoIcon = styled.div`
  width: 1px;
  height: ${sizes.LARGER};
`
