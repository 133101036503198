import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Typography, Col, Row } from 'antd'
import styled from 'styled-components'
import { sizes } from '@qflow/theme'

import { logoutStart } from 'portal/store/auth/actions'
import {
  NavDropdown,
  NavDropdownOption,
  UserAvatar
} from 'portal/lib/primitives'
import { USER_ROLES } from 'portal/lib/constants/UserRole'
import { useUserProjectRole } from 'portal/lib/hooks'
import { useUserAccountRole } from 'portal/lib/hooks/useUserAccountRole'

const { Text } = Typography

export function LoggedInControl() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const userName = auth.userId || ''
  const { accountId, projectId } = useParams()
  const { userRoleId } = useUserProjectRole(accountId, projectId)
  const { isAdmin } = useUserAccountRole(accountId)

  let userRole = null
  if (accountId) {
    if (isAdmin) {
      userRole = 'Account Admin'
    } else if (projectId && userRoleId) {
      userRole = USER_ROLES[userRoleId]
    }
  }

  const userCard = (
    <Row align="middle" gutter={[8, 0]}>
      <Col>
        <UserAvatar
          displayName={userName}
          displayTooltip={false}
          size={40}
        ></UserAvatar>
      </Col>
      <Col>
        <Row>
          <Text
            style={{
              fontSize: sizes.SMALL_MEDIUM,
              lineHeight: '1.5'
            }}
          >
            {userName}
          </Text>
        </Row>

        {accountId && (
          <Row>
            <Text
              style={{
                fontSize: sizes.SMALL,
                lineHeight: '1.5'
              }}
            >
              {userRole}
            </Text>
          </Row>
        )}
      </Col>
    </Row>
  )

  const handleLogout = useCallback(() => {
    dispatch(logoutStart())
  }, [dispatch])

  const [isOpen, setIsOpen] = useState(false)

  return (
    <FlexFill>
      <NavDropdown
        id="AccountNavButton"
        open={isOpen}
        setOpen={setIsOpen}
        label={userName}
        customLabelElement={userCard}
      >
        <NavDropdownOption
          id={`LogoutBtn`}
          label="Sign Out"
          onClick={handleLogout}
        />
      </NavDropdown>
    </FlexFill>
  )
}

const FlexFill = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
`
