import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { OptionButton, TrayItemContainer } from './primitives'

export function DropdownOption({
  item,
  selected = false,
  highlight = false,
  onSelect,
  ...props
}) {
  const handleClick = useCallback(() => onSelect(item), [item, onSelect])

  return (
    <TrayItemContainer {...props}>
      <OptionButton
        selected={selected}
        highlight={highlight}
        onClick={handleClick}
      >
        {item.label}
      </OptionButton>
    </TrayItemContainer>
  )
}

DropdownOption.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  selected: PropTypes.bool,
  highlight: PropTypes.bool,
  onSelect: PropTypes.func
}
