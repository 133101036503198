import axios from 'portal/utils/axios-msal'

export async function putRecord(
  accountId,
  projectId,
  moduleName,
  record,
  isImperial
) {
  // TODO: Handle FlexibleUnits PUT for US
  const uri =
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/modules/' +
    moduleName.toLowerCase() +
    '/records/' +
    record.recordId +
    (isImperial ? '/imperial' : '')

  return await axios.put(uri, record, {
    validateStatus: status => status === 201
  })
}
