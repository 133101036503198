import './SmallSpinner.css'

export const SmallSpinner = () => {
  return (
    <div
      className="small-spinner"
      data-testid="spinner-active"
      style={{ textAlign: 'center' }}
    >
      <div className="lds-grid">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
