import { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import flatten from 'flat'

import { useModuleIds } from 'portal/lib/hooks'
import { trackMixpanel } from 'portal/store/mixpanel/actions'

/**
 * @param {import("react-hook-form").UseFormMethods<Record<string, any>>} form
 */
export function useMixpanelEvents(form) {
  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()

  const { errors, getValues } = form

  const initialValues = useRef({})
  const setInitialValues = useCallback(values => {
    initialValues.current = values
  }, [])

  const trackEditOpen = useCallback(() => {
    const field_errors = getHookFormErrors(errors)

    dispatch(_trackEditOpen(moduleName, field_errors, accountId, projectId))
  }, [accountId, dispatch, errors, moduleName, projectId])

  const trackEditSave = useCallback(() => {
    dispatch(
      _trackEditSave(
        moduleName,
        initialValues.current,
        getValues(),
        accountId,
        projectId
      )
    )
  }, [accountId, dispatch, getValues, moduleName, projectId])

  const trackEditCancel = useCallback(() => {
    const field_errors = getHookFormErrors(errors)

    dispatch(_trackEditCancel(moduleName, field_errors, accountId, projectId))
  }, [accountId, dispatch, errors, moduleName, projectId])

  return {
    setInitialValues,
    trackEditOpen,
    trackEditSave,
    trackEditCancel
  }
}

/**
 * @param {import("react-hook-form").UseFormMethods<Record<string, any>>} form
 */
export function useLineItemEditMixpanelEvents(form, isNewItem = false) {
  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()
  const { errors, getValues } = form

  const initialValues = useRef({})
  const setInitialValues = useCallback(values => {
    initialValues.current = values
  }, [])

  const trackLineItemEditOpen = useCallback(() => {
    dispatch(
      _trackLineItemEditOpen(moduleName, isNewItem, accountId, projectId)
    )
  }, [accountId, dispatch, isNewItem, moduleName, projectId])

  const trackLineItemEditSave = useCallback(() => {
    dispatch(
      _trackLineItemEditSave(
        moduleName,
        isNewItem,
        initialValues.current,
        getValues(),
        accountId,
        projectId
      )
    )
  }, [accountId, dispatch, getValues, isNewItem, moduleName, projectId])

  const trackLineItemEditCancel = useCallback(() => {
    dispatch(
      _trackLineItemEditCancel(
        moduleName,
        isNewItem,
        errors,
        accountId,
        projectId
      )
    )
  }, [accountId, dispatch, errors, isNewItem, moduleName, projectId])

  return {
    setInitialValues,
    trackLineItemEditOpen,
    trackLineItemEditSave,
    trackLineItemEditCancel
  }
}

function _transformErrorsToFieldErrors(errors) {
  if (!errors) {
    return []
  }

  const flatErrors = flatten(errors)
  return Object.keys(flatErrors).filter(key => !!flatErrors[key])
}

function _transformValuesToFieldsChanged(initialValues, values) {
  const initialFlat = flatten(initialValues)
  const flat = flatten(values)
  const allKeys = Object.keys(flat)

  return allKeys.reduce((diff, key) => {
    if (initialFlat[key] !== flat[key]) {
      diff.push(key)
    }
    return diff
  }, [])
}

function _trackEditOpen(record_type, field_errors, accountId, projectId) {
  return trackMixpanel('frontend_records_edit_open', {
    record_type,
    field_errors_count: field_errors?.length ?? 0,
    field_errors,
    project_id: projectId,
    account_id: accountId
  })
}

function _trackEditCancel(record_type, field_errors, accountId, projectId) {
  return trackMixpanel('frontend_records_edit_cancel', {
    record_type,
    field_errors_count: field_errors?.length ?? 0,
    field_errors,
    project_id: projectId,
    account_id: accountId
  })
}

function _trackEditSave(
  record_type,
  initialValues = {},
  values = {},
  accountId,
  projectId
) {
  const fields_changed = _transformValuesToFieldsChanged(initialValues, values)
  const fields_changed_count = fields_changed.length

  return trackMixpanel('frontend_records_edit_save', {
    record_type,
    fields_changed_count,
    fields_changed,
    project_id: projectId,
    account_id: accountId
  })
}

function _trackLineItemEditOpen(
  record_type,
  is_new_item,
  accountId,
  projectId
) {
  return trackMixpanel('frontend_record_line_item_edit_open', {
    record_type,
    is_new_item,
    project_id: projectId,
    account_id: accountId
  })
}

function _trackLineItemEditSave(
  record_type,
  is_new_item,
  initialValues,
  values,
  accountId,
  projectId
) {
  const fields_changed = _transformValuesToFieldsChanged(initialValues, values)
  const fields_changed_count = fields_changed.length

  return trackMixpanel('frontend_record_line_item_edit_save', {
    record_type,
    is_new_item,
    fields_changed,
    fields_changed_count,
    project_id: projectId,
    account_id: accountId
  })
}

function _trackLineItemEditCancel(
  record_type,
  is_new_item,
  errors,
  accountId,
  projectId
) {
  const { field_errors, field_errors_count } = getHookFormErrors(errors)

  return trackMixpanel('frontend_record_line_item_edit_cancel', {
    record_type,
    is_new_item,
    field_errors,
    field_errors_count,
    project_id: projectId,
    account_id: accountId
  })
}

function getHookFormErrors(errors) {
  return _transformErrorsToFieldErrors(errors)
    .filter(e => e.endsWith('.message'))
    .map(e => e.replace('.message', ''))
}
