function RunValidationRulesFalsyIsValid(
  value: string | null,
  rules: [failureCondition: (v: string) => boolean, warning: string][]
): string[] {
  const errors: string[] = []

  if (!value) {
    return errors
  }

  for (const [failureCondition, warning] of rules) {
    if (failureCondition(value)) {
      errors.push(warning)
    }
  }

  return errors
}

function GenericLengthWarningMessage(
  max_length: number,
  min_length: number | null = null
): string {
  if (min_length) {
    return `should be between ${min_length} and ${max_length} characters`
  }
  return `should be no more than ${max_length} characters`
}

type DateObject = {
  day: string
  hour: string
  minute: string
  month: string
  year: string
}

const dateObjectHasMissingKey = (o: DateObject) => {
  if (
    o.day === '' ||
    o.hour === '' ||
    o.minute === '' ||
    o.month === '' ||
    o.year === ''
  ) {
    return true
  }

  return false
}

const formatDateObject = (o: DateObject) => {
  const formattedDateObject = { ...o }
  if (formattedDateObject.day.length === 1) {
    formattedDateObject.day = `0${formattedDateObject.day}`
  }
  if (formattedDateObject.month.length === 1) {
    formattedDateObject.month = `0${formattedDateObject.month}`
  }
  if (formattedDateObject.hour.length === 1) {
    formattedDateObject.hour = `0${formattedDateObject.hour}`
  }
  if (formattedDateObject.minute.length === 1) {
    formattedDateObject.minute = `0${formattedDateObject.minute}`
  }

  return formattedDateObject
}

export const CheckAddress = (value: string | null) => {
  const ADDRESS_MAX_LENGTH = 400
  const PERMITTED_REGEX = new RegExp(/^[a-zA-Z\d,.'&/\- ]*$/)

  const ADDRESS_LENGTH_ERROR_MESSAGE =
    GenericLengthWarningMessage(ADDRESS_MAX_LENGTH)
  const ADDRESS_SPECIAL_CHARS_WARNING_MESSAGE =
    "should only contain alphanumeric characters or / - & ' , ."

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > ADDRESS_MAX_LENGTH, ADDRESS_LENGTH_ERROR_MESSAGE],
    [v => !PERMITTED_REGEX.test(v), ADDRESS_SPECIAL_CHARS_WARNING_MESSAGE]
  ])
}

export const CheckCarrierLicence = (value: string | null) => {
  const MAX_LENGTH = 20
  const PERMITTED_CHARS = /^[a-zA-Z0-9/,.-]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = 'should only contain alphanumeric characters or / - , .'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckCarrierName = (value: string | null) => {
  const MAX_LENGTH = 60
  const PERMITTED_CHARS = /^[a-zA-Z0-9 &.'-]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = "should only contain alphanumeric characters or . ' & -"

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckCertificationScheme = (value: string | null) => {
  const MAX_LENGTH = 20
  const PERMITTED_CHARS = /^[a-zA-Z0-9 ,.//-]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = 'should only contain alphanumeric characters or / -'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckConsignmentOrDeliveryNumber = (value: string | null) => {
  const MAX_LENGTH = 50
  const PERMITTED_CHARS = /^[a-zA-Z0-9 //\-:.]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = 'should only contain alphanumeric characters or / - : .'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckContainerType = (value: string | null) => {
  const MIN_LENGTH = 5
  const MAX_LENGTH = 50
  const PERMITTED_REGEX = new RegExp(/^[a-zA-Z\d,.'()/\- ]*$/)

  const LENGTH_ERROR_MESSAGE = GenericLengthWarningMessage(
    MAX_LENGTH,
    MIN_LENGTH
  )
  const SPECIAL_CHARS_WARNING_MESSAGE =
    "should only contain alphanumeric characters or / - ( ) ' , ."

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length < MIN_LENGTH || v.length > MAX_LENGTH, LENGTH_ERROR_MESSAGE],
    [v => !PERMITTED_REGEX.test(v), SPECIAL_CHARS_WARNING_MESSAGE]
  ])
}

export const CheckDate1isBeforeDate2 = (
  date1Name: string | null,
  date1ValueObj: DateObject | null,
  date2Name: string | null,
  date2ValueObj: DateObject | null
) => {
  const DATE_WARNING = `${date2Name} should be after ${date1Name}`
  const errors: string[] = []

  if (
    !date1ValueObj ||
    !date2ValueObj ||
    dateObjectHasMissingKey(date1ValueObj) ||
    dateObjectHasMissingKey(date2ValueObj)
  ) {
    return errors
  }

  const formattedDate1ValueObj = formatDateObject(date1ValueObj)
  const formattedDate2ValueObj = formatDateObject(date2ValueObj)
  const date1Value = `${formattedDate1ValueObj.year}-${formattedDate1ValueObj.month}-${formattedDate1ValueObj.day}T${formattedDate1ValueObj.hour}:${formattedDate1ValueObj.minute}`
  const date2Value = `${formattedDate2ValueObj.year}-${formattedDate2ValueObj.month}-${formattedDate2ValueObj.day}T${formattedDate2ValueObj.hour}:${formattedDate2ValueObj.minute}`

  if (date1Value && date2Value && date1Value > date2Value) {
    errors.push(DATE_WARNING)
  }

  return errors
}

export const CheckDate1ObjisBeforeDate2String = (
  date1Name: string | null,
  date1ValueObj: DateObject | null,
  date2Name: string | null,
  date2Value: string | null
) => {
  const DATE_WARNING = `${date1Name} should be before ${date2Name}`

  const errors: string[] = []
  if (!date1ValueObj || !date2Value || dateObjectHasMissingKey(date1ValueObj)) {
    return errors
  }
  const formattedDate1ValueObj = formatDateObject(date1ValueObj)
  const date1Value = `${formattedDate1ValueObj.year}-${formattedDate1ValueObj.month}-${formattedDate1ValueObj.day}T${formattedDate1ValueObj.hour}:${formattedDate1ValueObj.minute}`

  if (date1Value && date2Value && date1Value > date2Value) {
    errors.push(DATE_WARNING)
  }

  return errors
}

export const CheckGivenSupplierName = (value: string | null) => {
  const MAX_LENGTH = 40
  const PERMITTED_CHARS = /^[a-zA-Z0-9 \-&.']*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = "should only contain alphanumeric characters or . - ' &"

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckHeightLengthWidth = (value: string | null) => {
  const DECIMAL_PLACE_REGEX = new RegExp(/^-?\d*(\.\d{1,5})?$/)
  const MAX_VALUE = 10000

  const DECIMAL_PLACE_REGEX_WARNING_MESSAGE =
    'should have a maximum of 5 decimal places'
  const HIGH_VALUE_WARNING_MESSAGE = `should be less than ${MAX_VALUE}`

  return RunValidationRulesFalsyIsValid(value, [
    [v => !DECIMAL_PLACE_REGEX.test(v), DECIMAL_PLACE_REGEX_WARNING_MESSAGE],
    [v => parseFloat(v) >= MAX_VALUE, HIGH_VALUE_WARNING_MESSAGE]
  ])
}

export const CheckNotes = (value: string | null) => {
  const MAX_LENGTH = 100
  const PERMITTED_CHARS = /^[a-zA-Z0-9 //\-,.&]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = `should only contain alphanumeric characters or / , - . &`

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckProductCertification = (value: string | null) => {
  const MAX_LENGTH = 100

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckProductName = (value: string | null) => {
  const MAX_LENGTH = 150

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckProductUsedAt = (
  producedAtName: string | null,
  producedAtValueObj: DateObject | null,
  usedAtName: string | null,
  usedAtValueObj: DateObject | null,
  exitDateName: string | null,
  exitDateValue: string | null
) => {
  const errors = [
    ...CheckDate1isBeforeDate2(
      producedAtName,
      producedAtValueObj,
      usedAtName,
      usedAtValueObj
    ),
    ...CheckDate1ObjisBeforeDate2String(
      usedAtName,
      usedAtValueObj,
      exitDateName,
      exitDateValue
    )
  ]

  return errors
}

export const CheckQuantity = (value: string | null) => {
  const MAX_LENGTH = 5

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckReceiverOrSupplierOrSenderOrCarrierReference = (
  value: string | null
) => {
  const MAX_LENGTH = 50
  const PERMITTED_CHARS = /^[a-zA-Z0-9 //\-,.]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = `should only contain alphanumeric characters or / , - .`

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckSubContractor = (value: string | null) => {
  const MAX_LENGTH = 50
  const PERMITTED_CHARS = /^[a-zA-Z0-9 \-&.']*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = "should only contain alphanumeric characters or . - ' &"

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckTradeContractor = (
  value: string | null,
  projectName: string | null
) => {
  const MAX_LENGTH = 40
  const PERMITTED_CHARS = /^[a-zA-Z0-9 \-&.']*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = "should only contain alphanumeric characters or . - ' &"
  const CONTAINS_PROJECT_NAME = 'should not match the project name'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS],
    [v => v?.toUpperCase() == projectName?.toUpperCase(), CONTAINS_PROJECT_NAME]
  ])
}

export const CheckVehicleReg = (value: string | null) => {
  const VALID_REG =
    /(?<Current>^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(?<Prefix>^[A-Z][0-9]{1,3}[A-Z]{3}$)|(?<Suffix>^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(?<DatelessLongNumberPrefix>^[0-9]{1,4}[A-Z]{1,2}$)|(?<DatelessShortNumberPrefix>^[0-9]{1,3}[A-Z]{1,3}$)|(?<DatelessLongNumberSuffix>^[A-Z]{1,2}[0-9]{1,4}$)|(?<DatelessShortNumberSufix>^[A-Z]{1,3}[0-9]{1,3}$)|(?<DatelessNorthernIreland>^[A-Z]{1,3}[0-9]{1,4}$)|(?<DiplomaticPlate>^[0-9]{3}[DX]{1}[0-9]{3}$)/g

  const INVALID_REG = 'does not appear to be a valid vehicle registration'

  return RunValidationRulesFalsyIsValid(value, [
    [v => !VALID_REG.test(v.replace(' ', '')), INVALID_REG]
  ])
}

export const CheckVolume = (value: string | null, unit: string | null) => {
  const DECIMAL_PLACE_REGEX = new RegExp(/^-?\d*(\.\d{1,5})?$/)
  const MAX_VALUE_BY_UNIT = {
    CubicMeter: 20.65,
    CubicYard: 27
  }
  const MAX_VALUE =
    unit && unit in MAX_VALUE_BY_UNIT ? MAX_VALUE_BY_UNIT[unit] : 1000

  const DECIMAL_PLACE_REGEX_WARNING_MESSAGE =
    'should have a maximum of 5 decimal places'
  const HIGH_VALUE_WARNING_MESSAGE = `should be ${MAX_VALUE} or less`

  return RunValidationRulesFalsyIsValid(value, [
    [v => !DECIMAL_PLACE_REGEX.test(v), DECIMAL_PLACE_REGEX_WARNING_MESSAGE],
    [v => parseFloat(v) > MAX_VALUE, HIGH_VALUE_WARNING_MESSAGE]
  ])
}

export const CheckWasteFacilityOperator = (value: string | null) => {
  const MAX_LENGTH = 50

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckWastePermit = (value: string | null) => {
  const MAX_LENGTH = 13

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckWasteEWC = (value: string | null) => {
  const MAX_LENGTH = 7

  const LENGTH_EXCEEDED = 'are normally 6 digits'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED]
  ])
}

export const CheckWasteSICCode = (value: string | null) => {
  const MAX_LENGTH = 8
  const PERMITTED_CHARS = /^[0-9/.]*$/g

  const LENGTH_EXCEEDED = GenericLengthWarningMessage(MAX_LENGTH)
  const INVALID_CHARS = 'should only contain numbers or / .'

  return RunValidationRulesFalsyIsValid(value, [
    [v => v.length > MAX_LENGTH, LENGTH_EXCEEDED],
    [v => !PERMITTED_CHARS.test(v), INVALID_CHARS]
  ])
}

export const CheckWeight = (value: string | null, unit: string | null) => {
  const DECIMAL_PLACE_REGEX = new RegExp(/^-?\d*(\.\d{1,5})?$/)
  const MAX_VALUE_BY_UNIT = {
    Pound: 471978,
    ShortTon: 236,
    Tonne: 214
  }
  const MAX_VALUE =
    unit && unit in MAX_VALUE_BY_UNIT ? MAX_VALUE_BY_UNIT[unit] : 1000

  const DECIMAL_PLACE_REGEX_WARNING_MESSAGE =
    'should have a maximum of 5 decimal places'
  const HIGH_VALUE_WARNING_MESSAGE = `should be ${MAX_VALUE} or less`

  return RunValidationRulesFalsyIsValid(value, [
    [v => !DECIMAL_PLACE_REGEX.test(v), DECIMAL_PLACE_REGEX_WARNING_MESSAGE],
    [v => parseFloat(v) > MAX_VALUE, HIGH_VALUE_WARNING_MESSAGE]
  ])
}
