import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useParams, Redirect, generatePath, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import querystring from 'query-string'

import { Spinner } from 'portal/lib/primitives'
import * as ROUTES from 'portal/routes'
import { bugsnag } from 'portal/utils/bugsnag'

import { queryActionRequired } from 'portal/store/destination/actions'
import { useMixpanelEvents } from './useMixpanelEvents'
import { select } from 'portal/lib/constants/moduleConstants'

export function DestinationRedirect() {
  const dispatch = useDispatch()
  const { error, data } = useSelector(state => state.destination)
  const { destinationType, destinationId } = useParams()
  const { trackRecordActionReached } = useMixpanelEvents()

  useEffect(() => {
    if (!destinationType || !destinationId) {
      return
    }

    dispatch(queryActionRequired(destinationType, destinationId))
  }, [destinationId, destinationType, dispatch])

  const location = useLocation()
  const hashParams = useMemo(() => {
    const { accountId } = querystring.parse(location.hash)

    return {
      accountId
    }
  }, [location.hash])

  const [actionRoute, setActionRoute] = useState(null)
  useEffect(() => {
    if (!destinationType || !destinationId || error) {
      setActionRoute(ROUTES.NOT_FOUND)
    }

    if (!data?.coordinates) {
      return
    }

    if (destinationType.toLowerCase() === ROUTES.DESTINATION_TYPES.RECORD) {
      const {
        accountId = hashParams.accountId,
        projectId,
        moduleName: apiModuleName,
        recordId
      } = data.coordinates
      const moduleName = apiModuleName

      if (!accountId || !projectId || !moduleName || !recordId) {
        reportInvalidCoordinates(data.coordinates)
        setActionRoute(ROUTES.NOT_FOUND)
        return
      }

      trackRecordActionReached(moduleName)

      setActionRoute(
        generatePath(
          select(moduleName, {
            waste: ROUTES.WASTE_RECORD_DETAILS,
            delivery: ROUTES.DELIVERY_RECORD_DETAILS
          }),
          {
            accountId,
            projectId,
            moduleName: moduleName.toLowerCase(),
            recordId
          }
        )
      )
    }
  }, [
    data,
    destinationId,
    destinationType,
    error,
    hashParams.accountId,
    trackRecordActionReached
  ])

  if (actionRoute != null) {
    return <Redirect to={actionRoute} />
  }

  return (
    <Container>
      <Spinner />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
`

function reportInvalidCoordinates({
  accountId = null,
  projectId = null,
  moduleName = null,
  recordId = null
}) {
  const err = `DestinationRedirect: destination data is missing required fields`
  const coordinates = {
    accountId,
    projectId,
    moduleName,
    recordId
  }

  bugsnag?.notify(new Error(err), event => {
    event.addMetadata('coordinates', coordinates)
  })
  console.error(err, coordinates)
}
