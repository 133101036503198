import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import styled from 'styled-components'
import { sizes } from '@qflow/theme'

import { projectUsersLoad } from 'portal/store/projectUsers/actions'

import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Checkbox, Dropdown, Menu, Space, Table } from 'antd'

import {
  useCheckboxStates,
  useModuleIds,
  useUserProjectRole
} from 'portal/lib/hooks'
import { convertAssignedToArrayToString } from 'portal/lib/functions'
import {
  ColourButton,
  icons,
  SmallSpinner,
  Spacer
} from 'portal/lib/primitives'

import { ActionStrip } from './ActionStrip'

import './RecordsTable.scss'

const RecordsTable = ({
  id,
  columns,
  data,
  handlePageChange,
  loading,
  count,
  showTotal,
  updateFilters,
  hasNextPage,
  footer
}) => {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()
  const { isProjectUser, isProjectAdmin, roleLoaded } = useUserProjectRole(
    accountId,
    projectId
  )

  const [
    checkedRecordIds,
    setCheckedRecordId,
    setBulkCheckedRecordId,
    clearCheckedRecords
  ] = useCheckboxStates()

  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [recordAssigneeMapping, setRecordAssigneeMapping] = useState({})
  const [selectedRecordsContainUnloaded, setSelectedRecordsContainUnloaded] =
    useState(false)

  const allRecordIds = useSelector(
    state => state.records.byModule[moduleName].allRecordIds
  )

  // Each data item returns assignedTo as an array of users
  // We will flatten this to a single comma seperate string of Usernames
  // to show in the table
  const formattedData = []

  data.forEach(dataItem => {
    if (dataItem.record && dataItem.record.assignedTo) {
      formattedData.push({
        ...dataItem,
        assignedTo: convertAssignedToArrayToString(dataItem.record.assignedTo)
      })
    } else {
      formattedData.push({
        ...dataItem
      })
    }
  })

  const handleSelectMenuClick = e => {
    switch (e.key) {
      // Select all records including ones not currently loaded
      case 'selectAll':
        setCheckAll(true)
        setBulkCheckedRecordId(allRecordIds, true)
        break
      case 'selectAllLoaded': {
        // Select currently loaded/displayed records that are not closed
        setBulkCheckedRecordId(
          data
            .filter(item => item.status !== 'Closed' && item.recordIdRaw !== 1)
            .map(item => item.recordIdRaw),
          true
        )
        break
      }
      case 'deselectAll':
        clearCheckedRecords()
        break
    }
  }

  const selectMenu = (
    <Menu onClick={handleSelectMenuClick}>
      <Menu.Item key="selectAll">Select all</Menu.Item>
      <Menu.Item key="selectAllLoaded">
        Select {formattedData.length - 1} records
      </Menu.Item>
      <Menu.Item key="deselectAll">Deselect all</Menu.Item>
    </Menu>
  )

  let updatedColumns = []

  // Add selection column if Project Admin
  if (isProjectAdmin) {
    const selectionColumn = {
      title: '',
      dataIndex: 'recordIdRaw',
      className: 'recordIdRaw',
      width: '60px',
      render: (text, record, index) =>
        index == 0 ? (
          allRecordIds && allRecordIds.length > 0 ? (
            <Dropdown overlay={selectMenu}>
              <Space>
                <Checkbox
                  style={{ pointerEvents: 'none' }}
                  indeterminate={indeterminate}
                  checked={checkAll}
                ></Checkbox>
                <DownOutlined />
              </Space>
            </Dropdown>
          ) : (
            ''
          )
        ) : (
          <Checkbox
            checked={checkedRecordIds.byKey[text]}
            onChange={e => {
              setCheckedRecordId(text, e.target.checked)
            }}
            disabled={record.status === 'Closed'}
          />
        )
    }
    updatedColumns = [selectionColumn, ...columns]
  } else {
    updatedColumns = [...columns]
  }

  useEffect(() => {
    clearCheckedRecords()
  }, [clearCheckedRecords])

  // Update select all indicator based on number selected
  useEffect(() => {
    // Filter out the Select All checkbox so it's not included in the count
    const filteredCheckedRecordIds = checkedRecordIds.list.filter(
      id => id.length > 1
    )
    if (filteredCheckedRecordIds.length === 0) {
      setIndeterminate(false)
      setCheckAll(false)
    } else if (filteredCheckedRecordIds.length < count) {
      setIndeterminate(true)
      setCheckAll(false)
    } else {
      setIndeterminate(false)
      setCheckAll(true)
    }
  }, [checkedRecordIds, count])

  // Create an object from the returned records that uses the recordId as the key
  // and an array of who is assigned to that record
  useEffect(() => {
    const tempMapping = {}
    data.forEach(dataItem => {
      if (dataItem.record) {
        tempMapping[dataItem.recordIdRaw] = dataItem.record.assignedTo || []
      }
    })
    setRecordAssigneeMapping(tempMapping)
  }, [data])

  // Check if 1 or more checkedRecordIds is not in loaded data
  useEffect(() => {
    const loadedDataIds = data.map(item => item.recordIdRaw)
    setSelectedRecordsContainUnloaded(
      checkedRecordIds.list.some(element => {
        return !loadedDataIds.includes(element)
      })
    )
  }, [checkedRecordIds, data])

  // Load Project Users in to Redux state
  useEffect(() => {
    if (roleLoaded && isProjectUser) {
      dispatch(projectUsersLoad(accountId, projectId, false))
    }
  }, [accountId, dispatch, isProjectUser, projectId, roleLoaded])

  // Infinite Scroll
  useEffect(() => {
    const handleTableScroll = event => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight
      let currentScroll = event.target.scrollTop
      if (currentScroll >= maxScroll - 65) {
        // load more data
        handlePageChange()
      }
    }

    const debouncedHandleTableScroll = debounce(
      event => handleTableScroll(event),
      100,
      {
        leading: true
      }
    )

    const tableContent = document.querySelector('.ant-table-body')
    if (tableContent) {
      // Only apply the Event Listener if there is more data to be loaded
      if (hasNextPage) {
        tableContent.addEventListener('scroll', debouncedHandleTableScroll)
      }
    }

    // Using useEffect return function to remove the Event Listener on unload
    return () => {
      tableContent.removeEventListener('scroll', debouncedHandleTableScroll)
    }
  }, [handlePageChange, hasNextPage])

  const scrollToTop = () => {
    const tableContent = document.querySelector('.ant-table-body')
    tableContent.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <ActionStrip
        recordIds={checkedRecordIds.list.filter(id => id.length > 1)}
        recordAssigneeMapping={recordAssigneeMapping}
        selectedRecordsContainUnloaded={selectedRecordsContainUnloaded}
        onClose={clearCheckedRecords}
        onApply={updateFilters}
      />

      <div
        id={id || 'RecordsTable'}
        style={{ position: 'relative' }}
        data-testid="records-table"
      >
        <Space direction="horizontal" align="center">
          <Table
            class="recordsTable"
            bordered={false}
            rowClassName={(record, index) => {
              if (index === 0) {
                if (isProjectUser) {
                  return 'table-row-filter'
                }
                // Hide Filter Row for non-project users
                return 'table-row-filter-hide'
              }
              return 'table-row-light'
            }}
            onRow={(record, rowIndex) => {
              if (rowIndex === 0) {
                return {}
              }
              return {
                'data-testid': 'RecordsTableRow'
              }
            }}
            dataSource={formattedData}
            rowKey={row => row.recordIdRaw}
            columns={updatedColumns}
            scroll={{ y: 'calc(100vh - 324px)' }}
            style={{ width: '99%' }} // if width is set to 100%, table sometimes flickers
            sticky
            pagination={false}
            footer={footer ? () => footer : null}
            loading={{
              spinning: loading,
              indicator: (
                <div>
                  <SmallSpinner />
                </div>
              )
            }}
          />
        </Space>
      </div>
      {!footer && (
        <TableFooter>
          {showTotal()}
          <ButtonContainer>
            <BTTButton id="BTTButton" onClick={scrollToTop}>
              Back to top
              <Spacer.Fine />
              <icons.ChevronRight size={sizes.SMALL} rotate={-90} />
            </BTTButton>
          </ButtonContainer>
        </TableFooter>
      )}
    </>
  )
}

const TableFooter = styled('div')`
  position: relative;
  width: 99%;
`

const ButtonContainer = styled('div')`
  position: absolute;
  right: 0;
  top: 0.75rem;
`
const BTTButton = styled(ColourButton.Basic)`
  line-height: 1;
  width: auto;
`

RecordsTable.propTypes = {
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  handlePageChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  current: PropTypes.number,
  showTotal: PropTypes.func
}

export default RecordsTable
