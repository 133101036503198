import produce from 'immer'

import {
  DESTINATION_ACTION_QUERY_REQUIRED,
  DESTINATION_ACTION_QUERY_SUCCESS,
  DESTINATION_ACTION_QUERY_FAILED
} from './actions'

const initialState = {
  working: false,
  error: false,
  data: null
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case DESTINATION_ACTION_QUERY_REQUIRED: {
        draft.working = true
        draft.error = false
        draft.data = null
        return
      }
      case DESTINATION_ACTION_QUERY_FAILED: {
        const { error } = action

        draft.working = false
        draft.error = error
        draft.data = null
        return
      }
      case DESTINATION_ACTION_QUERY_SUCCESS: {
        const { data } = action

        draft.working = false
        draft.error = null
        draft.data = data
        draft.data.recordId = data.coordinates?.recordId
        return
      }
    }
  })
}
