export const PROJECT_USER_EDIT_START = 'PROJECT_USER_EDIT_START'
export const projectUserEditStart = userDetails => ({
  type: PROJECT_USER_EDIT_START,
  userDetails
})

export const PROJECT_USERS_UPDATE_START = 'PROJECT_USERS_UPDATE_START'
export const projectUsersUpdateStart = (
  accountId,
  projectId,
  userId,
  projectPermissions,
  notificationsEnabled,
  projectSettings
) => ({
  type: PROJECT_USERS_UPDATE_START,
  accountId,
  projectId,
  userId,
  projectPermissions,
  notificationsEnabled,
  projectSettings
})

export const PROJECT_USERS_UPDATE_SUCCESS = 'PROJECT_USERS_UPDATE_SUCCESS'
export const projectUsersUpdateSuccess = (
  accountId,
  projectId,
  userId,
  memberships,
  userProfile
) => ({
  type: PROJECT_USERS_UPDATE_SUCCESS,
  accountId,
  projectId,
  userId,
  memberships,
  userProfile
})

export const PROJECT_USERS_UPDATE_FAIL = 'PROJECT_USERS_UPDATE_FAIL'
export const projectUsersUpdateFail = (
  accountId,
  projectId,
  userId,
  error
) => ({
  type: PROJECT_USERS_UPDATE_FAIL,
  accountId,
  projectId,
  userId,
  error
})
