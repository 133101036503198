import React from 'react'
import PropTypes from 'prop-types'

import { useResponsive } from 'portal/lib/hooks'

import { CardsContainer } from './Small'
import { TableContainer, ColumnHeader } from './Large'

/** @param {{
 * headers: [{ key, label, width }],
 * renderCard: ({ item: any, index: Number, headers: Array }) => object,
 * renderRow:  ({ item: any, index: Number, headers: Array }) => object,
 * data: Array
 * }} */
export function Table({
  headers = [],
  data = [],
  renderCard,
  renderRow,
  ...props
}) {
  const { isMobile } = useResponsive()

  if (isMobile) {
    return (
      <CardsContainer {...props}>
        {data.map((item, index) => renderCard({ item, index, headers }))}
      </CardsContainer>
    )
  } else {
    return (
      <TableContainer {...props}>
        <thead>
          <tr>
            {headers.map(header => (
              <ColumnHeader key={header.key}>{header.label}</ColumnHeader>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => renderRow({ item, index, headers }))}
        </tbody>
      </TableContainer>
    )
  }
}

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      width: PropTypes.string
    })
  ).isRequired,
  renderCard: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  data: PropTypes.array
}
