import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Delivery({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      {...props}
      style={{ height: size }}
    >
      <g fill={colour || 'currentColor'}>
        <path d="M16.6668 4.16668H14.1668V0.833344H2.50016C1.5835 0.833344 0.833496 1.58334 0.833496 2.50001V11.6667H2.50016C2.50016 13.05 3.61683 14.1667 5.00016 14.1667C6.3835 14.1667 7.50016 13.05 7.50016 11.6667H12.5002C12.5002 13.05 13.6168 14.1667 15.0002 14.1667C16.3835 14.1667 17.5002 13.05 17.5002 11.6667H19.1668V7.50001L16.6668 4.16668ZM5.00016 12.9167C4.3085 12.9167 3.75016 12.3583 3.75016 11.6667C3.75016 10.975 4.3085 10.4167 5.00016 10.4167C5.69183 10.4167 6.25016 10.975 6.25016 11.6667C6.25016 12.3583 5.69183 12.9167 5.00016 12.9167ZM16.2502 5.41668L17.8835 7.50001H14.1668V5.41668H16.2502ZM15.0002 12.9167C14.3085 12.9167 13.7502 12.3583 13.7502 11.6667C13.7502 10.975 14.3085 10.4167 15.0002 10.4167C15.6918 10.4167 16.2502 10.975 16.2502 11.6667C16.2502 12.3583 15.6918 12.9167 15.0002 12.9167Z" />
      </g>
    </svg>
  )
}

Delivery.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
