import { Assignee } from 'portal/lib/interfaces'

// Function: convertAssignedToArrayToString
// Parameters: [Array] assignedToArray - array of type Assignees
// Returns: [String] Comma and space (", ") seperate string of Usernames
export const convertAssignedToArrayToString = (assignedToArray: [Assignee]) => {
  const userNameArray = assignedToArray.map(assignee => assignee.userName)
  return userNameArray.sort((a, b) => a.localeCompare(b)).join(', ')
}

const unassignedAssignedToFilterKey = 'unassigned'

export const otherAssignedToFilters = [
  { key: unassignedAssignedToFilterKey, label: 'Unassigned' }
]

export interface Filters {
  [key: string]: string | string[]
}

export const splitOutUnassignedFilter = (
  filters: Filters,
  assigneeIdsFilterName: string,
  unassignedFilterName: string
) => {
  if (filters[assigneeIdsFilterName]?.includes(unassignedAssignedToFilterKey)) {
    filters[assigneeIdsFilterName] = (
      filters[assigneeIdsFilterName] as string[]
    ).filter(f => f != unassignedAssignedToFilterKey)
    filters[unassignedFilterName] = 'true'
  }
}
