import styled, { css } from 'styled-components'
import { sizes } from '@qflow/theme'

type TextProps = {
  highlight?: boolean
  highlightBg?: boolean
  error?: boolean
  dim?: boolean
  ellipsis?: boolean
  noWrap?: boolean
  size?: any
  strike?: boolean
  bold?: boolean
  right?: boolean
}

export const Text = styled.div<TextProps>`
  display: inline;

  color: ${({ highlight, error, dim, theme }) => {
    if (error) {
      return theme.primary.error
    }
    if (dim) {
      return theme.primary.fgDim
    }
    if (highlight) {
      return theme.primary.highlight
    }
    return theme.primary.fg
  }};

  background-color: ${({ highlightBg, theme }) => {
    if (highlightBg) {
      return theme.primary.highlightBg
    }

    return 'initial'
  }};
  text-decoration-color: currentColor;
  ${({ highlightBg }) =>
    highlightBg &&
    css`
      padding: 0 2px;
      margin: 0 2px;
    `}

  ${({ strike }) =>
    strike &&
    css`
      text-decoration: line-through;
    `}

  font-size: ${({ size }) => size || sizes.SMALL};
  font-weight: ${({ bold }) => (bold ? '700' : 'normal')};
  line-height: normal;
  text-align: ${({ right }) => (right ? 'end' : 'inherit')};

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    `}

  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'pre-line')};
  overflow-wrap: ${({ noWrap }) => (noWrap ? 'initial' : 'break-word')};
`
