import React, { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ErrorMessage } from '@hookform/error-message'
import { colours } from '@qflow/theme'

import {
  Text,
  icons,
  Spacer,
  Input as PrimitiveInputs,
  Dropdown
} from 'portal/lib/primitives'
import { useInfraction } from 'portal/store/infractions/InfractionsContext'
import { FormInfraction } from './FormInfraction'

const withFormInput = (WrappedComponent, defaultValueHOC = '') => {
  /**
   * @param {Object} params
   * @param {import("react-hook-form").ValidationRules} params.rules
   */
  const FormInput = ({
    label,
    name,
    hideErrors = false,
    rules,
    defaultValue = defaultValueHOC,
    disabled = false,
    simpleDataValidationRules = null,
    ...props
  }) => {
    const infraction = useInfraction(name)
    const {
      formState: { errors }
    } = useFormContext()

    const [simpleDataValidationWarnings, setSimpleDataValidationWarnings] =
      useState([])

    const runSimpleDataValidation = useCallback(
      value => {
        if (!simpleDataValidationRules) {
          return
        }
        const warnings = simpleDataValidationRules(value)
        setSimpleDataValidationWarnings(warnings)
      },
      [simpleDataValidationRules]
    )

    const splitName = name.split('.')
    const styleObject = {}

    if ((errors?.data && errors.data[splitName[1]]) || errors[name]) {
      styleObject.borderColor = colours.RED
    } else if (
      simpleDataValidationWarnings &&
      simpleDataValidationWarnings.length > 0
    ) {
      styleObject.borderColor = colours.ORANGE
    }

    return (
      <>
        <InputContainer>
          <Controller
            name={name}
            defaultValue={defaultValue}
            rules={rules}
            // Auto injects value,onChange,onBlur methods
            as={
              <WrappedComponent
                dataValidation={runSimpleDataValidation}
                disabled={disabled}
                {...props}
                style={styleObject}
              />
            }
          />
        </InputContainer>

        {infraction && (
          <>
            <Spacer.Tiny />

            <ErrorContainer>
              <FormInfraction infraction={infraction} />
            </ErrorContainer>
          </>
        )}

        {!hideErrors && (
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <ErrorContainer>
                <ErrorIcon />

                <Spacer.Fine />

                <Text error>{message}</Text>
              </ErrorContainer>
            )}
          />
        )}

        {simpleDataValidationWarnings.map((w, i) => (
          <WarningMessage key={i}>
            {label} {w}
          </WarningMessage>
        ))}
      </>
    )
  }

  FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    hideErrors: PropTypes.bool,
    disabled: PropTypes.bool,
    rules: PropTypes.object,
    defaultValue: PropTypes.any,
    simpleDataValidationRules: PropTypes.any
  }

  return FormInput
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 10rem;

  flex: 0 1 auto;
`

const ErrorIcon = styled(icons.EditError)`
  flex: 0 0 auto;
`

export const FormInput = {
  Text: withFormInput(PrimitiveInputs.Text),
  TextArea: withFormInput(PrimitiveInputs.TextArea),
  DateTimeCells: withFormInput(PrimitiveInputs.DateTimeCells, {}),
  Dropdown: withFormInput(Dropdown),
  Hidden: withFormInput(PrimitiveInputs.Hidden)
}

const ErrorContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  overflow: hidden;
  align-items: center;
  padding: 4px 0;
`

const WarningMessage = styled.div`
  min-width: 300px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M0.583008 12.5418H13.4163L6.99967 1.4585L0.583008 12.5418ZM7.58301 10.7918H6.41634V9.62516H7.58301V10.7918ZM7.58301 8.4585H6.41634V6.12516H7.58301V8.4585Z" fill="%23DA5E30" /></svg>');
  background-position: 0px 4px;
  background-repeat: no-repeat;
  color: ${colours.ORANGE};
  line-height: 13.86px;
  padding: 4px 10px 4px 18px;
`
