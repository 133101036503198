import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'portal/utils/axios-msal'
import { saveAs } from 'file-saver'

import { colours, sizes } from '@qflow/theme'
import { Text, Spacer, Clickable, icons } from 'portal/lib/primitives'
import { useModuleIds } from 'portal/lib/hooks'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { bugsnag } from 'portal/utils/bugsnag'

import { useMixpanelEvents } from './mixpanel'

export function ReportEventCell({ item }) {
  const [downloading, setDownloading] = useState(false)

  const { trackEventClick } = useMixpanelEvents()
  const trackClick = useCallback(() => {
    trackEventClick(item.sourceId, 'Report')
  }, [item, trackEventClick])

  const { accountId, projectId } = useModuleIds()
  const onClick = useCallback(() => {
    setDownloading(true)
    trackClick()
    async function download() {
      const url =
        '/accounts/' +
        accountId +
        '/projects/' +
        projectId +
        '/reports/' +
        item.sourceId
      try {
        const response = await axios.get(url, {
          validateStatus: status => status === 200
        })

        if (response.data.readUri) {
          const fileName =
            typeof item.description === 'string' &&
            item.description.endsWith('.csv')
              ? item.description
              : 'report.csv'
          saveAs(response.data.readUri, fileName)
        }
      } catch (error) {
        const errorString =
          error && error.response && error.response.data
            ? JSON.stringify(error.response.data, null, 2)
            : JSON.stringify(error)

        bugsnag?.notify(new Error(errorString), event => {
          event.addMetadata('url', url)
        })
        console.error(errorString)
      } finally {
        setDownloading(false)
      }
    }

    download()
  }, [accountId, item.description, item.sourceId, projectId, trackClick])

  function isSuccess(status) {
    return status === 'Completed'
  }

  const StateIcons = {
    Pending: <icons.StatusPending />,
    Processing: (
      <div style={{ color: colours.YELLOW }}>
        <CircularProgress size={sizes.MEDIUM} thickness={5} color={'inherit'} />
      </div>
    ),
    Error: <icons.StatusFailed />,
    Failed: <icons.StatusFailed />
  }

  return (
    <>
      {isSuccess(item.status) && (
        <BorderedClickable
          onClick={onClick}
          disabled={downloading}
          contentAlignLeft
        >
          {downloading ? (
            <CircularProgress
              size={sizes.LARGER}
              thickness={5}
              color={'inherit'}
            />
          ) : (
            <icons.Download />
          )}

          <Spacer.Fine />

          <StatusText dim={['Pending', 'Processing'].includes(item.status)}>
            {item.status === 'Completed' ? 'Download' : item.status}
          </StatusText>
        </BorderedClickable>
      )}

      {!isSuccess(item.status) && !!StateIcons[item.status] && (
        <>
          {StateIcons[item.status]}

          <StatusText dim={['Pending', 'Processing'].includes(item.status)}>
            {item.status}
          </StatusText>
        </>
      )}
    </>
  )
}

ReportEventCell.propTypes = {
  item: PropTypes.object.isRequired
}

const StatusText = styled(Text)`
  color: inherit;
  min-width: 4rem;
`

const BorderedClickable = styled(Clickable)`
  background-color: ${colours.WHITE};
  border: 1px solid ${colours.BORDER_GREY};
  border-radius: 5px;

  :focus {
    background-color: ${colours.CONTROL_PANEL_GREY};
    border-color: ${colours.TEAL};
  }

  :hover {
    color: ${colours.WHITE};
    background-color: ${colours.CORE_NAVY};
    border-color: ${colours.CORE_NAVY};
  }
`
