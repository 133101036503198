import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { sizes } from '@qflow/theme'

import {
  AssignmentContainer,
  AssignmentStripCard,
  AssignmentButtonContainer,
  ButtonWithMessageContainer,
  LoadingMessage,
  MaxThresholdMessage
} from 'portal/components/CommonStyles/AssignmentStripStyledComponents'

import { ColourButton, icons, Spacer, Text } from 'portal/lib/primitives'
import { AssignmentRow } from 'portal/lib/sharedComponents/AssignmentRow'
import 'portal/components/CommonStyles/antd-modal.scss'

export function AssignmentStrip({
  users,
  onApply,
  eventCount,
  recordCount,
  maxAssignment
}) {
  const emptyAssignee = {
    user: '',
    role: 2
  }

  const [assignees, setAssignees] = useState([{ id: 0, ...emptyAssignee }])
  const [isApplyBtnDisabled, setIsApplyBtnDisabled] = useState(true)
  const [showMaxThresholdMessage, setShowMaxThresholdMessage] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const userCount = Object.keys(users).length

  const showConfirmationModal = () => {
    setIsConfirmationModalOpen(true)
  }
  const handleConfirmationOk = () => {
    onApply(assignees)
    setIsConfirmationModalOpen(false)
  }
  const handleConfirmationCancel = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleAddRow = () => {
    // Check if max assignment threshold would be exceeded
    if (maxAssignment && recordCount * (assignees.length + 1) > maxAssignment) {
      setShowMaxThresholdMessage(true)
    } else {
      setShowMaxThresholdMessage(false)
      // Add new Assignee Row
      const ids = assignees.map(assignee => assignee.id)
      const newId = Math.max(...ids, 0) + 1
      setAssignees(currentAssignees => [
        ...currentAssignees,
        { id: newId, ...emptyAssignee }
      ])
    }
  }

  const handleDeleteRow = id => {
    const newAssignees = [...assignees]
    const assigneeIndex = newAssignees.findIndex(assignee => assignee.id === id)
    newAssignees.splice(assigneeIndex, 1)
    setAssignees(newAssignees)

    setShowMaxThresholdMessage(false)
  }

  const handleUpdateAssignee = (id, user, role) => {
    const newAssignees = [...assignees]
    const assigneeIndex = newAssignees.findIndex(assignee => assignee.id === id)
    newAssignees[assigneeIndex].user = user
    newAssignees[assigneeIndex].role = role
    setAssignees(newAssignees)
  }

  useEffect(() => {
    // Check all assignees has a value for user stored
    // Disable "Apply" button if one or more values are bad (empty string or null)
    const badAssignees = assignees.filter(
      assignee => assignee.user === '' || assignee.user === null
    )
    if (badAssignees.length > 0) {
      setIsApplyBtnDisabled(true)
    } else {
      setIsApplyBtnDisabled(false)
    }
  }, [assignees])

  return (
    <AssignmentContainer>
      <AssignmentStripCard>
        {userCount < 1 && (
          <LoadingMessage>
            <Text bold>Loading users, please wait...</Text>
          </LoadingMessage>
        )}

        {userCount > 0 &&
          assignees.map(assignee => (
            <AssignmentRow
              key={assignee.id}
              {...assignee}
              onChange={handleUpdateAssignee}
              onDelete={handleDeleteRow}
              disableRemoveRow={assignees.length < 2}
              users={users}
            />
          ))}

        <AssignmentButtonContainer
          $remainingAssignees={userCount - assignees.length}
        >
          {userCount > assignees.length && (
            <ColourButton.Basic type="button" onClick={handleAddRow}>
              <icons.Add colour="currentColor" size={sizes.MEDIUM} />
              <Spacer.Fine />
              Add row
            </ColourButton.Basic>
          )}
          <ButtonWithMessageContainer>
            <Text size={sizes.X_SMALL}>Users will be notified by email</Text>
            <ColourButton.Action
              type="button"
              onClick={showConfirmationModal}
              disabled={isApplyBtnDisabled}
            >
              Apply to {eventCount} event{eventCount > 1 && 's'}
            </ColourButton.Action>
          </ButtonWithMessageContainer>
        </AssignmentButtonContainer>
        {showMaxThresholdMessage && (
          <MaxThresholdMessage>
            <p>
              <Text error bold>
                Cannot add user row. You have hit the maximum number of changes
                that can be made in a single request.
              </Text>
            </p>
            <p>
              <Text error bold>
                Please apply to these users and then re-select to apply to
                additional users.
              </Text>
            </p>
          </MaxThresholdMessage>
        )}
      </AssignmentStripCard>

      <Modal
        title="Assigning records"
        visible={isConfirmationModalOpen}
        onOk={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
        footer={[
          <ColourButton.Basic
            key="back"
            type="button"
            onClick={handleConfirmationCancel}
            style={{ display: 'inline-block', marginRight: '.75rem' }}
          >
            Cancel
          </ColourButton.Basic>,
          <ColourButton.Action
            key="submit"
            type="button"
            onClick={handleConfirmationOk}
            style={{ display: 'inline-block' }}
          >
            OK
          </ColourButton.Action>
        ]}
      >
        <p>
          The {eventCount} selected event{eventCount > 1 && 's'} relate
          {eventCount === 1 && 's'} to {recordCount} record
          {recordCount > 1 && 's'}.
        </p>
        <p>
          {recordCount > 1
            ? 'The selected users will be assigned to these records and ALL of their related events.'
            : 'The selected users will be assigned to this record and ALL of its related events.'}
        </p>
      </Modal>
    </AssignmentContainer>
  )
}
