import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Units({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'} fillRule="evenodd">
        <path d="M3 0v1.5H1.5v.75H3v1.5h-.75v.75H3V6h-.75v.75L3 6.749V8.25h-.75V9H3v1.5h-.75v.75H3v1.5H1.5v.75H3V15H0V0h3z" />
        <rect width="5.25" height="1" x="6.75" rx=".375" />
        <path d="M9.375 1.75L11.25 5.75 7.5 5.75z" />
        <path d="M9 3H10V12H9z" />
        <rect width="5.25" height="1" x="6.75" y="14.25" rx=".375" />
        <path
          d="M9.375 9.25L11.25 13.25 7.5 13.25z"
          transform="rotate(-180 9.375 11.25)"
        />
      </g>
    </svg>
  )
}

Units.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
