export const EVENTS_LOAD = 'EVENTS_LOAD'
export const eventsLoad = (
  accountId,
  projectId,
  moduleName,
  pageSize,
  continuationToken,
  loadedEvents,
  filter
) => ({
  type: EVENTS_LOAD,
  accountId,
  projectId,
  moduleName,
  pageSize,
  continuationToken,
  loadedEvents,
  filter
})

export const EVENTS_RELOAD = 'EVENTS_RELOAD'
export const eventsReload = (
  accountId,
  projectId,
  moduleName,
  pageSize,
  loadedEvents,
  filter
) => ({
  type: EVENTS_RELOAD,
  accountId,
  projectId,
  moduleName,
  pageSize,
  loadedEvents,
  filter
})

export const EVENTS_LOAD_SUCCESS = 'EVENTS_LOAD_SUCCESS'
export const eventsLoadSuccess = (
  accountId,
  projectId,
  moduleName,
  events,
  continuationToken
) => ({
  type: EVENTS_LOAD_SUCCESS,
  accountId,
  projectId,
  moduleName,
  events,
  continuationToken
})

export const EVENTS_RELOAD_SUCCESS = 'EVENTS_RELOAD_SUCCESS'
export const eventsReloadSuccess = (
  accountId,
  projectId,
  moduleName,
  events,
  continuationToken
) => ({
  type: EVENTS_RELOAD_SUCCESS,
  accountId,
  projectId,
  moduleName,
  events,
  continuationToken
})

export const EVENTS_LOAD_FAILURE = 'EVENTS_LOAD_FAILURE'
export const eventsLoadFailure = (accountId, projectId, moduleName, error) => ({
  type: EVENTS_LOAD_FAILURE,
  accountId,
  projectId,
  moduleName,
  error
})

export const EVENTS_COUNT_LOAD = 'EVENTS_COUNT_LOAD'
export const eventsCountLoad = (accountId, projectId, moduleName, filter) => ({
  type: EVENTS_COUNT_LOAD,
  accountId,
  projectId,
  moduleName,
  filter
})

export const EVENTS_COUNT_RELOAD = 'EVENTS_COUNT_RELOAD'
export const eventsCountReload = (
  accountId,
  projectId,
  moduleName,
  filter
) => ({
  type: EVENTS_COUNT_RELOAD,
  accountId,
  projectId,
  moduleName,
  filter
})

export const EVENTS_COUNT_LOAD_SUCCESS = 'EVENTS_COUNT_LOAD_SUCCESS'
export const eventsCountLoadSuccess = (
  accountId,
  projectId,
  moduleName,
  count
) => ({
  type: EVENTS_COUNT_LOAD_SUCCESS,
  accountId,
  projectId,
  moduleName,
  count
})

export const EVENTS_COUNT_LOAD_FAILURE = 'EVENTS_COUNT_LOAD_FAILURE'
export const eventsCountLoadFailure = (
  accountId,
  projectId,
  moduleName,
  error
) => ({
  type: EVENTS_COUNT_LOAD_FAILURE,
  accountId,
  projectId,
  moduleName,
  error
})

export const ASSIGN_RECORDS = 'ASSIGN_RECORDS'
export const assignRecords = (
  accountId,
  projectId,
  relatedRecords,
  assignTo,
  onSuccess
) => ({
  type: ASSIGN_RECORDS,
  accountId,
  projectId,
  relatedRecords,
  assignTo,
  callbacks: { onSuccess }
})

export const ASSIGN_RECORDS_SUCCESS = 'ASSIGN_RECORDS_SUCCESS'
export const assignRecordsSuccess = (accountId, projectId, relatedRecords) => ({
  type: ASSIGN_RECORDS_SUCCESS,
  accountId,
  projectId,
  relatedRecords
})

export const ASSIGN_RECORDS_FAILURE = 'ASSIGN_RECORDS_FAILURE'
export const assignRecordsFailure = (
  accountId,
  projectId,
  relatedRecords,
  error
) => ({
  type: ASSIGN_RECORDS_FAILURE,
  accountId,
  projectId,
  relatedRecords,
  error
})

export const UNASSIGN_RECORDS = 'UNASSIGN_RECORDS'
export const unassignRecords = (
  accountId,
  projectId,
  relatedRecords,
  unassignUsers,
  onSuccess
) => ({
  type: UNASSIGN_RECORDS,
  accountId,
  projectId,
  relatedRecords,
  unassignUsers,
  callbacks: { onSuccess }
})

export const UNASSIGN_RECORDS_SUCCESS = 'UNASSIGN_RECORDS_SUCCESS'
export const unassignRecordsSuccess = (
  accountId,
  projectId,
  relatedRecords
) => ({
  type: UNASSIGN_RECORDS_SUCCESS,
  accountId,
  projectId,
  relatedRecords
})

export const UNASSIGN_RECORDS_FAILURE = 'UNASSIGN_RECORDS_FAILURE'
export const unassignRecordsFailure = (
  accountId,
  projectId,
  relatedRecords,
  error
) => ({
  type: UNASSIGN_RECORDS_FAILURE,
  accountId,
  projectId,
  relatedRecords,
  error
})

export const DISMISS_EVENTS = 'DISMISS_EVENTS'
export const dismissEvents = (accountId, projectId, eventKeys, onSuccess) => ({
  type: DISMISS_EVENTS,
  accountId,
  projectId,
  eventKeys,
  callbacks: { onSuccess }
})

export const DISMISS_EVENTS_SUCCESS = 'DISMISS_EVENTS_SUCCESS'
export const dismissEventsSuccess = (accountId, projectId, eventKeys) => ({
  type: DISMISS_EVENTS_SUCCESS,
  accountId,
  projectId,
  eventKeys
})

export const DISMISS_EVENTS_FAILURE = 'DISMISS_EVENTS_FAILURE'
export const dismissEventsFailure = (
  accountId,
  projectId,
  eventKeys,
  error
) => ({
  type: DISMISS_EVENTS_FAILURE,
  accountId,
  projectId,
  eventKeys,
  error
})

export const EVENTS_FILTER_LOAD = 'EVENTS_FILTER_LOAD'
export const loadEventsFilter = (accountId, projectId, moduleName) => ({
  type: EVENTS_FILTER_LOAD,
  accountId,
  projectId,
  moduleName
})

export const EVENTS_FILTER_LOAD_SUCCESS = 'EVENTS_FILTER_LOAD_SUCCESS'
export const eventsFilterLoadSuccess = (
  accountId,
  projectId,
  moduleName,
  filterOptions
) => ({
  type: EVENTS_FILTER_LOAD_SUCCESS,
  accountId,
  projectId,
  moduleName,
  filterOptions
})

export const EVENTS_FILTER_LOAD_FAILURE = 'EVENTS_FILTER_LOAD_FAILURE'
export const eventsFilterLoadFailure = (
  accountId,
  projectId,
  moduleName,
  error
) => ({
  type: EVENTS_FILTER_LOAD_FAILURE,
  accountId,
  projectId,
  moduleName,
  error
})
