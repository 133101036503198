import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { sizes } from '@qflow/theme'
import { ColourButton, icons, Spacer } from 'portal/lib/primitives'
import { useModuleIds, useFileInput } from 'portal/lib/hooks'

import {
  uploadStart,
  uploadReset
} from 'portal/store/records/attachmentUpload/actions'
import { trackAttachmentUpload } from './mixpanel/trackAttachmentUpload'

const ICON_SIZE = sizes.SMALL

export function AttachmentUploadButton({ record }) {
  const { working } = useSelector(state => state.records.attachmentUpload)

  const { handleUpload } = useAttachmentUploader(record)
  const { hiddenInput, handleUploadClick } = useFileInput(
    'image/*,text/*,application/pdf',
    handleUpload
  )

  return (
    <ColourButton.Basic onClick={handleUploadClick} disabled={working}>
      {working ? (
        <icons.Loading size={ICON_SIZE} />
      ) : (
        <icons.Plus size={ICON_SIZE} />
      )}
      <Spacer.Tiny />
      Add
      {hiddenInput}
    </ColourButton.Basic>
  )
}

AttachmentUploadButton.propTypes = {
  record: PropTypes.object.isRequired
}

function useAttachmentUploader(record) {
  const { accountId, projectId, moduleName } = useModuleIds()
  const dispatch = useDispatch()
  const handleUpload = useCallback(
    files => {
      const file = files[0]
      if (file) {
        dispatch(
          uploadStart(accountId, projectId, moduleName, record.recordId, file)
        )
        setTimeout(() => {
          dispatch(
            trackAttachmentUpload(accountId, projectId, moduleName, record)
          )
        }, 0)
      }
    },
    [accountId, dispatch, moduleName, projectId, record]
  )

  useEffect(() => {
    return () => {
      dispatch(uploadReset())
    }
  }, [dispatch])

  return {
    handleUpload
  }
}
