import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { ClickableText } from 'portal/lib/primitives/text'

export const LabelButton = forwardRef(
  ({ open = false, onClick, children, ...props }, ref) => {
    return (
      <ClickableText
        id="LabelButton"
        active={open}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {children}
      </ClickableText>
    )
  }
)

LabelButton.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}
