import React from 'react'
import PropTypes from 'prop-types'

export function QflowLogo({
  size = '100%',
  colour = 'currentColor',
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="24"
      viewBox="0 0 84 24"
      style={{
        width: size,
        height: 'auto'
      }}
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#00A4B4"
          d="M10.03,14.93l2.88,3.31c-0.99,0.25-2.05,0.3-3.15,0.09c-3.13-0.58-5.56-3.19-5.91-6.35   c-0.52-4.63,3.3-8.53,7.9-8.15c3.24,0.27,6.3,3.24,6.66,6.47c0.19,1.7-0.21,3.3-1.02,4.63h4.2c0.5-1.38,0.74-2.88,0.65-4.45   C21.91,4.74,17.15,0.14,11.39,0C4.89-0.15-0.38,5.28,0.02,11.84c0.34,5.41,4.62,9.84,10.01,10.37c1.99,0.19,3.88-0.14,5.56-0.88   l0.45,0.51c0.83,0.96,2.03,1.5,3.3,1.5h3.4l-3.76-4.33l-2.25-2.58c-0.83-0.96-2.03-1.5-3.3-1.5H10.03z"
        />
        <path
          fill={colour || 'currentColor'}
          d="M34.6,7.3v3.03h-4.18v12.84h-3.69V10.33H24.7V7.27h2.03V6.12c0-1.78,0.43-3.09,1.28-3.95   c0.85-0.86,2.07-1.29,3.65-1.29c0.65,0,1.2,0.04,1.67,0.12c0.46,0.08,0.9,0.18,1.3,0.3v3.09c-0.36-0.12-0.71-0.22-1.04-0.29   C33.25,4.04,32.89,4,32.48,4c-1.41,0-2.12,0.81-2.12,2.42V7.3H34.6z"
        />
        <path
          fill={colour || 'currentColor'}
          d="M37.19,23.17V1.06h3.69v22.1H37.19z"
        />
        <path
          fill={colour || 'currentColor'}
          d="M59.32,18.41c-0.43,1.01-1.03,1.89-1.79,2.65c-0.76,0.76-1.66,1.36-2.71,1.8c-1.05,0.44-2.19,0.67-3.42,0.67   c-1.21,0-2.34-0.22-3.38-0.65c-1.04-0.43-1.94-1.03-2.69-1.79s-1.35-1.64-1.77-2.63c-0.42-1-0.64-2.07-0.64-3.22   c0-1.15,0.22-2.24,0.65-3.25c0.43-1.02,1.03-1.91,1.79-2.66c0.76-0.76,1.65-1.36,2.69-1.8c1.04-0.44,2.17-0.67,3.41-0.67   c1.23,0,2.37,0.22,3.41,0.65c1.04,0.43,1.94,1.03,2.69,1.79s1.35,1.64,1.77,2.65c0.42,1.01,0.64,2.09,0.64,3.24   S59.75,17.4,59.32,18.41z M55.92,13.24c-0.24-0.63-0.58-1.18-1.01-1.65c-0.43-0.47-0.94-0.85-1.53-1.12   c-0.59-0.27-1.25-0.41-1.97-0.41c-0.72,0-1.38,0.14-1.97,0.41c-0.59,0.27-1.1,0.64-1.52,1.11c-0.42,0.46-0.74,1-0.96,1.62   c-0.22,0.62-0.33,1.28-0.33,1.98c0,0.71,0.12,1.38,0.36,2.01c0.24,0.64,0.58,1.19,1.01,1.65c0.43,0.46,0.94,0.83,1.53,1.11   c0.59,0.27,1.24,0.41,1.94,0.41c0.74,0,1.41-0.14,2-0.41c0.59-0.27,1.1-0.64,1.52-1.11c0.42-0.46,0.74-1,0.96-1.62   c0.22-0.62,0.33-1.28,0.33-1.98C56.28,14.53,56.16,13.86,55.92,13.24z"
        />
        <path
          fill={colour || 'currentColor'}
          d="M77.07,18.14l3.27-10.96H84l-5.24,16.11h-3.27l-3.57-10.93l-3.6,10.93h-3.27L59.87,7.18h3.75l3.21,10.96   l3.54-11.02h3.12L77.07,18.14z"
        />
      </g>
    </svg>
  )
}

QflowLogo.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.any
}
