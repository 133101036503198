import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Bell({ size = sizes.SMALL, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'}>
        <path d="M7.00016 17.125C7.91683 17.125 8.66683 16.375 8.66683 15.4583H5.3335C5.3335 16.375 6.07516 17.125 7.00016 17.125ZM12.0002 12.125V7.95833C12.0002 5.4 10.6335 3.25833 8.25016 2.69167V2.125C8.25016 1.43333 7.69183 0.875 7.00016 0.875C6.3085 0.875 5.75016 1.43333 5.75016 2.125V2.69167C3.3585 3.25833 2.00016 5.39167 2.00016 7.95833V12.125L0.333496 13.7917V14.625H13.6668V13.7917L12.0002 12.125Z" />
      </g>
    </svg>
  )
}

Bell.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
