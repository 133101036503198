import styled from 'styled-components'

export const Divider = styled.hr`
  border: none;

  border-top-style: solid;
  border-top-width: 1px;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;

  border-color: ${({ theme }) => theme.controlPanel.border};
`
