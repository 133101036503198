import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function SortArrows({ size = sizes.SMALL, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="11"
      viewBox="0 0 6 11"
      {...props}
      style={{ width: size, height: size }}
    >
      <g fill={colour || 'currentColor'} fillRule="evenodd">
        <path d="M3.539.645l1.76 2.4A.6.6 0 0 1 4.816 4h-3.6a.6.6 0 0 1-.476-.965l1.839-2.4a.6.6 0 0 1 .96.01zM3.539 10.355l1.76-2.4A.6.6 0 0 0 4.816 7h-3.6a.6.6 0 0 0-.476.965l1.839 2.4a.6.6 0 0 0 .96-.01z" />
      </g>
    </svg>
  )
}

SortArrows.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
