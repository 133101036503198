export const RECORD_DETAILS_LOAD = 'RECORD_DETAILS_LOAD'
export const loadDetails = (
  accountId,
  projectId,
  moduleName,
  recordId,
  isImperial
) => {
  return {
    type: RECORD_DETAILS_LOAD,
    accountId,
    projectId,
    moduleName,
    recordId,
    isImperial
  }
}

export const RECORD_DETAILS_LOAD_SUCCESS = 'RECORD_DETAILS_LOAD_SUCCESS'
export const loadDetailsSuccess = record => {
  return {
    type: RECORD_DETAILS_LOAD_SUCCESS,
    record
  }
}

export const RECORD_DETAILS_LOAD_FAILURE = 'RECORD_DETAILS_LOAD_FAILURE'
export const loadDetailsFailure = error => {
  return {
    type: RECORD_DETAILS_LOAD_FAILURE,
    error
  }
}

export const RECORD_DETAILS_CLEAR = 'RECORD_DETAILS_CLEAR'
export const clearDetails = () => {
  return {
    type: RECORD_DETAILS_CLEAR
  }
}
