import { all, takeEvery, takeLeading } from 'redux-saga/effects'

import { mixpanel } from 'portal/utils/mixpanel'
import { AUTH_CHECK_SUCCESS } from 'portal/store/auth/actions'
import { TRACK_MIXPANEL } from './actions'

function* identify(action) {
  const { userUuid, isQualisFlowInternal = false } = action

  // Link this user's machine to their account
  /* 
    Though the two work together, do not call identify() at the same time as alias(). 
    Calling the two at the same time can cause a race condition, so it is best practice 
    to call identify on the original, anonymous ID right after you've aliased it
    https://developer.mixpanel.com/docs/javascript-full-api-reference#section-mixpanel-identify
  */
  const oldId = mixpanel.get_distinct_id()
  mixpanel.alias(userUuid)
  mixpanel.identify(oldId)

  // Set people properties
  mixpanel.people.append()
  mixpanel.people.set_once({
    first_login: new Date()
  })
  mixpanel.people.set({
    user_uuid: userUuid,
    last_login: new Date(),
    qflow_internal: isQualisFlowInternal
  })

  // Set super properties to be attached on every future event
  yield mixpanel.register({
    user_uuid: userUuid
  })
}

function* track(action) {
  yield mixpanel.track(action.name, { ...action.params })
  if (action.opts?.reset) {
    yield mixpanel.reset()
  }
}

export function* watch() {
  yield all([
    takeEvery([AUTH_CHECK_SUCCESS], identify),
    takeLeading(TRACK_MIXPANEL, track)
  ])
}
