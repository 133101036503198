import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useDimensions } from 'portal/lib/hooks'

export function Panel({ children, footer, extraElements, ...props }) {
  const { height, dimensionsRef: footerRef } = useDimensions()

  return (
    <Container {...props}>
      {extraElements}

      <ScrollerContainer>
        <Scroller footerPadding={footer ? height : null}>{children}</Scroller>
      </ScrollerContainer>

      {footer && <FooterContainer ref={footerRef}>{footer}</FooterContainer>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;

  background-color: ${({ theme }) => theme.controlPanel.bg};

  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: ${({ theme }) => theme.controlPanel.border};

  height: 100%;
  max-height: 100%;
  min-width: 16rem;
  max-width: 16rem;
  overflow: visible;

  ${({ isMobile, mobileShow }) =>
    isMobile &&
    css`
      position: absolute;
      z-index: 2000;
      top: 0;
      bottom: 0;
      right: ${mobileShow ? 0 : '-16rem'};

      min-width: auto;
      width: 90%;
      max-width: 16rem;

      transition: all 0.3s ease-in-out;
      transition-property: right;
    `}
`

const ScrollerContainer = styled.div`
  display: flex;
  position: relative;

  height: 100%;
  max-height: 100%;
  width: 100%;

  overflow: auto;
  overflow-y: scroll;

  box-sizing: border-box;
`

const Scroller = styled.div`
  display: flex;

  position: absolute;
  left: 0;
  right: 0;
  margin: 1rem;

  flex-direction: column;
  justify-content: flex-start;

  ${({ footerPadding }) =>
    footerPadding != null &&
    css`
      padding-bottom: ${footerPadding}px;
    `}
`

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  padding-top: 0;

  pointer-events: none;
  > * {
    pointer-events: initial;
  }

  display: flex;
  justify-content: center;
`

Panel.propTypes = {
  ...Container.propTypes,
  footer: PropTypes.node,
  extraElements: PropTypes.node
}
