import React from 'react'
import {
  RecordLink,
  RecordsSelect
} from 'portal/components/RecordsTable/RecordsColumn'
import { HeadingWithDropdown } from 'portal/components/RecordsTable/TableHeading/HeadingWithDropdown'

import { RECORD_STATUS } from 'portal/lib/constants'
import { recordsComponentFactory } from './recordsComponentFactory'
import { Input } from 'antd'

const { Search } = Input

function setCellId(record, rowIndex) {
  if (rowIndex === 0) {
    return {}
  }
  if (record.removed) {
    return {
      id: 'RecordsTableRow',
      style: {
        textDecoration: 'line-through',
        opacity: '0.5'
      }
    }
  }
  return {
    id: 'RecordsTableCell'
  }
}

export const Delivery = recordsComponentFactory({
  ModuleName: 'Delivery',
  dateField: 'deliveryDate',
  buildRecordsForDisplay: function (records, projectTimeZone) {
    return {
      data: (records ?? []).map(record => {
        return {
          record: record,
          createdAt: new Date(record.createdAt).toLocaleDateString([], {
            timeZone: projectTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }),
          date: new Date(record.data.deliveryArrivalDate).toLocaleString([], {
            timeZone: projectTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }),
          deliveryId: record.data.deliveryId,
          deliveryAddress: record.data.deliveryAddress,
          tradeContractor: record.data.tradeContractor,
          knownSupplierName: record.data.knownSupplierName,
          recordIdRaw: record.recordId,
          removed: record.status === RECORD_STATUS.REMOVED
        }
      })
    }
  },
  buildColumnsForTable: function (
    { size },
    state,
    fetchOptions,
    assignedToOptions,
    filters,
    updateFilters,
    projectId,
    tableId,
    accountId,
    useCreatedAtDate,
    updateUseCreatedAtDate
  ) {
    const handleTableDateColumnClick = key => {
      if (key && updateUseCreatedAtDate) {
        updateUseCreatedAtDate(key === 'createdAt')
      }
    }

    const dateOptions = [
      { label: 'Arrival date', key: 'arrivalDate' },
      { label: 'Created at', key: 'createdAt' }
    ]

    return [
      useCreatedAtDate
        ? {
            dataIndex: 'createdAt',
            className: 'createdAt',
            title: () => (
              <HeadingWithDropdown
                onMenuItemClick={handleTableDateColumnClick}
                current="Created At"
                dropdownOptions={dateOptions}
              />
            ),
            width: '8.125rem',
            onCell: setCellId
          }
        : {
            dataIndex: 'date',
            className: 'arrivalDate',
            title: () => (
              <HeadingWithDropdown
                onMenuItemClick={handleTableDateColumnClick}
                current="Arrival Date"
                dropdownOptions={dateOptions}
              />
            ),
            width: '8.125rem',
            onCell: setCellId
          },
      {
        dataIndex: 'deliveryId',
        className: 'deliveryId',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return (
              <Search
                defaultValue={filters.deliveryId}
                placeholder={'Note: Case sensitive'}
                onSearch={value => {
                  updateFilters('deliveryId', value)
                }}
                onBlur={e => {
                  if (e.target.value != filters.deliveryId) {
                    updateFilters('deliveryId', e.target.value)
                  }
                }}
                data-testid={'deliveryIdFilter'}
              />
            )
          } else {
            return record.deliveryId
          }
        },
        title: 'Delivery ID',
        onCell: setCellId
      },
      size > 1 && {
        dataIndex: 'deliveryAddress',
        className: 'deliveryAddress',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'deliveryAddress',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.deliveryAddress
          }
        },
        title: 'Delivery Address',
        onCell: setCellId
      },
      size > 1 && {
        title: 'Trade Contractor',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'tradeContractor',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.tradeContractor
          }
        },
        dataIndex: 'tradeContractor',
        className: 'tradeContractor',
        onCell: setCellId
      },
      size > 2 && {
        title: 'Supplier Company',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'knownSupplierName',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.knownSupplierName
          }
        },
        dataIndex: 'knownSupplierName',
        className: 'knownSupplierName',
        onCell: setCellId
      },
      size > 1 && {
        title: 'Assigned To',
        ellipsis: false,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'assignedTo',
              state,
              assignedToOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.assignedTo
          }
        },
        dataIndex: 'assignedTo',
        className: 'assignedTo',
        onCell: setCellId
      },
      RecordLink(setCellId, filters, updateFilters)
    ]
  },
  initialFilterState: {
    deliveryId: '',
    deliveryDate: { startDate: undefined, endDate: undefined },
    deliveryAddress: [],
    tradeContractor: [],
    knownSupplierName: [],
    startDate: undefined,
    endDate: undefined
  }
})
