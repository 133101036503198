import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { SPACING } from '@qflow/theme'
import { Text, Spacer } from 'portal/lib/primitives'

const TypeCode = {
  ColumnMissing: 'ColumnMissing',
  Parsing: 'Parsing',
  Value: 'Value',
  Key: 'Key'
}

const SeverityCode = {
  Ok: 'Ok',
  Warning: 'Warning',
  Error: 'Error'
}

export function ImportMessageLine({
  message: { severity, type, fieldName, info, rowIndex, recordId, itemIndex }
}) {
  switch (type) {
    case TypeCode.ColumnMissing:
      return (
        <Item
          title={`Column missing`}
          info={`The column "${fieldName}" is not included and so the document cannot be imported. Please do not remove or rename columns from the exported document, as many of these are required to import data later.`}
          severity={severity}
        />
      )

    case TypeCode.Parsing:
      return (
        <Item
          title={`Row ${rowIndex}: Error reading document`}
          info={`The uploaded document could not be read, the value "${info}" in column "${fieldName}" is not valid.`}
          severity={severity}
        />
      )

    case TypeCode.Value:
      return (
        <Item
          title={`Row ${rowIndex}: Value might not be correct`}
          subtitle={`record_id: ${recordId}  item_index: ${itemIndex}`}
          info={`"${fieldName}" was set to "${info}"; this is unlikely to be correct.`}
          severity={severity}
        />
      )

    case TypeCode.Key:
      return (
        <Item
          title={`Row ${rowIndex}: Not found`}
          subtitle={`record_id: ${recordId}  item_index: ${itemIndex}`}
          info={`The record or line item could not be found and will not be imported`}
          severity={severity}
        />
      )

    default:
      return (
        <Item
          title={`Row ${rowIndex}: Unknown error`}
          subtitle={`record_id: ${recordId}  item_index: ${itemIndex}`}
          info={`There is an unknown problem with this item and it can not be imported. If you believe this is incorrect please contact us at support@qualisflow.com`}
          severity={severity}
        />
      )
  }
}

ImportMessageLine.propTypes = {
  message: PropTypes.shape({
    severity: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    fieldName: PropTypes.string,
    info: PropTypes.string,
    rowIndex: PropTypes.number,
    recordId: PropTypes.string,
    itemIndex: PropTypes.number
  }).isRequired
}

function Item({ title, subtitle, info, severity }) {
  return (
    <Container severity={severity}>
      <Text bold error={severity == SeverityCode.Error}>
        {title}
      </Text>

      <Spacer.Fine />

      {subtitle && (
        <>
          <Text bold dim>
            {subtitle}
          </Text>
          <Spacer.Fine />
        </>
      )}

      <Text>{info}</Text>
    </Container>
  )
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  info: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;

  flex-direction: column;

  border-left: 2px solid;
  border-left-color: ${({ theme, severity }) => {
    switch (severity) {
      case SeverityCode.Ok:
        return theme.primary.fg
      case SeverityCode.Warning:
        return theme.primary.warning
      case SeverityCode.Error:
        return theme.primary.error
    }
  }};
  margin-left: ${SPACING.FINE};
  padding-left: ${SPACING.TINY};
`
