export const PROJECT_USERS_LOAD = 'PROJECT_USERS_LOAD'
export const projectUsersLoad = (
  accountId,
  projectId,
  includeLastSignIn = false
) => ({
  type: PROJECT_USERS_LOAD,
  accountId,
  projectId,
  includeLastSignIn
})
export const PROJECT_USERS_LOADED = 'PROJECT_USERS_LOADED'
export const projectUsersLoaded = (
  accountId,
  projectId,
  memberships,
  userProfiles
) => ({
  type: PROJECT_USERS_LOADED,
  accountId,
  projectId,
  memberships,
  userProfiles
})
export const PROJECT_USERS_LOAD_FAILURE = 'PROJECT_USERS_LOAD_FAILURE'
export const projectUsersLoadFailure = (accountId, projectId, error) => ({
  type: PROJECT_USERS_LOAD_FAILURE,
  accountId,
  projectId,
  error
})
