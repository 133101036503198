import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function CircleTicked({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        d="M7.00001 0.333313C3.32001 0.333313 0.333344 3.31998 0.333344 6.99998C0.333344 10.68 3.32001 13.6666 7.00001 13.6666C10.68 13.6666 13.6667 10.68 13.6667 6.99998C13.6667 3.31998 10.68 0.333313 7.00001 0.333313ZM7.00001 12.3333C4.06001 12.3333 1.66668 9.93998 1.66668 6.99998C1.66668 4.05998 4.06001 1.66665 7.00001 1.66665C9.94001 1.66665 12.3333 4.05998 12.3333 6.99998C12.3333 9.93998 9.94001 12.3333 7.00001 12.3333Z"
        fill={'currentColor'}
      ></path>
      <path
        d="M10.06 4.05331L5.66668 8.44665L3.94001 6.72665L3.00001 7.66665L5.66668 10.3333L11 4.99998L10.06 4.05331Z"
        fill={colour || '#3e3f42'}
      ></path>
    </svg>
  )
}

CircleTicked.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
