import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import { colours, SPACING } from '@qflow/theme'

import { ColourButton } from 'portal/lib/primitives/buttons'
import { Dropdown } from 'portal/lib/primitives/Dropdown'
import { Input } from 'portal/lib/primitives/forms'
import { Spacer, Flex } from 'portal/lib/primitives/layout'
import { SubHeading } from 'portal/lib/primitives/text'
import { isEqualWithMomentSupport } from 'portal/utils/isEqualWithMomentSupport'

export function DateRangePickerInner({
  value = {},
  onChange,
  onClose,
  defaultRanges
}) {
  value = value || {}

  const [range, setRange] = useState(value)
  const [defaultRange, setDefaultRange] = useState(value.defaultRangeKey)

  const handleValueChange = useCallback(
    nextValue => {
      if (isEqualWithMomentSupport(value, nextValue)) {
        return
      }

      onChange && onChange(nextValue)
      setRange(nextValue)
    },
    [onChange, value]
  )

  useEffect(() => {
    if (defaultRange > 0) {
      handleValueChange({
        ...rangeFromDefaultRange(defaultRange),
        label: defaultRanges.find(dr => dr.key === defaultRange).label,
        defaultRangeKey: defaultRange
      })
    } else {
      handleValueChange({
        ...normaliseDates(range),
        label: null,
        defaultRangeKey: null
      })
    }
  }, [defaultRange, defaultRanges, handleValueChange, range])

  const handleRangeChange = useCallback(value => {
    const normalisedValue = normaliseDates(value)
    setRange(normalisedValue)
    setDefaultRange(null)
  }, [])

  // Configuration
  const maxDate = useMemo(
    () => moment().startOf('day').add(1, 'day').toISOString(true),
    []
  )

  return (
    <ContainerForm
      id="DateRangePickerPopup"
      onSubmit={e => {
        e.preventDefault()
        onClose?.()
      }}
    >
      <Flex.Row>
        <Dropdown
          id="DateRangePickerDefaultRangeDropdown"
          items={defaultRanges}
          value={defaultRange}
          onChange={setDefaultRange}
          placeholder="Default ranges"
        />
      </Flex.Row>

      <Spacer.Small />

      <Flex.Row verticalCentre>
        <Input.DatePicker
          id="DatePickerStart"
          date={range.startDate}
          onChange={date => handleRangeChange({ ...range, startDate: date })}
          maxDate={
            range.endDate && range.endDate < maxDate ? range.endDate : maxDate
          }
          placeholder="From Date"
        />

        <Spacer.Fine />
        <SubHeading isChild>-</SubHeading>
        <Spacer.Fine />

        <Input.DatePicker
          id="DatePickerEnd"
          date={range.endDate}
          onChange={date => handleRangeChange({ ...range, endDate: date })}
          minDate={range.startDate}
          maxDate={maxDate}
          placeholder="To Date"
        />
      </Flex.Row>

      <Spacer.Small />

      <Flex.Row amount={1} style={{ justifyContent: 'right' }}>
        <Flex.By amount={2} />
        <ColourButton.Action
          id="DateRangePickerApplyButton"
          type="submit"
          flex={1}
        >
          Save
        </ColourButton.Action>
      </Flex.Row>
    </ContainerForm>
  )
}

DateRangePickerInner.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    label: PropTypes.string,
    defaultRangeKey: PropTypes.number
  }),

  defaultRanges: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,

  onClose: PropTypes.func
}

function rangeFromDefaultRange(defaultRange) {
  const todayMoment = moment().endOf('day')

  const endDate = todayMoment.toISOString(true)

  const startDate = todayMoment
    .clone()
    .subtract(defaultRange, 'days')
    .startOf('day')
    .toISOString(true)

  return {
    startDate,
    endDate
  }
}

const normaliseDates = value => {
  const startDate =
    value.startDate && moment(value.startDate).startOf('day').toISOString(true)
  const endDate =
    value.endDate && moment(value.endDate).endOf('day').toISOString(true)
  return { ...value, startDate, endDate }
}

// Styled Components

const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;

  background-color: ${colours.WHITE};

  padding: ${SPACING.SMALL};
`
