import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colours, SPACING, mobileCSS, sizes } from '@qflow/theme'
import { ColourButton, Flex, icons, Text, Spacer } from 'portal/lib/primitives'
import { useInfraction } from 'portal/store/infractions/InfractionsContext'
import { FormInfraction } from './FormInfraction'

const DetailContainer = styled(Flex.Row)`
  margin: ${SPACING.TINY};
  ${({ $hidden }) => $hidden && `display: none;`}
  ${mobileCSS(css`
    flex-direction: column;
  `)}
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 2rem;
  width: 13rem;
  overflow: hidden;
`

const ContentWrapper = styled(Flex.Row)`
  flex: 1 1 auto;

  flex-direction: column;
  max-width: 30rem;
`

export function FormItem({ label, children, hidden = false }) {
  return (
    <DetailContainer hidden={hidden}>
      <LabelWrapper>
        <LabelText>{label}</LabelText>
      </LabelWrapper>

      <ContentWrapper>{children}</ContentWrapper>
    </DetailContainer>
  )
}

FormItem.propTypes = {
  label: PropTypes.any,
  children: PropTypes.node,
  hidden: PropTypes.bool
}

/**
 * @callback renderCallback
 * @param {Partial<import("react-hook-form").ArrayField<Record<string, any>, "id">>} field
 * @param {number} index
 * @param {import("react-hook-form").UseFieldArrayMethods<Record<string, any>, "id">} fieldArray
 * @returns {any}
 *
 * @param {{
 *   fieldArray: import("react-hook-form").UseFieldArrayMethods<Record<string, any>, "id">,
 *   render: renderCallback
 * }} param0
 */
export function FormArrayItem({
  label,
  name,
  fieldArray,
  render,
  defaultValue = {}
}) {
  const infraction = useInfraction(name)

  return (
    <FormItem label={label}>
      <Flex.Col>
        {infraction && (
          <ErrorRow verticalCentre>
            <FormInfraction infraction={infraction} />
          </ErrorRow>
        )}

        {fieldArray.fields.map((field, index) => {
          return (
            <Fragment key={field.id}>
              {render(field, index, fieldArray)}
            </Fragment>
          )
        })}

        <Flex.Row>
          <ColourButton.Basic
            type="button"
            onClick={() => {
              fieldArray.append(defaultValue, true)
            }}
          >
            <icons.Plus size={sizes.SMALL} />
            <Spacer.Tiny />
            Add
          </ColourButton.Basic>
        </Flex.Row>
      </Flex.Col>
    </FormItem>
  )
}

FormArrayItem.propTypes = {
  fieldArray: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

const LabelText = styled(Text)`
  color: ${colours.CHARCOAL_GREY};
`

const ErrorRow = styled(Flex.Row)`
  height: 2rem;
`
