import React from 'react'
import {
  RecordLink,
  RecordsSelect
} from 'portal/components/RecordsTable/RecordsColumn'
import { HeadingWithDropdown } from 'portal/components/RecordsTable/TableHeading/HeadingWithDropdown'

import { RECORD_STATUS } from 'portal/lib/constants'
import { recordsComponentFactory } from './recordsComponentFactory'
import { Input } from 'antd'
const { Search } = Input

function setCellId(record, rowIndex) {
  if (rowIndex === 0) {
    return {}
  }
  if (record.removed) {
    return {
      id: 'RecordsTableRow',
      style: {
        textDecoration: 'line-through',
        opacity: '0.5'
      }
    }
  }
  return {
    id: 'RecordsTableCell'
  }
}

export const Waste = recordsComponentFactory({
  ModuleName: 'Waste',
  dateField: 'removalDate',
  buildRecordsForDisplay: function (records, projectTimeZone) {
    return {
      data: (records ?? []).map(record => {
        const wasteCodes = record.data.items
          ?.reduce((codes, item) => {
            codes.push(item.wasteCode)
            return codes
          }, [])
          .join(',\n')
        return {
          record: record,
          createdAt: new Date(record.createdAt).toLocaleDateString([], {
            timeZone: projectTimeZone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }),
          removalDate: new Date(record.data.removalDate).toLocaleDateString(
            [],
            {
              timeZone: projectTimeZone,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }
          ),
          consignmentId: record.data.consignmentId,
          carrierName: record.data.carrierName,
          originSiteAddress: record.data.originSiteAddress || '----',
          wasteCodes: wasteCodes || '----',
          weight: record.data.items?.reduce((total, item) => {
            total += item.weight ?? 0
            return total
          }, 0),
          volume: record.data.items?.reduce((total, item) => {
            total += item.volume ?? 0
            return total
          }, 0),
          recordIdRaw: record.recordId,
          removed: record.status === RECORD_STATUS.REMOVED
        }
      })
    }
  },
  buildColumnsForTable: function (
    { size },
    state,
    fetchOptions,
    assignedToOptions,
    filters,
    updateFilters,
    projectId,
    tableId,
    accountId,
    useCreatedAtDate,
    updateUseCreatedAtDate
  ) {
    const handleTableDateColumnClick = key => {
      if (key && updateUseCreatedAtDate) {
        updateUseCreatedAtDate(key === 'createdAt')
      }
    }

    const dateOptions = [
      { label: 'Collection Date', key: 'removalDate' },
      { label: 'Created At', key: 'createdAt' }
    ]

    const columns = [
      useCreatedAtDate
        ? {
            dataIndex: 'createdAt',
            className: 'createdAt',
            title: () => (
              <HeadingWithDropdown
                onMenuItemClick={handleTableDateColumnClick}
                current="Created at"
                dropdownOptions={dateOptions}
              />
            ),
            width: '8.125rem',
            onCell: setCellId
          }
        : {
            dataIndex: 'removalDate',
            className: 'removalDate',
            title: () => (
              <HeadingWithDropdown
                onMenuItemClick={handleTableDateColumnClick}
                current="Collection date"
                dropdownOptions={dateOptions}
              />
            ),
            width: '9rem',
            onCell: setCellId
          },
      {
        dataIndex: 'consignmentId',
        className: 'consignmentId',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return (
              <Search
                defaultValue={filters.consignmentId}
                placeholder={'Note: Case sensitive'}
                onSearch={value => {
                  updateFilters('consignmentId', value, filters)
                }}
                onBlur={e => {
                  if (e.target.value != filters.consignmentId) {
                    updateFilters('consignmentId', e.target.value)
                  }
                }}
                data-testid={'consignmentIdFilter'}
              />
            )
          } else {
            return record.consignmentId
          }
        },
        title: 'Consignment Nº (WTN) ',
        onCell: setCellId
      },
      size > 1 && {
        dataIndex: 'carrierName',
        className: 'carrierName',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'carrierName',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.carrierName
          }
        },
        title: 'Carrier',
        onCell: setCellId
      },
      size > 1 && {
        dataIndex: 'originSiteAddress',
        className: 'originSiteAddress',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'originSiteAddress',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.originSiteAddress
          }
        },
        title: 'Origin Site Address',
        onCell: setCellId
      },
      size > 1 && {
        dataIndex: 'wasteCode',
        className: 'wasteCode',
        ellipsis: true,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'items[].wasteCode',
              state,
              fetchOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.wasteCodes
          }
        },
        title: 'Waste Code (EWC)',
        width: '9.75rem',
        onCell: setCellId
      },
      size > 2 && {
        dataIndex: 'weight',
        className: 'weight',
        title: (
          <span title="Weight">
            Weight (<span className="lowercase">t</span>)
          </span>
        ),
        width: '6.125rem',
        onCell: setCellId
      },
      size > 2 && {
        dataIndex: 'volume',
        className: 'volume',
        title: (
          <span title="Volume">
            Volume (
            <span className="lowercase">
              m<sup>3</sup>
            </span>
            )
          </span>
        ),
        width: '7.25rem',
        onCell: setCellId
      },
      size > 1 && {
        title: 'Assigned To',
        ellipsis: false,
        render: (_, record, index) => {
          if (index === 0) {
            return RecordsSelect(
              'assignedTo',
              state,
              assignedToOptions,
              filters,
              updateFilters,
              projectId,
              tableId
            )
          } else {
            return record.assignedTo
          }
        },
        dataIndex: 'assignedTo',
        className: 'assignedTo',
        onCell: setCellId
      },
      RecordLink(setCellId, filters, updateFilters)
    ]

    return columns
  },
  initialFilterState: {
    'consignmentId': '',
    'removalDate': '',
    'carrierName': [],
    'originSiteAddress': [],
    'items[].wasteCode': []
  }
})
