import React from 'react'
import PropTypes from 'prop-types'
import { sizes, useTheme } from '@qflow/theme'

export function RemovePerson({ size = sizes.SMALL, colour, ...props }) {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      style={{ width: size, height: size, marginRight: '4px' }}
    >
      <path
        d="M9 5.33332C9 3.85999 7.80667 2.66666 6.33333 2.66666C4.86 2.66666 3.66667 3.85999 3.66667 5.33332C3.66667 6.80666 4.86 7.99999 6.33333 7.99999C7.80667 7.99999 9 6.80666 9 5.33332ZM7.66667 5.33332C7.66667 6.06666 7.06667 6.66666 6.33333 6.66666C5.6 6.66666 5 6.06666 5 5.33332C5 4.59999 5.6 3.99999 6.33333 3.99999C7.06667 3.99999 7.66667 4.59999 7.66667 5.33332Z"
        fill={colour || theme.secondary.fg}
      />
      <path
        d="M1 12V13.3333H11.6667V12C11.6667 10.2267 8.11333 9.33332 6.33333 9.33332C4.55333 9.33332 1 10.2267 1 12ZM2.33333 12C2.46667 11.5267 4.53333 10.6667 6.33333 10.6667C8.12667 10.6667 10.18 11.52 10.3333 12H2.33333Z"
        fill={colour || theme.secondary.fg}
      />
      <path
        d="M15 6.66666H11V7.99999H15V6.66666Z"
        fill={colour || theme.secondary.fg}
      />
    </svg>
  )
}

RemovePerson.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
