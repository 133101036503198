import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import produce from 'immer'

import { FormInput, FormItem } from 'portal/components/RecordEditing'
import {
  CheckAddress,
  CheckCarrierLicence,
  CheckCarrierName,
  CheckConsignmentOrDeliveryNumber,
  CheckDate1isBeforeDate2,
  CheckNotes,
  CheckReceiverOrSupplierOrSenderOrCarrierReference,
  CheckSubContractor,
  CheckVehicleReg,
  CheckWasteFacilityOperator,
  CheckWastePermit,
  CheckWasteSICCode
} from 'portal/lib/functions/SimpleDataValidation'
import { Input as PrimitiveInput } from 'portal/lib/primitives'
import { dateValidator } from 'portal/lib/primitives/forms/Validators'

export function Waste({ config }) {
  const form = useFormContext()

  return (
    <>
      <FormItem label="Collection Arrival">
        <FormInput.DateTimeCells
          name="data.removalDate"
          formatter={PrimitiveInput.DateTimeCells.getLocaleStringFromValues}
          rules={dateValidator(
            form.getValues,
            'data.removalDate',
            true,
            'Collection Arrival'
          )}
        />
      </FormItem>

      <FormItem label="Collection Exit">
        <FormInput.DateTimeCells
          name="data.removalExitDate"
          formatter={PrimitiveInput.DateTimeCells.getLocaleStringFromValues}
          rules={dateValidator(form.getValues, 'data.removalExitDate')}
          simpleDataValidationRules={v =>
            CheckDate1isBeforeDate2(
              'Collection Arrival',
              form.getValues('data.removalDate'),
              'Collection Exit',
              form.getValues('data.removalExitDate')
            )
          }
        />
      </FormItem>

      <FormItem label="Consignment Number (WTN)">
        <FormInput.Text
          label="Consignment Number"
          name="data.consignmentId"
          rules={{
            required: 'Consignment Number is mandatory'
          }}
          simpleDataValidationRules={CheckConsignmentOrDeliveryNumber}
        />
      </FormItem>

      <FormItem label="Sender Reference">
        <FormInput.Text
          label="Sender Reference"
          name="data.receiverReferenceNumber"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <FormItem label="Subcontractor">
        <FormInput.Text
          label="Subcontractor"
          name="data.subcontractorName"
          simpleDataValidationRules={CheckSubContractor}
        />
      </FormItem>

      <FormItem label="Origin Site Address">
        <FormInput.TextArea
          label="Origin Site Address"
          name="data.originSiteAddress"
          simpleDataValidationRules={CheckAddress}
        />
      </FormItem>

      <FormItem label="SIC Code">
        <FormInput.Text
          label="SIC Code"
          name="data.classificationCode"
          simpleDataValidationRules={CheckWasteSICCode}
        />
      </FormItem>

      <FormItem label="Vehicle Reg">
        <FormInput.Text
          label="Vehicle Reg"
          name="data.vehicleRegistration"
          simpleDataValidationRules={CheckVehicleReg}
        />
      </FormItem>

      <FormItem label="Carrier">
        <FormInput.Text
          label="Carrier"
          name="data.carrierName"
          simpleDataValidationRules={CheckCarrierName}
        />
      </FormItem>

      <FormItem label="Carrier Reference">
        <FormInput.Text
          label="Carrier Reference"
          name="data.supplierReferenceNumber"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <FormItem label="Carrier Licence">
        <FormInput.Text
          label="Carrier Licence"
          name="data.carrierLicenceId"
          simpleDataValidationRules={CheckCarrierLicence}
        />
      </FormItem>

      <FormItem label="Facility Operator">
        <FormInput.Text
          label="Facility Operator"
          name="data.facilityOperatorName"
          simpleDataValidationRules={CheckWasteFacilityOperator}
        />
      </FormItem>

      <FormItem label="Facility Site Address">
        <FormInput.Text
          label="Facility Site Address"
          name="data.facilitySiteAddress"
          simpleDataValidationRules={CheckAddress}
        />
      </FormItem>

      <FormItem label="Facility Permit">
        <FormInput.Text
          label="Facility Permit"
          name="data.facilityPermitId"
          simpleDataValidationRules={CheckWastePermit}
        />
      </FormItem>

      <FormItem label="External Reference">
        <FormInput.TextArea
          label="External Reference"
          name="data.clientReference"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <FormItem label="Notes">
        <FormInput.TextArea
          label="Notes"
          name="data.notes"
          simpleDataValidationRules={CheckNotes}
        />
      </FormItem>
    </>
  )
}

Waste.propTypes = {
  config: PropTypes.object.isRequired
}

Waste.transformToFormData = (record, projectTimeZone) =>
  produce(record, draft => {
    draft.data.removalDate =
      PrimitiveInput.DateTimeCells.getDateTimeInputValuesFromISOString(
        draft.data.removalDate,
        projectTimeZone
      )
    draft.data.removalExitDate =
      PrimitiveInput.DateTimeCells.getDateTimeInputValuesFromISOString(
        draft.data.removalExitDate
      )
  })

Waste.transformFromFormData = (record, projectTimeZone) =>
  produce(record, draft => {
    draft.data.removalDate =
      PrimitiveInput.DateTimeCells.getISOStringFromValues(
        draft.data.removalDate,
        projectTimeZone
      )
    draft.data.removalExitDate =
      PrimitiveInput.DateTimeCells.getISOStringFromValues(
        draft.data.removalExitDate
      )
  })

Waste.validationSchema = yup.object({
  data: yup.object({
    removalDate: PrimitiveInput.DateTimeCells.yupValidator(
      'data.removalDate.value'
    ),
    consignmentId: yup.string().label('Consignment Number').min(2).required()
  })
})
