import React from 'react'
import { SideBar } from 'portal/lib/primitives'
import { icons } from 'portal/lib/primitives'
import { useResponsive } from 'portal/lib/hooks'
import { useUserAccountRole } from 'portal/lib/hooks/useUserAccountRole'

export function AccountSettingsSidebarButton({ accountId, accountsTable }) {
  const { isMobile } = useResponsive()
  const { isAdmin } = useUserAccountRole(accountId)

  if (!isAdmin || isMobile) {
    return null
  }

  return (
    <SideBar.NavButton
      disabled={accountsTable == ''}
      id="AccountSettingsPage"
      to={accountsTable}
      Icon={icons.AccountSettingsIcon}
    >
      Account Settings
    </SideBar.NavButton>
  )
}
