import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Brush({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'}>
        <path
          d="M7.845 9.159c.19.19.19.496 0 .686-.19.19-.497.19-.686 0-.19-.19-.19-.497 0-.686.19-.19.496-.19.686 0M6.845 11.159c.19.19.19.496 0 .686-.19.19-.497.19-.686 0-.19-.19-.19-.497 0-.686.19-.19.496-.19.686 0M8.854 10.146c.195.196.195.512 0 .708-.196.195-.512.195-.708 0-.195-.196-.195-.512 0-.708.196-.195.512-.195.708 0M9.846 0c-.343 0-.666.152-.886.416L7 2.77c.144.067.284.145.415.239.369.264.64.61.826.99l2.344-1.956c.264-.22.415-.544.415-.888C11 .519 10.482 0 9.846 0zM6.135 4.438c-.813-.643-1.94-.57-2.68.168-.462.462-1.868 1.868-3.185 2.185L0 6.856s.02.982.289 1.424c.526-.065.951-.185 1.098-.347-.324.423-.57.73-.76.958.157.232.35.452.566.665.777-.366 1.47-1 1.697-1.25-.456.842-.827 1.473-1.022 1.799.434-.28.963-.643 1.457-1.05-.185.205-.427.849-.623 1.583.218.117.833.362.833.362.945-.08 2.585-2.543 3.137-3.486.595-1.016.36-2.367-.537-3.076z"
          transform="translate(2 2)"
        />
      </g>
    </svg>
  )
}

Brush.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
