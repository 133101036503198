import React from 'react'
import styled from 'styled-components'

import { Spinner } from 'portal/lib/primitives'

import { useDashboardLoader } from './useDashboardLoader'
import { useDashboardRedirect } from './useDashboardRedirect'

export function Dashboard() {
  const dash = useDashboardLoader()

  useDashboardRedirect(dash.loaded && !dash.uri)

  if (dash.loading) {
    return <Spinner />
  }

  if (dash.error) {
    return <div>Error, please try reloading the page.</div>
  }

  return (
    <IFrame
      id="DashboardIframe"
      title="Dashboard"
      src={dash.uri}
      frameBorder="0"
      allowFullScreen={true}
    ></IFrame>
  )
}

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`
