import { takeEvery, all, put, call } from 'redux-saga/effects'
import axios, { axiosRaw } from 'portal/utils/axios-msal'

import { UPLOAD, uploadSuccess, uploadFailure } from './actions'
import { fileSizes } from 'portal/lib/constants'

function getURI(accountId, projectId, moduleName, recordId) {
  return (
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/modules/' +
    moduleName +
    '/records/' +
    recordId +
    '/attachments/register'
  )
}

function* uploadSaga(action) {
  const { accountId, projectId, moduleName, recordId, file } = action

  let attachment = null
  if (
    file.size &&
    file.size > fileSizes.ATTACHMENTLIMIT_MB * fileSizes.MEGABYTE
  ) {
    yield put(
      uploadFailure(
        accountId,
        projectId,
        moduleName,
        recordId,
        'ATTACHMENT_VALIDATION_TOO_LARGE'
      )
    )
    return
  }
  try {
    const response = yield call(
      axios.post,
      getURI(accountId, projectId, moduleName, recordId),
      {
        name: file.name
      }
    )

    attachment = response.data
  } catch (error) {
    console.error(error)
    yield put(
      uploadFailure(
        accountId,
        projectId,
        moduleName,
        recordId,
        'ATTACHMENT_REGISTRATION_ERROR'
      )
    )
    return
  }

  try {
    yield call(axiosRaw.put, attachment.writeUri, file, {
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      }
    })
  } catch (error) {
    console.error(error)
    yield put(
      uploadFailure(
        accountId,
        projectId,
        moduleName,
        recordId,
        'ATTACHMENT_UPLOAD_ERROR'
      )
    )
    return
  }

  yield put(
    uploadSuccess(accountId, projectId, moduleName, recordId, attachment)
  )
}

export function* watch() {
  yield all([takeEvery(UPLOAD, uploadSaga)])
}
