import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function GoldBadge({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ width: size, height: size }}
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8 0.666656L2 3.33332V7.33332C2 11.0333 4.56 14.4933 8 15.3333C11.44 14.4933 14 11.0333 14 7.33332V3.33332L8 0.666656ZM9.66667 8.39332L10.2667 10.98L8 9.61332L5.73333 10.98L6.33333 8.39999L4.33333 6.67332L6.97333 6.44666L8 4.01332L9.02667 6.43999L11.6667 6.66666L9.66667 8.39332Z"
        fill="#E2A60B"
      />
    </svg>
  )
}

GoldBadge.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
