import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Plus({ size = sizes.SMALL, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      style={{ width: size, height: size }}
    >
      <g fill="none" fillRule="evenodd">
        <path fill={colour || 'currentColor'} d="M11 0h2v24h-2z" />
        <path d="M0 0h24v24H0z" />
        <path fill={colour || 'currentColor'} d="M0 11h24v2H0z" />
      </g>
    </svg>
  )
}

Plus.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
