import { useState, useCallback } from 'react'

export const useToggleState = (initialValue = false) => {
  const [state, setState] = useState(initialValue)
  const setTrue = useCallback(() => setState(true), [])
  const setFalse = useCallback(() => setState(false), [])
  const toggle = useCallback(() => setState(!state), [state])

  return [state, setTrue, setFalse, toggle]
}
