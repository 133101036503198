import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function AvgPeriod({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={colour || colours.WHITE}>
        <circle
          cx="6.375"
          cy="1.875"
          r="1.5"
          fill={colour || colours.WHITE}
          strokeWidth=".75"
        />
        <circle
          cx="7.875"
          cy="13.125"
          r="1.5"
          fill={colour || colours.WHITE}
          strokeWidth=".75"
        />
        <path
          strokeLinecap="square"
          strokeWidth=".75"
          d="M2.625 7.5L6.75 11.625M6.75 2.625L7.125 11.25"
        />
        <path
          fill={colour || colours.WHITE}
          strokeDasharray="2.25"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M14.25 0.75L0.75 14.25"
        />
        <path
          strokeLinecap="square"
          strokeWidth=".75"
          d="M6.375 2.625L12.375 6.375"
        />
        <circle
          cx="1.875"
          cy="7.125"
          r="1.5"
          fill={colour || colours.WHITE}
          strokeWidth=".75"
        />
        <circle
          cx="13.125"
          cy="7.125"
          r="1.5"
          fill={colour || colours.WHITE}
          strokeWidth=".75"
        />
      </g>
    </svg>
  )
}

AvgPeriod.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
