/**
 * @param {import("react-hook-form").ValidationRules} rulesa
 */
const mergeRules = rulesa => {
  /**
   * @param {import("react-hook-form").ValidationRules} rulesb
   * @returns {import("react-hook-form").ValidationRules}
   */
  const rules = (rulesb = {}) => ({ ...rulesa, ...rulesb })
  return rules
}

const DecimalRegex = /^\d+(\.\d+)?$/
export const OptionalPositiveDecimal = mergeRules({
  validate: value => {
    if (!value || value === '') {
      return true
    }

    const num = parseFloat(value)
    if (num <= 0) {
      return 'Must be higher than 0 or left empty'
    }

    if (!DecimalRegex.test(value)) {
      return 'Must be a number'
    }

    return true
  }
})
