export const PROJ_GET_DETAILS_SUCCESS = 'PROJ_GET_DETAILS_SUCCESS'
export const PROJ_GET_DETAILS_START = 'PROJ_GET_DETAILS_START'
export const PROJ_GET_DETAILS_FAIL = 'PROJ_GET_DETAILS_FAIL'
export const PROJ_CLEAR_DETAILS = 'PROJ_CLEAR_DETAILS'

export const PROJ_GET_DETAILS_SAGA = 'PROJ_GET_DETAILS_SAGA'

export const projectGetDetailsStart = () => {
  return {
    type: PROJ_GET_DETAILS_START
  }
}

export const projectGetDetailsSaga = (accountId, projectId) => {
  return {
    type: PROJ_GET_DETAILS_SAGA,
    accountId: accountId,
    projectId: projectId
  }
}

export const projectGetDetailsSuccess = modules => {
  return {
    type: PROJ_GET_DETAILS_SUCCESS,
    modules: modules
  }
}

export const projectGetDetailsFail = error => {
  return {
    type: PROJ_GET_DETAILS_FAIL,
    error: error
  }
}

export const projectDetailsClear = () => {
  return {
    type: PROJ_CLEAR_DETAILS
  }
}
