export const USERS_LOAD = 'USERS_LOAD'
export const usersLoad = accountId => ({
  type: USERS_LOAD,
  accountId
})
export const USERS_LOADED = 'USERS_LOADED'
export const usersLoaded = (accountId, memberships, userProfiles) => ({
  type: USERS_LOADED,
  accountId,
  memberships,
  userProfiles
})
export const USERS_LOAD_FAILURE = 'USERS_LOAD_FAILURE'
export const usersLoadFailure = (accountId, error) => ({
  type: USERS_LOAD_FAILURE,
  accountId,
  error
})
