import { takeEvery, put } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import {
  projectUsersUpdateSuccess,
  projectUsersUpdateFail,
  PROJECT_USERS_UPDATE_START
} from './actions'

function getPutUserURI(accountId, projectId, userId) {
  return `accounts/${accountId}/projects/${projectId}/memberships/${userId}`
}

function getPutUserDetailsURI(accountId, projectId, userId) {
  return `accounts/${accountId}/projects/${projectId}/user-details/${userId}`
}

function* putProjectUserSaga(action) {
  const {
    accountId,
    projectId,
    userId,
    projectPermissions,
    notificationsEnabled,
    projectSettings
  } = action
  try {
    const [memberships, userProfile] = yield Promise.all([
      axios.put(getPutUserURI(accountId, projectId, userId), {
        projectPermissions: projectPermissions,
        validateStatus: status => status === 200
      }),
      axios.put(getPutUserDetailsURI(accountId, projectId, userId), {
        userId: userId,
        notificationsEnabled: notificationsEnabled,
        projectSettings: projectSettings,
        validateStatus: status => status === 200
      })
    ])
    yield put(
      projectUsersUpdateSuccess(
        accountId,
        projectId,
        userId,
        memberships.data,
        userProfile.data
      )
    )
  } catch (error) {
    yield put(projectUsersUpdateFail(accountId, projectId, userId, error))
  }
}

export function* watch() {
  yield takeEvery(PROJECT_USERS_UPDATE_START, putProjectUserSaga)
}
