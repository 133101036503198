import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router'

import { Typography, Table, Checkbox, Dropdown, Menu, Space } from 'antd'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useQueryParam, ArrayParam } from 'use-query-params'

import { DownOutlined } from '@ant-design/icons'
import { sizes } from '@qflow/theme'

import resetFilter from 'portal/assets/RecordsTable/resetFilterBlk.svg'
import { HorizontalDivider } from 'portal/components/Navigation/Main/Toolbar/Divider'

import { EVENTS } from 'portal/lib/constants'
import { select } from 'portal/lib/constants/moduleConstants'
import { useMixpanelEvents } from './mixpanel'

import {
  convertAssignedToArrayToString,
  DateFormatFull,
  otherAssignedToFilters
} from 'portal/lib/functions'
import {
  useResponsive,
  useCheckboxStates,
  useModuleIds,
  useUserProjectRole
} from 'portal/lib/hooks'
import {
  ColourButton,
  Flex,
  icons,
  SideBar,
  SmallSpinner,
  Spacer
} from 'portal/lib/primitives'
import {
  WASTE_TABLE,
  WASTE_TABS,
  DELIVERY_TABLE,
  DELIVERY_TABS
} from 'portal/routes'
import { projectUsersLoad } from 'portal/store/projectUsers/actions'

import { ActionStrip } from './ActionStrip'
import { EventsFilter } from './filters'
import { NavigateButton } from './NavigateButton'
import { ReportEventCell } from './ReportEventCell'
import { useEventsLoader } from './useEventsLoader'
import { useEventsFilterLoader } from './useEventsFilterLoader'

import './EventsTable.scss'

const { Title, Text } = Typography

const EMPTY_ARRAY = []

export function EventLog({ startDate, endDate }) {
  const dispatch = useDispatch()
  const { accountId, projectId, moduleName } = useModuleIds()

  const { isMobile, isSmall } = useResponsive()

  const { isProjectUser, roleLoaded } = useUserProjectRole(accountId, projectId)

  const [statusFilters = EMPTY_ARRAY, updateStatusFilters] = useQueryParam(
    'status',
    ArrayParam
  )

  const [sourceTypeFilters = EMPTY_ARRAY, updateSourceTypeFilters] =
    useQueryParam('sourceType', ArrayParam)

  const [descriptionFilters = EMPTY_ARRAY, updateDescriptionFilters] =
    useQueryParam('description', ArrayParam)

  const [tradeContractorFilters = EMPTY_ARRAY, updateTradeContractorFilters] =
    useQueryParam('tradeContractor', ArrayParam)

  const [wasteCarrierFilters = EMPTY_ARRAY, updateWasteCarrierFilters] =
    useQueryParam('wasteCarrier', ArrayParam)

  const [subcontractorFilters = EMPTY_ARRAY, updateSubcontractorFilters] =
    useQueryParam('subcontractor', ArrayParam)

  const [supplierFilters = EMPTY_ARRAY, updateSupplierFilters] = useQueryParam(
    'supplier',
    ArrayParam
  )

  const [assigneeIdsFilters = EMPTY_ARRAY, updateAssigneeIdsFilters] =
    useQueryParam('assigneeIds', ArrayParam)

  const projectTimeZone = useSelector(
    state => state.projectDetails.modules?.timeZone
  )
  const projectUsers = useSelector(state => state.projectUsers)

  const [assignableUsers, setAssignableUsers] = useState([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [eventRecordMapping, setEventRecordMapping] = useState({})
  const [selectedEventsContainUnloaded, setSelectedEventsContainUnloaded] =
    useState(false)

  const allEvents = useSelector(state => state.events[moduleName].allEvents)

  const {
    loading,
    error,
    items,
    count,
    reload,
    handlePageChange,
    hasNextPage,
    showTotalRecords,
    readOnly
  } = useEventsLoader(
    startDate,
    endDate,
    isMobile ? EMPTY_ARRAY : statusFilters,
    isMobile ? EMPTY_ARRAY : sourceTypeFilters,
    isMobile ? EMPTY_ARRAY : descriptionFilters,
    isMobile ? EMPTY_ARRAY : tradeContractorFilters,
    isMobile ? EMPTY_ARRAY : supplierFilters,
    isMobile ? EMPTY_ARRAY : assigneeIdsFilters,
    isMobile ? EMPTY_ARRAY : subcontractorFilters,
    isMobile ? EMPTY_ARRAY : wasteCarrierFilters
  )

  const {
    loadingFilter,
    errorFilter,
    description,
    tradeContractor,
    wasteCarrier,
    subcontractor,
    supplier
  } = useEventsFilterLoader()

  const tableId = 'EventsTable'
  const filterRow = [{ eventKey: -1 }]
  const displayItems = [...filterRow, ...items]
  const sourceTypeReport = 'report'

  const { trackOpen } = useMixpanelEvents()

  useEffect(() => {
    trackOpen()
  }, [trackOpen])

  // Each displayItem returns assignedTo as an array of users
  // We will flatten this to a single comma seperate string of Usernames
  // to show in the table
  const formattedDisplayItems = []
  displayItems.forEach(displayItem => {
    if (displayItem.assignedTo) {
      formattedDisplayItems.push({
        ...displayItem,
        assignedTo: convertAssignedToArrayToString(displayItem.assignedTo)
      })
    } else {
      // NOTE: Event keys with values 0 and negative values are used by pagination so make sure we keep these
      // Also possible that events could be returned with no value for assignedTo (instead of returning an empty array)
      formattedDisplayItems.push({
        ...displayItem
      })
    }
  })

  const [
    checkedEventKeys,
    setCheckedEventKey,
    setBulkCheckedEventKey,
    clearCheckedEvents
  ] = useCheckboxStates()

  const params = useParams()
  const tableUrl = useMemo(() => {
    return select(moduleName, {
      delivery: () =>
        generatePath(DELIVERY_TABLE, {
          ...params,
          tab: DELIVERY_TABS.TABLE
        }),
      waste: () =>
        generatePath(WASTE_TABLE, {
          ...params,
          tab: WASTE_TABS.TABLE
        })
    })
  }, [moduleName, params])

  const noEventsCTA = select(moduleName, {
    delivery: 'view deliveries',
    waste: 'view collections'
  })

  const handleSelectMenuClick = e => {
    switch (e.key) {
      // Select all records including ones not currently loaded
      case 'selectAll':
        setCheckAll(true)
        setBulkCheckedEventKey(
          allEvents.map(item => item.eventKey),
          true
        )
        break
      case 'selectAllLoaded': {
        // Select currently loaded/displayed events that are not closed
        setBulkCheckedEventKey(
          items
            .filter(item => item.status !== 'Closed')
            .map(item => item.eventKey),
          true
        )
        break
      }
      case 'deselectAll':
        clearCheckedEvents()
        break
    }
  }

  const SESSION_STORE_KEY = `${moduleName}-events-filters`
  const handleResetFilters = () => {
    sessionStorage.removeItem(SESSION_STORE_KEY)
    updateSourceTypeFilters(EMPTY_ARRAY)
    updateStatusFilters(EMPTY_ARRAY)
    updateDescriptionFilters(EMPTY_ARRAY)
    updateTradeContractorFilters(EMPTY_ARRAY)
    updateSupplierFilters(EMPTY_ARRAY)
    updateSubcontractorFilters(EMPTY_ARRAY)
    updateWasteCarrierFilters(EMPTY_ARRAY)
    updateAssigneeIdsFilters(EMPTY_ARRAY)
  }
  const selectMenu = (
    <Menu onClick={handleSelectMenuClick}>
      <Menu.Item key="selectAll">Select all</Menu.Item>
      <Menu.Item key="selectAllLoaded">
        Select {formattedDisplayItems.length - 1} events
      </Menu.Item>
      <Menu.Item key="deselectAll">Deselect all</Menu.Item>
    </Menu>
  )

  useEffect(() => {
    clearCheckedEvents()
  }, [
    statusFilters,
    sourceTypeFilters,
    descriptionFilters,
    tradeContractorFilters,
    supplierFilters,
    subcontractorFilters,
    wasteCarrierFilters,
    clearCheckedEvents
  ])

  // Update select all indicator based on number selected
  useEffect(() => {
    if (checkedEventKeys.list.length === 0) {
      setIndeterminate(false)
      setCheckAll(false)
    } else if (checkedEventKeys.list.length < count) {
      setIndeterminate(true)
      setCheckAll(false)
    } else {
      setIndeterminate(false)
      setCheckAll(true)
    }
  }, [checkedEventKeys, count])

  // Create an object whose keys is the eventKey
  // and value is an object containing
  //   the recordId, and
  //   an array of who is assigned to that record
  useEffect(() => {
    const tempMapping = {}
    // Populate from All Events then
    // overwrite from loaded items to populate assignedTo values
    if (allEvents && allEvents.length > 0) {
      allEvents.forEach(event => {
        tempMapping[event.eventKey] = {
          recordId: event.recordId,
          assignedTo: []
        }
      })
    }
    items.forEach(item => {
      tempMapping[item.eventKey] = {
        recordId: item.recordId,
        assignedTo: item.assignedTo
      }
    })
    setEventRecordMapping(tempMapping)
  }, [allEvents, items])

  // Check if 1 or more checkedRecordIds is not in loaded data
  useEffect(() => {
    const loadedItemIds = items.map(item => item.eventKey)
    setSelectedEventsContainUnloaded(
      checkedEventKeys.list.some(element => {
        return !loadedItemIds.includes(element)
      })
    )
  }, [checkedEventKeys.list, items])

  // Load Project Users in to Redux state
  useEffect(() => {
    if (roleLoaded && isProjectUser) {
      dispatch(projectUsersLoad(accountId, projectId, false))
    }
  }, [accountId, dispatch, isProjectUser, projectId, roleLoaded])

  // Reformat ProjectUsers for use in AssigneeIds filter
  useEffect(() => {
    const tempArray = Object.values(projectUsers.users)
      .map(({ userId: key, displayName: label }) => ({
        key,
        label
      }))
      .sort((a, b) => {
        const fa = a.label.toLowerCase()
        const fb = b.label.toLowerCase()

        if (fa < fb) {
          return -1
        } else if (fa > fb) {
          return 1
        }
        return 0
      })

    setAssignableUsers([...otherAssignedToFilters, ...tempArray])
  }, [projectUsers])

  const hideIfReportRow = useCallback(
    (item, index) => ({
      colSpan:
        index > 0 && item.sourceType.toLowerCase() === sourceTypeReport ? 0 : 1
    }),
    []
  )

  const descriptionColSpanIfReportRow = useCallback(
    (item, index) => ({
      colSpan:
        index > 0 && item.sourceType.toLowerCase() === sourceTypeReport ? 2 : 1
    }),
    []
  )

  const renderDescription = (text, item, index) => {
    if (index === 0 || !item.subDescription) {
      return text
    }
    return (
      <div>
        <div>{text}</div>
        <div className="subText">{item.subDescription}</div>
      </div>
    )
  }

  const columns = []
  if (!isMobile) {
    columns.push({
      title: '',
      dataIndex: 'eventKey',
      className: 'eventKey',
      width: '60px',
      render: (text, record, index) =>
        index == 0 ? (
          allEvents && allEvents.length > 0 ? (
            <Dropdown overlay={selectMenu}>
              <Space>
                <Checkbox
                  style={{ pointerEvents: 'none' }}
                  indeterminate={indeterminate}
                  checked={checkAll}
                ></Checkbox>
                <DownOutlined />
              </Space>
            </Dropdown>
          ) : (
            ''
          )
        ) : (
          <Checkbox
            checked={checkedEventKeys.byKey[text]}
            onChange={e => {
              setCheckedEventKey(text, e.target.checked)
            }}
            disabled={record.status === 'Closed'}
          />
        )
    })
  }
  columns.push({
    title: () => <div className="table-header-text">Date</div>,
    dataIndex: 'createdAt',
    className: 'createdAt',
    width: isMobile || isSmall ? '7rem' : '8%',
    render: (text, _, index) => {
      if (index == 0) {
        return ''
      }
      return DateTime.fromISO(text, { zone: 'local' }).toLocaleString(
        isMobile || isSmall
          ? {
              timeZone: projectTimeZone,
              year: '2-digit',
              month: 'numeric',
              day: 'numeric'
            }
          : DateFormatFull(projectTimeZone),
        { locale: Intl.DateTimeFormat().resolvedOptions().locale }
      )
    }
  })
  if (!isMobile) {
    columns.push({
      title: () => <div className="table-header-text">Type</div>,
      dataIndex: 'sourceType',
      className: 'sourceType',
      width: '100px',
      render: (text, _, index) =>
        index == 0 && !readOnly ? (
          <EventsFilter
            value={sourceTypeFilters}
            setValue={updateSourceTypeFilters}
            options={Object.values(EVENTS.SOURCE_TYPE)}
            loading={false}
            filterName={'sourceType'}
            tableId={tableId}
            testId={'eventsTypeFilter'}
          />
        ) : (
          (EVENTS.SOURCE_TYPE[text]?.label ?? text)
        )
    })
  }
  if (!isMobile) {
    columns.push({
      title: () => <div className="table-header-text">Status</div>,
      dataIndex: 'status',
      className: 'status',
      width: '85px',
      render: (text, _, index) =>
        index == 0 && !readOnly ? (
          <EventsFilter
            value={statusFilters}
            setValue={updateStatusFilters}
            options={Object.values(EVENTS.STATUS)}
            loading={false}
            filterName={'status'}
            tableId={tableId}
            testId={'eventsStatusFilter'}
          />
        ) : (
          (EVENTS.STATUS[text]?.label ?? '')
        )
    })
  }
  if (!isMobile) {
    columns.push({
      title: () => <div className="table-header-text">Description</div>,
      dataIndex: 'description',
      className: 'description',
      width: '20%',
      render: (text, item, index) =>
        index == 0 && !readOnly ? (
          <EventsFilter
            value={descriptionFilters}
            setValue={updateDescriptionFilters}
            options={description.map(desc => EVENTS.item(desc))}
            loading={loadingFilter}
            filterName={'description'}
            tableId={tableId}
            testId={'eventsDescriptionFilter'}
          />
        ) : (
          renderDescription(text, item, index)
        ),
      onCell: descriptionColSpanIfReportRow
    })
  } else {
    columns.push({
      title: () => <div className="table-header-text">Description</div>,
      dataIndex: 'description',
      className: 'description',
      render: renderDescription,
      onCell: descriptionColSpanIfReportRow
    })
  }
  if (!isMobile && moduleName.toLowerCase() == 'delivery') {
    columns.push(
      {
        title: () => <div className="table-header-text">Trade Contractor</div>,
        dataIndex: 'tradeContractor',
        className: 'tradeContractor',
        width: '12.5%',
        render: (text, _, index) =>
          index == 0 && !readOnly ? (
            <EventsFilter
              value={tradeContractorFilters}
              setValue={updateTradeContractorFilters}
              options={tradeContractor
                .map(tc => EVENTS.item(tc))
                .sort((a, b) =>
                  a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
                )}
              loading={loadingFilter}
              filterName={'tradeContractor'}
              tableId={tableId}
              testId={'eventsTradeContractorFilter'}
            />
          ) : (
            text
          ),
        onCell: hideIfReportRow
      },
      {
        title: () => <div className="table-header-text">Supplier Company</div>,
        dataIndex: 'supplier',
        className: 'supplier',
        width: '12.5%',
        render: (text, _, index) =>
          index == 0 && !readOnly ? (
            <EventsFilter
              value={supplierFilters}
              setValue={updateSupplierFilters}
              options={supplier
                .map(sup => EVENTS.item(sup))
                .sort((a, b) =>
                  a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
                )}
              loading={loadingFilter}
              filterName={'supplier'}
              tableId={tableId}
              testId={'eventsSupplierFilter'}
            />
          ) : (
            text
          )
      }
    )
  }
  if (!isMobile && moduleName.toLowerCase() == 'waste') {
    columns.push(
      {
        title: () => <div className="table-header-text">Subcontractor</div>,
        dataIndex: 'subcontractor',
        className: 'subcontractor',
        render: (text, _, index) =>
          index == 0 && !readOnly ? (
            <EventsFilter
              value={subcontractorFilters}
              setValue={updateSubcontractorFilters}
              options={subcontractor.map(sc => EVENTS.item(sc))}
              loading={loadingFilter}
              filterName={'subcontractor'}
              tableId={tableId}
              testId={'eventsSubcontractorFilter'}
            />
          ) : (
            text
          ),
        onCell: hideIfReportRow
      },
      {
        title: () => <div className="table-header-text">Carrier</div>,
        dataIndex: 'wasteCarrier',
        className: 'wasteCarrier',
        render: (text, _, index) =>
          index == 0 && !readOnly ? (
            <EventsFilter
              value={wasteCarrierFilters}
              setValue={updateWasteCarrierFilters}
              options={wasteCarrier.map(sc => EVENTS.item(sc))}
              loading={loadingFilter}
              filterName={'wasteCarrier'}
              tableId={tableId}
              testId={'eventsWasteCarrierFilter'}
            />
          ) : (
            text
          )
      }
    )
  }

  columns.push({
    title: () => <div className="table-header-text">Assigned To</div>,
    dataIndex: 'assignedTo',
    className: 'assignedTo',
    width: '12.5%',
    render: (text, _, index) =>
      index == 0 && isProjectUser ? (
        <EventsFilter
          value={assigneeIdsFilters}
          setValue={updateAssigneeIdsFilters}
          options={assignableUsers}
          loading={assignableUsers.length < 1}
          filterName={'assignedTo'}
          tableId={tableId}
          testId={'eventsAssigneeIdsFilter'}
        />
      ) : (
        text
      )
  })

  columns.push({
    title: () => <div className="table-header-text">Links</div>,
    dataIndex: 'sourceType',
    className: 'links',
    width: '12.5%',
    render: (text, record, index) => {
      return index == 0 ? (
        <ResetFilters title="Reset filters" onClick={handleResetFilters}>
          <img src={resetFilter} alt="" />
        </ResetFilters>
      ) : (
        <>
          {text == 'Report' && <ReportEventCell item={record} />}
          {text == 'Infraction' && record.destinationAddress && (
            <NavigateButton item={record} />
          )}
        </>
      )
    }
  })

  // Table Footer for when there are 0 records
  const footer =
    count <= 0 ? (
      <ZeroRecordsFooter>
        <icons.ManageSearch />
        <div>No events matched your search. Try increasing the date range.</div>
      </ZeroRecordsFooter>
    ) : null

  let style = { width: '99%' } // if width is set to 100%, table sometimes flickers
  if (isSmall && !isMobile) {
    style.minWidth = '32.5rem'
  }

  // Infinite Scroll
  useEffect(() => {
    const handleTableScroll = event => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight
      let currentScroll = event.target.scrollTop
      if (currentScroll >= maxScroll - 65) {
        // load more data
        handlePageChange()
      }
    }

    const debouncedHandleTableScroll = debounce(
      event => handleTableScroll(event),
      100,
      {
        leading: true
      }
    )

    const tableContent = document.querySelector('.ant-table-body')
    if (tableContent) {
      // Only apply the Event Listener if there is more data to be loaded
      if (hasNextPage) {
        tableContent.addEventListener('scroll', debouncedHandleTableScroll)
      }
    }

    // Using useEffect return function to remove the Event Listener on unload
    return () => {
      if (tableContent) {
        tableContent.removeEventListener('scroll', debouncedHandleTableScroll)
      }
    }
  }, [handlePageChange, hasNextPage])

  const scrollToTop = () => {
    const tableContent = document.querySelector('.ant-table-body')
    tableContent.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <ActionStrip
        eventKeys={checkedEventKeys.list}
        eventRecordMapping={eventRecordMapping}
        selectedEventsContainUnloaded={selectedEventsContainUnloaded}
        onClose={reloadNeeded => {
          clearCheckedEvents()
          if (reloadNeeded) {
            reload()
          }
        }}
      />
      <Flex.Row amount={0} style={{ width: '99%', alignItems: 'baseline' }}>
        <Title level={2} id="Heading" style={{ marginBottom: '0' }}>
          Events
        </Title>
        <Flex.By amount={1} />
        <SideBar.GlobalDatePicker />
      </Flex.Row>
      <HorizontalDivider style={{ width: '99%' }} />
      {(error || errorFilter) && (
        <Text type="danger">
          There was a problem loading this page, please try again
        </Text>
      )}
      {!error && (
        <>
          <Table
            id={tableId}
            columns={columns}
            dataSource={formattedDisplayItems}
            rowKey="eventKey"
            pagination={false}
            scroll={{ y: 'calc(100vh - 275px)' }}
            sticky
            style={style}
            rowClassName={(record, index) => {
              if (index === 0) {
                if (isProjectUser) {
                  return 'table-row-filter'
                }
                // Hide Filter Row for non-project users
                return 'table-row-filter-hide'
              }
              if (record.status === 'Closed') {
                return 'event-row-closed-light'
              }
              return 'event-row-open-light'
            }}
            loading={{
              spinning: loading,
              indicator: (
                <div>
                  <SmallSpinner />
                </div>
              )
            }}
            locale={{
              emptyText: (
                <Text>
                  No events found, <a href={tableUrl}>{noEventsCTA}</a>
                </Text>
              )
            }}
            onRow={(record, rowIndex) => {
              if (rowIndex === 0) {
                return {}
              }
              return {
                'data-testid': 'EventsTableRow'
              }
            }}
            footer={footer ? () => footer : null}
          />
          {!footer && (
            <TableFooter>
              {showTotalRecords()}
              <ButtonContainer>
                <BTTButton id="BTTButton" onClick={scrollToTop}>
                  Back to top
                  <Spacer.Fine />
                  <icons.ChevronRight size={sizes.SMALL} rotate={-90} />
                </BTTButton>
              </ButtonContainer>
            </TableFooter>
          )}
        </>
      )}
    </>
  )
}

const ZeroRecordsFooter = styled.div`
  display: flex;
  justify-content: center;
`

const TableFooter = styled('div')`
  position: relative;
  width: 99%;
`

const ButtonContainer = styled('div')`
  position: absolute;
  right: 0;
  top: 0.75rem;
`
const BTTButton = styled(ColourButton.Basic)`
  line-height: 1;
  width: auto;
`
const ResetFilters = styled('button')`
  height: 32px;
  width: 32px;
  padding: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #dddddd;
  }
`
EventLog.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object
}
