import { DateTime } from 'luxon'

export const DateFormatFull = (projectTimeZone = 'Europe/London') => {
  return {
    timeZone: projectTimeZone,
    timeZoneName: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
}

export const isoUTCtoIsoWithOffset = (
  value,
  projectTimeZone = 'Europe/London'
) => {
  const dateTime = DateTime.fromISO(value)
  const dateTimeInTZ = dateTime.setZone(projectTimeZone)
  return dateTimeInTZ.toString()
}
