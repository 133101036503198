import React from 'react'
import PropTypes from 'prop-types'

import { sizes } from '@qflow/theme'
import { ColourButton, icons, Label, Spacer } from 'portal/lib/primitives'

export function AddButton({ children = 'Add', ...props }) {
  return (
    <ColourButton.Basic {...props}>
      <icons.Plus size={sizes.SMALL} />

      <Spacer.Fine />

      <Label isChild colour="inherit">
        {children}
      </Label>
    </ColourButton.Basic>
  )
}

AddButton.propTypes = {
  ...ColourButton.Basic.propTypes,
  children: PropTypes.string
}
