import * as actions from './actions'
import produce from 'immer'

const initialState = {
  checked: false,
  userId: null,
  userUuid: null,
  isQualisFlowInternal: null,
  error: null,
  isLoading: false,
  selfLoading: false,
  selfError: null,
  accounts: null
}

const authStart = draft => {
  draft.isLoading = true
  draft.error = null
}

const authSuccess = (draft, action) => {
  draft.checked = true
  draft.userId = action.userId
  draft.userUuid = action.userUuid
  draft.isQualisFlowInternal = action.isQualisFlowInternal
  draft.isLoading = false
}

const selfSuccess = (draft, action) => {
  draft.accounts = action.data
  draft.selfLoading = false
}

const selfLoading = draft => {
  draft.selfLoading = true
  draft.selfError = null
  draft.accounts = null
}

const selfLoadFail = (draft, action) => {
  draft.selfLoading = false
  draft.selfError = action.error
}

const authFail = (draft, action) => {
  draft.checked = true
  draft.error = action.error
  draft.isLoading = false
}

const authLogoutSuccess = draft => {
  draft.checked = true
  draft.isLoading = false
  draft.error = null
}

const authLogoutFail = (draft, action) => {
  draft.error = action.error
  draft.isLoading = false
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actions.SELF_LOAD:
        return selfLoading(draft)
      case actions.SELF_LOADED:
        return selfSuccess(draft, action)
      case actions.SELF_LOAD_FAILURE:
        return selfLoadFail(draft, action)
      case actions.AUTH_LOGIN_START:
      case actions.AUTH_CHECK_STATE:
        return authStart(draft, action)
      case actions.AUTH_CHECK_SUCCESS:
        return authSuccess(draft, action)
      case actions.AUTH_CHECK_FAIL:
        return authFail(draft, action)
      case actions.AUTH_LOGOUT_SUCCESS:
        return authLogoutSuccess(draft, action)
      case actions.AUTH_LOGOUT_FAIL:
        return authLogoutFail(draft, action)
      default:
        return state
    }
  })
}

export { reducer }
