import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Reports({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'} fillRule="evenodd">
        <path d="M7.909.266l6.607 4.295c.174.112.223.345.11.518-.028.044-.066.082-.11.11L7.91 9.484c-.249.162-.57.162-.818 0L.484 5.19C.31 5.077.26 4.844.374 4.671c.028-.044.066-.082.11-.11L7.09.266c.249-.162.57-.162.818 0zm-4.561 3.37c-.172-.115-.405-.07-.521.102-.116.171-.07.404.101.52l4.974 3.355c.172.116.405.071.521-.1.116-.172.07-.405-.101-.521zm2.25-1.5c-.172-.115-.405-.07-.521.102-.116.171-.07.404.101.52l4.974 3.355c.172.116.405.071.521-.1.116-.172.07-.405-.101-.521zM7.527.936c-.172-.116-.405-.07-.52.101-.116.172-.071.405.1.52l1.866 1.259c.172.116.405.07.52-.101.116-.172.071-.405-.1-.52z" />
        <path d="M.175 7.132c.123-.122.311-.294.564-.516 1.35.926 3.465 2.308 6.346 4.146.252.16.575.156.823-.01 2.94-1.98 4.99-3.36 6.15-4.136.312.229.547.404.705.526.099.076.117.217.04.316-.014.019-.032.035-.052.049l-6.833 4.587c-.253.17-.583.17-.836 0L.208 7.48C.105 7.41.077 7.27.146 7.167c.009-.013.018-.024.029-.035z" />
        <path d="M.18 9.758c.12-.116.299-.278.537-.488 1.35.926 3.47 2.32 6.363 4.184.254.163.582.158.831-.013 2.917-2.005 4.955-3.395 6.114-4.17.305.223.54.393.704.509.1.072.125.212.053.314-.016.022-.036.041-.058.056l-6.806 4.57c-.253.17-.583.17-.836 0L.21 10.105c-.103-.07-.13-.209-.061-.312.009-.013.019-.025.03-.036z" />
      </g>
    </svg>
  )
}

Reports.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
