import React from 'react'
import PropTypes from 'prop-types'
import { colours, sizes } from '@qflow/theme'

export function StatusPending({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
      style={{ width: size, height: size }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={colour || colours.CHARCOAL_GREY}
          d="M5.748 5a1 1 0 0 1 .999.967c.136 4.073 2.162 6.508 6.079 7.305.057.014.174.012.174.224V19.5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-14A.5.5 0 0 1 .5 5h5.248zM11.5 17h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm0-3h-10a.5.5 0 1 0 0 1h10a.5.5 0 1 0 0-1zm-5-3h-5a.5.5 0 1 0 0 1h5a.5.5 0 1 0 0-1zm-2-3h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
        />
        <path
          fill={colours.YELLOW}
          d="M14 0a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm-.542 1.99a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5l-.082-.006A.5.5 0 0 0 13.5 7h4a.5.5 0 1 0 0-1h-3.543V2.49a.5.5 0 0 0-.5-.5z"
        />
      </g>
    </svg>
  )
}

StatusPending.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
