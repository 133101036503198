import produce from 'immer'

import {
  PROJECT_USERS_LOAD,
  PROJECT_USERS_LOADED,
  PROJECT_USERS_LOAD_FAILURE
} from './actions'

const initialState = {
  loading: false,
  error: null,
  users: {}
}

export function getKeyFromIds(projectId, userId) {
  return `${projectId}_${userId}`.toLowerCase()
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case PROJECT_USERS_LOAD: {
        draft.loading = true
        draft.error = null
        return
      }

      case PROJECT_USERS_LOADED: {
        const { projectId, memberships, userProfiles } = action
        draft.loading = false
        draft.users = {}

        memberships?.forEach(membership => {
          const userId = membership.userId
          const userProfile = userProfiles.find(p => p.userId == userId)
          const key = getKeyFromIds(projectId, userId)
          if (userProfile) {
            draft.users[key] = {
              userId: userId,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              displayName: userProfile.displayName,
              email: userProfile.email,
              lastActivity: userProfile.lastActivity,
              projectPermissions: membership.projectPermissions,
              notificationsEnabled: userProfile.notificationsEnabled,
              projectSettings: userProfile.projectSettings
            }
          }
        })

        return
      }

      case PROJECT_USERS_LOAD_FAILURE: {
        draft.loading = false
        draft.error = action.error
        return
      }
    }
  })
}
