import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { TokenType, BackgroundType } from 'powerbi-models'
import { generatePath, useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import './BIReport.css'

import { SPACING } from '@qflow/theme'
import { Spinner } from 'portal/lib/primitives'
import { sizes } from '@qflow/theme'

import { Text, icons, Spacer, Flex, Label } from 'portal/lib/primitives'
import { useBIReportLoader } from './useBIReportLoader'
import { DELIVERY, DELIVERY_TABS, ACCOUNT_SETTINGS } from 'portal/routes'
import {
  biReportNavigatedAway as visitedOnce,
  biReportLoad
} from 'portal/store/biReports/actions'
import * as moduleTags from 'portal/lib/constants/moduleConstants'

export function BIEmbededReport() {
  const { accountId, reportType } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, loaded, error, status, biReport, firstVisit } =
    useBIReportLoader()
  const { projects, isLoading: projectsLoading } = useSelector(
    state => state.projects
  )

  if (loading || !loaded || projectsLoading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error, please try reloading the page.</div>
  }

  if (status !== 'success' && firstVisit) {
    if (reportType.toLowerCase() === 'dashboard') {
      if (!projects || projects.length === 0) {
        const uri = generatePath(ACCOUNT_SETTINGS, {
          accountId
        })
        history.push(uri)
      } else {
        const uri = generatePath(DELIVERY, {
          accountId,
          projectId: projects[0].projectId,
          moduleName: moduleTags.DELIVERY.toLowerCase(),
          tab: DELIVERY_TABS.TABLE
        })
        history.push(uri)
      }
    }

    dispatch(visitedOnce(accountId, 'Dashboard'))
    dispatch(visitedOnce(accountId, 'Professional'))
  }

  if (status === 'success') {
    return (
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: biReport.reportId,
          embedUrl: biReport.embedUrl,
          accessToken: biReport.accessToken,
          tokenType: TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: BackgroundType.Transparent
          }
        }}
        eventHandlers={
          new Map([
            [
              'error',
              function (event) {
                if (event.detail.message == 'TokenExpired') {
                  dispatch(biReportLoad(accountId, reportType))
                }
                console.log(event.detail)
              }
            ]
          ])
        }
        cssClassName={'report-style-class'}
      />
    )
  } else {
    return (
      <Container id="NoBIReportDisplayed">
        <InnerContainer>
          <Spacer.Large />
          <Spacer.Large />
          <Spacer.Large />
          <Label isChild size="6rem">
            💡
          </Label>
          <Spacer.Large />
          <Text size={sizes.MEDIUM} isChild>
            {status === 'unavailable'
              ? 'Upgrade your Qflow licence to access the'
              : "You don't currently have access to the "}{' '}
            {reportType.toLowerCase() === 'professional'
              ? 'Pro dashboard'
              : 'dashboard'}
            .
          </Text>
          <Spacer.Medium />
          <Text size={sizes.MEDIUM} isChild>
            Please speak with your Customer Success Manager to find out more
            information, or contact us at{' '}
            <a href="mailto:support@qualisflow.com">support@qualisflow.com</a>
          </Text>

          <Spacer.Medium />
          <Spacer.Large />
          <Workers />
          <Spacer.Tiny />

          <Spacer.Medium />
          <Flex.By amount={1} />
        </InnerContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;

  align-self: center;
  justify-content: center;

  margin-bottom: 2rem;
`

const InnerContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  width: 100%;
  max-width: 60rem;

  height: 100%;
  max-height: 30rem;

  box-sizing: border-box;
  padding: ${SPACING.SMALL};
  padding-top: ${SPACING.MEDIUM};
  padding-bottom: ${SPACING.MEDIUM};
`

const Workers = styled(icons.Workers).attrs(() => ({
  style: undefined
}))`
  display: flex;
  flex: 0 0 auto;

  width: 45%;
  height: auto;
`
