import produce from 'immer'
import _ from 'lodash'

import {
  RECORD_INFRACTIONS_LOAD,
  RECORD_INFRACTIONS_LOAD_SUCCESS,
  RECORD_INFRACTIONS_LOAD_FAILURE,
  RECORD_INFRACTIONS_CLEAR
} from './actions'

const initialState = {
  record: {
    loading: false,
    error: null,
    list: [],
    byFieldName: {}
  }
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case RECORD_INFRACTIONS_LOAD: {
        draft.record.loading = true
        draft.record.error = null
        return
      }
      case RECORD_INFRACTIONS_LOAD_SUCCESS: {
        draft.record.loading = false
        draft.record.error = null

        draft.record.list = action.infractions
        draft.record.byFieldName = _.keyBy(action.infractions, i => i.fieldName)
        return
      }
      case RECORD_INFRACTIONS_LOAD_FAILURE: {
        draft.record.loading = false
        draft.record.error = action.error
        return
      }
      case RECORD_INFRACTIONS_CLEAR: {
        draft.record.loading = false
        draft.record.error = null
        draft.record.list = []
        return
      }
    }
  })
}

export { reducer }
