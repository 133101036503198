import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Spacer } from 'portal/lib/primitives/layout'
import { OptionButton, TrayItemContainer } from './primitives'
import { Label } from 'portal/lib/primitives/text'
import { usePassback, useResponsive } from 'portal/lib/hooks'
import { SPACING } from '@qflow/theme'

export function NavDropdownOption({
  label,
  Icon,
  uri = '#',
  onClick,
  onClickPassbackValue,
  ...props
}) {
  const handleClick = usePassback(onClick, onClickPassbackValue)

  const { isMobile } = useResponsive()

  return (
    <TrayItemContainer>
      <StyledLink {...props} to={uri} onClick={handleClick}>
        <OptionButton
          forwardedAs="div"
          css={ButtonCSS}
          mobilePadding={isMobile}
        >
          {Icon && <Icon />}

          {Icon && <Spacer.Tiny />}

          <Label isChild noWrap>
            {label}
          </Label>
        </OptionButton>
      </StyledLink>
    </TrayItemContainer>
  )
}

NavDropdownOption.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  uri: PropTypes.string,
  onClickPassbackValue: PropTypes.any,
  onClick: PropTypes.func
}

const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`

const ButtonCSS = css`
  width: 100%;
  padding: 0.5rem 1rem;

  ${({ mobilePadding }) =>
    mobilePadding &&
    css`
      padding-top: ${SPACING.SMALL};
      padding-bottom: ${SPACING.SMALL};
    `}
`
