import produce from 'immer'

import {
  BIREPORT_LOAD,
  BIREPORT_LOADED,
  BIREPORT_LOAD_FAILURE,
  BIREPORT_HAD_FIRST_VISIT
} from './actions'

const initialState = {}

export const initialKeyState = Object.freeze({
  loading: false,
  loaded: false,
  error: null,
  biReport: null,
  status: null,
  firstVisit: true,
  reportRendering: false
})

export function getKeyFromIds(accountId, reportType) {
  return `${accountId}_${reportType}`.toLowerCase()
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    function produceBIReport(mutator) {
      const { accountId, reportType } = action
      const key = getKeyFromIds(accountId, reportType)
      if (!draft[key]) {
        draft[key] = { ...initialKeyState }
      }

      mutator(draft[key])
    }

    switch (action.type) {
      case BIREPORT_LOAD: {
        produceBIReport(draft => {
          draft.loading = true
          draft.loaded = false
          draft.error = null
          draft.biReport = null
          draft.status = null
        })
        return
      }

      case BIREPORT_LOADED: {
        produceBIReport(draft => {
          draft.loading = false
          draft.loaded = true
          draft.error = null
          draft.biReport = action.biReport
          draft.status = action.status
        })
        return
      }

      case BIREPORT_LOAD_FAILURE: {
        produceBIReport(draft => {
          draft.loading = false
          draft.loaded = true
          draft.error = action.error
          draft.biReport = null
          draft.status = null
        })
        return
      }

      case BIREPORT_HAD_FIRST_VISIT: {
        produceBIReport(draft => {
          draft.firstVisit = false
        })
        return
      }
    }
  })
}
