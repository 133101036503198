import React, { useMemo } from 'react'

import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { SPACING, colours } from '@qflow/theme'
import { Text } from 'portal/lib/primitives/text'
import { Spacer, Flex } from 'portal/lib/primitives/layout'

export const CardsContainer = styled.div`
  display: flex;
  flex: 1 0 auto;

  flex-direction: column;
`

export const Card = styled.div`
  padding: ${SPACING.TINY};

  border-bottom: 1px solid ${colours.WHITE_10};
  :last-child {
    border-bottom: none;
  }
`

const CardContentFlow = styled.div`
  display: flex;
  flex-direction: column;
`

const CardContentItemContainer = styled.div`
  margin-right: ${SPACING.FINE};
  margin-top: ${SPACING.TINY};

  ${({ removed }) =>
    removed &&
    css`
      text-decoration: line-through;
      opacity: 0.5;
    `}
`

const ControlsContainer = styled(CardContentItemContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-top: ${SPACING.SMALL};
`

const TdInner = styled.div`
  display: flex;
  align-items: flex-start;
`

export const AutoCard = ({
  index,
  item,
  titleKey,
  headers,
  controls,
  removed = false,
  renderIcon = null,
  ...props
}) => {
  const [title, otherHeaders] = useMemo(() => {
    if (!titleKey || titleKey.length === 0) {
      return [null, headers]
    }

    const index = headers.findIndex(header => header.key === titleKey)
    if (index < 0) {
      console.error(`Field ${titleKey} not found in headers`)
      return [null, headers]
    }

    const otherHeaders = [...headers]
    const title = otherHeaders.splice(index, 1)[0]

    return [title, otherHeaders]
  }, [headers, titleKey])

  const convertObjectArrayToString = objectArray => {
    if (Array.isArray(objectArray)) {
      const str = objectArray
        .filter(a => a.value !== 0)
        .map(a => `${Object.values(a).join(' ')} `)
        .join(', ')
        .replace('CubicYard', 'Cubic yard')
        .replace('Pound', 'lbs')
      return str
    }
    return objectArray
  }

  return (
    <Card {...props}>
      {title && (
        <CardContentItemContainer removed={removed}>
          <TdInner>
            {renderIcon?.(title.key) && (
              <>
                {renderIcon?.(title.key)}
                <Spacer.Fine />
              </>
            )}

            <Text bold>{item[title.key]}</Text>
          </TdInner>
        </CardContentItemContainer>
      )}

      <CardContentFlow>
        {otherHeaders
          .filter(header => {
            if (renderIcon?.(header.key)) {
              return true
            }

            return typeof header.render === 'function'
              ? Boolean(header.render(item, index))
              : Boolean(item[header.key])
          })
          .map(header => (
            <CardContentItemContainer key={header.key} removed={removed}>
              <TdInner>
                <Text dim>{header.label}:</Text>

                <Spacer.Fine />
                <Flex.By amount={1} />

                {renderIcon?.(header.key)}

                <Spacer.Fine />

                {typeof header.render === 'function' ? (
                  header.render(item, index)
                ) : (
                  <Text right>
                    {convertObjectArrayToString(item[header.key])}
                  </Text>
                )}
              </TdInner>
            </CardContentItemContainer>
          ))}
      </CardContentFlow>

      {controls && <ControlsContainer>{controls}</ControlsContainer>}
    </Card>
  )
}

AutoCard.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.any.isRequired,
  titleKey: PropTypes.string,
  headers: PropTypes.array.isRequired,
  controls: PropTypes.node,
  removed: PropTypes.bool,
  renderIcon: PropTypes.func
}
