// Check auth

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE'
export const authCheckState = ({ interactOnUnauthorised = false } = {}) => {
  return {
    type: AUTH_CHECK_STATE,
    interactOnUnauthorised
  }
}

export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS'
export const authCheckSuccess = (userId, userUuid, isQualisFlowInternal) => {
  return {
    type: AUTH_CHECK_SUCCESS,
    userId,
    userUuid,
    isQualisFlowInternal
  }
}

export const AUTH_CHECK_FAIL = 'AUTH_CHECK_FAIL'
export const authCheckFail = error => {
  return {
    type: AUTH_CHECK_FAIL,
    error: error
  }
}

//
// Login

export const AUTH_LOGIN_START = 'AUTH_LOGIN'
export const loginStart = ({ firstSignIn = false, signInHint = null } = {}) => {
  return {
    type: AUTH_LOGIN_START,
    firstSignIn,
    signInHint
  }
}

//
// Logout

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const logoutStart = () => {
  return {
    type: AUTH_LOGOUT
  }
}

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const logoutSuccess = () => {
  return {
    type: AUTH_LOGOUT_SUCCESS
  }
}

export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL'
export const logoutFail = error => {
  return {
    type: AUTH_LOGOUT_FAIL,
    error: error
  }
}

export const SELF_LOAD = 'SELF_LOAD'
export const selfLoad = () => ({
  type: SELF_LOAD
})

export const SELF_LOADED = 'SELF_LOADED'
export const selfLoaded = data => ({
  type: SELF_LOADED,
  data
})

export const SELF_LOAD_FAILURE = 'SELF_LOAD_FAILURE'
export const selfLoadFailure = error => ({
  type: SELF_LOAD_FAILURE,
  error
})
