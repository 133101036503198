import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { DateRangePicker } from 'portal/lib/primitives/DateRangePicker'
import { datePickerChanged } from 'portal/store/global/actions'
import { selectGlobalDateISO } from 'portal/store/global/selectors'
import { trackMixpanel } from 'portal/store/mixpanel/actions'
import { useModuleIds } from 'portal/lib/hooks'

const selectGlobalDateFunc = selectGlobalDateISO()
export function GlobalDatePicker({
  valueKey = 'global',
  disabled = false,
  visible = true
}) {
  const { moduleName } = useModuleIds()

  const globalValue = useSelector(selectGlobalDateFunc)
  const [localValue, setLocalValue] = useState(globalValue)

  const dispatch = useDispatch()
  const commitValueToGlobalStore = useCallback(() => {
    dispatch(datePickerChanged(localValue, valueKey))
    dispatch(
      trackValueChanged(
        moduleName,
        valueKey,
        localValue.startDate,
        localValue.endDate
      )
    )
  }, [dispatch, localValue, moduleName, valueKey])

  return (
    <DateRangePicker
      value={localValue}
      onChange={setLocalValue}
      onClosed={commitValueToGlobalStore}
      disabled={disabled}
      visible={visible}
    />
  )
}

GlobalDatePicker.propTypes = {
  disabled: PropTypes.bool,
  valueKey: PropTypes.string
}

function trackValueChanged(module_type, key, startDate, endDate) {
  if (endDate == null) {
    endDate = startDate.clone()
    endDate.add(1, 'days')
  }
  return trackMixpanel('frontend_global_date_changed', {
    key,
    module_type,
    start: startDate,
    end: endDate,
    length_days: moment(endDate).diff(moment(startDate), 'days')
  })
}
