import { useRef, useLayoutEffect, useState } from 'react'
import { css } from 'styled-components'

const DEFAULT = {
  y: 0,
  x: 0,
  required: false
}

function getRefDimensions(ref) {
  const { width, height, x, y, left, top } = ref.current.getBoundingClientRect()

  return {
    width,
    height,
    x: x === undefined ? left : x,
    y: y === undefined ? top : y
  }
}

function getContainerEnds(ref) {
  if (ref && ref.current) {
    const { width, height, x, y } = getRefDimensions(ref)

    return [x + width, y + height]
  } else {
    const width = window.innerWidth
    const height = window.innerHeight

    return [width, height]
  }
}

export const useModalOffset = (
  visible = true,
  {
    padding = 10,
    awaitLaggyLayout = false,
    containerRef = null,
    xAxis = true,
    yAxis = true
  } = {}
) => {
  const modalRef = useRef()

  const [offset, setOffset] = useState(DEFAULT)

  useLayoutEffect(() => {
    let run = true

    const call = awaitLaggyLayout ? cb => setTimeout(cb, 0) : cb => cb()
    call(() => {
      if (!run) {
        return
      }

      if (!visible || !modalRef.current) {
        setOffset(DEFAULT)
        return
      }

      const { width, height, x, y } = getRefDimensions(modalRef)

      const [containerRight, containerBottom] = getContainerEnds(containerRef)

      const xOffset = containerRight - (x + width)
      const yOffset = containerBottom - (y + height)

      setOffset({
        x: xAxis && xOffset < 0 ? xOffset - padding : 0,
        y: yAxis && yOffset < 0 ? yOffset - padding : 0,
        required: (xAxis && xOffset < 0) || (yAxis && yOffset < 0)
      })
    })

    return () => {
      run = false
    }
  }, [awaitLaggyLayout, containerRef, padding, visible, xAxis, yAxis])

  return {
    modalRef,
    offset
  }
}

export const modalOffsetCSS = css`
  ${({ modalOffset: { required, x, y } = {} }) =>
    !!required &&
    css`
      transform: translate(${x}px, ${y}px);
    `}
`
