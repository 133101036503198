import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'

import { colours, shadows, sizes, useTheme } from '@qflow/theme'
import { Cross } from 'portal/lib/primitives/icons'
import { Label } from 'portal/lib/primitives/text'
import { Clickable } from 'portal/lib/primitives/buttons'

import { Portal } from './Portal'

function stopEventPropagation(e) {
  e.stopPropagation()
}

export function Modal({
  children,
  title,
  show,
  closeClick,
  onCloseFinished,
  IconLeft,
  centred = false,
  backfillClickable = true,
  css = null,
  ...props
}) {
  const theme = useTheme()

  const handleBackfillClick = useCallback(() => {
    if (backfillClickable) {
      closeClick()
    }
  }, [backfillClickable, closeClick])

  return (
    <Portal>
      <Fade in={show} onExited={onCloseFinished}>
        <BackFill
          id="ModalBackfill"
          onClick={handleBackfillClick}
          centred={centred}
        >
          {centred || <FlexSpacer />}

          <Container {...props} onClick={stopEventPropagation} css={css}>
            <Header>
              <IconWrapper>
                {IconLeft && (
                  <IconLeft size={sizes.LARGER} colour={theme.primary.fg} />
                )}
              </IconWrapper>

              <Title>{title}</Title>

              <IconWrapper>
                <CrossButton id="ModalCloseButton" onClick={closeClick}>
                  <Cross size={sizes.SMALL} colour={theme.primary.fg} />
                </CrossButton>
              </IconWrapper>
            </Header>

            <Body>{children}</Body>
          </Container>
        </BackFill>
      </Fade>
    </Portal>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  show: PropTypes.bool,
  closeClick: PropTypes.func,
  onCloseFinished: PropTypes.func,
  IconLeft: PropTypes.elementType,
  centred: PropTypes.bool,
  backfillClickable: PropTypes.bool,
  css: PropTypes.any
}

const BackFill = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ centred }) => (centred ? 'center' : 'flex-start')};
  align-items: center;

  background-color: ${({ theme }) => theme.modal.backfill};

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  overflow: scroll;
`

const FlexSpacer = styled.div`
  max-height: 2rem;
  flex: 1 1 auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  min-width: 18rem;

  margin: 0.5rem;
  border-radius: 0.3rem;
  background-color: ${({ theme }) => theme.general.bg};

  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.modal.border};
  ${shadows.HIGH}

  ${({ css }) => css}
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;

  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  overflow: hidden;

  background-color: ${colours.WHITE};
  color: ${({ theme }) => theme.primary.fg};
`
const Title = styled(Label)`
  margin: 0;
  font-size: ${sizes.MEDIUM};
`

const CrossButton = styled(Clickable)`
  flex: 1 1 auto;
  border-radius: 0;
  min-height: 100%;
`

const IconWrapper = styled.div`
  width: 3rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Body = styled.div`
  color: ${({ theme }) => theme.primary.fg};

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${colours.WHITE_10};

  padding: 0;

  flex: 0 0 auto;
`
