import styled, { css } from 'styled-components'

const withCSS = css`
  ${({ css }) => css}
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${({ amount, zeroBasis }) => css`
    flex: ${amount} ${amount} ${zeroBasis ? '0' : 'auto'};
  `}

  align-items: ${({ verticalCentre }) =>
    verticalCentre ? 'center' : 'initial'};

  justify-content: ${({ horizontalCentre }) =>
    horizontalCentre ? 'center' : 'initial'};

  ${withCSS}
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${({ amount, zeroBasis }) => css`
    flex: ${amount} ${amount} ${zeroBasis ? '0' : 'auto'};
  `}

  justify-content: ${({ verticalCentre }) =>
    verticalCentre ? 'center' : 'initial'};

  align-items: ${({ horizontalCentre }) =>
    horizontalCentre ? 'center' : 'initial'};

  ${withCSS}
`

const By = styled.div`
  display: flex;
  ${({ amount, zeroBasis }) => css`
    flex: ${amount} ${amount} ${zeroBasis ? '0' : 'auto'};
  `}

  ${withCSS}
`

const Centred = styled(By)`
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Flex = {
  Row,
  Col,
  By,
  Centred
}
