import produce from 'immer'

import {
  MASS_UPLOAD_START,
  MASS_UPLOAD_SUCCESS,
  MASS_UPLOAD_FAILURE,
  MASS_UPLOAD_RESET,
  MASS_UPLOAD_CONFIRM_SUCCESS,
  MASS_UPLOAD_CONFIRM_FAILURE,
  MASS_UPLOAD_CONFIRM_START
} from './actions'

/**
 * @enum
 */
export const STATUS = {
  READY: 'ready',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  CONFIRMING: 'confirming',
  CONFIRMED: 'confirmed'
}

const initialState = {
  status: STATUS.READY,
  error: null,
  data: {
    id: null,
    outputs: [],
    summary: {
      canUpdate: false,
      validCount: 0,
      invalidCount: 0
    }
  }
}

export const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MASS_UPLOAD_RESET: {
        return initialState
      }

      //
      // Upload

      case MASS_UPLOAD_START: {
        Object.assign(draft, initialState)
        draft.status = STATUS.UPLOADING

        return
      }

      case MASS_UPLOAD_FAILURE: {
        const { error } = action

        draft.status = STATUS.UPLOADED
        draft.error = error ?? true

        return
      }

      case MASS_UPLOAD_SUCCESS: {
        const { id, outputs, summary } = action

        draft.status = STATUS.UPLOADED
        draft.error = null
        draft.data.id = id ?? ''
        draft.data.outputs = outputs ?? []
        draft.data.summary = summary ?? initialState.data.summary

        return
      }

      //
      // Confirmation

      case MASS_UPLOAD_CONFIRM_START: {
        draft.status = STATUS.CONFIRMING

        return
      }

      case MASS_UPLOAD_CONFIRM_SUCCESS: {
        draft.status = STATUS.CONFIRMED

        return
      }

      case MASS_UPLOAD_CONFIRM_FAILURE: {
        const { error } = action

        draft.status = STATUS.CONFIRMED
        draft.error = error ?? true

        return
      }
    }
  })
}
