import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Premium({ size = sizes.LARGER, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      style={{ width: size, height: size }}
      {...props}
    >
      <g fill={colour || 'currentColor'}>
        <path d="M7.325 1.05522L1.49167 3.64688C0.891667 3.91355 0.5 4.51355 0.5 5.17189V9.08855C0.5 13.7136 3.7 18.0385 8 19.0886C12.3 18.0385 15.5 13.7136 15.5 9.08855V5.17189C15.5 4.51355 15.1083 3.91355 14.5083 3.64688L8.675 1.05522C8.25 0.863552 7.75 0.863552 7.325 1.05522ZM5.74167 13.4969L3.58333 11.3386C3.25833 11.0136 3.25833 10.4886 3.58333 10.1636C3.90833 9.83855 4.43333 9.83855 4.75833 10.1636L6.33333 11.7302L11.2333 6.83022C11.5583 6.50522 12.0833 6.50522 12.4083 6.83022C12.7333 7.15522 12.7333 7.68022 12.4083 8.00522L6.91667 13.4969C6.6 13.8219 6.06667 13.8219 5.74167 13.4969Z" />
      </g>
    </svg>
  )
}

Premium.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
