import React, { Component } from 'react'
import { PageContainer } from 'portal/pages/PageContainer'
import { LightThemeProvider } from '@qflow/theme'

import { AccountSettingsTable } from 'portal/components/Settings'
import { Sidebar } from 'portal/components/Sidebar'

class AccountSettings extends Component {
  render() {
    return (
      <PageContainer id="AccountSettingsPage">
        <LightThemeProvider>
          <Sidebar />
          <AccountSettingsTable />
        </LightThemeProvider>
      </PageContainer>
    )
  }
}

export default AccountSettings
