import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Modal, Tooltip } from 'antd'
import styled from 'styled-components'
import { colours } from '@qflow/theme'

import { useModuleIds } from 'portal/lib/hooks'
import { ColourButton, icons } from 'portal/lib/primitives'
import { trackMixpanel } from 'portal/store/mixpanel/actions'

export function UpgradeToPro() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { moduleName } = useModuleIds()
  const { accountId, projectId } = useParams()

  const UPGRADE_MODAL_OPEN = 'frontend_record_details_upgrade_modal_open'
  const trackUpgradeModalOpened = useCallback(() => {
    dispatch(
      trackMixpanel(UPGRADE_MODAL_OPEN, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  const UPGRADE_MODAL_LINK_CLICKED =
    'frontend_record_details_upgrade_modal_link_clicked'
  const trackUpgradeModalLinkClick = useCallback(() => {
    dispatch(
      trackMixpanel(UPGRADE_MODAL_LINK_CLICKED, {
        record_type: moduleName,
        project_id: projectId,
        account_id: accountId
      })
    )
  }, [accountId, dispatch, moduleName, projectId])

  const showModal = () => {
    trackUpgradeModalOpened()
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleCTAClick = () => {
    trackUpgradeModalLinkClick()
    handleCancel()
  }

  const tooltipText = (
    <p style={{ marginBottom: '0' }}>
      <b>Premium Feature</b>
      <br />
      Click the icon for more information.
    </p>
  )

  const tooltipStyles = {
    borderRadius: '5px',
    padding: '11px 13px',
    color: colours.CORE_NAVY,
    boxShadow: '0px 2px 8px 0px #00000038'
  }

  return (
    <>
      <Tooltip
        title={tooltipText}
        overlayInnerStyle={tooltipStyles}
        color={colours.WHITE}
        placement="right"
      >
        <icons.GoldBadge onClick={showModal} title="Premium Feature" />
      </Tooltip>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        width={400}
        footer={null}
        bodyStyle={{ padding: '27px' }}
        zIndex={20000}
      >
        <QLogoWrapper>
          <icons.QLogo size={'24px'} />
        </QLogoWrapper>
        <h2>Unlock this feature with an upgrade</h2>
        <p>
          It looks like you haven't purchased access to this feature yet. To
          upgrade your licence, click below to contact our team and discover our
          complete range of features.
        </p>
        <a
          href="https://qualisflowhelp.zendesk.com/hc/en-gb/requests/new"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <ColourButton.Action
            key="submit"
            type="button"
            style={{ width: '100%', marginTop: '1.5rem' }}
            onClick={handleCTAClick}
          >
            Get in touch
          </ColourButton.Action>
        </a>
      </Modal>
    </>
  )
}

export const QLogoWrapper = styled.div`
  display: inline-block;
  line-height: 0;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 13px;
  margin: 0 0 1rem;
`
