import React from 'react'
import produce from 'immer'
import { useFormContext } from 'react-hook-form'

import { FormInput, FormItem } from 'portal/components/RecordEditing'
import {
  CheckAddress,
  CheckCertificationScheme,
  CheckConsignmentOrDeliveryNumber,
  CheckDate1isBeforeDate2,
  CheckGivenSupplierName,
  CheckNotes,
  CheckReceiverOrSupplierOrSenderOrCarrierReference,
  CheckTradeContractor,
  CheckVehicleReg
} from 'portal/lib/functions/SimpleDataValidation'
import { Spacer, Input } from 'portal/lib/primitives'
import { dateValidator } from 'portal/lib/primitives/forms/Validators'
export function Delivery() {
  const form = useFormContext()

  return (
    <>
      <FormItem label="Delivery Arrival">
        <FormInput.DateTimeCells
          name="data.deliveryArrivalDate"
          rules={dateValidator(
            form.getValues,
            'data.deliveryArrivalDate',
            true,
            'Delivery Arrival'
          )}
        />
      </FormItem>

      <FormItem label="Delivery Exit">
        <FormInput.DateTimeCells
          name="data.deliveryExitDate"
          rules={dateValidator(form.getValues, 'data.deliveryExitDate')}
          simpleDataValidationRules={v =>
            CheckDate1isBeforeDate2(
              'Delivery Arrival',
              form.getValues('data.deliveryArrivalDate'),
              'Delivery Exit',
              form.getValues('data.deliveryExitDate')
            )
          }
        />
      </FormItem>

      <FormItem label="Delivery ID">
        <FormInput.Text
          label="Delivery ID"
          name="data.deliveryId"
          autoFocus
          rules={{
            required:
              '"Save" is only available if there is a valid Delivery ID. Please enter one before continuing'
          }}
          simpleDataValidationRules={CheckConsignmentOrDeliveryNumber}
        />
      </FormItem>

      <FormItem label="Delivery Address">
        <FormInput.TextArea
          label="Delivery Address"
          name="data.deliveryAddress"
          simpleDataValidationRules={CheckAddress}
        />
      </FormItem>

      <FormItem label="Dispatch Address">
        <FormInput.TextArea
          label="Dispatch Address"
          name="data.givenDispatchAddress"
          simpleDataValidationRules={CheckAddress}
        />
      </FormItem>

      <Spacer.Small />

      <FormItem label="Receiver Reference">
        <FormInput.Text
          label="Receiver Reference"
          name="data.receiverReferenceNumber"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <FormItem label="Trade Contractor">
        <FormInput.Text
          label="Trade Contractor"
          name="data.tradeContractor"
          simpleDataValidationRules={CheckTradeContractor}
        />
      </FormItem>

      <Spacer.Small />

      <FormItem label="Given Supplier Name">
        <FormInput.Text
          label="Given Supplier Name"
          name="data.supplierName"
          simpleDataValidationRules={CheckGivenSupplierName}
        />
      </FormItem>

      <FormItem label="Supplier Reference">
        <FormInput.Text
          label="Supplier Reference"
          name="data.supplierReferenceNumber"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <FormItem label="Given Supplier Certifications">
        <FormInput.Text
          label="Given Supplier Certifications"
          name="data.givenSupplierCertifications"
          simpleDataValidationRules={CheckCertificationScheme}
        />
      </FormItem>

      <FormItem label="Supplier Vehicle Registration">
        <FormInput.Text
          label="Supplier Vehicle Registration"
          name="data.vehicleRegistration"
          simpleDataValidationRules={CheckVehicleReg}
        />
      </FormItem>

      <FormItem label="External Reference">
        <FormInput.Text
          label="External Reference"
          name="data.clientReference"
          simpleDataValidationRules={
            CheckReceiverOrSupplierOrSenderOrCarrierReference
          }
        />
      </FormItem>

      <Spacer.Small />

      <FormItem label="Notes">
        <FormInput.TextArea
          label="Notes"
          name="data.notes"
          simpleDataValidationRules={CheckNotes}
        />
      </FormItem>
    </>
  )
}

Delivery.transformToFormData = (item, projectTimeZone) =>
  produce(item, draft => {
    draft.data.deliveryArrivalDate =
      Input.DateTimeCells.getDateTimeInputValuesFromISOString(
        item.data.deliveryArrivalDate,
        projectTimeZone
      )
    draft.data.deliveryExitDate =
      Input.DateTimeCells.getDateTimeInputValuesFromISOString(
        item.data.deliveryExitDate,
        projectTimeZone
      )
  })

Delivery.transformFromFormData = (formItem, projectTimeZone) =>
  produce(formItem, draft => {
    draft.data.deliveryArrivalDate = Input.DateTimeCells.getISOStringFromValues(
      formItem.data.deliveryArrivalDate,
      projectTimeZone
    )
    draft.data.deliveryExitDate = Input.DateTimeCells.getISOStringFromValues(
      formItem.data.deliveryExitDate,
      projectTimeZone
    )
  })
