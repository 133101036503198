import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Form, Select, Space, Typography } from 'antd'
import { LightThemeProvider } from '@qflow/theme'

import { Sidebar } from 'portal/components/Sidebar'
import { ProjectSettingsTable } from 'portal/components/ProjectSettingsTable'
import { PageContainer } from 'portal/pages/PageContainer'
import { Flex } from 'portal/lib/primitives'
import {
  projectPutSaga,
  projectsGetSaga,
  timeZonesGetSaga
} from 'portal/store/projects/actions'
const { Title, Paragraph } = Typography

export const ProjectSettings = () => {
  const dispatch = useDispatch()
  const { accountId, projectId } = useParams()
  const projects = useSelector(state => state.projects)
  const currentProject = projects.projects?.find(p => p.projectId == projectId)

  useEffect(() => {
    dispatch(timeZonesGetSaga())
  }, [dispatch])

  const timeZoneOptionKeyMap = { displayName: 'label', id: 'value' }
  const timeZoneOptions = []

  if (projects.timeZones) {
    projects.timeZones.forEach(timeZone => {
      let updatedItem = Object.keys(timeZone).map(key => {
        const newKey = timeZoneOptionKeyMap[key]
        return { [newKey]: timeZone[key] }
      })
      timeZoneOptions.push(
        updatedItem.reduce((a, b) => Object.assign({}, a, b))
      )
    })
  }
  const handleTimeZoneChange = value => {
    const updatedProject = { ...currentProject, timeZone: value }
    // Update current project's timezone
    dispatch(projectPutSaga(accountId, projectId, updatedProject))
    // Reload all projects in store.
    // Using setTimeout to ensure this happens after the update
    setTimeout(() => {
      dispatch(projectsGetSaga(accountId))
    }, 1000)
  }

  if (!currentProject) {
    return ''
  }

  const timeZoneFeatureFlag = window.location.hostname !== 'app.qualisflow.com'

  return (
    <PageContainer id="ProjectSettings">
      <LightThemeProvider>
        <Sidebar />
        <Space direction="vertical" size="middle">
          <Flex.Row
            style={{
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Title level={3}>{currentProject.name} Settings</Title>
              <Paragraph>
                View project members. View and edit project access assignments
                and notifications.
              </Paragraph>
            </div>
            {timeZoneFeatureFlag && (
              <Form layout="inline">
                <Form.Item
                  label="Time zone"
                  name="timezone"
                  initialValue={currentProject.timeZone}
                >
                  <Select
                    onChange={handleTimeZoneChange}
                    options={timeZoneOptions}
                    style={{ width: 440 }}
                  />
                </Form.Item>
              </Form>
            )}
          </Flex.Row>
          <ProjectSettingsTable />
        </Space>
      </LightThemeProvider>
    </PageContainer>
  )
}
