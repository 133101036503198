import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { sizes } from '@qflow/theme'
import {
  Container,
  InnerContainer,
  ActionStripCard,
  AssignmentButtonGroup
} from 'portal/components/CommonStyles/ActionStripStyledComponents'
import {
  icons,
  Clickable,
  Text,
  Padding,
  ColourButton,
  Spacer,
  Spinner
} from 'portal/lib/primitives'
import {
  assignRecords,
  unassignRecords,
  dismissEvents
} from 'portal/store/events/actions'
import { useModuleIds, useUserProjectRole } from 'portal/lib/hooks'

import { AssignmentStrip } from './AssignmentStrip'
import { UnassignmentStrip } from './UnassignmentStrip'
import { AssignmentLongRunningOperationModal } from 'portal/lib/sharedComponents/AssignmentLongRunningOperationModal'

export function ActionStrip({
  eventKeys,
  eventRecordMapping,
  selectedEventsContainUnloaded,
  onClose
}) {
  const LONG_RUNNING_WARNING_THRESHOLD = 1000
  const MAX_ASSIGNMENT_LIMIT = 10000

  const dispatch = useDispatch()
  const { accountId, projectId } = useModuleIds()
  const [showAssignmentStrip, setShowAssignmentStrip] = useState(false)
  const [showUnassignmentStrip, setShowUnassignmentStrip] = useState(false)
  const [showLongRunningOperationModal, setShowLongRunningOperationModal] =
    useState(false)
  const { isProjectAdmin } = useUserProjectRole(accountId, projectId)

  const projectUsers = useSelector(state => state.projectUsers)
  const assign = useSelector(state => state.events.assign)
  const unassign = useSelector(state => state.events.unassign)
  const dismissal = useSelector(state => state.events.dismissal)

  // Use selected Events to create a set of unique Records
  const relatedRecords = useMemo(() => {
    const relatedRecordIds = new Set()
    eventKeys.forEach(eventKey => {
      if (eventRecordMapping[eventKey] !== null) {
        relatedRecordIds.add(eventRecordMapping[eventKey].recordId)
      }
    })
    return relatedRecordIds
  }, [eventKeys, eventRecordMapping])

  // Use selected Events to create a set of unique users who are assigned to 1 or more events/records
  const relatedAssignees = useMemo(() => {
    const assignedUsers = {}
    eventKeys.forEach(eventKey => {
      if (
        eventRecordMapping[eventKey] !== null &&
        eventRecordMapping[eventKey].assignedTo
      ) {
        eventRecordMapping[eventKey].assignedTo.forEach(assignee => {
          assignedUsers[assignee.userId] = {
            userId: assignee.userId,
            displayName: assignee.userName,
            email: projectUsers.users[`${projectId}_${assignee.userId}`]?.email
          }
        })
      }
    })
    if (Object.keys(assignedUsers).length === 0) {
      setShowUnassignmentStrip(false)
    }
    return assignedUsers
  }, [eventKeys, eventRecordMapping, projectId, projectUsers.users])

  const handleAssignBtnClick = () => {
    setShowUnassignmentStrip(false)
    setShowAssignmentStrip(!showAssignmentStrip)
  }

  const handleUnassignBtnClick = () => {
    setShowAssignmentStrip(false)
    setShowUnassignmentStrip(!showUnassignmentStrip)
  }

  const handleRecordsAssign = useCallback(
    assignTo => {
      dispatch(
        assignRecords(accountId, projectId, relatedRecords, assignTo, () => {
          setShowLongRunningOperationModal(false)
          onClose?.(true)
        })
      )
      setShowAssignmentStrip(false)
      if (
        relatedRecords.size * assignTo.length >
        LONG_RUNNING_WARNING_THRESHOLD
      ) {
        setShowLongRunningOperationModal(true)
      }
    },
    [accountId, dispatch, relatedRecords, onClose, projectId]
  )

  const handleRecordsUnassign = useCallback(
    unassignUsers => {
      dispatch(
        unassignRecords(
          accountId,
          projectId,
          relatedRecords,
          unassignUsers,
          () => {
            onClose?.(true)
            setShowLongRunningOperationModal(false)
          }
        )
      )
      setShowUnassignmentStrip(false)
      if (
        relatedRecords.size * unassignUsers.length >
        LONG_RUNNING_WARNING_THRESHOLD
      ) {
        setShowLongRunningOperationModal(true)
      }
    },
    [accountId, dispatch, relatedRecords, onClose, projectId]
  )

  const handleEventsDismissal = useCallback(() => {
    dispatch(
      dismissEvents(accountId, projectId, eventKeys, () => {
        onClose?.(true)
      })
    )
  }, [accountId, dispatch, eventKeys, onClose, projectId])

  const working = dismissal.loading || assign.loading || unassign.loading
  const visible = eventKeys?.length > 0
  const showUnassignBtn =
    Object.keys(relatedAssignees).length > 0 || selectedEventsContainUnloaded

  return (
    <Container $visible={visible}>
      {working && <Spinner />}
      <InnerContainer>
        <ActionStripCard>
          <Padding noVertical>
            <Text bold size={sizes.MEDIUM}>
              {eventKeys.length} selected
            </Text>
          </Padding>

          {isProjectAdmin && (
            <AssignmentButtonGroup>
              <ColourButton.Basic
                type="button"
                onClick={handleAssignBtnClick}
                disabled={working}
                data-testid="Assign"
                className={showAssignmentStrip ? 'selected' : ''}
                style={{
                  borderRadius: showUnassignBtn ? '5px 0 0 5px' : '5px'
                }}
              >
                <icons.AddPerson colour="currentColor" size={sizes.MEDIUM} />
                <Spacer.Fine />
                Assign
              </ColourButton.Basic>

              {showUnassignBtn && (
                <ColourButton.Basic
                  type="button"
                  data-testid="Unassign"
                  onClick={handleUnassignBtnClick}
                  disabled={working}
                  className={showUnassignmentStrip ? 'selected' : ''}
                  style={{ borderLeft: 'none', borderRadius: '0 5px 5px 0' }}
                >
                  <icons.RemovePerson
                    colour="currentColor"
                    size={sizes.MEDIUM}
                  />
                  <Spacer.Fine />
                  Unassign
                </ColourButton.Basic>
              )}
            </AssignmentButtonGroup>
          )}

          <Spacer.Small />

          <ColourButton.Caution
            type="button"
            onClick={handleEventsDismissal}
            disabled={working}
          >
            <icons.ClearAll />
            <Spacer.Fine />
            Dismiss
          </ColourButton.Caution>

          <Clickable
            size="small"
            title="Close"
            onClick={() => onClose(false)}
            disabled={working}
          >
            <icons.Cross size={sizes.X_SMALL} colour="inherit" />
          </Clickable>
        </ActionStripCard>

        {isProjectAdmin && showAssignmentStrip && (
          <AssignmentStrip
            users={projectUsers.users}
            onApply={handleRecordsAssign}
            eventCount={eventKeys.length}
            recordCount={relatedRecords.size}
            maxAssignment={MAX_ASSIGNMENT_LIMIT}
          />
        )}

        {isProjectAdmin && showUnassignmentStrip && (
          <UnassignmentStrip
            users={
              selectedEventsContainUnloaded
                ? projectUsers.users
                : relatedAssignees
            }
            onApply={handleRecordsUnassign}
            eventCount={eventKeys.length}
            recordCount={relatedRecords.size}
            maxAssignment={MAX_ASSIGNMENT_LIMIT}
          />
        )}

        {showLongRunningOperationModal && (
          <AssignmentLongRunningOperationModal />
        )}
      </InnerContainer>
    </Container>
  )
}

ActionStrip.propTypes = {
  eventKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func
}
