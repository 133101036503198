import { takeEvery, all, put, call } from 'redux-saga/effects'

import axios from 'portal/utils/axios-msal'
import * as actions from './actions'

function* accountsGetSaga() {
  yield put(actions.accsGetStart())
  try {
    const response = yield call(axios.get, '/accounts/')
    yield put(actions.accsGetSuccess(response.data))
  } catch (error) {
    yield put(actions.accsGetFail(error))
  }
}

export function* watch() {
  yield all([takeEvery(actions.ACCS_GET_SAGA, accountsGetSaga)])
}
