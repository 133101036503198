import { trackMixpanel } from 'portal/store/mixpanel/actions'

const MODULE_SWITCHER_SELECT = 'frontend_module_switcher_select'

export const trackSwitcherSelect = (
  currentModule,
  nextModule,
  accountId,
  projectId
) =>
  trackMixpanel(MODULE_SWITCHER_SELECT, {
    current_module: currentModule,
    next_module: nextModule,
    project_id: projectId,
    account_id: accountId
  })
