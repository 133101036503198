import _ from 'lodash'
import moment from 'moment'

const momentCustomiser = (v1, v2) => {
  if (moment.isMoment(v1)) {
    return v1.isSame(v2)
  }
}

export function isEqualWithMomentSupport(obj1, obj2) {
  return _.isEqualWith(obj1, obj2, momentCustomiser)
}
