import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { colours } from '@qflow/theme'
import { Text } from 'portal/lib/primitives/text'

/**
 * @param {import('react-router-dom').LinkProps} props
 */
export function TextLink({ children, inheritSize = false, ...props }) {
  return (
    <StyledLink {...props}>
      {typeof children === 'string' ? (
        <StyledText inheritSize={inheritSize}>{children}</StyledText>
      ) : (
        children
      )}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  text-decoration-color: ${colours.TEAL};
  color: ${colours.TEAL};

  :hover {
    color: ${colours.TEAL};
  }

  :visited {
    color: initial;
  }
`

const StyledText = styled(Text)`
  color: inherit;

  ${({ inheritSize }) =>
    inheritSize &&
    css`
      font-size: inherit;
    `}
`

TextLink.propTypes = {
  ...Link.propTypes,
  inheritSize: PropTypes.bool
}
