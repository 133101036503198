import React from 'react'
import PropTypes from 'prop-types'
import { sizes } from '@qflow/theme'

export function Download({ size = sizes.MEDIUM, colour, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
      style={{ width: size, height: size }}
    >
      <path
        d="M10.0001 8.00002V10H2.00008V8.00002H0.666748V10C0.666748 10.7334 1.26675 11.3334 2.00008 11.3334H10.0001C10.7334 11.3334 11.3334 10.7334 11.3334 10V8.00002H10.0001ZM9.33341 5.33335L8.39341 4.39335L6.66675 6.11335V0.666687H5.33341V6.11335L3.60675 4.39335L2.66675 5.33335L6.00008 8.66669L9.33341 5.33335Z"
        fill={colour || 'currentColor'}
      />
    </svg>
  )
}

Download.propTypes = {
  colour: PropTypes.string,
  size: PropTypes.string
}
