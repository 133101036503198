import { takeEvery, all, put, call, takeLatest } from 'redux-saga/effects'
import axios from 'portal/utils/axios-msal'
import {
  loadDetails,
  loadDetailsSuccess,
  loadDetailsFailure,
  RECORD_DETAILS_LOAD
} from './actions'

import * as removalActions from 'portal/store/records/removal/actions'
import * as attachmentActions from 'portal/store/records/attachmentUpload/actions'

function getURI(accountId, projectId, moduleName, recordId, isImperial) {
  return (
    '/accounts/' +
    accountId +
    '/projects/' +
    projectId +
    '/modules/' +
    moduleName +
    '/records/' +
    recordId +
    (isImperial ? '/imperial' : '')
  )
}

function* loadDetailsSaga(action) {
  const { accountId, projectId, moduleName, recordId, isImperial } = action
  try {
    const response = yield call(() =>
      axios.get(getURI(accountId, projectId, moduleName, recordId, isImperial))
    )
    yield put(loadDetailsSuccess(response.data))
  } catch (error) {
    yield put(loadDetailsFailure(error))
  }
}

function* reloadDetailsSaga(action) {
  const { accountId, projectId, moduleName, recordId, isImperial } = action
  yield put(loadDetails(accountId, projectId, moduleName, recordId, isImperial))
}

export function* watch() {
  yield all([
    takeLatest(RECORD_DETAILS_LOAD, loadDetailsSaga),
    takeEvery(
      [
        removalActions.RECORDS_REMOVE_RECORD_SUCCESS,
        attachmentActions.UPLOAD_SUCCESS
      ],
      reloadDetailsSaga
    )
  ])
}
