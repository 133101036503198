import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import mixpanel from 'mixpanel-browser'
import { trackMixpanel } from 'portal/store/mixpanel/actions'

import { env } from 'portal/lib/config/env-config'

export function initialiseMixpanel() {
  let API_KEY = env.MIXPANEL_API_TOKEN
  if (!API_KEY || API_KEY.length <= 2) {
    console.warn('Qflow Warning: Mixpanel API key is not set.')
    API_KEY = null
  }

  mixpanel.init(API_KEY || 'DISABLED', {
    batch_requests: true,
    ignore_dnt: true
  })
  if (API_KEY === null) {
    console.warn('Qflow Warning: Mixpanel is disabled')
    mixpanel.disable()
  }

  // Set super properties to be attached on every single event
  mixpanel.register({
    ci_buildnumber: env.CI_BUILDNUMBER,
    ci_buildid: env.CI_BUILDID,
    ci_git_sha: env.CI_GIT_SHA,
    app: 'qflow-portal'
  })
}

export const useMixpanel = () => {
  const dispatch = useDispatch()
  const { accountId, projectId } = useParams()

  function track(name = '', params = {}) {
    dispatch(
      trackMixpanel(name, {
        project_id: projectId,
        account_id: accountId,
        ...params
      })
    )
  }

  return { track }
}

export const withMixpanel = WrapperComponent => {
  return props => {
    const { track } = useMixpanel()
    return <WrapperComponent {...props} track={track} />
  }
}

withMixpanel.withProps = {
  track: PropTypes.func.isRequired
}

export { mixpanel }
