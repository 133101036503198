import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { bugsnag } from 'portal/utils/bugsnag'

import * as reducers from './reducers'
import * as sagas from './sagas'

export function initialiseStore() {
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : null || compose

  const sagaMiddleware = createSagaMiddleware({
    onError: (error, sagaInfo) => {
      console.error('Saga Error', { error, sagaInfo })
      bugsnag?.notify(error, event => {
        event.context = 'sagas'
        event.addMetadata('sagaInfo', sagaInfo)
      })
    }
  })

  const rootReducer = combineReducers(reducers)
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  for (const saga of Object.values(sagas)) {
    sagaMiddleware.run(saga)
  }

  return store
}
