import React from 'react'
import styled from 'styled-components'

import { colours } from '@qflow/theme'
import { Help } from 'portal/lib/primitives/icons'
import { Tiny } from 'portal/lib/primitives/layout/Spacer'
import { sizes } from '@qflow/theme'

export function SideBarHelpLink() {
  return (
    <HelpContainer>
      <HelpAnchor
        href="https://qualisflow.com/qflow-support/"
        target="_blank"
        rel="noopener noreferrer nofollow"
        aria-label="Help, training videos and guidance documents"
      >
        <Help colour={colours.TEAL} size={sizes.MEDIUM} />
        <Tiny />
        Help
      </HelpAnchor>
    </HelpContainer>
  )
}

const HelpAnchor = styled.a`
  display: flex;
  font-size: 0.85rem;
  align-content: center;
  text-decoration: none;

  color: ${({ theme }) => theme.sidebar.navButtonFg};
`

const HelpContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: initial;
  align-items: center;
  margin-left: 0.5rem;
`
