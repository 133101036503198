export const USER_ROLES = Object.freeze({
  1: 'Reader',
  2: 'Contributor',
  3: 'Project Admin'
})

export const ROLE_INFORMATION = {
  'Reader': 'View events and records. Cannot export or edit data.',
  'Contributor': 'View, edit and export events and records.',
  'Project Admin':
    'View, edit and export events and records. View users in an account and edit project role assignments.'
}

export function getRoleFromId(roleId) {
  return USER_ROLES[roleId]
}

export function getIdFromRole(roleName) {
  return Object.keys(USER_ROLES).find(key => USER_ROLES[key] == roleName)
}
