import React from 'react'
import styled, { css } from 'styled-components'
import { SPACING } from '@qflow/theme'

const Spacer = styled.div(({ sizeCSS }) => sizeCSS)

function makeCSS(size) {
  return css`
    min-height: ${size};
    max-height: ${size};
    min-width: ${size};
    max-height: ${size};
  `
}

export const Fine = () => <Spacer sizeCSS={makeCSS(SPACING.FINE)} />

export const Tiny = () => <Spacer sizeCSS={makeCSS(SPACING.TINY)} />

export const Small = () => <Spacer sizeCSS={makeCSS(SPACING.SMALL)} />

export const Medium = () => <Spacer sizeCSS={makeCSS(SPACING.MEDIUM)} />

export const Large = () => <Spacer sizeCSS={makeCSS(SPACING.LARGE)} />
