import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

function calculateDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(calculateDimensions)

  useEffect(() => {
    const handleLayout = debounce(
      () => {
        setDimensions(calculateDimensions())
      },
      50,
      { trailing: true }
    )

    window.addEventListener('resize', handleLayout)
    return () => {
      handleLayout.cancel()
      window.removeEventListener('resize', handleLayout)
    }
  }, [])

  return {
    width: dimensions.width,
    height: dimensions.height
  }
}
